import { createContext } from 'react';
import { ModalData } from './useModal';

export const ModalContext = createContext<{
  modals: ModalData[];
  addModal: any;
  closeModal: any;
}>({
  modals: [],
  addModal: () => {
    /* no action */
  },
  closeModal: () => {
    /* no action */
  },
});
