import React, {
  CSSProperties, ForwardedRef,
  MutableRefObject,
  useEffect, useMemo, useState,
} from 'react';
import styled, { withTheme } from 'styled-components';
import AmCharts from '@amcharts/amcharts3-react';
import omit from 'lodash/omit';
import { randomString } from '../../../utils/string';
import { darkTheme } from '../../../constants/styledTheme';
import AmGraph from 'amcharts/AmGraph';

const VisibilityWrapper = styled.div`
  margin: 0 9px;

  span {
    font-size: 12px;
    text-transform: uppercase;
  }
`;

const defaultStyle = {
  width: '99%',
  height: 300,
};

type Chart3ConfigProps = {
  categoryField: string;
  graphs: AmGraph[];
} & Partial<{
  chartScrollbar: {
    enabled: boolean;
  };
  export: {
    enabled?: boolean;
  }
  legend: {
    enabled?: boolean;
  };
  theme: CSSProperties;
  valueAxes: any[];
}>;

type Chart3Config = Chart3ConfigProps & {
  graphs: AmGraph[];
} & Partial<{
  allLabels: {
    text: string;
    bold: boolean;
    x: string;
    y: string;
    align: string;
  }[];
  chartScrollbar: Chart3ConfigProps['chartScrollbar'] & {
    graph: string;
  };
  dataProvider: any[];
  export: Chart3ConfigProps['export'] & {
    divId?: string;
  };
  legend: Chart3ConfigProps['legend'] & {
    divId?: string;
    graph?: string;
    position?: 'bottom' | 'right';
    width?: string | number;
  };
}>;

type Chart3Ref = { state: { chart: { hideGraph: (graph: AmGraph) => void; showGraph: (graph: AmGraph) => void } } };

type Chart3Props = {
  config: Chart3Config;
  data: any[];
  downloadId: string;
  innerRef: { current?: MutableRefObject<any> };
  theme: typeof darkTheme.amcharts3;
} & Partial<{
  style?: CSSProperties;
}>;

/**
 * Component which contains the dynamic state for the AmChart 3
 *
 * Default style is in src/constants/styledTheme.js
 */
const Chart3 = React.forwardRef((props: Chart3Props, chartRef: ForwardedRef<Chart3Ref>) => {
  const [config, setConfig] = useState<Chart3Config>(props.config);
  const [data, setData] = useState(props.data);
  const legendDiv = useMemo(() => randomString(10, 'aA#'), []);

  useEffect(() => {
    setData(props.data);
  }, [props.data]);
  useEffect(() => {
    setConfig(props.config);
  }, [props.config]);

  const toggleAll = (visible) => {
    if (!chartRef) {
      return;
    }

    Object.keys(config.graphs).forEach((i) => {
      if (visible) {
        (chartRef as MutableRefObject<Chart3Ref>)?.current?.state.chart.showGraph(config.graphs[i] as AmGraph);
      } else {
        (chartRef as MutableRefObject<Chart3Ref>)?.current?.state.chart.hideGraph(config.graphs[i] as AmGraph);
      }
    });

    setConfig((prev) => ({
      ...prev,
      graphs: prev.graphs.map((g) => ({ ...g, hidden: !visible })) as AmGraph[],
    }));
  };

  const style: CSSProperties = { ...defaultStyle, ...props.style };
  if (!style.height || style.height < 10) {
    style.height = defaultStyle.height;
  }

  const options: Chart3Config = { ...config };
  options.dataProvider = data || [{}];
  // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
  options.theme = props.theme.amcharts3;

  const hasHideButton = options.graphs
    && (options.graphs.length >= 10 || options.graphs.find((g) => g.id === 'allhide'));
  if (hasHideButton) {
    options.graphs = options.graphs.filter((g) => g.id !== 'allhide');
  }

  if (!options.legend) {
    // Temporary solution
    options.legend = {
      enabled: true,
    };
  } else if (!Object.prototype.hasOwnProperty.call(options.legend, 'enabled')) {
    options.legend.enabled = true;
  }
  options.legend.divId = `${legendDiv}_legend`;

  if (
    options.chartScrollbar
    && options.chartScrollbar.enabled
    && options.graphs
  ) {
    const graphs: AmGraph[] = [];
    options.graphs.forEach((g) => {
      const newValue = { ...g } as AmGraph;
      if (!newValue.id) {
        newValue.id = newValue.valueField;
      }
      graphs.push(newValue);
    });
    options.graphs = graphs;
    if (!options.chartScrollbar.graph && options.graphs.length > 0) {
      options.chartScrollbar.graph = options.graphs[0].id;
    }
  }

  if (options?.export?.enabled === true) {
    options.export = {
      ...options.export,
      divId: 'dummyId',
    };
  }

  if (data.length === 0) {
    if (!options.valueAxes || !Array.isArray(options.valueAxes)) {
      options.valueAxes = [{}];
    }
    const dataPoint = {
      dummyValue: 0,
    };
    dataPoint[options.categoryField] = '';
    options.dataProvider = [dataPoint];
    options.allLabels = [
      {
        text: 'No data',
        bold: true,
        x: '0',
        y: '50%',
        align: 'center',
      },
    ];
  }

  return (
    <div className="row">
      <div
        className={(options.legend.enabled && options.legend.position === 'right')
          ? `col-${(12 - parseInt((options.legend.width || 6).toString(), 10)).toString()}`
          : 'col-12'}
      >
        <AmCharts.React
          style={style}
          {...omit(props, ['config', 'data', 'style'])}
          options={options}
          ref={chartRef}
        />
      </div>
      <div className={((options.legend.enabled || hasHideButton) && options.legend.position === 'right')
        ? `col-${(options.legend.width || 6).toString()}`
        : 'col-12'}
      >
        <div id={`${legendDiv}_legend`} className="chart-legend" />
        {hasHideButton && (
        <VisibilityWrapper>
          <span
            role="button"
            className="btn"
            onClick={() => toggleAll(false)}
          >
            <i className="fa fa-eye-slash" />
            {' '}
            Hide all
          </span>
          <span
            role="button"
            className="btn"
            onClick={() => toggleAll(true)}
          >
            <i className="fa fa-eye" />
            {' '}
            Show all
          </span>
        </VisibilityWrapper>
        )}
      </div>
    </div>
  );
});

export default withTheme(Chart3);
