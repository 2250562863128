import {
  useState,
  useEffect,
  useRef,
  useCallback,
  MutableRefObject,
} from 'react';

type Props = Partial<{
  defaultSticky: boolean;
  offsetY: number;
  ref: MutableRefObject<any> | undefined;
}>;

const adjustment = 2 * 68;

const useSticky = ({ defaultSticky = false, offsetY = 0 }: Props) => {
  const [isSticky, setIsSticky] = useState(defaultSticky);
  const [isBottomSticky, setIsBottomSticky] = useState(false);
  const [leftShift, setLeftShift] = useState(0);
  const [topShift, setTopShift] = useState(0);
  const [bottomShift, setBottomShift] = useState(0);
  const tableRef = useRef<HTMLTableElement | null>(null);

  const toggleStickiness = useCallback(
    ({ left, top, bottom }: { left: number, top: number, bottom: number }) => {
      if (leftShift !== left) {
        setLeftShift(left);
      }

      if (
        top <= offsetY
        // When scrolling from bottom to top when and
        // the last row is visible enough, sticky header will be triggered.
        && bottom > adjustment
      ) {
        if (!isSticky) {
          setIsSticky(true);
        }
        setTopShift(Math.floor(-top + offsetY));
      } else {
        if (isSticky) {
          setIsSticky(false);
        }
        setTopShift(0);
      }

      const newValue = top + adjustment < window.innerHeight && bottom > window.innerHeight;
      setIsBottomSticky(newValue);
      setBottomShift(Math.floor(bottom - window.innerHeight));
    },
    [isSticky],
  );

  useEffect(() => {
    const handleScroll = () => {
      tableRef.current
        && toggleStickiness(tableRef.current.getBoundingClientRect());
    };
    window.addEventListener('scroll', handleScroll);
    window.addEventListener('resize', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
      window.removeEventListener('resize', handleScroll);
    };
  }, [toggleStickiness]);

  return {
    tableRef, isSticky, topShift, leftShift, isBottomSticky, bottomShift,
  };
};

export default useSticky;
