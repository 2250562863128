import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getRem } from '../../../utils/css';

const Wrapper = styled.div`
  width: ${(p) => getRem(p.size * 30)};
  margin: 0 auto;

  svg {
    stroke: ${(p) => p.color};
    -webkit-animation: rotation 1.35s linear infinite;
    animation: rotation 1.35s linear infinite;
  }

  circle {
    stroke-dasharray: 180;
    stroke-dashoffset: 0;
    -webkit-transform-origin: center;
    -ms-transform-origin: center;
    transform-origin: center;
    -webkit-animation: turn 1.35s ease-in-out infinite;
    animation: turn 1.35s ease-in-out infinite;
  }

  @-webkit-keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }

  @keyframes rotation {
    0% {
      -webkit-transform: rotate(0deg);
      transform: rotate(0deg);
    }

    100% {
      -webkit-transform: rotate(270deg);
      transform: rotate(270deg);
    }
  }

  @-webkit-keyframes turn {
    0% {
      stroke-dashoffset: 180;
    }

    50% {
      stroke-dashoffset: 45;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 180;
      -webkit-transform: rotate(450deg);
      transform: rotate(450deg);
    }
  }

  @keyframes turn {
    0% {
      stroke-dashoffset: 180;
    }

    50% {
      stroke-dashoffset: 45;
      -webkit-transform: rotate(135deg);
      transform: rotate(135deg);
    }

    100% {
      stroke-dashoffset: 180;
      -webkit-transform: rotate(450deg);
      transform: rotate(450deg);
    }
  }
`;

const Spinner = (props) => (
  <Wrapper {...props}>
    <svg
      width={getRem(props.size * 30)}
      height={getRem(props.size * 30)}
      viewBox="0 0 68 68"
    >
      <circle fill="none" strokeWidth="8" cx="34" cy="34" r="30" />
    </svg>
  </Wrapper>
);
Spinner.defaultProps = {
  size: 1.3,
  color: '#d40511',
};
Spinner.propTypes = {
  size: PropTypes.number,
  color: PropTypes.string,
};

export default Spinner;
