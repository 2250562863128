import { withoutTimezone } from './dateTimezone';
import { IntervalState } from '../types/GlobalFilter';

class IntervalHelper {
  static getDate(date: IntervalState['timestamp']): Date {
    if (date === 'now') {
      return withoutTimezone(new Date());
    }
    if (typeof date === 'string') {
      return new Date(date);
    }
    return new Date(date);
  }

  static getUrlValue(date: IntervalState['timestamp']): string {
    if (date === 'now') {
      return 'now';
    }
    if (typeof date === 'string') {
      return (new Date(date)).toISOString();
    }
    return date.toISOString();
  }

  static addIntervalTime(date: IntervalState['timestamp'], difference: number, unit: string): Date {
    const newDateTime: Date = IntervalHelper.getDate(date);
    if (unit.toLowerCase() === 'month') {
      newDateTime.setMonth(newDateTime.getMonth() + difference);
    } else if (unit.toLowerCase() === 'week') {
      newDateTime.setDate(newDateTime.getDate() + difference * 7);
    } else if (unit.toLowerCase() === 'day') {
      newDateTime.setDate(newDateTime.getDate() + difference);
    } else if (unit.toLowerCase() === 'hour') {
      newDateTime.setHours(newDateTime.getHours() + difference);
    } else if (unit.toLowerCase() === 'minute') {
      newDateTime.setMinutes(newDateTime.getMinutes() + difference);
    } else if (unit.toLowerCase() === 'second') {
      newDateTime.setSeconds(newDateTime.getSeconds() + difference);
    }

    return newDateTime;
  }
}

export default IntervalHelper;
