import React, { useEffect } from 'react';
import {
  getErrorMessage,
  GraphQLErrorObject,
  hasCriticalError,
  shouldShowErrorPopup,
} from 'utils/error';
import { GraphQLError } from 'graphql';
import {
  Container, ContentWrapper, Content, ButtonWrapper,
} from './Styles';

type Props = Partial<{
  errors: GraphQLErrorObject[];
  removeAllErrors: () => void;
  onReloadButton: () => void;
  onCloseButton: () => void;
}>;

const ErrorPopup: React.FC<Props> = ({
  errors = [],
  removeAllErrors = () => {
    /* empty by default */
  },
  onReloadButton = () => {
    /* empty by default */
  },
  onCloseButton = () => {
    /* empty by default */
  },
}) => {
  const shouldShowPopup = shouldShowErrorPopup(errors);
  const isCritical = hasCriticalError(errors);
  const errorCount = errors.length;

  useEffect(() => {
    let handle: ReturnType<typeof setTimeout> | undefined;
    if (shouldShowPopup && !isCritical) {
      handle = setTimeout(() => {
        handleOnClose();
      }, 7000);
    }
    return () => {
      if (handle) {
        clearTimeout(handle);
      }
    };
    // eslint-disable-next-line
  }, [isCritical, shouldShowPopup, errorCount]);

  const handleOnClose = () => {
    removeAllErrors();
    onCloseButton();
  };

  const handleReload = () => {
    onReloadButton();
  };

  if (!shouldShowPopup) return null;

  return (
    <Container>
      <ContentWrapper>
        <Content>{getErrorMessage(errors, isCritical)}</Content>
        <ButtonWrapper>
          {isCritical && (
            <span
              role="button"
              className="btn btn-sm btn-outline-theme mr-3"
              onClick={handleReload}
            >
              Reload
            </span>
          )}
          <span
            role="button"
            className="btn btn-sm btn-outline-theme"
            onClick={handleOnClose}
          >
            Close
          </span>
        </ButtonWrapper>
      </ContentWrapper>
    </Container>
  );
};

export default ErrorPopup;
