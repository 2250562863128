import React, {
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';

const MediaContext = createContext({});

const getMedia = () => {
  if (window.matchMedia('(max-width: 23.499rem)').matches) {
    return 'xs';
  }
  if (window.matchMedia('(max-width: 1200px)').matches) {
    return 'sm';
  }
  if (window.matchMedia('(max-width: 64.01rem)').matches) {
    return 'md';
  }
  if (window.matchMedia('(max-width: 75.01rem)').matches) {
    return 'lg';
  }
  return 'xl';
};
const getIsMediaMd = () => window.matchMedia('(max-width: 1200px)').matches;
const getShouldScrollNavMenu = () => false;

export const MediaProvider = (props) => {
  const [isMediaMd, setMediaMd] = useState(getIsMediaMd());
  const [media, setMedia] = useState(getMedia());
  const [shouldScrollNavMenu, setShouldScrollNavMenu] = useState(
    getShouldScrollNavMenu(),
  );
  const value = useMemo(
    () => [
      isMediaMd,
      shouldScrollNavMenu,
      setMediaMd,
      setShouldScrollNavMenu,
      media,
      setMedia,
    ],
    [isMediaMd, shouldScrollNavMenu, media],
  );
  return <MediaContext.Provider value={value} {...props} />;
};

export const useMedia = () => {
  const context = useContext(MediaContext);
  const [
    isMediaMd,
    shouldScrollNavMenu,
    setMediaMd,
    setShouldScrollNavMenu,
    media,
    setMedia,
  ] = context;
  if (!context) {
    throw new Error('useMedia must be used within a MediaProvider');
  }

  useEffect(() => {
    const onResize = () => {
      setMediaMd(getIsMediaMd());
      setMedia(getMedia());
      setShouldScrollNavMenu(getShouldScrollNavMenu());
    };
    window.addEventListener('resize', onResize);
    return () => {
      window.removeEventListener('resize', onResize);
    };
  }, []);

  return { isMediaMd, shouldScrollNavMenu, media };
};
