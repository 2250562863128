const defaultJoyrideSettings = {
  continuous: true,
  disableOverlayClose: true,
  showProgress: true,
  scrollOffset: 100,
  styles: {
    buttonBack: {
      color: '#fff',
    },
    options: {
      arrowColor: '#000',
      backgroundColor: '#000',
      primaryColor: '#0069d9',
      spotlightShadow: '0 0.3rem 1rem rgb(255 255 255 / 18%)',
      textColor: '#fff',
      width: '500px',
    },
  },
};

export default defaultJoyrideSettings;
