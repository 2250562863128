import React from 'react';
import Spinner from 'components/molecules/Loader/Spinner';
import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getRem } from '../../../utils/css';

const Wrapper = styled.div`
  margin-top: ${(p) => p.marginTop};
  margin-bottom: ${(p) => p.marginBottom};
`;

const Loading = (props) => (
  <Wrapper marginTop={props.marginTop} marginBottom={props.marginBottom}>
    {props.text ? (
      <div style={{ textAlign: 'center', fontSize: '1.5rem' }}>
        {props.text}
      </div>
    ) : null}
    <Spinner size={props.size} color={props.color} />
  </Wrapper>
);
Loading.defaultProps = {
  size: 1.3,
  marginTop: getRem(20),
  marginBottom: getRem(20),
  color: '#d40511',
};
Loading.propTypes = {
  text: PropTypes.string,
  size: PropTypes.number,
  marginTop: PropTypes.string,
  marginBottom: PropTypes.string,
  color: PropTypes.string,
};

export default Loading;
