import React, { useState } from 'react';
import Joyride, {
  ACTIONS, CallBackProps, EVENTS, STATUS,
} from 'react-joyride';
import defaultJoyrideSettings from './defaultJoyrideSettings';
import { ControlledStep } from '.';

type ControlledJoyrideProps = {
  steps: ControlledStep[];
} & Partial<{
  onFinished: (status: ControlledJoyrideStatus) => void;
}>;

export type ControlledJoyrideStatus = 'finished' | 'closed';

const ControlledJoyride = ({ onFinished, steps }: ControlledJoyrideProps) => {
  const [guideState, setGuideState] = useState({
    run: true,
    step: 0,
  });

  const handleJoyrideCallback = (data: CallBackProps & { step: ControlledStep }) => {
    const {
      action, index, status, type,
    } = data;
    if (status === STATUS.FINISHED || action === ACTIONS.CLOSE) {
      if (onFinished) {
        onFinished(status === STATUS.FINISHED ? 'finished' : 'closed');
      }
      return;
    }
    if (([EVENTS.STEP_AFTER, EVENTS.TARGET_NOT_FOUND] as string[]).includes(type)) {
      const newIndex = index + (action === ACTIONS.PREV ? -1 : 1);
      const nextStep = steps[newIndex];
      if (nextStep && nextStep.beforeActive) {
        setGuideState((prev) => ({ ...prev, run: false }));
        nextStep.beforeActive(nextStep)
          .then(() => {
            setGuideState((prev) => ({ ...prev, run: true, step: newIndex }));
          })
          .catch(() => {
            setGuideState((prev) => ({ ...prev, run: true, step: newIndex }));
          });
      } else {
        setGuideState((prev) => ({ ...prev, run: true, step: newIndex }));
      }
    }
  };

  return (
    <Joyride
      {...defaultJoyrideSettings}
      run={guideState.run}
      steps={steps}
      stepIndex={guideState.step}
      callback={handleJoyrideCallback}
    />
  );
};

export default ControlledJoyride;
