import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { connect } from 'react-redux';
import { docco } from 'react-syntax-highlighter/dist/esm/styles/hljs';
import SyntaxHighlighter from 'react-syntax-highlighter';
import LazyDebugModal from '../../molecules/Debug/LazyDebugModal';
import CopyClipboard from '../../atoms/CopyClipboard';

class Debug extends LazyDebugModal {
  title = 'Page debug';

  getContent = () => {
    const { content } = this.props;

    return (
      <>
        <Tabs>
          <Tab title="Content" eventKey="request">
            <div>
              {content ? (
                <>
                  <CopyClipboard text={JSON.stringify(content)} />
                  <SyntaxHighlighter language="html" wrapLines style={docco}>
                    {content}
                  </SyntaxHighlighter>
                </>
              ) : (
                <></>
              )}
            </div>
          </Tab>
        </Tabs>
      </>
    );
  };
}

Debug.defaultProps = {};
Debug.propTypes = {
  content: PropTypes.string.isRequired,
};

export default connect(
  (state) => ({
    debug: get(state, 'userSettings.debug'),
  }),
  {},
)(Debug);
