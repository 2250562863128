import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import { useMutation, useLazyQuery } from '@apollo/client';
import { useNavigate } from 'react-router-dom';
import { deleteTuningNoteGql, getTuningNotesGql } from '../../../../queries/tuningNotes';
import { TuningTask } from '../../../../types/TuningTask';
import ErrorMessage from '../../../atoms/ErrorMessage';
import StaticTable from '../../../molecules/GridTable/StaticTable';
import Filter, { FilterValue } from '../../../molecules/Filter';
import { graphqlInterval } from '../../../../utils/definitionParser';
import CardWrapper from '../../../atoms/CardWrapper';
import { filterToUrl } from '../../../../utils/urlHelper';
import { filterUrlPrefix } from '../../../../constants/variables';
import { saveFilterPreference } from '../../../../redux/userSettings/actions';
import { useInstanceIdSelector, useIntervalSelector, usePollIntervalSelector } from '../../../../redux/hooks';
import useInterval from '../../../../hooks/useInterval';
import getRefreshInterval from '../../../../utils/refreshInterval/getRefreshInterval';
import { useCompare } from '../../../../hooks/useCompare';
import { ColumnFilter } from '../../../molecules/GridTable/Filters';

type Props = {
  filter: { [key: string]: FilterValue };
} & Partial<{
  onSelected: (id: number) => void;
  onDelete: (id: number) => void;
}>;

const isTrue = (value: boolean | string):
  boolean => (typeof value === 'boolean' && value) || (typeof value === 'string' && value === 'true');

const DefaultColumnFilter = ({
  column: { filterValue, preFilteredRows, setFilter },
}) => {
  const count = preFilteredRows.length;

  return (
    <input
      value={filterValue || ''}
      onChange={(e) => {
        setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
      }}
      placeholder={`Search ${count} records...`}
    />
  );
};

const TuningNotesTable: React.FunctionComponent<Props> = ({
  onSelected,
  onDelete,
  filter: filters,
}) => {
  const navigate = useNavigate();
  const instanceScopeId = useInstanceIdSelector();
  const pollInterval = usePollIntervalSelector();
  const interval = useIntervalSelector();
  const dispatch = useDispatch();
  const [filter, setFilter] = useState(filters || {});

  const columns = [
    {
      Header: 'Edit',
      Cell: ({ row }) => (
        <a role="link" className="text-primary" onClick={() => onSelected && onSelected(row.original.noteId)}>
          Edit
        </a>
      ),
    },
    {
      Header: 'Delete',
      Cell: ({ row }) => (
        <a
          className="text-danger"
          onClick={() => {
            if (window.confirm('Are you sure you wish to delete this item?')) {
              deleteTuningNote({
                variables: { noteId: row.original.noteId },
              }).then(() => {
                /* ignore promise here */
              }).catch(() => undefined);
              if (onDelete) {
                onDelete(row.original.noteId);
              }
            }
          }}
        >
          Delete
        </a>
      ),
    },
    {
      colTag: 'noteId',
      accessor: 'noteId',
      Header: 'ID',
      Filter: ColumnFilter,
    },
    {
      colTag: 'visibleName',
      accessor: 'visibleName',
      Header: 'Visible Name',
      canFilter: true,
      Filter: ColumnFilter,
    },
    {
      colTag: 'before_queryhash',
      accessor: 'before_queryhash',
      Header: 'Queryhash before',
      canFilter: true,
      Filter: ColumnFilter,
    },
    {
      colTag: 'after_queryhash',
      accessor: 'after_queryhash',
      Header: 'Queryhash after',
      Filter: ColumnFilter,
    },
    {
      colTag: 'user_name',
      accessor: 'user_name',
      Header: 'User',
      Filter: ColumnFilter,
    },
    {
      colTag: 'stamp',
      accessor: 'stamp',
      Header: 'Tuning date',
      Filter: ColumnFilter,
    },
    {
      colTag: 'status',
      accessor: 'status',
      Header: 'Status',
    },
  ];

  const apiFilter = {
    ...omitBy(
      {
        onlyOwn: isTrue(filter.onlyOwn as string) || null,
        useTimeline: isTrue(filter.useTimeline as string) || null,
        status: filter.status || null,
        instanceId: instanceScopeId,
        interval: graphqlInterval(interval),
      },
      (v) => isNil(v) || v === '',
    ),
  };

  const [
    fetchTasks, {
      called, data, error, refetch,
    },
  ] = useLazyQuery(getTuningNotesGql, {
    variables: apiFilter,
  });
  const filterChanged = useCompare({
    instanceScopeId, interval, filter,
  });
  useEffect(() => {
    if (filterChanged) {
      fetchTasks({ variables: apiFilter }).catch(undefined);
    }
  }, [fetchTasks, instanceScopeId, interval, refetch, filterChanged, apiFilter]);
  useInterval(() => {
    fetchTasks({
      variables: apiFilter,
    }).catch(undefined);
  }, getRefreshInterval(pollInterval / 1000, 30) * 1000);

  const [deleteTuningNote] = useMutation(deleteTuningNoteGql, {
    onCompleted: () => { refetch(apiFilter).catch(undefined); },
  });

  if (error || get(data, 'getTuningNotesList.errors')) {
    return <ErrorMessage>Error when loading tuning notes data</ErrorMessage>;
  }

  const tableData: Array<TuningTask> = called
  && data ? get(data, 'getTuningNotesList.data.tunings', []) as Array<TuningTask> : [];

  return (
    <>
      <CardWrapper>
        <Filter
          contextId="tuningNotesAdmin"
          storage={{ browser: true, url: true }}
          instanceScope={instanceScopeId}
          interval={interval}
          filter={filters}
          name="filtername"
          onChange={(newFilter) => {
            const cleanedFilter = omit(newFilter, ['add']);
            dispatch(saveFilterPreference(2191, cleanedFilter));
            const urlFilter = filterToUrl(cleanedFilter, filterUrlPrefix);
            navigate(
              window.location.pathname
              + (urlFilter ? `?${urlFilter}` : '')
              + (window.location.hash ? window.location.hash : ''),
            );

            setFilter(cleanedFilter);
          }}
          controls={[
            {
              filterKey: 'onlyOwn',
              type: 'checkbox_input',
              title: 'Show only mine',
            },
            {
              filterKey: 'useTimeline',
              type: 'checkbox_input',
              title: 'Use Timeline',
            },
            {
              filterKey: 'status',
              type: 'select',
              title: 'Status',
              query: {
                procedureName: 'reporting.[TuningRecordStatusList]',
                procedureParams: '',
                queryParams: '',
                smtConfig: true,
                valueCol: 'status',
                titleCol: 'status',
                noInterval: true,
                noFilter: true,
              },
            },
          ]}
        />
      </CardWrapper>

      <StaticTable columns={columns} data={tableData} />
      {/* <Table */}
      {/*  columns={columns} */}
      {/*  data={tableData} */}
      {/*  // style="default" */}
      {/*  fetchData={() => new Promise<void>(() => { */}
      {/*     no-op */}
      {/*  })} */}
      {/* /> */}
    </>
  );
};

export default TuningNotesTable;
