import types from './action-types';
import get from 'lodash/get';
import { BaseAction } from '../types';
import { GraphQLErrorObject } from '../../utils/error';

export type State = {
  errors: GraphQLErrorObject[]
};

const initialState: State = {
  errors: [],
} as State;

type Action = {
  type: 'ERROR/ADD_ERROR';
  payload: {
    error: GraphQLErrorObject;
  };
} | {
  type: 'ERROR/ADD_ERROR';
  payload: number;
} | BaseAction;

const authReducer = (state: State = initialState, action: Action = {}) => {
  switch (action.type) {
    case 'ERROR/ADD_ERROR': {
      if (
        state.errors.find(
          (err) => err.error.code === get(action, 'payload.error.code')
            && err.error.message === get(action, 'payload.error.message'),
        )
      ) {
        return state;
      }
      return { ...state, errors: [...state.errors, action.payload] };
    }
    case types.REMOVE_ALL_ERROR:
      return initialState;
    case types.REMOVE_ERROR:
      return {
        ...state,
        errors: state.errors.splice(action.payload as number, 1),
      };
    default:
      return { ...state };
  }
};

export default authReducer;
