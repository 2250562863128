import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';
import error from './error/reducer';
import app from './app/reducer';
import userSettings from './userSettings/reducer';
import page from './page/reducer';
import auth from './auth/reducer';

const reducers = (history) => combineReducers({
  router: connectRouter(history),
  error,
  app,
  userSettings,
  page,
  auth,
});

export default reducers;
