import { withoutTimezone } from '../utils/dateTimezone';

export class TuningTask {
  constructor(
    public noteId: number | null,
    public regServer_ID: number | null,
    public database_name: string | null,
    public user_name: string | null,
    public stamp: Date | null,
    public tags: string[] = [],
    public sql_message: string | null,
    public execday: number | null,
    public before_query_plan: string | null,
    public before_query_text: string | null,
    public before_stats: string | null,
    public before_duration_ms: number | null,
    public before_cpu_ms: number | null,
    public before_reads: number | null,
    public before_queryhash: string | null,
    public before_planId: number | null,
    public before_stmtId: number | null,
    public after_query_plan: string | null,
    public after_query_text: string | null,
    public after_stats: string | null,
    public after_duration_ms: number | null,
    public after_cpu_ms: number | null,
    public after_reads: number | null,
    public after_queryhash: string | null,
    public after_planId: number | null,
    public after_stmtId: number | null,
    public status: string | null,
  ) {}

  static create(event: TuningTask) {
    return {
      noteId: parseInt(event.noteId as any) || null,
      regServer_ID: parseInt(event.regServer_ID as any) || null,
      database_name: event.database_name,
      user_name: event.user_name,
      stamp: event.stamp,
      tags: event.tags,
      sql_message: event.sql_message,
      execday: parseInt(event.execday as any) || null,
      before_query_plan: event.before_query_plan,
      before_query_text: event.before_query_text,
      before_stats: event.before_stats,
      before_duration_ms: parseInt(event.before_duration_ms as any) || null,
      before_cpu_ms: parseInt(event.before_cpu_ms as any) || null,
      before_reads: parseInt(event.before_reads as any) || null,
      before_queryhash: event.before_queryhash || null,
      before_planId: parseInt(event.before_planId as any) || null,
      before_stmtId: parseInt(event.before_stmtId as any) || null,
      after_query_plan: event.after_query_plan,
      after_query_text: event.after_query_text,
      after_stats: event.after_stats,
      after_duration_ms: parseInt(event.after_duration_ms as any) || null,
      after_cpu_ms: parseInt(event.after_cpu_ms as any) || null,
      after_reads: parseInt(event.after_reads as any) || null,
      after_queryhash: event.after_queryhash || null,
      after_planId: parseInt(event.after_planId as any) || null,
      after_stmtId: parseInt(event.after_stmtId as any) || null,
      status: event.status || '',
    };
  }
}

export class TuningTaskMap {
  public static fromRaw(raw: any): TuningTask {
    return TuningTask.create({
      ...raw,
      stamp: raw.stamp ? new Date(raw.stamp) : null,
      tags: typeof raw.tags === 'string' ? JSON.parse(raw.tags) : raw.tags,
    });
  }

  public static toPersistence(tuningTask: TuningTask): any {
    const ret = {};
    const stamp = tuningTask.stamp
      ? withoutTimezone(tuningTask.stamp)
      : undefined;
    tuningTask.stamp = stamp ? (stamp.toJSON() as any) : null;
    // @ts-ignore
    tuningTask.tags = Array.isArray(tuningTask.tags) && tuningTask.tags.length > 0
      ? JSON.stringify(tuningTask.tags)
      : null;
    Object.getOwnPropertyNames(tuningTask)
      .filter((k) => k !== 'noteId')
      .forEach((k) => (ret[k] = tuningTask[k]));

    return ret;
  }
}
