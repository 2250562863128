import { UserPreferences } from '../../../../constants/UserSettingsEnum';

export interface NotificationItem {
  instanceId: number;
  category: string;
  priority: number;
  icon: string;
  header: string;
  detail: string;
  timestamp: string;
  userName?: string;
  isNew?: boolean;
  tablecode4FE: 'tb_socketalerts' | 'tb_socketevents';
}

export const numToCode: {
  [key: number]: ('tb_socketalerts' | 'tb_socketevents')[];
} = {
  0: [],
  1: ['tb_socketalerts'],
  2: ['tb_socketalerts', 'tb_socketevents'],
};

export const getHash = (item: NotificationItem) => `${item.instanceId}-${item.tablecode4FE}-${item.timestamp}`;

export const cleanData = (
  data: NotificationItem[] = [],
  type: ('tb_socketalerts' | 'tb_socketevents')[] = [],
): NotificationItem[] => {
  const filtered = type.length === 0
    ? data
    : data.filter((i) => type.indexOf(i.tablecode4FE) !== -1);
  return notificationSort(
    filtered.filter(
      (item, index, self) => self.findIndex(
        (t) => t.instanceId === item.instanceId
          && t.priority === item.priority
          && t.detail === item.detail
          && t.timestamp === item.timestamp,
      ) === index,
    ),
  );
};

export const notificationSort = (
  data: NotificationItem[] = [],
): NotificationItem[] => [...data].sort((i1, i2) => {
  const t1 = new Date(i1.timestamp);
  const t2 = new Date(i2.timestamp);
  if (t1 < t2) {
    return 1;
  }
  if (t1 > t2) {
    return -1;
  }
  return 0;
});

export const isUnread = (
  item: NotificationItem,
  latest?: { [key: string]: string },
): boolean => {
  if (!latest || !latest[item.instanceId]) {
    return true;
  }

  return (
    new Date(item.timestamp).toJSON()
    > new Date(latest[item.instanceId]).toJSON()
  );
};

export const getMaxDate = (data: NotificationItem[]): number => Math
  .max(...data.map((item) => new Date(item.timestamp).valueOf()));

export const getUnreadCount = (
  data: NotificationItem[] = [],
  read: { [key: string]: string } = {},
  ignore: NotificationItem[] = [],
  ignoreCategories: string[] = [],
): number => getUnreadMessages(data, read, ignore, ignoreCategories).length;

export const getUnreadMessages = (
  received: NotificationItem[] = [],
  read: { [key: string]: string } = {},
  ignore: NotificationItem[] = [],
  ignoreCategories: string[] = [],
): NotificationItem[] => {
  let filtered = received.filter((item) => isUnread(item, read));

  if (ignore.length !== 0) {
    const ignoreIndexes = ignore.map(getHash);
    filtered = filtered.filter((i) => ignoreIndexes.indexOf(getHash(i)) === -1);
  }
  if (ignoreCategories.length !== 0) {
    filtered = filtered.filter((i) => ignoreCategories
      .indexOf(`${i.category}:${i.priority.toString()}`) === -1);
  }

  return filtered;
};

type NotificationIgnore = UserPreferences['notification_alert']['ignore'];

// Add or remove item from array
// eslint-disable-next-line no-bitwise
export const notificationToggle = (original: NotificationIgnore, item: string) => (original.includes(item)
  ? original.filter((i) => i !== item)
  : [...original, item]);

// eslint-disable-next-line no-bitwise
export const hasNotification = (original: NotificationIgnore, item: string) => original.includes(item);
