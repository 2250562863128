import merge from 'lodash/merge';
import { theme as Theme } from './Theme';

const darkThemeDefinition = {
  colors: {
    black: '#000000',
    black2: 'rgba(0, 0, 0, 0.02)',
    black5: 'rgba(0, 0, 0, 0.05)',
    black10: 'rgba(0, 0, 0, 0.1)',
    black20: 'rgba(0, 0, 0, 0.2)',
    black35: 'rgba(0, 0, 0, 0.35)',
    black45: 'rgba(0, 0, 0, 0.45)',
    black60: 'rgba(0, 0, 0, 0.6)',
    black90: 'rgba(0, 0, 0, 0.9)',
    postYellow95: 'rgba(255, 204, 0, 0.95)',
    smtRed: '#d40511',
    postYellow: '#ffcc00',
    white: '#ffffff',
    whiteSmoke: '#f6f6f6',
    white90: 'rgba(255, 255, 255, 0.9)',
    white95: 'rgba(255, 255, 255, 0.95)',
    gray80: '#333333',
    gray60: '#666666',
    gray60_90: 'rgba(102,102,102,0.9)',
    gray45: '#8c8c8c',
    gray30: '#b2b2b2',
    gray20: '#cccccc',
    gray10: '#e5e5e5',
    gray08: '#ebebeb',
    gray05: '#f2f2f2',
    darkGreen: '#007c39',
    brightGreen: '#76bd22',
    hoverRed: '#eb131e',
    visitedRed: '#9a0000',
    yellowShadowDark: '#722900',
    yellowShadowMedium: '#b74300',
    yellowShadowLight: '#f29005',
  },
  fonts: {
    // @todo: we may not need these with the new version, if we do, we need to change them to rem
    desktop: {
      h1: {
        size: '39px',
        lineHeight: '43px',
      },
      h2: {
        size: '31px',
        lineHeight: '35px',
      },
      h3: {
        size: '27px',
        lineHeight: '31px',
      },
      h4: {
        size: '20px',
        lineHeight: '24px',
      },
      h5: {
        size: '16px',
        lineHeight: '22px',
      },
      h6: {
        size: '14px',
        lineHeight: '18px',
      },
      button: {
        size: '14px',
        lineHeight: '25px',
      },
      regular: {
        size: '14px',
        lineHeight: '18px',
      },
      copy: {
        size: '14px',
        lineHeight: '18px',
      },
      label: {
        size: '12px',
        lineHeight: '16px',
      },
      caption: {
        size: '12px',
        lineHeight: '16px',
      },
      tabs: {
        size: '16px',
        lineHeight: '26px',
      },
    },
    tablet: {
      h1: {
        size: '36px',
        lineHeight: '40px',
      },
      h2: {
        size: '28px',
        lineHeight: '33px',
      },
      h3: {
        size: '24px',
        lineHeight: '29px',
      },
      h4: {
        size: '18px',
        lineHeight: '24px',
      },
      h5: {
        size: '16px',
        lineHeight: '20px',
      },
      h6: {
        size: '14px',
        lineHeight: '18px',
      },
      button: {
        size: '14px',
        lineHeight: '25px',
      },
      regular: {
        size: '14px',
        lineHeight: '18px',
      },
      copy: {
        size: '14px',
        lineHeight: '18px',
      },
      label: {
        size: '12px',
        lineHeight: '16px',
      },
      caption: {
        size: '12px',
        lineHeight: '16px',
      },
      tabs: {
        size: '16px',
        lineHeight: '26px',
      },
    },
    mobile: {
      h1: {
        size: '33px',
        lineHeight: '36px',
      },
      h2: {
        size: '25px',
        lineHeight: '29px',
      },
      h3: {
        size: '21px',
        lineHeight: '25px',
      },
      h4: {
        size: '20px',
        lineHeight: '24px',
      },
      h5: {
        size: '16px',
        lineHeight: '20px',
      },
      h6: {
        size: '14px',
        lineHeight: '18px',
      },
      button: {
        size: '14px',
        lineHeight: '18px',
      },
      regular: {
        size: '14px',
        lineHeight: '18px',
      },
      noZoomCopy: {
        size: '16px',
      },
      copy: {
        size: '12px',
        lineHeight: '16px',
      },
      label: {
        size: '12px',
        lineHeight: '16px',
      },
      caption: {
        size: '12px',
        lineHeight: '16px',
      },
      tabs: {
        size: '14px',
        lineHeight: '18px',
      },
    },
  },
  amcharts3: {
    themeName: 'amcharts3',
    AmChart: { color: '#FFFFFF', backgroundColor: '#FFFFFF' },
    AmCoordinateChart: {
      colors: [
        '#FF6600',
        '#FCD202',
        '#B0DE09',
        '#0D8ECF',
        '#2A0CD0',
        '#CD0D74',
        '#CC0000',
        '#00CC00',
        '#0000CC',
        '#E86946',
        '#999999',
        '#333333',
        '#990000',
      ],
    },
    AmStockChart: {
      colors: [
        '#FF6600',
        '#FCD202',
        '#B0DE09',
        '#0D8ECF',
        '#2A0CD0',
        '#CD0D74',
        '#CC0000',
        '#00CC00',
        '#0000CC',
        '#E86946',
        '#999999',
        '#333333',
        '#990000',
      ],
    },
    AmSlicedChart: {
      colors: [
        '#FF6600',
        '#FCD202',
        '#B0DE09',
        '#0D8ECF',
        '#2A0CD0',
        '#CD0D74',
        '#CC0000',
        '#00CC00',
        '#0000CC',
        '#E86946',
        '#999999',
        '#333333',
        '#990000',
      ],
      outlineAlpha: 1,
      outlineThickness: 0,
      labelTickColor: '#000000',
      labelTickAlpha: 0.3,
    },
    AmRectangularChart: {
      zoomOutButtonColor: '#000000',
      zoomOutButtonRollOverAlpha: 0.15,
      zoomOutButtonImage: 'lens',
    },
    AxisBase: {
      axisColor: '#000000',
      axisAlpha: 0.3,
      gridAlpha: 0.1,
      gridColor: '#000000',
    },
    ChartScrollbar: {
      backgroundColor: '#000000',
      backgroundAlpha: 0.12,
      graphFillAlpha: 0.5,
      graphLineAlpha: 0,
      selectedBackgroundColor: '#FFFFFF',
      selectedBackgroundAlpha: 0.4,
      gridAlpha: 0.15,
    },
    ChartCursor: { cursorColor: '#000000', color: '#FFFFFF', cursorAlpha: 0.5 },
    AmLegend: { color: '#FFFFFF' },
    AmGraph: { lineAlpha: 0.9 },
    GaugeArrow: {
      color: '#000000',
      alpha: 0.8,
      nailAlpha: 0,
      innerRadius: '40%',
      nailRadius: 15,
      startWidth: 15,
      borderAlpha: 0.8,
      nailBorderAlpha: 0,
    },
    GaugeAxis: {
      tickColor: '#000000',
      tickAlpha: 1,
      tickLength: 15,
      minorTickLength: 8,
      axisThickness: 3,
      axisColor: '#000000',
      axisAlpha: 1,
      bandAlpha: 0.8,
    },
    TrendLine: { lineColor: '#c03246', lineAlpha: 0.8 },
    AreasSettings: {
      alpha: 0.8,
      color: '#67b7dc',
      colorSolid: '#003767',
      unlistedAreasAlpha: 0.4,
      unlistedAreasColor: '#000000',
      outlineColor: '#FFFFFF',
      outlineAlpha: 0.5,
      outlineThickness: 0.5,
      rollOverColor: '#3c5bdc',
      rollOverOutlineColor: '#FFFFFF',
      selectedOutlineColor: '#FFFFFF',
      selectedColor: '#f15135',
      unlistedAreasOutlineColor: '#FFFFFF',
      unlistedAreasOutlineAlpha: 0.5,
    },
    LinesSettings: { color: '#000000', alpha: 0.9, thickness: 2 },
    ImagesSettings: {
      alpha: 0.8,
      labelColor: '#000000',
      color: '#000000',
      labelRollOverColor: '#3c5bdc',
    },
    ZoomControl: { buttonFillAlpha: 0.7, buttonIconColor: '#a7a7a7' },
    SmallMap: {
      mapColor: '#000000',
      rectangleColor: '#f15135',
      backgroundColor: '#FFFFFF',
      backgroundAlpha: 0.7,
      borderThickness: 1,
      borderAlpha: 0.8,
    },
    PeriodSelector: { color: '#000000' },
    PeriodButton: {
      color: '#000000',
      background: 'transparent',
      opacity: 0.7,
      border: '1px solid rgba(0, 0, 0, .3)',
      MozBorderRadius: '5px',
      borderRadius: '5px',
      margin: '1px',
      outline: 'none',
      boxSizing: 'border-box',
    },
    PeriodButtonSelected: {
      color: '#000000',
      backgroundColor: '#b9cdf5',
      border: '1px solid rgba(0, 0, 0, .3)',
      MozBorderRadius: '5px',
      borderRadius: '5px',
      margin: '1px',
      outline: 'none',
      opacity: 1,
      boxSizing: 'border-box',
    },
    PeriodInputField: {
      color: '#000000',
      background: 'transparent',
      border: '1px solid rgba(0, 0, 0, .3)',
      outline: 'none',
    },
    DataSetSelector: {
      color: '#000000',
      selectedBackgroundColor: '#b9cdf5',
      rollOverBackgroundColor: '#a8b0e4',
    },
    DataSetCompareList: {
      color: '#000000',
      lineHeight: '100%',
      boxSizing: 'initial',
      webkitBoxSizing: 'initial',
      border: '1px solid rgba(0, 0, 0, .3)',
    },
    DataSetSelect: { border: '1px solid rgba(0, 0, 0, .3)', outline: 'none' },
  },
};

export const darkTheme = merge(darkThemeDefinition, Theme);
