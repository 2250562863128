import styled from 'styled-components';
import { getRem, getRemMedia } from 'utils/css';
import SVG from 'components/atoms/SVGWrapper';

export const Container = styled.div`
  position: fixed;
  z-index: 20000;
  margin-left: auto;
  margin-right: auto;
  left: 0;
  right: 0;
  bottom: ${getRem(40)};
  background-color: #323232;
  display: flex;
  flex-direction: row;
  width: ${getRem(500)};
  max-width: 100%;
  box-shadow: 0 ${getRem(4)} ${getRem(32)} 0 rgba(0, 0, 0, 0.07),
    0 0 ${getRem(2)} 0 rgba(0, 0, 0, 0.1);
  @media (max-width: ${getRemMedia(768.2)}) {
    max-width: ${getRem(343)} !important;
  }
  @media (max-width: ${getRemMedia(375.9)}) {
    max-width: ${getRem(300)} !important;
    padding: 0;
  }
`;

export const SVGWrapper = styled(SVG)`
  padding: ${getRem(20)};
  align-items: center;
  @media (max-width: ${getRemMedia(375.9)}) {
    padding: ${getRem(20)} ${getRemMedia(10)};
  }
`;

export const ContentWrapper = styled.div`
  padding: ${getRem(24)} ${getRem(20)} ${getRem(24)} ${getRem(24)};
  display: flex;
  flex-direction: column;
`;

export const Content = styled.div`
  font-size: ${getRem(16)};
  font-weight: 100;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: rgba(255, 255, 255, 0.9);
  @media (max-width: ${getRemMedia(375.9)}) {
    font-size: ${getRem(14)};
  }
`;

export const ButtonWrapper = styled.div`
  padding-top: ${getRem(16)};
`;
