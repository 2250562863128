import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import styled from 'styled-components';
import { saveIntervalPreference } from '../../../redux/userSettings/actions';
import { getRem } from '../../../utils/css';
import FilterBox from './FilterBox';
import { RootState } from 'redux/store';

const Wrapper = styled.div`
  flex: 0 0 auto;
  padding-right: ${getRem(20)};
  position: relative;
  cursor: pointer;
`;

const GlobalFilter = () => {
  const interval = useSelector<RootState, {timestamp: string, range: string}>((state) => state.userSettings?.interval);
  const intervalLoading = useSelector<RootState, boolean>((state) => state.userSettings?.intervalLoading);
  const instanceScope = useSelector<RootState, number>((state) => state.userSettings?.instanceScope.id);
  const dispatch = useDispatch();

  if (intervalLoading) {
    return null;
  }

  return (
    <Wrapper>
      <FilterBox
        onChange={(filter) => {
          const newFilter = filter;
          if (newFilter.timestamp) {
            newFilter.timestamp = typeof filter.timestamp === 'string' ? filter.timestamp : filter.timestamp.toJSON();
          }
          dispatch(saveIntervalPreference(newFilter));
        }}
        filter={interval || {}}
        instanceScope={instanceScope}
      />
    </Wrapper>
  );
};

export default GlobalFilter;
