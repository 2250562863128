import React, { useState } from 'react';
import PropTypes from 'prop-types';
import InfoModal from './InfoModal';

const CardWrapper = ({
  actions,
  children,
  className,
  style,
  title,
  info,
  expandable,
  expanded,
}) => {
  const [expandedState, setExpandedState] = useState(expanded || true);
  return (
    <div className={`card${className ? ` ${className}` : ''}`} style={style}>
      <div className="card-body">
        {title && (
          <h4 className="card-title">
            <span dangerouslySetInnerHTML={{ __html: title }} />
            {' '}
            {info && info.length > 0 && (
              <InfoModal title={title}>
                <span dangerouslySetInnerHTML={{ __html: info }} />
              </InfoModal>
            )}
          </h4>
        )}
        <div className="actions">
          {actions.length > 0 && actions}
          {expandable
            && (expandedState ? (
              <i
                role="button"
                label="Collapse"
                className="actions__item zwicon-chevron-down"
                onClick={() => setExpandedState(!expandedState)}
              />
            ) : (
              <i
                role="button"
                label="Expand"
                className="actions__item zwicon-chevron-up"
                onClick={() => setExpandedState(!expandedState)}
              />
            ))}
        </div>

        <div className={!expandedState ? 'd-none' : ''}>{children}</div>
      </div>
    </div>
  );
};

CardWrapper.defaultProps = {
  actions: [],
  style: {},
  className: '',
  expandable: false,
  expanded: true,
};

CardWrapper.propTypes = {
  actions: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.arrayOf(PropTypes.node), PropTypes.node]),
  ),
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  style: PropTypes.object,
  className: PropTypes.string,
  title: PropTypes.string,
  info: PropTypes.string,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
};

export default CardWrapper;
