import React from 'react';
import PropTypes from 'prop-types';

const DebugApiMessages = ({ messages }) => (
  <div>
    <b>API messages</b>
    {messages.map((m, i) => (
      <div key={i}>
        <div>
          code:
          {m.code}
        </div>
        <div>
          level:
          {m.level}
        </div>
        <p dangerouslySetInnerHTML={{ __html: m.message }} />
      </div>
    ))}
  </div>
);

DebugApiMessages.defaultProps = {
  messages: [],
};

DebugApiMessages.propTypes = {
  messages: PropTypes.array,
};

export default DebugApiMessages;
