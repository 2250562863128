import React, { useEffect, useState } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import Nav from 'components/organisms/Nav';
import ContentWrapper from 'components/atoms/ContentWrapper';
import { useHideNav, useMedia } from 'hooks';
import Auth from 'containers/Auth';
import { useSelector } from 'react-redux';

import 'react-toastify/dist/ReactToastify.css';

import * as Sentry from '@sentry/react';
import ErrorFallback from 'components/atoms/ErrorFallback';

import Preload from 'containers/Preload';
import { useHideTopBar } from 'hooks/useHideTopBar';
import SmtIntro from 'components/SmtIntro';
import { RootState } from 'redux/store';

const AuthLayout = () => {
  const location = useLocation();
  const theme = useSelector<RootState, string>((state) => state.userSettings.theme as string);

  const [navCollapsed, setNavCollapsed] = useState(true);
  const hideNav = useHideNav(location.pathname);
  const hideTopBar = useHideTopBar();
  const { media } = useMedia();
  const belowLg = media === 'md' || media === 'sm' || media === 'xs';

  const shouldScrollNavMenu = false;
  const validPage = true;

  useEffect(() => {
    document.body.setAttribute('data-sa-theme', theme);
  }, [theme]);

  return (
    <Auth isMobile={belowLg}>
      <Preload>
        {!validPage ? null : (
          <Nav
            navCollapsed={navCollapsed}
            setNavCollapsed={setNavCollapsed}
            hideNav={hideNav}
            hideTopBar={hideTopBar}
            shouldScrollNavMenu={shouldScrollNavMenu}
            belowLg={belowLg}
          />
        )}
        <Sentry.ErrorBoundary
          fallback={(params) => <ErrorFallback {...params} />}
        >
          <ContentWrapper>
            <section className="content">
              <Outlet />
            </section>
          </ContentWrapper>
          <SmtIntro />
        </Sentry.ErrorBoundary>
      </Preload>
    </Auth>

  );
};

export default AuthLayout;
