const background = 'black';
const color = 'white';
const selected = '#216ba5';
const hover = '#444';
const height = '32';

const selectStyles = {
  control: (base, state) => ({
    ...base,
    color,
    backgroundColor: 'transparent',
    backgroundClip: 'padding-box',
    border: '1px solid rgba(255, 255, 255, 0.2)',
    borderRadius: 0,
    boxShadow: 'none',
    minHeight: `${height}px`,
    '&:hover': {
      // Overwrittes the different states of border
      borderColor: '1px solid rgba(255, 255, 255, 0.2)',
    },
  }),
  dropdownIndicator: (base) => ({
    ...base,
    padding: '3px',
    '&:hover': {
      color,
    },
  }),
  clearIndicator: (base) => ({
    ...base,
    padding: '3px',
    '&:hover': {
      color,
    },
  }),
  menu: (base) => ({
    ...base,
    backgroundColor: background,
    // override border radius to match the box
    borderRadius: 0,
    color: 'white',
    // kill the gap
    marginTop: 0,
    zIndex: 10,
    // Width to fill the values
    width: 'auto',
    minWidth: '100%',
    maxWidth: '200%',
  }),
  menuList: (base) => ({
    ...base,
    padding: 0,
    backgroundColor: background,
    color: 'white',
  }),
  option: (styles, { isSelected }) => ({
    ...styles,
    backgroundColor: isSelected ? selected : background,
    color,
    display: 'flex',
    whiteSpace: 'nowrap',
    width: 'auto',
    paddingRight: '30px',
    '&:hover': {
      backgroundColor: hover,
    },
    ...(isSelected
      ? {
        '&:after': {
          content: '"✔"',
          display: 'inline-block',
          color: 'white',
          float: 'right',
          margin: '0 10px',
          right: '0px',
          position: 'absolute',
        },
      }
      : {}),
  }),
  singleValue: (styles) => ({
    ...styles,
    color: 'unset',
    textOverflow: 'ellipsis',
    width: '90%',
    whiteSpace: 'nowrap',
    overflow: 'hidden',
  }),
  multiValue: (styles) => ({
    ...styles,
    backgroundColor: selected,
  }),
  multiValueLabel: (styles, { data }) => ({
    ...styles,
    color,
  }),
  input: (styles) => ({
    ...styles,
    color,
  }),
  valueContainer: (styles) => ({
    ...styles,
    maxHeight: '28px',
    maxWidth: '90%',
  }),
};

export default selectStyles;
