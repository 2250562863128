export const CommonFragment = `
  status
  code
  messages {
    level
    code
    message
  }
  errors {
    message
    extensions {
      code
    }
  }
`;

export type CommonFragmentResponse = {
  status: number;
  code: number;
  messages: Array<{
    level: string;
    code: number;
    message: string;
  }>;
  errors: Array<{
    message: string;
    extensions: Array<{
      code: string;
    }>
  }> | null;
};
