import {
  ApolloClient,
  defaultDataIdFromObject,
  HttpLink,
  InMemoryCache,
  Observable,
} from '@apollo/client';
import { setContext } from 'apollo-link-context';
import includes from 'lodash/includes';
import get from 'lodash/get';
import { BatchHttpLink } from '@apollo/client/link/batch-http';
import { split } from 'apollo-link';
import { getCookie, hasValidRefreshToken } from './utils/auth';
import { clearOut, refreshToken } from './utils/interceptor';
import getEnvVal from './constants/getEnvVal';
import { includesAnyOf } from './utils/string';
import { makeErrorObject } from './utils/error';
import { store } from './redux/store';
import { addError } from './redux/error/actions';
import { createProfilerLink } from './utils/ApolloProfiler';
import { customOnError } from './utils/customOnError';
import { ERROR_CODES } from './constants/common';
import debug from './utils/debug';

const batchHttpLink = new BatchHttpLink({
  uri: getEnvVal('GRAPHQL_ENDPOINT'),
  batchInterval: 300,
  headers: { batch: 'true' },
});

const httpLink = new HttpLink({
  uri: getEnvVal('GRAPHQL_ENDPOINT'),
});

const authLink = setContext((_, { headers }) => {
  const token = getCookie('access_token');

  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : '',
    },
  };
});

const getGqlStatusCode = (graphQLError) => {
  const code = get(graphQLError, 'extensions.exception.statusCode')
    || get(graphQLError, 'extensions.response.status')
    || get(graphQLError, 'extensions.code');

  return code ? code.toString() : null;
};

const errorLink = customOnError(
  ({
    graphQLErrors, networkError, operation, forward,
  }) => {
    const isSilent = operation.getContext().silent || false;
    if (!isSilent && networkError) {
      store.dispatch(
        addError(
          makeErrorObject(
            [
              {
                code: ERROR_CODES.NO_CONNECTION,
                message: `API unavailable, please check that following endpoint is available: ${getEnvVal(
                  'GRAPHQL_ENDPOINT',
                )}`,
              },
            ],
            operation,
            ERROR_CODES.NO_CONNECTION,
          ),
        ),
      );
    }
    if (graphQLErrors) {
      const statusCodeInExt = getGqlStatusCode(graphQLErrors[0]);

      if (
        includes(
          graphQLErrors.map((g) => g?.extensions?.code?.toString()),
          '401',
        )
        || statusCodeInExt === '401'
        // temporary solution for api errors from keycloak
        || (graphQLErrors[0]?.extensions?.code?.toString() === '500'
          && graphQLErrors[0].message === 'Invalid Keycloak data')
      ) {
        if (hasValidRefreshToken()) {
          return new Observable((observer) => {
            refreshToken()
              .then((token) => {
                const oldHeaders = operation.getContext().headers;
                operation.setContext({
                  headers: {
                    ...oldHeaders,
                    authorization: `Bearer ${token}`,
                  },
                });
              })
              .then(() => {
                const subscriber = {
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                };
                forward(operation).subscribe(subscriber);
              });
          });
        }
        debug.error('Apollo client didn\'t found valid refresh token');
        clearOut();
      }

      if (
        !isSilent
        && includes(
          ['500', '501', '503', '504', '505', '403', '404'],
          statusCodeInExt,
        )
      ) {
        store.dispatch(
          addError(makeErrorObject(graphQLErrors, operation, statusCodeInExt)),
        );
      }
    }
  },
);

const cache = new InMemoryCache({
  dataIdFromObject: (object) => defaultDataIdFromObject(object) // fall back to default handling
  ,
});

let link;
if (process.env.NODE_ENV === 'production') {
  link = authLink.concat(errorLink);
} else {
  const profilerLink = createProfilerLink({
    filter: (operation) => includesAnyOf(operation.operationName, [
      'getGraphDefinition',
      'getGraphData',
      'getTableDefinition',
      'getTableData',
    ]),
  });
  link = authLink.concat(profilerLink).concat(errorLink);
}

const client = new ApolloClient({
  cache,
  link: split(
    (operation) => operation.getContext().batch === true,
    link.concat(batchHttpLink),
    link.concat(httpLink),
  ),
  queryDeduplication: true,
  defaultOptions: {
    watchQuery: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'ignore',
    },
    query: {
      fetchPolicy: 'no-cache',
      errorPolicy: 'all',
    },
    mutate: {
      errorPolicy: 'all',
    },
  },
});

export default client;
