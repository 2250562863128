import gql from 'graphql-tag';
import { CommonFragmentResponse } from './common';

export const getAutocomplete = gql`
  query getAutocomplete(
    $instance: Int!
    $code: String!
    $interval: DateInterval
    $filter: [FilterItem]
    $smtConfig: Boolean! = false
  ) {
    getAutocomplete(
      instanceId: $instance
      code: $code
      interval: $interval
      filter: $filter
      smtConfig: $smtConfig
    ) {
      errors {
        message
        extensions {
          code
        }
      }
      messages {
        level
        code
        message
      }
      data {
        values
      }
    }
  }
`;

export type GetAutocompleteDataResponse<T = any> = {
  getAutocomplete: CommonFragmentResponse & {
    data: {
      values: T
    }
  }
};
