import { all, fork, takeEvery } from 'redux-saga/effects';
import types from './action-types';
import { getToken, logOut, tokenErrorEffect } from './effects';

function* watchGetToken() {
  yield takeEvery(types.GET_TOKEN, getToken);
}

function* watchGetTokenError() {
  yield takeEvery(types.GET_TOKEN_ERROR, tokenErrorEffect);
}

function* watchLogOut() {
  yield takeEvery(types.LOG_OUT, logOut);
}

export default function* rootSaga() {
  yield all([fork(watchGetToken), fork(watchLogOut), fork(watchGetTokenError)]);
}
