import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Modal } from 'react-bootstrap';
import { ERROR_CODES } from 'constants/common';
import {
  getInstances,
  getIntervals,
  getPages,
  getUser,
  loadFilterPreference,
  loadIntervalPreference,
  saveInstanceScope,
} from '../redux/userSettings/actions';
import Loading from '../components/molecules/Loader/Loading';
import Gap from '../components/atoms/Gap';
import getEnvVal from '../constants/getEnvVal';
import { RootState } from '../redux/store';
import { useInstanceIdSelector, useInstancesSelector, usePagesSelector } from '../redux/hooks';

const ModalButton = ({ children, onClick }: React.PropsWithChildren<{ onClick: () => void; }>) => (
  <button
    type="button"
    className="btn btn-theme"
    onClick={(e) => {
      e.preventDefault();
      onClick();
    }}
  >
    {children}
  </button>
);

const Preload = ({ children }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [expired, setExpired] = useState(false);
  const gettingMe = useSelector<RootState, boolean>((state) => state.userSettings?.gettingMe);
  const instance = useInstanceIdSelector();
  const instances = useInstancesSelector();
  const pages = usePagesSelector();

  const loadingPages = useSelector<RootState, boolean>((state) => state.userSettings.gettingPages);

  const gettingInstances = useSelector<RootState, boolean>((state) => state.userSettings.gettingInstances);
  const filtersLoading = useSelector<RootState, boolean>((state) => state.userSettings.filtersLoading);
  const intervalLoading = useSelector<RootState, boolean>((state) => state.userSettings.intervalLoading);
  const pagesError = useSelector<RootState>((state) => state.userSettings.pagesError);
  const instancesError = useSelector<RootState>((state) => state.userSettings.instancesError);
  const errors = useSelector<RootState, Array<{ error: { code: number | null } }>>((state) => state.error.errors);

  useEffect(() => {
    dispatch(loadFilterPreference());
    dispatch(loadIntervalPreference());
    dispatch(getInstances());
    dispatch(getPages());
    dispatch(getUser());
    dispatch(getIntervals());
  }, [dispatch]);

  setTimeout(() => setExpired(true), 15000);

  const serverErrors = errors.filter(
    (err) => err.error.code === ERROR_CODES.NO_CONNECTION,
  );
  if (serverErrors.length > 0 && (pagesError || instancesError)) {
    return (
      <Modal
        size="lg"
        show
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <h3 style={{ color: 'red' }}>
            There was an error loading the application data
          </h3>
          <p>
            API unavailable, please check that following endpoint is
            available:
            {' '}
            {getEnvVal('GRAPHQL_ENDPOINT')}
          </p>
          <div className="m-2">
            <ModalButton onClick={() => window.location.reload()}>
              Reload
            </ModalButton>
            <Gap />
            <ModalButton onClick={() => navigate('/logout')}>
              Logout
            </ModalButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  if (pagesError || instancesError) {
    return (
      <Modal
        size="lg"
        show
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Body className="text-center">
          <h3 style={{ color: 'red' }}>
            There was an error loading the application data.
          </h3>
          <p>
            You can try to refresh the page, if the issues persist, you can
            try to log-in again.
          </p>
          <div className="m-2">
            <ModalButton onClick={() => window.location.reload()}>
              Reload
            </ModalButton>
            <Gap />
            <ModalButton onClick={() => navigate('/logout')}>
              Logout
            </ModalButton>
          </div>
        </Modal.Body>
      </Modal>
    );
  }
  if (
    filtersLoading
    || intervalLoading
    || loadingPages
    || gettingInstances
    || gettingMe
  ) {
    return (
      <>
        <Loading text="Loading the application..." />
        {expired && (
          <Modal
            size="lg"
            show
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Body className="text-center">
              <p>
                It looks like the application is still loading.
              </p>
              <p>
                You can try to refresh the page, if the issues persist, you can
                try to log-in again.
              </p>
              <div className="m-2">
                <ModalButton onClick={() => window.location.reload()}>
                  Reload
                </ModalButton>
                <Gap />
                <ModalButton onClick={() => navigate('/logout')}>
                  Logout
                </ModalButton>
              </div>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }
  if (!loadingPages && !gettingInstances) {
    let message: React.ReactNode = null;
    let additionalActions: React.ReactNode = null;
    if (instances.length === 0) {
      message = (
        <p>
          No Monitored instance found for current user. Make sure that
          monitored instances have been configured and current user has
          rights to access it.
        </p>
      );
    } else if (!instances.find((i) => i.id === instance)) {
      message = <p>Selected instance is not available for the current user</p>;
      additionalActions = (
        <ModalButton onClick={() => dispatch(saveInstanceScope(instances[0]))}>
          Change instance
        </ModalButton>
      );
    } else if (pages.length === 0) {
      message = <p>No report pages have been found?</p>;
    }

    if (message) {
      return (
        <Modal
          size="lg"
          show
          aria-labelledby="contained-modal-title-vcenter"
          centered
        >
          <Modal.Body className="text-center">
            {message}
            <div className="m-2">
              <ModalButton onClick={() => window.location.reload()}>
                Reload
              </ModalButton>
              {additionalActions && (
              <>
                <Gap />
                {additionalActions}
              </>
              )}
              <Gap />
              <ModalButton onClick={() => navigate('/logout')}>
                Logout
              </ModalButton>
            </div>
          </Modal.Body>
        </Modal>
      );
    }
  }

  // @TODO there is a TS issue in App when using Preload
  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

export default Preload;
