import React, { useEffect, useState } from 'react';
import { Route, Routes } from 'react-router-dom';
import Login from 'routes/Login';
import Logout from 'routes/Logout';
import { useMedia } from 'hooks';
import Dashboard from 'routes/Dashboard';
import OfflineBanner from 'components/molecules/OfflineBanner';
import { useDispatch, useSelector } from 'react-redux';
import { removeAllErrors } from './redux/error/actions';
import Preview from './routes/Preview';
import { genReportUrlPrefix } from './constants/common';
import DynamicPage from './routes/DynamicPage';

import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { IconPack, library } from '@fortawesome/fontawesome-svg-core';
import { fas } from '@fortawesome/free-solid-svg-icons';
import * as Sentry from '@sentry/react';
import ErrorFallback from './components/atoms/ErrorFallback';

import ErrorPopup from 'components/organisms/ErrorPopup';
import TuningTask from 'routes/TuningTask';
import { hasRole } from 'utils/auth';
import UserSettings from './routes/UserSettings';
import { RootState } from './redux/store';
import AuthLayout from './containers/AuthLayout';

import 'App.scss';

library.add(fas as IconPack);

const App = () => {
  const errors = useSelector<RootState, RootState['error']['errors']>((state) => state.error.errors);
  const dispatch = useDispatch();
  const [isUserOnline, setIsUserOnline] = useState(() => (typeof navigator !== 'undefined'
  && typeof navigator.onLine === 'boolean'
    ? navigator.onLine
    : true));
  const goOnline = () => {
    setIsUserOnline(true);
  };
  const goOffline = () => {
    setIsUserOnline(false);
  };
  useEffect(() => {
    window.addEventListener('online', goOnline);
    window.addEventListener('offline', goOffline);
    return () => {
      window.removeEventListener('online', goOnline);
      window.removeEventListener('offline', goOffline);
    };
    // eslint-disable-next-line
  }, []);

  const { media } = useMedia();
  const belowLg = media === 'md' || media === 'sm' || media === 'xs';

  return (
    <Sentry.ErrorBoundary fallback={(params) => <ErrorFallback {...params} />}>
      <main className="main">
        <Routes>
          <Route path="/login" element={<Login />} />
          <Route path="/logout" element={<Logout />} />
          <Route
            path="/"
            element={<AuthLayout />}
          >
            <Route
              path={`/${genReportUrlPrefix}/:id`}
              element={<DynamicPage />}
            />
            <Route
              path="/tuning-task/:id"
              element={<TuningTask />}
            />
            <Route
              path="/preferences"
              element={<UserSettings />}
            />
            {hasRole('smt_admin') && (
            <Route
              path="/admin/preview"
              element={<Preview />}
            />
            )}
            <Route
              path="/"
              element={<Dashboard />}
            />
          </Route>
        </Routes>

        <OfflineBanner isMobile={belowLg} show={!isUserOnline} />
        <ErrorPopup
          onReloadButton={() => window.location.reload()}
          errors={errors}
          removeAllErrors={() => dispatch(removeAllErrors())}
        />
        <ToastContainer />
      </main>
    </Sentry.ErrorBoundary>
  );
};

export default App;
