import React, { useEffect, useState } from 'react';
import { Redirect, useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import { getCookie, hasValidAuthToken } from 'utils/auth';
import { connect } from 'react-redux';
import { getQueryParams } from 'utils/string';
import { getToken } from 'redux/auth/actions';
import get from 'lodash/get';
import Loading from '../components/molecules/Loader/Loading';
import { getRem } from '../utils/css';
import { clearOut } from '../utils/interceptor';
import debug from '../utils/debug';

const Wrapper = styled.div`
  padding: ${getRem(60)} ${getRem(30)};
  min-height: calc(100vh - 13.4rem);
`;

const Login = (props) => {
  const navigate = useNavigate();
  const [hasError, setHasError] = useState(false);
  const queryParams = getQueryParams(window.location.search);
  const { code } = queryParams;

  useEffect(() => {
    if (code && !get(props, 'auth.loginInProgress')) {
      props.getToken(code);
    }
    // eslint-disable-next-line
  }, [code]);

  if (hasError) {
    return <>Error</>;
  }

  if (
    hasValidAuthToken()
    && !get(props, 'auth.loginInProgress')
    && !get(props, 'auth.tokenRefreshInProgress')
  ) {
    const returnUri = getCookie('returnUri');
    navigate(returnUri || '/');
    return null;
  }
  if (!code) {
    debug.error('Component:Login: No code parameter in URL', queryParams, window.location);
    clearOut();
    setTimeout(() => setHasError(true), 5000);
    return <Wrapper>Redirecting</Wrapper>;
  }

  return (
    <Wrapper>
      {code && (
        <Wrapper>
          <Loading />
        </Wrapper>
      )}
    </Wrapper>
  );
};

export default connect((state) => ({ auth: state.auth }), { getToken })(Login);
