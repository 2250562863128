import React from 'react';
import * as ReactDOM from 'react-dom/client';
import { Provider as StoreProvider } from 'react-redux';
import { ApolloProvider } from '@apollo/client/react/context/ApolloProvider';
import { ThemeProvider } from 'styled-components';
import { MediaProvider } from 'hooks';
import { BrowserRouter, BrowserRouter as Router } from 'react-router-dom';
import { ConnectedRouter } from 'connected-react-router';
import axios from 'axios';
import getEnvVal from 'constants/getEnvVal';
import {
  requestInterceptor,
  responseErrorInterceptor,
  responseSuccessInterceptor,
} from 'utils/interceptor';
import * as Sentry from '@sentry/react';
import { Integrations } from '@sentry/tracing';
import { configureScope, Scope } from '@sentry/browser';
import { darkTheme } from './constants/styledTheme';
import apolloClient from './apolloClient';
import * as serviceWorker from './serviceWorker';
import App from './App';
import { store, history } from './redux/store';
import packageInfo from '../package.json';

import 'assets/scss/app.scss';

Sentry.init({
  dsn: getEnvVal('SENTRY_DSN'),
  release: `${packageInfo.name}@${packageInfo.version}`,
  normalizeDepth: 5,
  integrations: [new Integrations.BrowserTracing()],
  tracesSampleRate: getEnvVal('SENTRY_SAMPLE_RATE') || 0.1,
});

if (getEnvVal('CLIENT_ID')) {
  configureScope((scope: Scope) => {
    scope.setTags({ clientId: getEnvVal('CLIENT_ID') });
  });
}

axios.interceptors.request.use(requestInterceptor);
axios.interceptors.response.use(
  responseSuccessInterceptor,
  responseErrorInterceptor,
);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement,
);
root.render(
  <ApolloProvider client={apolloClient}>
    <ThemeProvider theme={darkTheme}>
      <StoreProvider store={store}>
        {/* <ConnectedRouter location={history?.location} navigator={history}> */}
        <BrowserRouter>
          <MediaProvider>
            <App />
          </MediaProvider>
        </BrowserRouter>
        {/* </ConnectedRouter> */}
      </StoreProvider>
    </ThemeProvider>
  </ApolloProvider>,
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
