import types from './action-types';

const initialState = {
  loginInProgress: false,
  redirect: false,
};

const authReducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case types.GET_TOKEN: {
      return { ...state, loginInProgress: true, redirect: false };
    }
    case types.GET_TOKEN_SUCCESS:
      return {
        ...state,
        loginInProgress: false,
        redirect: true,
      };
    case types.GET_TOKEN_ERROR:
    case types.LOG_OUT:
      return {
        ...initialState,
      };
    default:
      return { ...state };
  }
};

export default authReducer;
