import React, { useState } from 'react';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  TableOptions,
  TableInstance,
  Cell as ReactTableCell,
  Row as ReactTableRow,
  UseGroupByCellProps,
  UseGroupByRowProps,
  UseExpandedRowProps,
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Form from 'react-bootstrap/Form';
import { QueryLazyOptions } from '@apollo/client/react/types/types';
import { GraphQLError } from 'graphql';
import Loading from '../Loader/Loading';
import { useCompare } from '../../../hooks/useCompare';
import { filterTypes } from './Filters';
import { graphqlFilter, graphqlInterval } from '../../../utils/definitionParser';
import InfoBox from '../../atoms/InfoBox';
import DebugText from '../Debug/DebugText';
import { getServerErrors } from '../../../utils/error';
import { TableStyle } from './types';

type Props = {
  columns: any[];
  data: any[];
  fetchData: (options?: QueryLazyOptions<any>) => Promise<void>;
} & Partial<{
  loading: boolean;
  error: GraphQLError[];
  paging: boolean;
  interval: any;
  filter: any;
  totalCount: number;
  pageSize: number;
  sticky: boolean;
  style: TableStyle;
}>;

type TableType<T extends object = {}> = TableInstance<T> & {
  canPreviousPage: boolean;
  canNextPage: boolean;
  gotoPage: (index: number) => void;
  nextPage: () => void;
  page: any;
  pageCount: number;
  previousPage: () => void;
  setPageSize: (size: number) => void;
  state: {
    pageIndex: number;
    pageSize: number;
    sortBy: any;
    filters: any;
  };
  sticky: boolean;
};

type TableCell = ReactTableCell & UseGroupByCellProps<any>;
type TableRow = ReactTableRow & UseGroupByRowProps<any> & UseExpandedRowProps<any>;

const Table: React.FC<Props> = ({
  columns = [],
  data = [],
  fetchData = async () => {
    /* empty by default */
  },
  loading = false,
  error = [],
  paging = true,
  interval = {},
  filter = {},
  totalCount: controlledTotalCount = 0,
  pageSize: defaultPageSize = 20,
  style = 'default',
}) => {
  const initialState: any = {
    pageIndex: 0,
    pageSize: defaultPageSize,
    groupBy: null,
  };
  const [pageSize, setPageSize] = useState<number>(defaultPageSize);
  const [dataLoading, setDataLoading] = useState<boolean>(false);
  const groupedColumns = columns.filter((c) => c.group > 0);
  if (groupedColumns) {
    initialState.groupBy = [...groupedColumns].sort((a, b) => (a.group > b.group ? 1 : -1)).map((c) => c.colTag);
  }

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    // Get the state from the instance
    state: { pageIndex, sortBy, filters },
  } = useTable(
    {
      columns,
      data,
      filterTypes,
      initialState,
      manualPagination: true, // Tell the usePagination
      manualSortBy: true, // If paging enabled we need to sort on server
      manualFiltering: true,
      defaultCanSort: false,
      pageCount: controlledTotalCount && pageSize ? Math.ceil(controlledTotalCount / pageSize) : 0,
      autoResetPage: false,
      autoResetFilters: false,
    } as TableOptions<object>,
    useFilters,
    useGroupBy,
    useSortBy,
    useExpanded,
    usePagination,
  ) as TableType<object>;

  const hasChanged = useCompare([pageIndex, pageSize, sortBy, interval, filter, filters]);
  const hasFilterChanged = useCompare([interval, filter, pageSize]);
  React.useEffect(() => {
    if (hasFilterChanged) {
      gotoPage(0);
    }
  }, [hasFilterChanged, gotoPage]);

  // Listen for changes in pagination and use the state to fetch our new data
  React.useEffect(() => {
    if (hasChanged) {
      setDataLoading(true);
      fetchData({
        variables: {
          offset: hasFilterChanged ? 0 : pageIndex * pageSize,
          limit: pageSize,
          sort: sortBy.map((s) => ({
            field: s.id,
            direction: s.desc ? 'DESC' : 'ASC',
          })),
          interval: graphqlInterval(interval),
          filter: graphqlFilter([...(filter || []), ...(filters || []).map((f) => ({ param: f.id, value: f.value }))]),
        },
      }).then(() => { setDataLoading(false); });
    }
  }, [pageIndex, pageSize, hasFilterChanged, hasChanged, filter, filters, fetchData]);

  if (loading) {
    return <Loading text="Loading table data..." />;
  }

  const pages: Array<React.ReactChild> = [];
  for (let number = Math.max(1, pageIndex - 1); number <= Math.min(pageCount, pageIndex + 3); number++) {
    pages.push(
      <button
        key={number}
        type="button"
        className={`btn btn-link paginate_button ${number === pageIndex + 1 ? 'current' : ''}`}
        aria-controls="data-table"
        data-dt-idx={number}
        tabIndex={0}
        onClick={(e) => { e.preventDefault(); gotoPage(number - 1); }}
      >
        {number}
      </button>,
    );
  }

  let footer: React.ReactNode = null;
  let pagingComponent = (
    <div className="dataTables_paginate paging_simple_numbers" id="data-table_paginate" style={{ height: '35px' }} />
  );
  if (loading) {
    footer = <Loading text="Loading table data..." />;
  } else if (getServerErrors(error)) {
    footer = (
      <InfoBox level="error">
        Problem when loading the table!
        <DebugText>
          <p>
            Errors from api:
            {JSON.stringify(error)}
          </p>
        </DebugText>
      </InfoBox>
    );
  } else if (paging && controlledTotalCount > 0) {
    if (pages.length > 1) {
      pagingComponent = (
        <div className="dataTables_paginate paging_simple_numbers" id="data-table_paginate" style={{ height: '35px' }}>
          <button
            type="button"
            className={`btn btn-link paginate_button${!canPreviousPage ? ' disabled' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              if (canPreviousPage) {
                gotoPage(0);
              }
            }}
          >
            <i className="zwicon-expand-left" />
          </button>
          <button
            type="button"
            className={`btn btn-link paginate_button${!canPreviousPage ? ' disabled' : ''}`}
            aria-controls="data-table"
            data-dt-idx="0"
            tabIndex={0}
            id="data-table_previous"
            onClick={(e) => {
              e.preventDefault();
              if (canPreviousPage) {
                previousPage();
              }
            }}
          >
            <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'angle-left' }} />
          </button>
          <span>{pages}</span>
          <button
            type="button"
            className={`btn btn-link paginate_button${!canNextPage ? ' disabled' : ''}`}
            aria-controls="data-table"
            data-dt-idx="5"
            tabIndex={0}
            id="data-table_next"
            onClick={(e) => {
              e.preventDefault();
              if (canNextPage) {
                nextPage();
              }
            }}
          >
            <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'angle-right' }} />
          </button>
          <button
            type="button"
            className={`btn btn-link paginate_button${!canNextPage ? ' disabled' : ''}`}
            onClick={(e) => {
              e.preventDefault();
              if (canNextPage) {
                gotoPage(pageCount - 1);
              }
            }}
          >
            <i className="zwicon-expand-right" />
          </button>
        </div>
      );
    }
    footer = (
      <div className="dataTables__bottom">
        <div className="dataTables_info" id="data-table_info" role="status" aria-live="polite">
          Showing
          {' '}
          {pageIndex * pageSize + 1}
          {' '}
          to
          {' '}
          {Math.min((pageIndex + 1) * pageSize, controlledTotalCount)}
          {' '}
          of
          {' '}
          {controlledTotalCount}
          {' '}
          entries
        </div>
        {pagingComponent}
      </div>
    );
  }

  let top = <></>;
  if (paging) {
    let pagingOptions: Array<number> = [10, 20, 50];
    if (pagingOptions.indexOf(pageSize) === -1) {
      pagingOptions.push(pageSize);
      pagingOptions = Array.from(new Set(pagingOptions)).sort((a: number, b: number) => {
        if (a > b) {
          return 1;
        }
        if (a < b) {
          return -1;
        }
        return 0;
      });
    }
    top = (
      <div className="dataTables__top" style={{ display: 'block' }}>
        <div>{pagingComponent}</div>
        <div
          className="dataTables_length"
          style={{
            margin: '0 0 0 auto',
            position: 'absolute',
            right: 0,
            top: 0,
          }}
        >
          <label>
            Show
            <Form.Control
              as="select"
              defaultValue={pageSize}
              onChange={(event) => setPageSize(parseInt(event.target.value))}
            >
              {pagingOptions.map((s) => (
                <option key={s} value={s}>
                  {s}
                  {' '}
                  Rows
                </option>
              ))}
            </Form.Control>
            {' '}
            entries
          </label>
        </div>
      </div>
    );
  }

  let hasFilter = false;

  let header: React.ReactNode = null;
  if (style !== 'button') {
    header = (
      <thead>
        {headerGroups.map((headerGroup) => (
          <tr role="row" {...headerGroup.getHeaderGroupProps()}>
            {headerGroup.headers.map((column: any, ck) => {
              if (column.canFilter && column.Filter) {
                hasFilter = true;
              }

              let thClass = '';
              if (column.canSort) {
                if (column.isSorted) {
                  thClass = column.isSortedDesc ? ' sorting_desc' : ' sorting_asc';
                } else {
                  thClass = ' sorting';
                }
              }

              return (
                <th key={ck} style={{ position: 'relative' }} className={`align-top${thClass}`}>
                  <div {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</div>
                  <div style={{ position: 'absolute', top: '8px', right: '4px' }}>{column.tooltip}</div>
                </th>
              );
            })}
          </tr>
        ))}
        {hasFilter ? (
          headerGroups.map((headerGroup, hk) => (
            <tr role="row" key={hk}>
              {headerGroup.headers.map((column: any, ck) => (
                <th className="align-top" style={{ position: 'relative' }} key={ck}>
                  <div>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                </th>
              ))}
            </tr>
          ))
        ) : (
          null
        )}
      </thead>
    );
  }

  // Render the UI for your table
  return (
    <div className="dataTables_wrapper no-footer">
      {top}
      <table
        className={`table ${style !== 'button' ? 'table-bordered' : 'table-borderless'} table-sm dataTable no-footer`}
        {...getTableProps()}
      >
        {header}
        <tbody {...getTableBodyProps()}>
          {(dataLoading && page.length === 0) ? (
            <Loading />
          ) : (
            page.map((row, kr) => {
              prepareRow(row);
              return (
                <tr key={kr} {...row.getRowProps()}>
                  {row.cells.map((cell, kc) => (
                    <Cell key={kc} cell={cell} row={row} />
                  ))}
                </tr>
              );
            }))}
        </tbody>
      </table>

      {footer}
    </div>
  );
};

const Cell = ({ cell, row }: { cell: TableCell, row: TableRow }): React.ReactElement => {
  let content: React.ReactNode = null;
  if (cell.isGrouped) {
    content = (
      <>
        <span {...row.getToggleRowExpandedProps()}>
          {row.isExpanded ? <i className="zwicon-minus-square" /> : <i className="zwicon-plus-square" />}
        </span>
        &nbsp;
        {cell.render('Cell')}
        {' '}
        (
        {row.subRows.length}
        )
      </>
    );
  } else if (cell.isAggregated) {
    content = cell.render('Aggregated') as React.ReactElement;
  } else if (!cell.isPlaceholder) {
    content = cell.render('Cell') as React.ReactElement;
  }

  return (
    <td {...cell.getCellProps()} style={(cell.column as any)?.style as React.CSSProperties || {}}>
      {content}
    </td>
  );
};

export default Table;
