import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { getUser } from '../../redux/userSettings/actions';
import { useMutation } from '@apollo/client';
import { updateUserSettingGql } from '../../queries/user';
import { useGuideSelector, usePagesSelector, usePreferencesSelector } from '../../redux/hooks';
import { setShowGuide } from '../../redux/app/actions';
import CategoryGuide from './CategoryGuide';
import SettingsGuide from './SettingsGuide';
import BasicGuide from './BasicGuide';
import { GuideType } from 'redux/app/types';

type IntroStorage = { [key: string]: number };

const SmtIntro: React.FC = () => {
  const reduxGuide = useGuideSelector();
  const guide = useMemo(() => (reduxGuide.guide !== undefined ? reduxGuide.guide : 'basic'), [reduxGuide]);
  const [update] = useMutation(updateUserSettingGql);
  const preferences = usePreferencesSelector();
  const pages = usePagesSelector();
  const dispatch = useDispatch();

  const nextGuide: { [key: string]: GuideType } = {
    basic: 'category',
  };
  if (pages.find((p) => p.id === 2213)) {
    nextGuide.category = 'settings';
  }

  let introValue: IntroStorage = {};
  try {
    introValue = JSON.parse(preferences.intro.toString() || '{}') as IntroStorage;
  } catch {
    introValue = {};
  }
  if (reduxGuide.guide === '' || (reduxGuide.guide === undefined && introValue.basic)) {
    return null;
  }

  const change = () => {
    update({
      variables: {
        code: 'intro',
        value: JSON.stringify({ ...introValue, [guide]: 1 }),
      },
    }).then(() => {
      if (reduxGuide.continue && guide in nextGuide) {
        dispatch(setShowGuide({ continue: true, guide: nextGuide[guide] }));
      } else {
        dispatch(setShowGuide({ continue: false, guide: '' }));
      }
      setTimeout(() => dispatch(getUser()), 200);
    }).catch(() => undefined);
  };

  if (guide === 'category') {
    return <CategoryGuide onFinished={change} />;
  }
  if (guide === 'settings') {
    return <SettingsGuide onFinished={change} />;
  }

  return <BasicGuide onFinished={change} />;
};

export default SmtIntro;
