import React from 'react';
import PropTypes from 'prop-types';
import InfoModal from '../../atoms/InfoModal';
import Gap from '../../atoms/Gap';

const ControlBox = (props) => {
  const tooltip = props.control.tooltip ? <InfoModal iconSize="xs">{props.control.tooltip}</InfoModal> : null;

  return (
    <div
      className={`mb-2 ${
        typeof props.className !== 'undefined' ? props.className : 'col-2'
      }`}
      style={props.style ? props.style : {}}
      title={props.title}
    >
      {props.hideLabel !== true ? (
        // eslint-disable-next-line jsx-a11y/label-has-associated-control
        <label>
          {props.control.title}
          <Gap />
          {tooltip}
        </label>
      ) : tooltip}
      {props.children}
    </div>
  );
};

ControlBox.propTypes = {
  control: PropTypes.shape({
    title: PropTypes.string.isRequired,
    tooltip: PropTypes.string,
  }).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]).isRequired,
  className: PropTypes.string,
  hideLabel: PropTypes.bool,
  title: PropTypes.string,
  style: PropTypes.object,
};

export default ControlBox;
