export const scrollNavigationTo = (pageId: number) => {
  const scrollbar = document.getElementById('sidebar-scrollbar');
  const el = document.querySelector(`.navigation [data-id="${pageId.toString()}"]`);
  if (!scrollbar || !el) {
    return;
  }
  scrollbar.scrollTop = (el.getBoundingClientRect().top || 0) + scrollbar.scrollTop - 150;
};

export const centerElement = (selector: string) => {
  document.querySelector(selector)?.scrollIntoView({
    behavior: 'smooth',
  });
};
