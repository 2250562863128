import { filterToUrl, getPersistentParameters, intervalToUrl } from 'utils/urlHelper';
import { filterUrlPrefix, instanceUrlPrefix } from 'constants/variables';
import { ControlType, FilterType } from './index';
import { DynamicCellValueType } from './index.d';
import { FilterBoxValue } from '../GlobalFilter/FilterBox';
import { NavigateFunction } from 'react-router/dist/lib/hooks';

const urlFilterStore = (filter: FilterType, navigate: NavigateFunction) => {
  const urlFilter = filterToUrl(filter, filterUrlPrefix);
  const url = window.location.pathname
    + (urlFilter ? `?${urlFilter}` : '')
    + (window.location.hash ? window.location.hash : '');

  if (navigate) {
    navigate(url);
  } else {
    window.location.replace(url);
  }
};

type RedirectToStateParam = {
  filter: FilterType;
  interval: FilterBoxValue;
  hash: string;
};

const redirectToState = (params: RedirectToStateParam, navigate: NavigateFunction): void => {
  const { filter, interval, hash } = params;
  const queryParams = getPersistentParameters();

  const filterUrl = filterToUrl(filter, filterUrlPrefix, true);
  const intervalUrl = intervalToUrl(interval);
  const hashUrl = hash ? `#${hash}` : '';
  navigate(
    `${window.location.pathname
    }?${instanceUrlPrefix}instance=${queryParams.instance as string
    }&${intervalUrl}&${filterUrl}${hashUrl}`,
  );
};

const filterControlValues = (controls: ControlType[], filter: FilterType) => {
  const ret: FilterType = {};
  controls.forEach((control) => {
    if (Object.prototype.hasOwnProperty.call(filter, control.filterKey)) {
      ret[control.filterKey] = filter[control.filterKey];
    }
  });
  return ret;
};

const parseDynamicFilter = (value: string): DynamicCellValueType => {
  const cellValue = JSON.parse(value) as unknown as DynamicCellValueType;

  cellValue.fields = cellValue.fields.map((field) => {
    const newField = { ...field };
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (Object.prototype.hasOwnProperty.call(newField, 'values') && typeof newField.values === 'string') {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-argument
      newField.values = JSON.parse(newField.values);
    }
    return newField;
  });

  return cellValue;
};

export {
  filterControlValues, parseDynamicFilter, redirectToState, urlFilterStore,
};
