import { useSelector } from 'react-redux';
import { RootState } from './store';
import { FilterType } from '../components/molecules/Filter/index';
import { Interval, PageDefinition } from './userSettings/types';
import { State as AppState } from './app/reducer';
import { ServerInstance } from '../types/ServerInstance';
import { UserPreferences } from '../constants/UserSettingsEnum';

type BookmarkItem = {
  title: string;
  filter: FilterType;
  interval: Interval;
};

type BookmarkStorageType = { [key: number]: BookmarkItem[] };

export const useBookmarkSelector = () => useSelector<RootState, BookmarkStorageType>((state) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (state.userSettings.preferences?.bookmarks) {
    try {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-argument,@typescript-eslint/no-unsafe-member-access
      return JSON.parse(state.userSettings.preferences?.bookmarks) as BookmarkStorageType;
    } catch {
      return {};
    }
  }

  return {};
});

export const usePagesSelector = () => useSelector<RootState, PageDefinition[]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
>((state) => state.userSettings.pages);

export const usePageSelector = (id: number) => useSelector<RootState, PageDefinition
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
>((state) => state.userSettings.pages.find((item) => item.id.toString() === id.toString()));

export const useDebugSelector = () => useSelector<RootState, boolean
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
>((state) => state.userSettings.debug || false);

export const usePreferencesSelector = () => useSelector<RootState, UserPreferences
  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
>((state) => state.userSettings.preferences || false);

export const useGuideSelector = () => useSelector<RootState, AppState['guide']
>((state) => state.app.guide);

export const useIntervalSelector = () => useSelector<RootState, Interval
>((state) => state.userSettings.interval as Interval);

export const useInstanceIdSelector = () => useSelector<RootState, number
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.instanceScope?.id);

export const useInstanceSelector = () => useSelector<RootState, ServerInstance
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.instanceScope);

export const useInstancesSelector = () => useSelector<RootState, ServerInstance[]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.instances);

export const useFiltersSelector = () => useSelector<RootState
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.filters);

export const usePollIntervalSelector = () => useSelector<RootState, number
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.pollInterval);

export const useMe = () => useSelector<RootState
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.me);

export const useIntervalsSelector = () => useSelector<RootState, {
  display: string,
  name: string,
  unit: string;
  duration: number
}[]
  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
>((state) => state.userSettings.intervals);
