import xmlFormatter from 'xml-formatter';

const formatXml = (xml: string) => {
  try {
    return xmlFormatter(xml, { indentation: '    ', lineSeparator: '\n' });
  } catch {
    let formatted = xmlFormatter(`<xxx>${xml}</xxx>`, { indentation: '    ', lineSeparator: '\n' });
    formatted = formatted.replace(/^( {4})/gm, '');
    const splitArray = formatted.split('\n');
    return splitArray.slice(1, -1).join('\n');
  }
};

export default formatXml;
