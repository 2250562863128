import {
  hasNotification,
  notificationToggle,
} from 'components/organisms/TopBar/Partials/Notifications.utils';
import React from 'react';
import UserSettingChange from '../components/atoms/UserSettingChange';
import { UserPreferences, UserSettingsEnum } from '../constants/UserSettingsEnum';
import { toBool } from '../utils/string';
import { useInstanceIdSelector, usePreferencesSelector } from '../redux/hooks';
import { useQuery } from '@apollo/client';
import { getAutocomplete, GetAutocompleteDataResponse } from '../queries/filter';
import Loading from '../components/molecules/Loader/Loading';

const priorities = [
  { id: 0, name: 'Event' },
  { id: 1, name: 'High' },
  { id: 2, name: 'Medium' },
  { id: 3, name: 'Low' },
];

type NotificationList = UserPreferences['notification_alert'];

type AutocompleteRow = { CategoryName: string, CategoryDesc: string };

const UserSettings = () => {
  const preferences = usePreferencesSelector();
  const instanceId = useInstanceIdSelector();
  const {
    data,
    loading: notificationsLoading,
  } = useQuery<GetAutocompleteDataResponse<AutocompleteRow>>(getAutocomplete, {
    variables: {
      instance: instanceId,
      code: 'api.AlertCategories',
      smtConfig: true,
    },
  });

  const supportPush = 'Notification' in window;
  const isSsl = window.location.protocol === 'https:';

  const changeNotification = (current: NotificationList, toggle: string): NotificationList => ({
    ...current,
    ignore: notificationToggle(current.ignore, toggle),
  });

  if (notificationsLoading) {
    return <Loading />;
  }

  const notifications = (data?.getAutocomplete.data.values || []) as AutocompleteRow[];

  return (
    <>
      <header className="content__title">
        <h1>User settings</h1>
      </header>

      <div className="card">
        <div className="card-body">
          <h4 className="card-title">Notifications</h4>

          <div className="mb-5">
            <UserSettingChange code={UserSettingsEnum.SHOW_NOTIFICATION_ALERT}>
              {({ change, code, loading }) => (
                <>
                  <div
                    role="button"
                    className="custom-control custom-checkbox mb-2"
                    onClick={() => change({ ...preferences[code], enabled: !preferences[code].enabled })}
                  >
                    <input
                      type="checkbox"
                      className="custom-control-input"
                      checked={preferences[code].enabled}
                      disabled={loading}
                      readOnly
                    />
                    <label className="ms-2 custom-control-label">
                      Show notifications for new alerts
                    </label>
                  </div>

                  {preferences[code].enabled && (
                    <div className="ms-5">
                      <table className="table table-condensed" style={{ display: 'inline-block' }}>
                        <thead>
                          <tr>
                            <td>Notification category</td>
                            {priorities.map((priority) => (
                              <td key={priority.id.toString()}>
                                {priority.name}
                              </td>
                            ))}
                          </tr>
                        </thead>
                        <tbody>
                          {notifications.map((item) => (
                            <tr key={item.CategoryName}>
                              <th>
                                {item.CategoryName}
                                <br />
                                <small>{item.CategoryDesc}</small>
                              </th>
                              {priorities.map((priority) => (
                                <td key={priority.id.toString()}>
                                  <input
                                    type="checkbox"
                                    className="custom-control-input"
                                    checked={!hasNotification(
                                      preferences[code].ignore,
                                      `${item.CategoryName}:${priority.id.toString()}`,
                                    )}
                                    onChange={() => change(
                                      changeNotification(
                                        preferences[code],
                                        `${item.CategoryName}:${priority.id.toString()}`,
                                      ),
                                    )}
                                    disabled={loading}
                                  />
                                </td>
                              ))}
                            </tr>
                          ))}
                        </tbody>
                      </table>
                    </div>
                  )}
                </>
              )}
            </UserSettingChange>
          </div>

          <div>
            <UserSettingChange code={UserSettingsEnum.SHOW_NOTIFICATION_PUSH}>
              {({ change, code, loading }) => (
                <div
                  className="custom-control custom-checkbox mb-2"
                  onClick={() => change((!toBool(preferences[code])).toString())}
                >
                  <input
                    type="checkbox"
                    className="custom-control-input"
                    checked={toBool(preferences[code])}
                    disabled={loading}
                    readOnly
                  />
                  <label className="ms-2 custom-control-label">
                    Show push notifications for new alerts
                  </label>
                </div>
              )}
            </UserSettingChange>
          </div>

          {!supportPush && (
            <div className="alert alert-danger">
              Your browser does not support Push notifications
            </div>
          )}
          {!isSsl && (
            <div className="alert alert-danger">
              <p>
                SMT does not support Push notification, it must run with SSL to support that.
              </p>
              <p>
                How to
                {' '}
                <a href="https://clients.woodler.eu/documents/SMTSSL.pdf" target="_blank" rel="noreferrer">
                  set up SSL
                  connection
                </a>
              </p>
            </div>
          )}

          {supportPush
            && (Notification.permission === 'default'
              || Notification.permission === 'denied') && (
              <div className="card">
                <div className="card-body">
                  {Notification.permission === 'default' && (
                    <p>
                      <span
                        className="btn btn-lg btn-outline-theme"
                        onClick={() => Notification.requestPermission().then(() => {
                          /* ignore */
                        })}
                      >
                        Request permission
                      </span>
                    </p>
                  )}
                  {Notification.permission === 'denied' && (
                    <div>
                      <p>
                        To allow the notifications, you need to enable them in
                        browser settings
                        {' '}
                        {!isSsl && 'and SMT must run with SSL'}
                        .
                        <br />
                        Please see the documentation or contact us if you would
                        like to help with configuring SSL.
                      </p>
                      <p>How to allow browser notifications:</p>
                      <ul>
                        <li>
                          <a
                            href="https://support.google.com/chrome/answer/3220216?hl=en&co=GENIE.Platform%3DDesktop"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Chrome
                          </a>
                        </li>
                        <li>
                          <a
                            href="https://support.mozilla.org/en-US/kb/push-notifications-firefox"
                            target="_blank"
                            rel="noreferrer"
                          >
                            Firefox
                          </a>
                        </li>
                      </ul>
                    </div>
                  )}
                </div>
              </div>
          )}
        </div>
      </div>
    </>
  );
};

export default UserSettings;
