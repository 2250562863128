import React from 'react';
import PropTypes from 'prop-types';
import TopBar from 'components/organisms/TopBar/TopBar';
import SideBar from 'components/organisms/SideBar/SideBar';
import { userItems } from 'data/nav';
import text from 'data/text';
import { connect } from 'react-redux';
import { getRem } from '../../../utils/css';

const Nav = (props) => (
  <>
    <TopBar
      onMenuClick={() => {
        props.setNavCollapsed(false);
      }}
      hideTopBar={props.hideTopBar}
      belowLg={props.belowLg}
    />
    <SideBar
      hideNav={props.hideNav}
      paddingTop={props.belowLg ? getRem(40) : getRem(80)}
      offsetTop={props.belowLg ? 9.0 : 4.6}
        /* items={getMenuItems} */
      userItems={userItems}
      belowLg={props.belowLg}
      collapsed={props.navCollapsed}
      setCollapsed={props.setNavCollapsed}
      collapseMenuText={text.collapseMenu}
      shouldScrollMenu={props.shouldScrollNavMenu}
    />
  </>
);

Nav.defaultProps = {
  navCollapsed: true,
  hideTopBar: true,
};
Nav.propTypes = {
  navCollapsed: PropTypes.bool,
  hideNav: PropTypes.bool,
  hideTopBar: PropTypes.bool,
  inMapView: PropTypes.bool,
  setNavCollapsed: PropTypes.func,
  shouldScrollNavMenu: PropTypes.bool,
  belowLg: PropTypes.bool,
};

export default Nav;
