export const UserSettingsEnum = {
  SHOW_NOTIFICATION_PUSH_SETTINGS: 'notifications_push_settings',
  LAST_NOTIFICATIONS: 'last_notifications',
  SHOW_NOTIFICATION_ALERT: 'notification_alert',
  SHOW_NOTIFICATION_PUSH: 'notification_push',
  SHOW_MENU_DESCRIPTION: 'menu_description',
  BOOKMARKS: 'bookmarks',
};

export type UserPreferences = {
  notifications_push_settings: boolean;
  last_notifications: string;
  notification_alert: {
    enabled: boolean;
    ignore: string[];
  };
  notification_push: string;
  menu_description: string;
  bookmarks: string;
  intro: object;
};
