import actionTypes from './action-types';

const initialState = {
  statusInfo: {},
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.CLEAN_PAGE_STATE:
      return initialState;
    case actionTypes.SET_STATUS_INFO:
      return {
        ...state,
        statusInfo: {
          ...state.statusInfo,
          [action.payload.code]: action.payload.messages,
        },
      };

    default:
      return state;
  }
};

export default reducer;
