import React, { useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import PerfectScrollbar from 'react-perfect-scrollbar';
import TopBarItem from './TopBarItem';
import { stripTags } from 'utils/definitionParser';
import filterPages from '../../../../utils/filterPages';
import { PageDefinition } from '../../../../redux/userSettings/types';
import { usePagesSelector } from '../../../../redux/hooks';

const fulltextSearch = (items: PageDefinition[], search: string) => {
  const text = search.toLowerCase().split(' ');
  return items.filter((item) => text.every((el) => item.content?.toLowerCase()?.includes(el)
    || item.title?.toLowerCase()?.includes(el)
    || item.keywords?.toLowerCase()?.includes(el)));
};

const sortPagesByOrder = (pages: PageDefinition[], parentId: number | null = null) => {
  const result: PageDefinition[] = [];
  const children = pages.filter((item) => item.parent === parentId);

  children.sort((a, b) => ((a.order ?? 0) > (b.order ?? 0) && a.inMenu === true ? -1 : 1));

  children.forEach((child) => {
    result.push(child);
    result.push(...sortPagesByOrder(pages, child.id));
  });

  return result;
};

const Search = () => {
  const [search, setSearch] = useState('');
  const allPages = usePagesSelector();
  const pages: PageDefinition[] = useMemo(
    () => sortPagesByOrder(filterPages(allPages))
      .map((p) => ({
        ...p,
        content: stripTags(p.content),
      })).filter((p) => p.content.length > 10),
    [allPages],
  );

  const filteredPages = useMemo(() => fulltextSearch(pages, search), [search, pages]);

  const renderItems = () => {
    if (search.length === 0) {
      return <div className="text-center m-3">Start typing to search</div>;
    }
    if (filteredPages.length === 0) {
      return <div className="text-center m-3">No page found</div>;
    }

    const getTitle = (page: PageDefinition) => {
      if (!page.parent) {
        return page.title;
      }

      const titles: string[] = [];

      let currentPage: PageDefinition | undefined = page;
      while (currentPage?.parent) {
        const parentIndex = currentPage.parent;
        const parentPage: PageDefinition | undefined = allPages.find((p) => p.id === parentIndex);

        if (parentPage && parentPage.title) {
          titles.unshift(parentPage.title);
        }

        currentPage = parentPage;
      }

      if (page.title) {
        titles.push(page.title);
      }

      return titles.join(' > ');
    };

    return filteredPages
      .slice(0, 10)
      .map((p) => (
        <Link to={`/report/${p.id}`} key={p.id} className="listview__item">
          <h5>{stripTags(getTitle(p))}</h5>
          {stripTags(p.tooltip && p.tooltip?.length > 10 ? p.tooltip : p.content).substr(0, 200)}
        </Link>
      ));
  };

  /* eslint-disable jsx-a11y/no-autofocus */
  return (
    <TopBarItem
      dataIntroId="button-search"
      iconName="zwicon-search"
      items={(
        <>
          <div className="dropdown-header">
            <div className="row">
              <div className="col">
                <input
                  type="text"
                  className="form-control"
                  placeholder="Search pages..."
                  value={search}
                  onChange={(e) => setSearch(e.target.value)}
                  autoFocus
                />
              </div>
            </div>
          </div>
          <div className="listview listview--hover">
            <div
              className="os-content"
              style={{ padding: 0, height: '100%', width: '100%' }}
            >
              <PerfectScrollbar style={{ maxHeight: 300 }}>
                {renderItems()}
              </PerfectScrollbar>
            </div>
          </div>
        </>
      )}
      dropdownStyle={{ minWidth: '400px', maxWidth: '600px' }}
      onHide={() => {
        setSearch('');
      }}
      title="Search"
    />
  );
};

export default Search;
