import React from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import ReactJsonSyntaxHighlighter from 'react-json-syntax-highlighter';
import { connect } from 'react-redux';
import LazyDebugModal, { DebugModalProps } from './LazyDebugModal';
import DebugApiMessages from './DebugApiMessages';
import CopyClipboard from '../../atoms/CopyClipboard';

type ApiResponse = Partial<{ data: { data: { [key: string]: any }[] }; messages: object[]; errors: object[] }>;

type Props = DebugModalProps & {
  code: string;
  filter: object[];
  definition: ApiResponse;
  data: ApiResponse;
};

class RequestDebugModal<P extends Props> extends LazyDebugModal<P> {
  getContent = () => {
    const {
      code, definition, data, filter,
    } = this.props;

    return (
      <>
        <div>
          <b>Code:</b>
          {' '}
          {code}
        </div>
        <Tabs>
          <Tab title="Request" eventKey="request">
            <div>
              <b>Filter:</b>
              {' '}
              {filter ? (
                <>
                  <CopyClipboard text={JSON.stringify(filter)} />
                  {' '}
                  <ReactJsonSyntaxHighlighter obj={filter} />
                </>
              ) : null}
            </div>
          </Tab>
          <Tab title="Definition" eventKey="definition">
            <div>
              <b>Config:</b>
              {' '}
              {get(definition, 'data') ? (
                <>
                  <CopyClipboard text={JSON.stringify(definition?.data || {})} />
                  {' '}
                  <ReactJsonSyntaxHighlighter obj={definition?.data} />
                </>
              ) : null}
            </div>
          </Tab>
          <Tab title="Response data" eventKey="response">
            {data ? (
              <>
                <div>
                  <b>Returned count:</b>
                  {' '}
                  {data?.data?.data?.length}
                </div>
                <div>
                  <b>Data:</b>
                  {' '}
                  {data ? (
                    <>
                      <CopyClipboard text={JSON.stringify(data?.data || {})} />
                      {' '}
                      <ReactJsonSyntaxHighlighter obj={data?.data} />
                    </>
                  ) : null}
                </div>
              </>
            ) : null}
          </Tab>
          <Tab title="Messages" eventKey="messages">
            <div>
              <b>Definition</b>
              {get(definition, 'messages') ? (
                <DebugApiMessages messages={definition.messages} />
              ) : (
                ''
              )}
            </div>
            <div>
              <b>Data</b>
              {get(data, 'messages') ? (
                <DebugApiMessages messages={data.messages} />
              ) : (
                ''
              )}
            </div>
          </Tab>
          <Tab title="Errors" eventKey="errors">
            <div>
              <b>Definition errors</b>
              {get(definition, 'errors') ? (
                <DebugApiMessages messages={definition.errors} />
              ) : (
                ''
              )}
            </div>
            <div>
              <b>Data errors</b>
              {get(data, 'errors') ? (
                <DebugApiMessages messages={data.errors} />
              ) : (
                ''
              )}
            </div>
          </Tab>
        </Tabs>
      </>
    );
  };
}

export default connect(
  (state) => ({
    debug: get(state, 'userSettings.debug'),
  }),
  {},
// @ts-ignore
)(RequestDebugModal);
