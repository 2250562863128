import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Auth from 'containers/Auth';
import { Link } from 'react-router-dom';
import TopBarWrapper from './Partials/TopBarWrapper';
import InstanceSelector from './Partials/InstanceSelector';
import Gap from '../../atoms/Gap';
import GlobalFilter from '../../molecules/GlobalFilter';
import TopBarItem from './Partials/TopBarItem';
import ThemeSelector from './Partials/ThemeSelector';
import { saveLiveDataPreference } from '../../../redux/userSettings/actions';
import getEnvVal from '../../../constants/getEnvVal';
import Notifications from './Partials/Notifications';
import MemoryUsage from './Partials/MemoryUsage';
import { RootState } from '../../../redux/store';
import Search from './Partials/Search';
import UserMenu from './Partials/UserMenu';
import SupportLink from './Partials/SupportLink';
import Version from './Partials/Version';

type TopBarProps = {
  belowLg: boolean;
} & Partial<{
  onMenuClick: () => void;
}>;

const TopBar = ({
  onMenuClick = () => undefined,
  belowLg,
  ...otherProps
}: TopBarProps) => {
  const isLive = useSelector<RootState, boolean>((state) => state.userSettings.isLive);
  const pollInterval = useSelector<RootState, number>((state) => state.userSettings.pollInterval as number);
  const dispatch = useDispatch();

  const [scrolled, setScrolled] = useState(false);

  const handleScroll = () => {
    setScrolled(window.scrollY > 10);
  };

  useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const [showThemes, setShowThemes] = useState(false);

  return (
    <TopBarWrapper {...otherProps} scrolled={scrolled} isMobile={belowLg}>
      {belowLg && (
        <div
          className="navigation-trigger"
          data-sa-action="aside-open"
          data-sa-target=".sidebar"
          role="button"
          onClick={onMenuClick}
        >
          <i className="zwicon-hamburger-menu" />
        </div>
      )}
      {!belowLg && (
        <div className="logo d-none d-sm-inline-flex">
          <Link to="/">
            <div style={{ float: 'left' }}>
              <img src="/logo.png" alt="SMT" />
            </div>
            <div style={{ float: 'left', margin: '10px 0 0 20px' }}>
              Woodler SMT
              <br />
              <small><Version /></small>
            </div>
          </Link>
        </div>
      )}
      <Auth inline>
        <div data-intro-id="top-filter" className="top-nav top-nav-left" style={{ display: 'flex' }}>
          <ul className="top-nav">
            <InstanceSelector />
          </ul>
          <Gap />
          <GlobalFilter />
        </div>
        <ul className="top-nav">
          <MemoryUsage />
          <Search />
          {getEnvVal('NOTIFICATIONS_ENABLE') && <Notifications />}
          <TopBarItem
            dataIntroId="button-auto-refresh"
            title="Auto refresh"
            badge={
              isLive && pollInterval
                ? `${Math.round(pollInterval / 1000)} sec`
                : null
            }
            iconClass={isLive && pollInterval ? 'text-success' : undefined}
            iconName="zwicon-history"
            items={[
              ...[
                // eslint-disable-next-line jsx-a11y/anchor-is-valid
                <a
                  href="#"
                  key="none"
                  className="dropdown-item"
                  onClick={(e) => {
                    e.preventDefault();
                    return dispatch(saveLiveDataPreference(false));
                  }}
                >
                  Disable
                </a>,
              ],
              ...((getEnvVal('AUTO_REFRESH_INTERVALS')
                .split(',') as unknown) as number[])
                .map((t) => (
                  // eslint-disable-next-line jsx-a11y/anchor-is-valid
                  <a
                    href="#"
                    key={t}
                    className="dropdown-item"
                    role="button"
                    onClick={(e) => {
                      e.preventDefault();
                      return dispatch(saveLiveDataPreference(t * 1000));
                    }}
                  >
                    {t}
                    {' '}
                    sec
                  </a>
                )),
            ]}
          />
          <TopBarItem
            dataIntroId="button-theme"
            iconName="zwicon-palette"
            onClick={() => setShowThemes(true)}
            items={[]}
            title="Visual themes"
          />
          <SupportLink key="support" type="icon" />
          <UserMenu />
        </ul>
        {showThemes && <ThemeSelector onHide={() => setShowThemes(false)} />}
      </Auth>
    </TopBarWrapper>
  );
};

export default TopBar;
