import { useEffect, useState } from 'react';

const routesToHideNavIn = ['profile', 'map'];

const getHideNav = (locationPathname) => routesToHideNavIn.some((i) => locationPathname.includes(i));

export const useHideNav = (locationPathname) => {
  const [hideNav, setHideNav] = useState(getHideNav(locationPathname));
  useEffect(() => {
    const newHideNav = getHideNav(locationPathname);
    if (newHideNav !== hideNav) {
      setHideNav(newHideNav);
    }
  }, [locationPathname]);
  return hideNav;
};
