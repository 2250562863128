import React, {
  useCallback, useEffect, useMemo, useRef, useState,
} from 'react';
import { useLazyQuery } from '@apollo/client';
import { useSelector } from 'react-redux';
import { graphqlInterval } from '../../utils/definitionParser';
import { loadDashboardData, LoadDashboardDataResponse } from '../../queries/dashboard';
import SparklineWidget from '../molecules/Widgets/SparklineWidget';
import ErrorMessage from './ErrorMessage';
import useInterval from '../../hooks/useInterval';
import { RootState } from '../../redux/store';
import { IntervalState } from '../../types/GlobalFilter';
import { useCompare } from '../../hooks/useCompare';

type Props = {
  instanceScope: number;
  sparklines: Array<{
    key: string;
    title: string;
  } & Partial<{
    class: string;
  }>>;
  interval: IntervalState;
} & Partial<{
  pollInterval: number;
  title: string;
}>;

const SparklineGroup: React.FC<Props> = ({
  instanceScope,
  sparklines = [],
  interval,
  title = '',
}) => {
  const pollInterval = useSelector<RootState, number>((state) => state?.userSettings?.pollInterval as number || 0);
  const [data, setData] = useState<LoadDashboardDataResponse | undefined>();
  const [hasData, setHasData] = useState(false);

  const widgetLoading = useRef(true);
  const [fetchData, { called, loading }] = useLazyQuery<LoadDashboardDataResponse>(loadDashboardData, {
    variables: { instanceId: instanceScope, interval: graphqlInterval(interval) },
    onCompleted: (result) => {
      setData(result);
      setHasData(true);
    },
    pollInterval: pollInterval || 0,
  });

  const refetchData = useCallback(() => {
    if (instanceScope && graphqlInterval(interval)) {
      fetchData({
        variables: { instanceId: instanceScope, interval: graphqlInterval(interval) },
      }).catch(undefined);
    }
  }, [fetchData, instanceScope, interval]);

  const filterChanged = useCompare({ instanceScope, interval });
  useEffect(() => {
    if (filterChanged) {
      refetchData();
    }
  }, [fetchData, instanceScope, interval, refetchData, filterChanged]);

  useInterval(() => {
    refetchData();
  }, pollInterval);

  const kpi = useMemo(() => {
    if (!data) {
      return {};
    }

    const kpiData: { [key: string]: number[] } = {};
    [...data?.getKpi?.data?.values || []]
      .sort((a, b) => Date.parse(a.STAMP) - Date.parse(b.STAMP))
      .forEach((v) => {
        if (!Object.prototype.hasOwnProperty.call(kpiData, v.Cntr)) {
          kpiData[v.Cntr] = [];
        }
        kpiData[v.Cntr].push(v.Cntr_value);
      });
    widgetLoading.current = false;

    return kpiData;
  }, [data]);

  if (!data?.getKpi?.data?.values && data?.getKpi?.errors) {
    return (
      <div className="card">
        <div className="card-body">
          <h4 className="card-title">{title}</h4>
          <div className="row">
            <div className="col">
              <ErrorMessage>Error loading data</ErrorMessage>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="card">
      <div className="card-body">
        <h4 className="card-title">{title}</h4>
        <div className="row">
          {sparklines.map((item) => (
            <div className="col-6" key={item.key}>
              <SparklineWidget
                data={kpi[item.key] || []}
                loading={(!called || loading) && !hasData}
                subtitle={item.title}
                className={item.class || ''}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SparklineGroup;
