import React, { useEffect, useState } from 'react';
import { Text, Wrapper } from './styles';
import { ReactComponent as NoConnectionIcon } from '../../../assets/icons/app/no-connection.svg';
import SVGWrapper from '../../atoms/SVGWrapper';
import { getRem } from '../../../utils/css';

const iconStyles = {
  width: getRem(48),
  height: getRem(48),
  padding: getRem(20),
};

const OfflineBanner = ({ isMobile, show }) => {
  const [shouldRender, setRender] = useState(show);
  useEffect(() => {
    if (show) {
      setRender(true);
    }
    // eslint-disable-next-line
  }, [show]);

  const onAnimationEnd = () => {
    if (!show) {
      setRender(false);
    }
  };
  return (
    shouldRender && (
      <Wrapper isMobile={isMobile} onAnimationEnd={onAnimationEnd} show={show}>
        <SVGWrapper
          width={iconStyles.width}
          height={iconStyles.height}
          padding={iconStyles.padding}
        >
          <NoConnectionIcon />
        </SVGWrapper>
        <Text>
          You seem to be offline. You may not be able to use some functions.
        </Text>
      </Wrapper>
    )
  );
};

export default OfflineBanner;
