import gql from 'graphql-tag';

const getInstances = gql`
  query getInstances {
    getInstances {
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
      data {
        instances {
          id
          groupName
          name
        }
      }
    }
  }
`;

export default getInstances;
