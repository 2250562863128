import gql from 'graphql-tag';

export const getDownload = gql`
  query getDownload(
    $instanceId: Int!
    $primaryId: String!
    $secondaryId: String
    $type: String
  ) {
    download(
      instanceId: $instanceId
      primaryId: $primaryId
      secondaryId: $secondaryId
      type: $type
    ) {
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
      data {
        link
      }
    }
  }
`;
