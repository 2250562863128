import types from './action-types';

export const getPages = () => ({
  type: types.GET_PAGES,
});

export const getPagesSuccess = (payload) => ({
  type: types.GET_PAGES_SUCCESS,
  payload,
});

export const getPagesError = (payload = {}) => ({
  type: types.GET_PAGES_ERROR,
  payload,
});

export const getInstances = () => ({
  type: types.GET_INSTANCES,
});

export const saveInstanceScope = (payload) => ({
  type: types.SAVE_INSTANCE_SCOPE,
  payload,
});

export const getInstancesSuccess = (payload) => ({
  type: types.GET_INSTANCES_SUCCESS,
  payload,
});

export const getIntervals = () => ({
  type: types.LOAD_INTERVALS,
});

export const getIntervalsSuccess = (payload) => ({
  type: types.LOAD_INTERVALS_SUCCESS,
  payload,
});

export const getInstancesError = (payload = {}) => ({
  type: types.GET_INSTANCES_ERROR,
  payload,
});

export const getLiveDataPreference = () => ({
  type: types.GET_LIVE_DATA_PREFERENCE,
});

export const saveLiveDataPreference = (payload) => ({
  type: types.SAVE_LIVE_DATA_PREFERENCE,
  payload,
});

export const loadFilterPreference = () => ({
  type: types.LOAD_FILTER_PREFERENCE,
});

export const loadFilterSuccess = (payload) => ({
  type: types.LOAD_FILTER_SUCCESS,
  payload,
});

export const loadFilterError = (payload) => ({
  type: types.LOAD_FILTER_ERROR,
  payload,
});

export const setFilter = (key, value) => ({
  type: types.SET_FILTER,
  payload: { key, value },
});

export const unsetFilter = (key) => ({
  type: types.UNSET_FILTER,
  payload: { key },
});

export const resetFilter = () => ({
  type: types.RESET_FILTER,
  payload: {},
});

export const saveFilterPreference = (page, filter, silent = false) => ({
  type: types.SAVE_FILTER_PREFERENCE,
  payload: { page, filter, silent },
});

export const loadIntervalPreference = () => ({
  type: types.LOAD_INTERVAL_PREFERENCE,
});

export const loadIntervalSuccess = (payload) => ({
  type: types.LOAD_INTERVAL_SUCCESS,
  payload,
});

export const loadIntervalError = (payload) => ({
  type: types.LOAD_INTERVAL_ERROR,
  payload,
});

export const saveIntervalPreference = (payload) => ({
  type: types.SAVE_INTERVAL_PREFERENCE,
  payload,
});

export const setThemePreference = (payload) => ({
  type: types.SET_THEME_PREFERENCE,
  payload,
});

export const setDebugMode = (payload) => ({
  type: types.SET_DEBUG,
  payload,
});

export const getUser = () => ({
  type: types.GET_USER,
});

export const getUserSuccess = (payload) => ({
  type: types.GET_USER_SUCCESS,
  payload,
});

export const getUserError = (payload = {}) => ({
  type: types.GET_USER_ERROR,
  payload,
});

export const setUserSetting = (code: string, value: string) => ({
  type: types.SET_USER_SETTING,
  payload: { code, value },
});
