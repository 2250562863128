import React, { CSSProperties, useRef, useState } from 'react';
import PerfectScrollbar from 'react-perfect-scrollbar';
import useOutsideClick from '../../../../hooks/useOutsideClick';

const badgeStyle = {
  position: 'absolute',
  right: '0px',
  bottom: '0px',
  fontSize: '0.6rem',
} as CSSProperties;

type Props = {
  disableHide?: boolean;
  dropdownClass?: string;
  dropdownStyle?: CSSProperties;
  hideOnClick?: boolean;
  items: string | string[] | React.ReactElement | React.ReactElement[] | null[] | any | ({ hide: () => void });
} & Partial<{
  badge: number | string | null;
  dataIntroId: string;
  iconName?: string;
  iconClass?: string;
  itemWrapperClassName?: string;
  label?: string | React.ReactElement;
  onClick?: () => void;
  onHide?: () => void;
  onShow?: () => void;
  position: 'start' | 'end';
  scrollable?: boolean;
  title?: string;
}>;

const TopBarItem = ({
  badge,
  disableHide,
  label,
  hideOnClick,
  iconName,
  iconClass,
  itemWrapperClassName,
  items,
  onClick,
  title,
  dropdownClass,
  dropdownStyle,
  position = 'end',
  ...props
}: Props) => {
  const [visible, setVisible] = useState(false);
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => {
    if (disableHide) {
      return;
    }

    if (props.onHide) {
      props.onHide();
    }
    setVisible(false);
  });

  let filteredItems: Props['items'];
  if (Array.isArray(items)) {
    filteredItems = (items).filter((i) => i !== null);
  } else if (typeof items === 'function') {
    filteredItems = items({
      hide: () => {
        if (props.onHide) {
          props.onHide();
        }
        setVisible(false);
      },
    });
  } else {
    filteredItems = items;
  }

  return (
    <li
      ref={wrapperRef}
      className="dropdown d-none d-sm-inline-block"
      data-intro-id={props.dataIntroId}
    >
      {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
      <a
        href="#"
        title={title || ''}
        role="button"
        data-toggle="dropdown"
        onClick={(e) => {
          e.preventDefault();
          if (visible && props.onHide) {
            props.onHide();
          } else if (!visible && props.onShow) {
            props.onShow();
          }

          if (onClick) {
            onClick();
          } else {
            setVisible(!visible);
          }
        }}
        style={{ lineHeight: '18px' }}
      >
        {label || <i className={iconName} />}
        {badge && (
          <span className="badge bg-info" style={badgeStyle}>
            {badge}
          </span>
        )}
      </a>
      {visible && (
        <div
          className={`dropdown-menu dropdown-menu-${position} ${dropdownClass || ''}${
            visible ? ' show' : ''
          }`}
          role="button"
          style={dropdownStyle}
          onClick={() => hideOnClick && setVisible(false)}
        >
          {props.scrollable ? (
            <PerfectScrollbar style={{ maxHeight: 300 }}>
              {filteredItems}
            </PerfectScrollbar>
          ) : (
            filteredItems
          )}
        </div>
      )}
    </li>
  );
};

TopBarItem.defaultProps = {
  disableHide: false,
  hideOnClick: false,
  scrollable: false,
  dropdownClass: '',
  dropdownStyle: {},
  onShow: () => { /* no action */ },
  onHide: () => { /* no action */ },
};

export default TopBarItem;
