import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Loading from '../Loader/Loading';
import SparklineBar from './SparklineBar';

const sparklineProps = {
  ariaLabel: 'This is a Sparkline of...',
  width: 100,
  height: 50,
  margin: {
    top: 0, right: 0, bottom: 0, left: 0,
  },
};

type BadgeInfoKey = 'state' | 'icon';
type Badges = 'up' | 'down' | 'line';

const badgeMap: { [key in Badges]: { [typeKey in BadgeInfoKey]: string }} = {
  up: { state: 'danger', icon: 'up' },
  down: { state: 'success', icon: 'down' },
  line: { state: 'info', icon: 'right' },
};

const getBadge = (type: 'state' | 'icon', avg: number, last: number) => {
  let key: Badges = 'line';
  if (avg > last) {
    key = 'down';
  } else if (avg < last) {
    key = 'up';
  }

  return badgeMap[key][type];
};

type SparklineWidgetProps = {
  subtitle: string;
  data: number[];
  loading: boolean;
  className: string;
} & Partial<{
  title: string;
  badge: React.ReactNode;
}>;

const SparklineWidget: React.FC<SparklineWidgetProps> = ({
  badge,
  data: dataInput,
  loading,
  title,
  subtitle,
  className,
}) => {
  if (loading) {
    return <Loading />;
  }

  if (!dataInput || dataInput.length === 0) {
    return <>No data</>;
  }

  const data = dataInput.slice(0, sparklineProps.width);

  let renderBadge = badge;
  if (!renderBadge && data) {
    const avg = data.length > 0 ? Math.round((data.reduce((a, b) => a + b) / data.length) * 100) / 100 : 0;
    const last = data[data.length - 1];
    renderBadge = (
      <div className={`badge bg-${getBadge('state', avg, last)}`}>
        AVG
        {' '}
        {avg}
        {' '}
        <FontAwesomeIcon icon={['fas', `arrow-${getBadge('icon', avg, last)}` as unknown as any]} />
      </div>
    );
  }
  const renderTitle = () => {
    if (!title && data) {
      return data[data.length - 1];
    }
    return title;
  };

  return (
    <div
      className={`quick-stats__item ${className}`}
      style={{
        position: 'relative',
        fontSize: '0.7rem',
        padding: '1.85rem 1.0rem 1.0rem 1rem',
      }}
    >
      {renderBadge ? (
        <div
          style={{
            position: 'absolute', left: '0px', top: '0px', right: 'auto',
          }}
        >
          {renderBadge}
        </div>
      ) : null}
      <div className="quick-stats__info">
        <h2>{renderTitle() || '0'}</h2>
        {subtitle && <small>{subtitle}</small>}
      </div>
      <div className="peity">
        <SparklineBar data={data} {...sparklineProps} />
      </div>
    </div>
  );
};

export default SparklineWidget;
