import { applyMiddleware, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import { createBrowserHistory } from 'history';
import { routerMiddleware } from 'connected-react-router';
import reducers from './reducers';
import sagas from './sagas';
import { State as ErrorState } from './error/reducer';

export const history = createBrowserHistory();

const configureStore = (initialState = {}) => {
  const isEnvDev = process.env.NODE_ENV === 'development';

  const sagaMiddleware = createSagaMiddleware();
  const middlewares = [routerMiddleware(history), sagaMiddleware];

  const enhancer = isEnvDev
    && typeof window === 'object'
    && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
      // Specify extension’s options like name, actionsBlacklist, actionsCreators, serialize...
    })
    : compose;

  const reduxStore = createStore(
    reducers(history),
    initialState,
    enhancer(
      applyMiddleware(...middlewares),
      // persistState(["userSettings"], {
      //   // TODO this might cause an issue cuz its not getting cleared on logout
      //   key: "",
      // })
    ),
  );

  sagaMiddleware.run(sagas);

  return reduxStore;
};

export const store = configureStore();

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = Omit<ReturnType<typeof store.getState>, 'error'> & {
  error: ErrorState
};

// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
