import React from 'react';
import { Modal } from 'react-bootstrap';
import Filter, { FilterType, Props as FilterProps } from './index';

type Props = FilterProps & {
  title: string;
  placeholder: string;
};

const FilterInput: React.FC<Props> = ({
  onChange, placeholder, title, ...props
}) => {
  const { filter } = props;
  const value = filter;
  const [showModal, setShowModal] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<FilterType>(value || {});

  return (
    <>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={JSON.stringify(currentValue)}
          readOnly
        />
        <button
          className="input-group-text btn btn-secondary"
          onClick={() => setShowModal(true)}
          type="button"
        >
          <i className="fa fa-edit" />
          {' '}
          edit
        </button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{title || ' '}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Filter
            {...props}
            manualSubmit
            submitTitle="Save"
            onChange={(val: FilterType) => {
              setCurrentValue(val);
              if (onChange) {
                onChange(val, () => undefined);
              }
              setShowModal(false);
            }}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};

export default FilterInput;
