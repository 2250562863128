import React, { useEffect, useState, useMemo } from 'react';
import get from 'lodash/get';
import debounce from 'lodash/debounce';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { useNavigate } from 'react-router-dom';
import ErrorMessage from '../../atoms/ErrorMessage';
import ControlBox from './ControlBox';
import Select from './Select';
import text from '../../../data/text';
import { parseParams } from '../../../utils/definitionParser';
import FilterHistory from './FilterHistory';
import getEnvVal from '../../../constants/getEnvVal';
import DatePickerTimeInput from '../../atoms/DatePickerTimeInput';
import { saveFilterPreference } from '../../../redux/userSettings/actions';
import { parseDynamicFilter, urlFilterStore } from './helpers';
import './index.styles.css';
import { useCompare } from '../../../hooks/useCompare';
import {
  ControlCheckboxType, ControlDateTimeType, ControlDropdownType,
  ControlHiddenType, ControlSelectType, ControlTextType, ControlType,
} from './index.d';
import MultiInput from './MultiInput';
import FilterInput from './FilterInput';

type FieldError = { field: string, error: string };
type GlobalError = string;
export type FilterValue = string | string[] | number | number[] | boolean | null;
export type FilterType = { [key: string]: FilterValue };

export type { ControlType };

export const isDateTimeControl = (
  control: ControlType,
): control is ControlDateTimeType => control.type === 'date' || control.type === 'time';
export const isTextControl = (
  control: ControlType,
): control is ControlTextType => control.type === 'text' || control.type === 'input';
export const isDropdownControl = (control: ControlType): control is ControlDropdownType => control.type === 'dropdown';
export const isSelectControl = (control: ControlType): control is ControlSelectType => control.type === 'select';
export const isCheckboxControl = (
  control: ControlType,
): control is ControlCheckboxType => control.type === 'checkbox' || control.type === 'checkbox_input';
export const isHiddenControl = (control: ControlType): control is ControlHiddenType => control.type === 'hidden';

export type Props = {
  // Name of the filter, should be unique on the page
  name: string;
  // ID of the selected server
  instanceScope: number;
  // List of filter inputs
  controls: ControlType[];
} & Partial<{
  defaultFilter: FilterType;
  // Values for current filter
  filter: FilterType;
  interval?: object;
  layout: 'default' | 'compact';
  onChange: (
    values: FilterType,
    onError: (errors: Array<FieldError | GlobalError>) => void
  ) => void;
  wrapperClassName: string;
  submitTitle: string;
  // Filter must be submitted manually with a button
  manualSubmit: boolean;
  // Add button to clear filter values to default
  clearButton: boolean;
  // Number of used filters which will be shown in the filter box
  historySize: number;
  // Direction of the fields
  direction: 'vertical' | 'horizontal';
  // Id under which the state will be stored
  contextId: string;
  // Enable storages
  storage: { browser?: boolean; url?: boolean };
}>;

const delayedOnFilterSet = debounce(
  (cb: () => void) => {
    cb();
  },
  getEnvVal('FILTER_DEBOUNCE_INTERVAL'),
  { trailing: true },
);

const asBool = (val: string | boolean): boolean => {
  if (typeof val === 'boolean') {
    return val;
  }

  return val === 'true' || val === '1';
};

const Filter: React.FC<Props> = ({
  manualSubmit = false,
  submitTitle = text.filterSubmit,
  clearButton = false,
  direction = 'horizontal',
  filter = {},
  onChange = () => undefined,
  layout = 'default',
  defaultFilter,
  storage = { browser: false, url: false },
  ...props
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [errors, setErrors] = useState<Array<FieldError | GlobalError>>([]);

  const cleanDependentFilters = (key: string) => {
    let changeState = {};
    props.controls.forEach((control) => {
      if (
        isSelectControl(control)
        && control.query
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        && control.query.queryParams
      ) {
        if (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
          parseParams(control.query.queryParams)
            .map((c: { field: string }) => c.field)
            .includes(key)
        ) {
          changeState[control.filterKey] = '';
          changeState = {
            ...changeState,
            ...cleanDependentFilters(control.filterKey),
          };
        }
      }
    });

    return changeState;
  };

  function onFilterSet(changeState: FilterType, submit = false) {
    setFilterState({ ...filterState, ...changeState });
    if (!manualSubmit || submit) {
      handleChange({ ...filterState, ...changeState });
    }
  }

  function onControlChange(key: string | undefined, value: FilterValue, delayed = false) {
    if (!key) {
      return;
    }

    let changeState = { [key]: value };
    props.controls.forEach(() => {
      changeState = { ...changeState, ...cleanDependentFilters(key) };
    });
    setValueState({ ...filterState, ...changeState });
    if (delayed) {
      delayedOnFilterSet(() => {
        onFilterSet(changeState);
      });
    } else {
      onFilterSet(changeState);
    }
  }

  const handleChange = (newFilter: { [key: string]: FilterValue }) => {
    if (storage) {
      if (storage.browser && props.contextId) {
        dispatch(saveFilterPreference(props.contextId, newFilter));
      }
      if (storage.url) {
        urlFilterStore(newFilter, navigate);
      }
    }
    setErrors([]);
    if (onChange) {
      onChange(newFilter, (err) => { setErrors(err); });
    }
  };

  const defaultState: FilterType = useMemo(() => {
    const val: FilterType = {};
    props.controls.forEach((control) => {
      if ('defaultValue' in control) { // Has value defined in filter definition JSON
        val[control.filterKey] = control.defaultValue as string | boolean;
      } else if (control.type === 'checkbox' || control.type === 'checkbox_input') {
        val[control.filterKey] = false;
      } else {
        val[control.filterKey] = '';
      }
    });

    if (!isEmpty(defaultFilter)) {
      Object.keys(defaultFilter).forEach((k) => {
        val[k] = (defaultFilter)[k];
      });
    }

    return val;
  }, [props.controls]);

  // Inner state of the filter
  const [filterState, setFilterState] = useState(
    !isEmpty(filter) ? { ...defaultState, ...filter } : defaultState,
  );

  const [valueState, setValueState] = useState(
    !isEmpty(filter) ? { ...defaultState, ...filter } : defaultState,
  );
  const filterChanged = useCompare(filter);
  useEffect(() => {
    if (filterChanged) {
      setFilterState(!isEmpty(filter) ? { ...defaultState, ...filter } : defaultState);
      setValueState(!isEmpty(filter) ? { ...defaultState, ...filter } : defaultState);
    }
  }, [filter, filterChanged, defaultState]);
  const required = useMemo(
    () => props.controls.filter((c) => c.required).map((c) => c.filterKey),
    [props.controls],
  );

  if (
    !props.controls
    || !Array.isArray(props.controls)
    || props.controls.length === 0
  ) {
    return <ErrorMessage>Filter not defined</ErrorMessage>;
  }

  function getFilterDefault<T extends string | boolean>(control: ControlType, defaultValue: T): T {
    return get(filterState, control.filterKey, defaultValue) as T;
  }

  /**
   * @param {number} number
   * @return {string}
   */
  function zeropad(number: number): string {
    return (number < 10 ? '0' : '') + number.toString();
  }

  /**
   * @param {string} dateString
   * @return {Date|null}
   */
  function parseDate(dateString: string) {
    const parts: string[] | null = /^(\d{4})-(\d{1,2})-(\d{1,2})$/.exec(dateString);
    if (parts) {
      return new Date(
        parseInt(parts[1], 10),
        parseInt(parts[2], 10) - 1,
        parseInt(parts[3], 10),
        0,
        0,
        0,
        0,
      );
    }
    return null;
  }

  /**
   * @param {Date} date
   * @return {string}
   */
  function formatDate(date: Date) {
    return `${date.getFullYear()}-${zeropad(date.getMonth() + 1)}-${zeropad(
      date.getDate(),
    )}`;
  }

  /**
   * @param {string} dateString
   * @return {Date|null}
   */
  function parseTime(dateString: string) {
    const parts: string[] | null = /^(\d{1,2}):(\d{1,2})$/.exec(dateString);
    if (parts) {
      const date = new Date();
      date.setHours(parseInt(parts[1], 10));
      date.setMinutes(parseInt(parts[2], 10));
      return date;
    }
    return null;
  }

  /**
   * @param {Date} date
   * @return {string}
   */
  function formatTime(date: Date) {
    return `${zeropad(date.getHours())}:${zeropad(date.getMinutes())}`;
  }

  let wrapperClass = 'filter-card row mt-2 align-items-start';
  let itemGridClass = direction === 'vertical' ? 'col-12' : 'col-2';
  let wrapperStyle = {};
  if (props.wrapperClassName) {
    wrapperClass = props.wrapperClassName;
  } else if (layout === 'compact') {
    wrapperClass = 'mt-2 mb-2';
    itemGridClass = '';
    wrapperStyle = { display: 'flex', flexWrap: 'wrap' };
  }

  wrapperClass += ' inline';
  return (
    <div className={wrapperClass} style={wrapperStyle}>
      {errors.length > 0
        && <div className="mb-2 col-12"><ErrorMessage>{errors.map((e) => <p>{e.toString()}</p>)}</ErrorMessage></div>}
      {props.historySize && props.historySize > 0 && (
        <div className={itemGridClass}>
          <FilterHistory
            filterKeys={props.controls.map((c) => c.filterKey)}
            filter={filter}
            size={props.historySize}
            onFilterChange={(value) => {
              const changeState = {};
              value.forEach((f) => {
                changeState[f.filterKey] = f.value;
              });
              onFilterSet(changeState, true);
            }}
          />
        </div>
      )}
      {props.controls.map((control) => {
        if (control.type === 'hidden') {
          return null;
        }
        const itemClass = direction !== 'vertical' && control.gridWidth
          ? `col-${control.gridWidth.toString()}`
          : itemGridClass;

        const additionalProps: { [key: string]: any } = {};
        if (control?.readonly) {
          additionalProps.readOnly = 'readonly';
        }
        if (control?.required) {
          additionalProps.required = 'required';
        }
        if ('addEmptyVal' in control) {
          additionalProps.addEmptyVal = control.addEmptyVal === true;
        }
        if ('placeholder' in control) {
          additionalProps.placeholder = control.placeholder;
        }

        if (control.type === 'checkbox' || control.type === 'checkbox_input') {
          return (
            <ControlBox key={control.filterKey} control={control} className={itemClass}>
              <div>
                <div
                  className="toggle-switch"
                  style={{ top: '5px' }}
                  role="button"
                  onClick={(event: any) => !additionalProps.readOnly
                    && onControlChange(control.filterKey, event.target.checked)}
                >
                  <input
                    type="checkbox"
                    className="toggle-switch__checkbox"
                    checked={asBool(get(valueState, control.filterKey, false) as string | boolean)}
                    onChange={() => {
                      /* no additional actions */
                    }}
                  />
                  <i className="toggle-switch__helper" />
                </div>
              </div>
            </ControlBox>
          );
        }
        if (control.type === 'input') {
          return (
            <ControlBox key={control.filterKey} control={control} className={itemClass}>
              <input
                type="text"
                className="form-control"
                placeholder={control.placeholder || control.filterKey}
                value={get(valueState, control.filterKey, '') as string}
                onChange={(event) => onControlChange(control.filterKey, event.target.value, true)}
                style={control.inputHeight ? { height: control.inputHeight } : {}}
                {...additionalProps}
              />
            </ControlBox>
          );
        }
        if (control.type === 'multi') {
          return (
            <ControlBox
              key={control.filterKey}
              control={control}
              className={`${itemClass} fullWidth`}
            >
              <MultiInput
                placeholder={control.placeholder || control.filterKey}
                value={get(valueState, control.filterKey, '') as string}
                onChange={(value) => onControlChange(control.filterKey, value, true)}
                title={control.title}
                {...additionalProps}
              />
            </ControlBox>
          );
        }
        if (control.type === 'text') {
          return (
            <ControlBox key={control.filterKey} control={control} className={itemClass}>
              <textarea
                className="form-control"
                placeholder={control.placeholder || control.filterKey}
                value={get(valueState, control.filterKey, '') as string}
                onChange={(event) => onControlChange(control.filterKey, event.target.value, true)}
                rows={control.rows ? control.rows : 1}
                {...additionalProps}
              />
            </ControlBox>
          );
        }
        if (control.type === 'dropdown' || control.type === 'select') {
          return (
            <ControlBox key={control.filterKey} control={control} className={itemClass}>
              <Select
                control={control}
                instanceScope={props.instanceScope}
                interval={props.interval}
                filters={filterState}
                value={get(valueState, control.filterKey, '')}
                onChange={(event) => onControlChange(control.filterKey, event.target.value)}
                {...additionalProps}
              />
            </ControlBox>
          );
        }
        if (control.type === 'date') {
          return (
            <ControlBox
              key={control.filterKey}
              control={control}
              className={`${itemClass} fullWidth`}
            >
              <DatePicker
                selected={parseDate(getFilterDefault(control, ''))}
                onChange={(date) => onControlChange(control.filterKey, date ? formatDate(date) : null)}
                dateFormat="dd/MM/yyyy"
                className="form-control"
                {...additionalProps}
              />
            </ControlBox>
          );
        }
        if (control.type === 'time') {
          return (
            <ControlBox
              key={control.filterKey}
              control={control}
              className={`${itemClass} fullWidth`}
            >
              <DatePicker
                selected={parseTime(getFilterDefault(control, ''))}
                onChange={(date) => onControlChange(control.filterKey, date ? formatTime(date) : null)}
                dateFormat="H:mm"
                timeFormat="HH:mm"
                className="form-control"
                showTimeSelect
                showTimeSelectOnly
                customTimeInput={<DatePickerTimeInput />}
                {...additionalProps}
              />
            </ControlBox>
          );
        }
        if (control.type === 'dynamic') {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
          const cellValue = parseDynamicFilter(get(valueState, control.filterKey, '{}') as string);

          return (
            <ControlBox
              key={control.filterKey}
              control={control}
              className={`${itemClass} fullWidth`}
              style={{ width: '100%' }}
            >
              <FilterInput
                title={control.title}
                name={control.title}
                placeholder={control.title}
                instanceScope={props.instanceScope}
                interval={props.interval}
                filter={cellValue.fields.reduce((acc, cur) => {
                  if (cur.filterKey) {
                    // eslint-disable-next-line max-len
                    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-unsafe-assignment
                    acc[cur.filterKey] = cur?.defaultValue;
                  }
                  return acc;
                }, {})}
                manualSubmit
                clearButton={false}
                direction="vertical"
                controls={cellValue.fields as ControlType[]}
                onChange={(values) => {
                  const newValue = {
                    ...cellValue,
                    fields: cellValue.fields.map((c) => {
                      if (Object.prototype.hasOwnProperty.call(values, c.filterKey)) {
                        return {
                          ...c,
                          defaultValue: values[c.filterKey],
                        };
                      }
                      return c;
                    }),
                  };
                  onControlChange(control.filterKey, JSON.stringify(newValue));
                }}
              />
            </ControlBox>
          );
        }

        return (
          <div key={control.filterKey}>
            err:
            {control.type}
          </div>
        );
      })}

      {(manualSubmit || clearButton) && (
        <div className={`${itemGridClass} align-self-start mb-2 ${direction === 'vertical' ? 'd-grid' : ''}`}>
          {direction !== 'vertical' && <label>&nbsp;</label>}
          {manualSubmit && (
            <Button
              variant="primary"
              className={direction === 'vertical' ? 'mt-2' : ''}
              style={{ display: 'block' }}
              disabled={
                isEmpty(required)
                  ? false
                  : required.filter(
                    (r) => typeof filterState[r] === 'undefined'
                    || filterState[r] === '',
                  ).length > 0
              }
              onClick={() => handleChange(filterState)}
            >
              {submitTitle || text.filterSubmit}
            </Button>
          )}
          {' '}
          {clearButton && (
            <Button
              variant="secondary"
              className={direction === 'vertical' ? 'mt-2' : ''}
              style={{ display: 'block' }}
              onClick={() => handleChange(
                props.controls.reduce((obj, item) => {
                  obj[item.filterKey] = null;
                  return obj;
                }, {}),
              )}
            >
              Clear
            </Button>
          )}
        </div>
      )}
    </div>
  );
};

export default Filter;
