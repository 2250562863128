import _get from 'lodash/get';

const get = (obj, path, defValue) => {
  const val = _get(obj, path, defValue);
  if (val === null) {
    return defValue;
  }
  return val;
};

export const getArray = (obj, path, defValue) => {
  const val = _get(obj, path, defValue);
  if (Array.isArray(val)) {
    return val;
  }
  if (Array.isArray(defValue)) {
    return defValue;
  }
  return [];
};

export const getParsedArray = (str) => {
  let arr;
  try {
    arr = JSON.parse(str);
  } catch (e) {
    arr = [];
  }
  if (!Array.isArray(arr)) {
    arr = [];
  }
  return arr;
};

export default get;
