import React from 'react';
import RequestDebugModal from '../Debug/RequestDebugModal';

const Debug = (props) => {
  return (
    <RequestDebugModal title="Table debug" {...props} />
  );
};

export default Debug;
