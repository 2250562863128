import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { getGraphDefinition, getGraphData } from '../../../queries/graphs';
import 'amcharts3';
import 'amcharts3/amcharts/serial';
import 'amcharts3/amcharts/pie';
import 'amcharts3/amcharts/radar';
import 'amcharts3/amcharts/themes/dark';
import Chart3 from './Chart3';
import Chart4 from './Chart4';
import Loading from '../Loader/Loading';
import ErrorMessage from '../../atoms/ErrorMessage';
import { getLiveDataPreference } from '../../../redux/userSettings/actions';
import {
  fillFilter,
  graphqlFilter,
  graphqlInterval,
  isFilterValid,
  parseMapping,
} from '../../../utils/definitionParser';
import ErrorBoundary from '../../atoms/ErrorBoundary';
import InfoBox from '../../atoms/InfoBox';
import DebugText from '../Debug/DebugText';
import StatusInfo from '../../atoms/StatusInfo';
import CardWrapper from '../../atoms/CardWrapper';
import { setStatusInfo } from '../../../redux/page/actions';
import { statusInfoMessages } from '../../../utils/message';
import UserError from '../../atoms/UserError';
import { getServerErrors } from '../../../utils/error';
import Debug from './Debug';
import { randomString } from '../../../utils/string';
import { makeSlug } from 'utils/urlHelper';
import { useCompare } from '../../../hooks/useCompare';
import useInterval from '../../../hooks/useInterval';
import getRefreshInterval from '../../../utils/refreshInterval/getRefreshInterval';

const Chart = (props) => {
  const [graphData, setGraphData] = useState([]);
  const chartRef = useRef(null);
  const [uniqueId] = useState(`graph${randomString(10, 'aA#')}`);

  const apiFilter = fillFilter(
    Object.keys(parseMapping(props.queryParams)).join(','),
    props.filter,
    props.queryParams,
    true,
  );
  const [hasData, setHasData] = useState(false);

  const changed = useCompare({
    instanceScope: props.instanceScope,
    code: props.code,
    interval: props.interval,
    filter: apiFilter,
  });

  const [fetchDefinition, definitionResult] = useLazyQuery(getGraphDefinition, {
    variables: {
      instanceId: props.instanceScope,
      code: props.code,
      interval: graphqlInterval(props.interval),
      filter: graphqlFilter(apiFilter),
    },
    skip: !isFilterValid(apiFilter),
  });

  const [fetchData, dataResult] = useLazyQuery(getGraphData, {
    variables: {
      instanceId: props.instanceScope,
      code: props.code,
      interval: graphqlInterval(props.interval),
      filter: graphqlFilter(apiFilter),
      cache: props.cache,
    },
    skip: !definitionResult.called || definitionResult.loading,
    onCompleted: (result) => {
      if (!props.showStatusInfo) {
        props.setStatusInfo(
          props.code,
          statusInfoMessages(result.getGraphData.messages),
        );
      }
    },
  });

  useEffect(() => {
    if (changed) {
      setHasData(false);
      fetchDefinition({
        variables: {
          instanceId: props.instanceScope,
          code: props.code,
          interval: graphqlInterval(props.interval),
          filter: graphqlFilter(apiFilter),
        },
      });

      fetchData({
        variables: {
          instanceId: props.instanceScope,
          code: props.code,
          interval: graphqlInterval(props.interval),
          filter: graphqlFilter(apiFilter),
          cache: props.cache,
        },
      });
    }
  }, [fetchData, fetchDefinition, changed, props.cache, props.instanceScope, props.code, props.interval, apiFilter]);

  const dataChanged = useCompare(dataResult.data?.getGraphData);
  useEffect(() => {
    if (dataChanged && !dataResult.loading) {
      setGraphData(get(dataResult.data, 'getGraphData.data.data', []));
      setHasData(true);
    }
  }, [dataChanged, dataResult, definitionResult]);

  useInterval(() => {
    fetchData({
      variables: {
        instanceId: props.instanceScope,
        code: props.code,
        interval: graphqlInterval(props.interval),
        filter: graphqlFilter(apiFilter),
        cache: props.cache,
      },
    });
  }, getRefreshInterval(props.pollInterval / 1000, props.minRefresh) * 1000);

  if (!isFilterValid(apiFilter)) {
    return <InfoBox>Select values in the filter to show the graph</InfoBox>;
  }
  if (
    getServerErrors(definitionResult.errors)
    || getServerErrors(dataResult.errors)
  ) {
    return (
      <InfoBox level="error">
        Problem when loading the graph!
        <DebugText>
          <p>
            Code:
            {props.code}
          </p>
          {getServerErrors(definitionResult.errors) ? (
            <p>
              Definition error:
              {' '}
              {JSON.stringify(getServerErrors(definitionResult.errors))}
            </p>
          ) : (
            ''
          )}
          {getServerErrors(dataResult.errors) ? (
            <p>
              Data error:
              {' '}
              {JSON.stringify(getServerErrors(dataResult.errors))}
            </p>
          ) : (
            ''
          )}
        </DebugText>
      </InfoBox>
    );
  }

  if (
    (
      !definitionResult.called
      || definitionResult.loading
    ) || !hasData
  ) {
    return <Loading text="Loading graph data... " />;
  }

  const definition = get(definitionResult.data, 'getGraphDefinition.data');
  if (!definition) {
    return (
      <ErrorMessage>
        Problem when loading the graph!
        <DebugText>
          <p>
            Code:
            {props.code}
          </p>
          <p>
            No definition found:
            {JSON.stringify(definitionResult.data)}
          </p>
        </DebugText>
      </ErrorMessage>
    );
  }

  definition.definition = { ...definition.definition };
  const data = get(dataResult.data, 'getGraphData.data.data', []);
  const version = (definition.version.toString() === '4' || definition.version.toString() === 'v4') ? 4 : 3;

  let component;
  if (version === 4) {
    component = (
      <Chart4
        style={{
          width: '100%',
          height: definition.height,
        }}
        config={definition.definition}
        data={graphData}
      />
    );
  } else {
    component = (
      <Chart3
        style={{
          width: '100%',
          height: definition.height,
        }}
        config={definition.definition}
        data={graphData}
        downloadId={uniqueId}
        ref={chartRef}
      />
    );
  }

  return (
    <CardWrapper
      title={definition.title}
      info={definition.description}
      expandable={props.expandable}
      expanded={props.expanded}
      actions={[
        <Debug
          key="debug"
          theme="action"
          code={props.code}
          definition={get(definitionResult, 'data.getGraphDefinition')}
          data={get(dataResult, 'data.getGraphData')}
          filter={apiFilter}
        />,
        ...(version === 3 && definition.definition?.export?.enabled ? [<i
          key="download3"
          aria-label="Download"
          role="button"
          className="actions__item fa fa-download"
          title="Download"
          onClick={() => {
            const { chart } = chartRef.current.state;
            chart.export.toCSV({ data }, (d) => {
              chart.export.download(d, 'text/csv', makeSlug(`SMT_${definition.title}.csv`));
            });
          }}
        />] : []),
      ]}
    >
      <div style={{ position: 'relative' }}>
        <ErrorBoundary errorContent="fff" errorMessage="eee" silent>
          {props.showStatusInfo ? (
            <StatusInfo
              messages={get(dataResult, 'data.getGraphData.messages', []) || []}
            />
          ) : (
            ''
          )}
          <UserError errors={get(dataResult, 'data.getGraphData.errors', [])} />
        </ErrorBoundary>
        <ErrorBoundary>{component}</ErrorBoundary>
      </div>
    </CardWrapper>
  );
};

Chart.defaultProps = {
  pollInterval: null,
  interval: {},
  filter: {},
  cache: true,
  showStatusInfo: false,
  expandable: false,
  expanded: true,
  minRefresh: 0,
};

Chart.propTypes = {
  instanceScope: PropTypes.number.isRequired,
  code: PropTypes.string.isRequired,
  queryParams: PropTypes.string.isRequired,
  interval: PropTypes.object,
  filter: PropTypes.object,
  cache: PropTypes.bool,
  pollInterval: PropTypes.number,
  showStatusInfo: PropTypes.bool,
  expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  minRefresh: PropTypes.number,
};

export default connect(
  (state) => ({
    pollInterval: get(state, 'userSettings.pollInterval'),
  }),
  { getLiveDataPreference, setStatusInfo },
)(Chart);
