import React from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';
import Page from '../components/organisms/Page/Page';
import { setTitle } from '../utils/pageMeta';
import { useParams } from 'react-router-dom';
import { usePageSelector } from '../redux/hooks';

const DynamicPage = () => {
  const params = useParams();
  const page = usePageSelector(params.id);
  if (!page) {
    setTitle('Page not found');
    return <>Page not found</>;
  }

  if (page.content) {
    setTitle(page.title);
    return <Page pageId={page.id} content={page.content} />;
  }
  return <>Only GenReport supported now</>;
};

export default DynamicPage;
