import gql from 'graphql-tag';

export const loadMenuItems = gql`
  query getPages($instanceId: Int!) {
    getPages(instanceId: $instanceId) {
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
      data {
        pages {
          id
          parent
          order
          iconType
          icon
          title
          presenter
          content
          inMenu
          keywords
          tooltip
        }
      }
    }

    getUserPermissions (instanceId: $instanceId){
      code
      data {
        roles
      }
      messages {
        message
        code
        level
      }
      errors {
        message
        path
      }
    }
  }
`;
