import { UserPreferences } from '../../constants/UserSettingsEnum';

export const defaultUserSettings: UserPreferences = {
  notifications_push_settings: false,
  last_notifications: '',
  notification_alert: {
    enabled: true,
    ignore: [],
  },
  notification_push: '0',
  menu_description: '',
  bookmarks: '',
  intro: {},
};
