import React, {
  ReactElement, useEffect, useMemo, useState,
} from 'react';
import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { saveInstanceScope } from 'redux/userSettings/actions';
import TopBarItem from './TopBarItem';
import { getPersistentParameters } from '../../../../utils/urlHelper';
import { ServerInstance } from '../../../../types/ServerInstance';
import { useInstanceSelector, useInstancesSelector } from 'redux/hooks';

const InstanceSelector = () => {
  const params = useLocation();
  const instances = useInstancesSelector();
  const instanceScope = useInstanceSelector();
  const dispatch = useDispatch();
  const [expanded, setExpanded] = useState<string | null>(instanceScope.groupName);
  const urlInstance = useMemo(
    () => getPersistentParameters().instance,
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [params.search],
  );
  useEffect(() => {
    setExpanded(instanceScope.groupName);
  }, [instanceScope]);
  useEffect(() => {
    if (urlInstance !== instanceScope.id.toString()) {
      const found = instances.find((i) => i.id.toString() === urlInstance);
      if (found) {
        dispatch(saveInstanceScope(found));
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [urlInstance]);

  const groupedInstances: { [key: string]: ServerInstance[] } = instances
    .reduce((hash, obj) => ({ ...hash, [obj.groupName]: (hash[obj.groupName] || []).concat(obj) }), {});

  return (
    <TopBarItem
      title="Choose monitored instance"
      position="start"
      label={(
        <span style={{ fontWeight: 600, color: 'white', marginRight: '10px' }}>
          {instanceScope?.name}
        </span>
      )}
      items={({ hide }) => Object.keys(groupedInstances).map((group) => [
        <div
          key={group}
          className="dropdown-item"
          style={{ backgroundColor: 'rgba(255, 255, 255, 0.15)', fontWeight: 'bolder' }}
          onClick={() => setExpanded(group === expanded ? null : group)}
        >
          {group}
          <span className="float-end">
            {expanded === group ? <i className="zwicon-minus-square" /> : <i className="zwicon-plus-square" />}
          </span>
        </div>,
        expanded === group ? groupedInstances[group].map((item) => (
          // eslint-disable-next-line jsx-a11y/anchor-is-valid
          <a
            href="#"
            role="button"
            key={item.id.toString()}
            className="dropdown-item"
            style={{ marginLeft: '10px' }}
            onClick={(e) => {
              hide();
              e.preventDefault();
              dispatch(saveInstanceScope(item));
            }}
          >
            {item.name}
          </a>
        )) : null,
      ]).flat() as ReactElement[]}
    />
  );
};

export default InstanceSelector;
