import React from 'react';
import PropTypes from 'prop-types';
import {
  useTable,
  usePagination,
  useSortBy,
  useFilters,
  useGroupBy,
  useExpanded,
  TableOptions,
} from 'react-table';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import ScrollBar from 'react-perfect-scrollbar';
import Form from 'react-bootstrap/Form';
import Loading from '../Loader/Loading';
import { useCompare } from '../../../hooks/useCompare';
import { filterTypes } from './Filters';
import { graphqlFilter, graphqlInterval } from '../../../utils/definitionParser';
import InfoBox from '../../atoms/InfoBox';
import DebugText from '../Debug/DebugText';
import { getServerErrors } from '../../../utils/error';

export default function StaticTable({ columns, data }) {
  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
  }: any = useTable(
    {
      columns,
      data,
    } as TableOptions<object>,
    useFilters,
    useSortBy,
  );

  let hasFilter = false;

  // Render the UI for your table
  return (
    <ScrollBar>
      <div className="dataTables_wrapper no-footer">
        <table
          className="table table-bordered table-sm dataTable no-footer"
          {...getTableProps()}
        >
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr role="row" {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column, ck) => {
                  if (column.canFilter && column.Filter) {
                    hasFilter = true;
                  }

                  let thClass = '';
                  if (column.canSort) {
                    if (column.isSorted) {
                      thClass = column.isSortedDesc ? ' sorting_desc' : ' sorting_asc';
                    } else {
                      thClass = ' sorting';
                    }
                  }

                  return (
                    <th key={ck} style={{ position: 'relative' }} className={`align-top${thClass}`}>
                      <div {...column.getHeaderProps(column.getSortByToggleProps())}>{column.render('Header')}</div>
                      <div style={{ position: 'absolute', top: '8px', right: '4px' }}>{column.tooltip}</div>
                    </th>
                  );
                })}
              </tr>
            ))}
            {hasFilter ? (
              headerGroups.map((headerGroup, hk) => (
                <tr role="row" key={hk}>
                  {headerGroup.headers.map((column: any, ck) => (
                    <th className="align-top" style={{ position: 'relative' }} key={ck}>
                      <div>{column.canFilter && column.Filter ? column.render('Filter') : null}</div>
                    </th>
                  ))}
                </tr>
              ))
            ) : (
              null
            )}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row);
              return (
                <tr {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={cell.column.style || {}}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </ScrollBar>
  );
}

StaticTable.propTypes = {
  columns: PropTypes.array.isRequired,
  data: PropTypes.array.isRequired,
};
