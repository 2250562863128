const baseType = 'AUTH';

const types = {
  GET_TOKEN: `${baseType}/GET_TOKEN`,
  GET_TOKEN_SUCCESS: `${baseType}/GET_TOKEN_SUCCESS`,
  GET_TOKEN_ERROR: `${baseType}/GET_TOKEN_ERROR`,
  LOG_OUT: `${baseType}/LOG_OUT`,
};

export default types;
