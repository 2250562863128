import { getUsername, hasValidRefreshToken, setCookie } from 'utils/auth';
import styled from 'styled-components';
import React from 'react';
import { configureScope } from '@sentry/browser';
import { clearOut } from '../utils/interceptor';
import debug from '../utils/debug';

const Wrapper = styled.div`
  min-height: calc(100vh - 13.4rem);
`;

const Auth = (props) => {
  if (hasValidRefreshToken()) {
    configureScope((scope) => {
      scope.setUser({ id: getUsername() });
    });

    return props.children;
  }
  if (!props.inline) {
    if (
      !window.location.pathname.includes('logout')
      && !window.location.pathname.includes('login')
    ) {
      setCookie('returnUri', window.location.pathname + window.location.search);
    }
    debug.error('Component:Auth: User is not logged in');
    clearOut();
    return <Wrapper />;
  }
  return <span />;
};

export default Auth;
