import React, { useEffect, useState } from 'react';
import ReactSelect from 'components/molecules/Form/Select';
import { useLazyQuery } from '@apollo/client';
import ErrorMessage from '../../atoms/ErrorMessage';
import {
  fillFilter,
  graphqlFilter,
  graphqlInterval,
  isFilterValid,
} from '../../../utils/definitionParser';
import { getAutocomplete } from '../../../queries/filter';
import { useCompare } from '../../../hooks/useCompare';
import { isEmptyValue } from '../../../utils/string';
import DebugModal from '../Debug/DebugModal';

type FilterControl = {
  filterKey: string;
  type: string;
  title: string;
} & Partial<{
  addEmptyVal: boolean;
  values: {[key: string]: string};
  isMulti: boolean;
  query: FilterControlQuery;
  ui: FilterControlUi;
}>;

type FilterControlUi = {
  hideIndicator: boolean;
  hideSelected: boolean;
};

type FilterControlQuery = {
  procedureName: string;
  valueCol: string;
  titleCol: string;
} & Partial<{
  procedureParams: string;
  queryParams: string;
  smtConfig: boolean;
  noFilter: boolean;
  noInterval: boolean;
  searchable: boolean;
}>;

type SelectProps = {
  instanceScope: number;
  control: FilterControl;
  value: any;
  filters: any;
  onChange: (e: {target: {value: string | string[] | null}}) => void;
} & Partial<{
  interval: any;
  placeholder: string;
}>;

const valuesToOptions = (
  values: {[key: string]: string},
  valueIndex?: string,
  titleIndex?: string,
  allowEmpty = false,
): {label: string; value: string}[] => {
  const options: {label: string; value: string}[] = [];
  if (typeof values === 'object') {
    for (const [key, val] of Object.entries(values)) {
      if (
        isEmptyValue(valueIndex ? val[valueIndex] : key)
        || (!allowEmpty && isEmptyValue(titleIndex ? val[titleIndex] : val))
      ) {
        continue;
      }
      options.push({
        label: ((titleIndex ? val[titleIndex] : val) || '').toString(),
        value: (valueIndex ? val[valueIndex] : key).toString(),
      });
    }
  }

  return options;
};

const Select: React.FC<SelectProps> = ({ control, ...props }) => {
  const allowEmpty = control.addEmptyVal !== false;
  const [options, setOptions] = useState(
    valuesToOptions(control?.values || {}, undefined, undefined, allowEmpty),
  );
  useEffect(() => {
    setOptions(
      valuesToOptions(control?.values || {}, undefined, undefined, allowEmpty),
    );
  }, [control?.values]);

  let params: any[] = [];
  const procedureParams = control?.query?.procedureParams || '';
  const queryParams = control?.query?.queryParams || '';
  useEffect(() => {
    params = fillFilter(procedureParams, props.filters, queryParams);
  }, [props.filters, control]);
  params = fillFilter(procedureParams, props.filters, queryParams);

  const [callAutocomplete, { loading, data }] = useLazyQuery(getAutocomplete, {
    variables: {
      instance: props.instanceScope,
      code: (control?.query?.procedureName || '').trim(),
      filter: graphqlFilter(params),
      ...(control?.query?.smtConfig ? { smtConfig: true } : {}),
    },
    onCompleted: (resData) => {
      const first = resData?.getAutocomplete?.data?.values[0];
      if (first && !value && control.addEmptyVal === false) {
        props.onChange({
          target: { value: first[control?.query?.valueCol as string] },
        });
      }
      if (!resData.getAutocomplete.errors) {
        setOptions(
          valuesToOptions(
            resData.getAutocomplete?.data?.values || {},
            control.query?.valueCol,
            control.query?.titleCol,
            allowEmpty,
          ),
        );
      }
    },
  });

  const hasChanged = useCompare(params);

  const hasIntervalChanged = useCompare(props.interval);
  const hasInstanceChanged = useCompare(props.instanceScope);
  useEffect(() => {
    if (!hasChanged && !hasIntervalChanged && !hasInstanceChanged) {
      return;
    }

    if (control?.query?.procedureName && isFilterValid(params)) {
      callAutocomplete({
        // @ts-ignore
        variables: {
          ...{
            instance: props.instanceScope,
            code: control?.query?.procedureName.trim(),
          },
          ...(control?.query?.noFilter === true
            ? {}
            : { filter: graphqlFilter(params) }),
          ...(control?.query?.noInterval === true
            ? {}
            : { interval: graphqlInterval(props.interval) }),
        },
        context: { batch: true },
      });
    }
  }, [hasChanged, hasInstanceChanged, hasIntervalChanged]);

  const [value, setValue] = useState<string | undefined>(props.value);
  useEffect(() => {
    if (
      isEmptyValue(props.value)
      && !allowEmpty
      && control.values
      && Object.keys(control.values).length > 0
    ) {
      setValue(Object.keys(control.values)[0]);
      props.onChange({ target: { value: Object.keys(control.values)[0] } });
    } else {
      setValue(props.value);
    }
  }, [props.value]);

  const components: {[key: string]: (props: any) => JSX.Element | null} = {};
  if (control?.ui?.hideIndicator === true) {
    components.DropdownIndicator = () => null;
    components.IndicatorSeparator = () => null;
  }
  const additionalProps: { [key: string]: any } = {
    components,
  };
  if ('placeholder' in props) {
    additionalProps.placeholder = props.placeholder;
  }

  // Wrong definition
  if (typeof control.values !== 'object' && !control?.query?.procedureName) {
    return <ErrorMessage>Missing source of options for selectbox</ErrorMessage>;
  }

  if (control?.query?.procedureName && control?.query?.searchable === true) {
    additionalProps.onInputChange = (newValue: string) => {
      params = fillFilter(
        procedureParams,
        { ...props.filters, [control.filterKey]: newValue },
        queryParams,
      );

      callAutocomplete({
        // @ts-ignore
        variables: {
          ...{
            instance: props.instanceScope,
            code: control?.query?.procedureName.trim(),
          },
          ...(control?.query?.noFilter === true
            ? {}
            : { filter: graphqlFilter(params) }),
          ...(control?.query?.noInterval === true
            ? {}
            : { interval: graphqlInterval(props.interval) }),
        },
        context: { batch: true },
      });
    };
  }

  return (
    <>
      <ReactSelect
        isMulti={control?.isMulti || false}
        options={options}
        value={options.filter((v) => (Array.isArray(value)
          ? value.indexOf(v.value) !== -1
          : value?.toString() === v.value?.toString()))}
        onChange={(e) => {
          if (Array.isArray(e)) {
            props.onChange({ target: { value: e.map((v) => v.value) } });
          } else {
            props.onChange({ target: { value: e?.value || null } });
          }
        }}
        isClearable={allowEmpty}
        // hideIndicator={control?.ui?.hideIndicator === true}
        hideSelectedOptions={control?.ui?.hideSelected === true}
        {...additionalProps}
      />
      <DebugModal
        content={(
          <>
            <p>
              Control:
              {' '}
              <code>{JSON.stringify(control)}</code>
            </p>
            <p>
              Data:
              {' '}
              <code>
                {JSON.stringify(
                  control?.values ?? data?.getAutocomplete?.data?.values,
                )}
              </code>
            </p>
          </>
        )}
        style={{ position: 'absolute', right: '5px', top: '5px' }}
      />
    </>
  );
};

export default Select;
