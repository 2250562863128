import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import pick from 'lodash/pick';
import omit from 'lodash/omit';
import { useCompare } from '../../../hooks/useCompare';

const tdIconStyle = {
  whiteSpace: 'nowrap',
};
const tdFilterStyle = {
  width: '100%',
};

const FilterHistory = ({
  size,
  filter: currentFilter,
  filterKeys,
  hashKeys,
  onFilterChange,
}) => {
  if (!hashKeys) {
    hashKeys = omit(currentFilter, filterKeys);
  }
  currentFilter = pick(currentFilter, filterKeys);
  const hash = JSON.stringify(hashKeys);
  const [filterHistory, setFilterHistory] = useState({});
  const currentHistory = filterHistory[hash] || [];

  const filterHasChanged = useCompare(currentFilter);
  useEffect(() => {
    if (!filterHasChanged || isEmpty(currentFilter)) {
      return;
    }
    setFilterHistory({
      ...filterHistory,
      [hash]: [
        currentFilter,
        ...currentHistory.filter((f) => !isEqual(f, currentFilter)),
      ],
    });
  }, [currentFilter, filterHasChanged]);

  return (
    <div>
      <table className="table table-sm">
        <tbody>
          {currentHistory.slice(0, size || 5).map((row, i) => (
            <tr key={i}>
              <td style={tdIconStyle}>
                <a
                  href="#"
                  className="btn btn-danger btn--icon btn-sm"
                  title="Delete"
                  onClick={(e) => {
                    e.preventDefault();
                    setFilterHistory({
                      ...filterHistory,
                      ...{
                        [hash]: currentHistory.filter((f) => !isEqual(f, row)),
                      },
                    });
                  }}
                >
                  <i className="zwicon-delete" />
                </a>
                <a
                  href="#"
                  className="btn btn-theme btn--icon btn-sm"
                  title="Switch to filter"
                  onClick={(e) => {
                    e.preventDefault();
                    onFilterChange(
                      Object.keys(row).map((f) => ({
                        filterKey: f,
                        value: row[f],
                      })),
                    );
                  }}
                >
                  <i className="zwicon-refresh-left" />
                </a>
              </td>
              <td style={tdFilterStyle}>
                {Object.keys(row)
                  .map((f) => row[f])
                  .join(', ')}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};

FilterHistory.defaultProps = {
  filter: {},
  filterKeys: [],
  hashKeys: [],
  onFilterChange: () => undefined,
  size: 5,
};

FilterHistory.propTypes = {
  filterKeys: PropTypes.array,
  filter: PropTypes.object,
  hashKeys: PropTypes.array,
  onFilterChange: PropTypes.func,
  size: PropTypes.number,
};

export default FilterHistory;
