import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const SideBarItemWrapper = styled(({ userMenu, ...rest }) => <li {...rest} />)``;

SideBarItemWrapper.defaultProps = {
  userMenu: false,
};

SideBarItemWrapper.propTypes = {
  userMenu: PropTypes.any,
};
export default SideBarItemWrapper;
