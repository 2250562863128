import React from 'react';
import propTypes from 'prop-types';
import { getUserErrors } from '../../utils/error';

const UserError = ({ errors }) => {
  const errorMessages = getUserErrors(errors);
  if (errorMessages.length === 0) {
    return <></>;
  }

  return (
    <div className="card">
      <div
        className="card-body"
        style={{ color: '#ff6600', fontWeight: 'bold', fontSize: '120%' }}
      >
        {errorMessages.map((m, i) => (
          <div key={i} dangerouslySetInnerHTML={{ __html: m.message }} />
        ))}
      </div>
    </div>
  );
};

UserError.defaultProps = {
  errors: [],
};

UserError.propTypes = {
  errors: propTypes.array,
};

export default UserError;
