import React from 'react';
import PropTypes from 'prop-types';

const StatusInfo = ({ messages }) => {
  const statusMessages = (messages || []).filter(
    (m) => m.code.toString() === '1' && m?.message.length > 0,
  );
  if (statusMessages.length === 0) {
    return <></>;
  }

  return (
    <div className="card">
      <div className="card-body status-info-box">
        {statusMessages.map((m, i) => (
          <div key={i} dangerouslySetInnerHTML={{ __html: m.message }} />
        ))}
      </div>
    </div>
  );
};

StatusInfo.defaultProps = {
  messages: [],
};
StatusInfo.propTypes = {
  messages: PropTypes.array.isRequired,
};

export default StatusInfo;
