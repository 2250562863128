export const numberOfSearchHistoryItem = 50;
export const LIST_TYPE = {
  PAGES: 'pages',
  PAGE: 'data',
  NO_USER_ROLES_ASSIGNED: 'no_user_roles_assigned',
};

export const ERROR_CODES = {
  FORBIDDEN: 403,
  NOT_FOUND: 404,
  SERVER_ERROR: 500,
  NO_CONNECTION: 600,
};

export const genReportUrlPrefix = '/report';
