import React, { useEffect, useMemo, useState } from 'react';
import { Link } from 'react-router-dom';
import { connect, useDispatch } from 'react-redux';
import websocketClient from 'websocketClient';
import PerfectScrollbar from 'react-perfect-scrollbar';
import getEnvVal from 'constants/getEnvVal';
import Modal from 'react-bootstrap/Modal';
import clip from 'text-clipper';
import { toast } from 'react-toastify';
import { toNumber } from 'utils/string';
import { getUser, setUserSetting } from '../../../../redux/userSettings/actions';
import { UserPreferences, UserSettingsEnum } from '../../../../constants/UserSettingsEnum';
import TopBarItem from './TopBarItem';
import useLocalStorage from '../../../../hooks/useLocalStorage';
import {
  cleanData,
  getMaxDate,
  getUnreadCount,
  getUnreadMessages, hasNotification,
  isUnread,
  NotificationItem, notificationToggle,
  numToCode,
} from './Notifications.utils';
import { RootState } from 'redux/store';
import { useMutation } from '@apollo/client';
import { getUserGql, updateUserSettingGql } from '../../../../queries/user';

// This is only ugly hack to allow reading last value in the socket listener
let lastReadGlobal = {};

const Notifications = ({
  preferences,
  instances,
  instanceScope,
  setUserSetting,
}) => {
  const dispatch = useDispatch();
  const [updateUserSettings] = useMutation(updateUserSettingGql, { refetchQueries: [{ query: getUserGql }] });
  const toastId = React.useRef<string | null>(null);
  const [lastPush, setLastPush] = useLocalStorage<number>(
    'last_push_notification',
    undefined,
  );
  const limit = getEnvVal('NOTIFICATIONS_LIMIT', 10);
  const [updating, setUpdating] = useState<boolean>(false);
  const [selected, setSelected] = useState<NotificationItem | undefined>();
  const [notifications, setNotifications] = useState<NotificationItem[]>([]);
  const [show, setShow] = useState<boolean>(false);
  const [tab, setTab] = useState<'alerts' | 'events'>('alerts');

  const notificationSetting = preferences[UserSettingsEnum.SHOW_NOTIFICATION_ALERT] as
    UserPreferences['notification_alert'];
  const notificationsEnabled = toNumber(notificationSetting.enabled, 0) > 0;

  Notification.requestPermission().then(() => { /* ignore result */ });

  const lastRead = useMemo(() => {
    const last = {};
    Object.keys(preferences).forEach((s) => {
      if (s.startsWith(UserSettingsEnum.LAST_NOTIFICATIONS)) {
        last[s.substr(UserSettingsEnum.LAST_NOTIFICATIONS.length + 1)] = preferences[s];
      }
    });
    lastReadGlobal = last;
    return last;
  }, [preferences]);

  useEffect(() => {
    websocketClient.onMessage('notification', (data: { data: NotificationItem[] }) => {
      const cleaned = cleanData([
        ...notifications,
        ...data.data,
      ] as NotificationItem[]);
      const notify = cleanData(
        cleaned,
      );

      let newMessages = getUnreadMessages(
        notify,
        lastReadGlobal,
        notifications,
        notificationSetting.ignore,
      );

      if (notificationsEnabled) {
        newMessages = [];
      }

      if (
        notify !== notifications
        && newMessages.length > 0
      ) {
        let toastMessage: string;
        if (newMessages.length === 1) {
          toastMessage = newMessages[0].header;
        } else {
          toastMessage = `You have ${newMessages.length} unread notifications`;
        }
        if (notificationsEnabled) {
          if (toastId?.current) {
            toast.update(toastId.current, {
              render: (
                <>
                  <b>New notification</b>
                  <p>{toastMessage}</p>
                </>
              ),
            });
          } else {
            toastId.current = toast.warn(
              <>
                <b>New notification</b>
                <p>{toastMessage}</p>
              </>,
              {
                theme: 'dark',
                autoClose: false,
                onClose: () => {
                  toastId.current = null;
                },
              },
            ) as string;
          }
        }

        const maxDate = getMaxDate(newMessages);
        if (
          (!lastPush || lastPush < maxDate)
          && preferences[UserSettingsEnum.SHOW_NOTIFICATION_PUSH] === 'true'
          && document.visibilityState !== 'visible'
        ) {
          // eslint-disable-next-line
          new Notification('New SMT notification', {body: toastMessage});
        }
        setLastPush(maxDate);
      }
      setNotifications(cleaned);
    });
    websocketClient.send({ name: 'loadNotifications' });
  }, []);

  const currentNotifications = notifications.map((item) => ({
    ...item,
    ...{
      isNew: isUnread(item, lastRead) && !hasNotification(
        notificationSetting.ignore,
        `${item.category}:${item.priority.toString()}`,
      ),
    },
  }));
  const newCount = notificationsEnabled ? getUnreadCount(
    currentNotifications,
    lastRead,
    [],
    notificationSetting.ignore,
  ) : 0;

  const markRead = async () => {
    if (newCount === 0) {
      return;
    }

    setUpdating(true);
    const updates: { [key: string]: string[] } = {};
    currentNotifications.forEach((item) => {
      if (item.isNew) {
        if (!updates[item.instanceId]) {
          updates[item.instanceId] = [];
        }
        updates[item.instanceId].push(item.timestamp);
      }
    });
    const newLastRead = {};
    await Promise.all(
      Object.entries(updates).map(([inst, dates]) => {
        const updateValue = dates.reduce((a, b) => (new Date(a) > new Date(b) ? a : b));
        newLastRead[inst] = updateValue;
        setUserSetting(
          `${UserSettingsEnum.LAST_NOTIFICATIONS}_${inst}`,
          updateValue,
        );
      }),
    );
    setTimeout(() => setUpdating(false), 5000);
  };

  let items = <div className="text-center">No notifications</div>;
  if (show && currentNotifications && currentNotifications.length > 0) {
    items = (
      <>
        <div className="dropdown-header">
          &nbsp;
          <div className="actions">
            <Link to="/preferences" className="btn btn-lg">
              <span className="zwicon-cog" />
              {' '}
              Settings
            </Link>
            <span
              className={`btn btn-lg${
                newCount === 0 || updating ? ' disabled' : ''
              }`}
              onClick={() => {
                newCount > 0 && !updating && markRead();
              }}
            >
              <span className="zwicon-checkmark-square" />
              {' '}
              Mark as read
            </span>
          </div>
        </div>
        <div className="dropdown-header">
          <div className="row">
            <div className="col d-grid">
              <div
                className={`btn btn-sm btn-${
                  tab === 'alerts' ? 'light' : 'outline-theme'
                }`}
                onClick={() => setTab('alerts')}
              >
                Alerts
              </div>
            </div>
            <div className="col d-grid">
              <div
                className={`btn btn-sm btn-${
                  tab === 'events' ? 'light' : 'outline-theme'
                }`}
                onClick={() => setTab('events')}
              >
                Events
              </div>
            </div>
          </div>
        </div>
        <div className="listview listview--hover">
          <div
            className="os-content"
            style={{ padding: 0, height: '100%', width: '100%' }}
          >
            <PerfectScrollbar style={{ maxHeight: 300 }}>
              {currentNotifications
                .filter(
                  (item) => (tab === 'alerts'
                      && item.tablecode4FE === 'tb_socketalerts')
                    || (tab === 'events'
                      && item.tablecode4FE === 'tb_socketevents'),
                )
                .filter((item, idx) => item.isNew || idx < limit)
                .slice(0, limit * 2)
                .map((item, index) => (
                  <div
                    className="listview__item"
                    key={index.toString()}
                  >
                    <div style={{ display: 'flex' }}>
                      <span style={{ marginRight: 10 }}>
                        <i className={`fas fa-3x fa-${item.icon}`} />
                      </span>
                      <a
                        className="listview__content"
                        style={{ flex: 1, minWidth: 0 }}
                        onClick={() => setSelected(item)}
                      >
                        <div className="listview__heading">{item.header}</div>
                        <p
                          dangerouslySetInnerHTML={{
                            __html:
                              item.detail?.length > 160
                                ? clip(item.detail, 150, { html: true })
                                : item?.detail,
                          }}
                        />
                        <p className="at">
                          at
                          {' '}
                          {item.timestamp}
                        </p>
                      </a>
                      {item.isNew && (
                        <span style={{ marginLeft: 10 }} title="New message">
                          <i className="text-white fas fa-circle" />
                        </span>
                      )}
                      {!hasNotification(notificationSetting.ignore, `${item.category}:${item.priority.toString()}`) && (
                        <span
                          style={{ marginLeft: 10 }}
                          title="Silent similar messages"
                          onClick={() => {
                            updateUserSettings({
                              refetchQueries: ['me'],
                              variables: {
                                code: UserSettingsEnum.SHOW_NOTIFICATION_ALERT,
                                value: JSON.stringify({
                                  ...notificationSetting,
                                  ignore: notificationToggle(
                                    notificationSetting.ignore,
                                    `${item.category}:${item.priority.toString()}`,
                                  ),
                                }),
                              },
                            }).then(() => dispatch(getUser())).catch(() => undefined);
                          }}
                        >
                          <i className="text-white fas fa-bell" />
                        </span>
                      )}
                    </div>
                    <div>
                      <span className="badge bg-light text-dark rounded-pill">
                        {instances.find((i) => i.id === item.instanceId)?.name}
                      </span>
                    </div>
                  </div>
                ))}
            </PerfectScrollbar>
            <Link to="/report/2201" className="view-more">
              View all messages
            </Link>
          </div>
        </div>
        {selected && (
          <Modal
            size="lg"
            show
            onHide={() => setSelected(undefined)}
            aria-labelledby="example-modal-sizes-title-sm"
            centered
          >
            <Modal.Header closeButton closeVariant="white">&nbsp;</Modal.Header>
            <Modal.Body>
              <table className="listview__detail_table">
                <tr>
                  <th>Instance</th>
                  <td>
                    {instances.find((i) => i.id === selected?.instanceId)?.name}
                  </td>
                </tr>
                <tr>
                  <th>Timestamp</th>
                  <td>{selected?.timestamp}</td>
                </tr>
                <tr>
                  <th>User</th>
                  <td>
                    {selected?.userName && selected?.userName !== '-1'
                      ? selected?.userName
                      : ''}
                  </td>
                </tr>
                <tr>
                  <th>Title</th>
                  <td>{selected?.header}</td>
                </tr>
                <tr>
                  <th>Detail</th>
                  <td>
                    <span
                      dangerouslySetInnerHTML={{ __html: selected?.detail }}
                    />
                  </td>
                </tr>
              </table>
            </Modal.Body>
          </Modal>
        )}
      </>
    );
  }

  return (
    <TopBarItem
      dataIntroId="button-notifications"
      badge={newCount || null}
      iconName={newCount > 0 ? 'zwicon-bell-alt-ring' : 'zwicon-bell'}
      items={items}
      dropdownStyle={{ minWidth: '400px', maxWidth: '600px' }}
      onShow={() => setShow(true)}
      onHide={() => {
        setShow(false);
        setSelected(undefined);
      }}
      disableHide={!!selected}
      title="Notifications"
    />
  );
};

const mapStateToProps = (state: RootState) => ({
  instanceScope: state.userSettings.instanceScope,
  instances: state.userSettings.instances,
  preferences: state.userSettings.preferences,
});

const mapDispatchToProps = { setUserSetting };

export default connect(mapStateToProps, mapDispatchToProps)(Notifications);
