import isEmpty from 'lodash/isEmpty';
import { getCookie } from './utils/auth';
import getEnvVal from './constants/getEnvVal';

type WsData = string | ArrayBufferLike | Blob | ArrayBufferView | Record<string, unknown>;
type DataType = {
  name: string;
  data: Record<string, unknown>;
};

class WebsocketClient {
  socket?: WebSocket;

  queue: WsData[] = [];

  globalConsumers: any[] = [];

  consumers: {[key: string]: any[]} = {};

  authenticated = false;

  constructor(path) {
    const socket = new WebSocket(path);
    this.socket = socket;
    const that = this;

    socket.onopen = () => {
      if (getCookie('access_token')) {
        this.auth(getCookie('access_token'));
      }
    };

    socket.onclose = () => {
      /* no close action */
    };

    socket.onmessage = (message) => {
      let data: DataType = { name: '', data: {} };
      try {
        data = JSON.parse(message.data || '{}') as DataType;
      } catch (e) {
        // Ignore any error
      }
      this.globalConsumers.forEach((cb: (e: Record<string, unknown>) => void) => cb(data));
      if (
        Object.prototype.hasOwnProperty.call(data, 'name')
        && data.name
        && Object.prototype.hasOwnProperty.call(this.consumers, data.name)
      ) {
        this.consumers[data.name].forEach((cb: (e: Record<string, unknown>) => void) => cb(data));
      }
    };

    this.onMessage('unauthorized', () => {
      this.authenticated = false;
      this.send(
        JSON.stringify({
          name: 'authentication',
          data: { jwt: getCookie('access_token') },
        }),
        true,
      );
    });
    this.onMessage('authenticated', () => {
      this.authenticated = true;
      this.queue = this.queue.filter((el) => {
        that.send(el);
        return false;
      });
    });
  }

  auth = (jwt: string) => {
    this.send({ name: 'authentication', data: { jwt } }, true);
  };

  onMessage = (type: string | null, cb: (data: any) => void) => {
    if (isEmpty(type)) {
      this.globalConsumers.push(cb);
    } else {
      if (!this.consumers[type as string]) {
        this.consumers[type as string] = [];
      }
      this.consumers[type as string].push(cb);
    }
  };

  send = (data: WsData, alwaysSend = false): void => {
    if (this.socket) {
      if (this.authenticated || alwaysSend) {
        if (typeof data === 'object') {
          data = JSON.stringify(data);
        }
        this.socket.send(data);
      } else {
        this.queue.push(data);
      }
    }
  };
}

const websocketClient = new WebsocketClient(getEnvVal('WEBSOCKET_ENDPOINT'));

export default websocketClient;
