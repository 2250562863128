import gql from 'graphql-tag';

const getIntervals = gql`
  query getIntervals {
    getIntervals {
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
      data {
        intervals {
          display
          length
          name
          unit
        }
      }
    }
  }
`;

export type GetIntervalsResponseProps = {
  getIntervals: {
    status: string;
    code: string;
    messages: {
      level: string;
      code: string;
      message: string;
    }[];
    errors: {
      message: string;
      extensions: {
        code: string;
      };
    }[];
    data: {
      intervals: {
        display: string;
        length: number;
        name: string;
        unit: string;
      }[];
    };
  };
};

export default getIntervals;
