import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';

const iconStyle = { width: '1rem', height: '1rem', fontSize: '0.5rem' };
const style = {
  position: 'absolute', right: '5px', top: '5px', zIndex: '60',
};

export type DebugModalProps = {
  debug: boolean;
  theme: 'absolute' | 'action';
  title: string;
} & Partial<{
  style: any;
}>;

type State = {
  show: boolean;
};

class LazyDebugModal<T extends DebugModalProps> extends Component<T, State> {
  title = 'Debug';

  constructor(props: T) {
    super(props);

    if (props.title) {
      this.title = props.title;
    }

    this.state = {
      show: false,
    };
  }

  handleClose = () => this.setState({ show: false });

  toggleModal = () => this.setState({ show: !this.state.show });

  getContent = () => <></>;

  render() {
    const { show } = this.state;
    const { debug, theme: propTheme } = this.props;
    const theme = propTheme || 'absolute';

    if (!debug) {
      return null;
    }

    let buttonStyle = iconStyle;
    if (theme === 'absolute') {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
      buttonStyle = { ...buttonStyle, ...style, ...this.props.style };
    }

    return (
      <>
        {theme === 'absolute' ? (
          <button
            type="button"
            className="btn btn-theme btn-sm btn--icon"
            style={buttonStyle}
            onClick={(e) => {
              e.stopPropagation();
              e.preventDefault();
              this.toggleModal();
              return false;
            }}
          >
            <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'bug' }} />
          </button>
        ) : (
          <i
            role="button"
            aria-label="Debug"
            className="actions__item fa fa-bug"
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              this.toggleModal();
              return false;
            }}
          />
        )}

        {show ? (
          // eslint-disable-next-line jsx-a11y/no-static-element-interactions
          <div
            onClick={(e) => {
              e.preventDefault();
              e.stopPropagation();
              return false;
            }}
          >
            <Modal size="lg" show={show} onHide={this.handleClose} centered>
              <Modal.Header closeButton closeVariant="white">
                <Modal.Title>{this.title}</Modal.Title>
              </Modal.Header>
              <Modal.Body>{this.getContent()}</Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={this.handleClose}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>
          </div>
        ) : null}
      </>
    );
  }
}

export default LazyDebugModal;
