import React, { useState } from 'react';
import Proptypes from 'prop-types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import Modal from 'react-bootstrap/Modal';

const InfoModal = ({
  title,
  button,
  children,
  linkStyle,
  iconSize,
  modalSize = 'lg',
}) => {
  const [show, setShow] = useState(false);

  if (
    typeof children !== 'function'
    && ((typeof children === 'string' && children.length === 0)
      || (typeof children !== 'string' && React.Children.count(children) < 1))
  ) {
    return <></>;
  }

  return (
    <>
      <span
        className="info-modal-button-wrapper"
        onClick={(e) => {
          e.preventDefault();
          setShow(true);
        }}
      >
        {button || (
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              setShow(true);
            }}
            className="btn btn-sm btn-info btn--icon"
            style={linkStyle}
          >
            <FontAwesomeIcon
              size={iconSize}
              icon={{ prefix: 'fas', iconName: 'question' }}
            />
          </a>
        )}
      </span>
      <Modal
        size={modalSize || 'lg'}
        show={show}
        onHide={() => setShow(false)}
        aria-labelledby="example-modal-sizes-title-sm"
        centered
      >
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title id="example-modal-sizes-title-sm">
            {title || 'Detail'}
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {typeof children === 'function'
            ? children({ close: () => setShow(false) })
            : children}
        </Modal.Body>
      </Modal>
    </>
  );
};

InfoModal.defaultProps = {
  linkStyle: {},
  iconSize: 'sm',
  modalSize: 'lg',
};

InfoModal.propTypes = {
  title: Proptypes.string,
  linkStyle: Proptypes.object,
  iconSize: Proptypes.string,
  button: Proptypes.any,
  modalSize: Proptypes.string,
};

export default InfoModal;
