import React from 'react';
import { useQuery } from '@apollo/client';
import { getTuningNoteGql } from '../queries/tuningNotes';
import Loading from '../components/molecules/Loader/Loading';
import TuningTaskForm from '../components/organisms/TuningTaskForm';

const TuningTask = (props) => {
  const {
    data, error, loading, called,
  } = useQuery(getTuningNoteGql, {
    variables: {
      noteId: parseInt(props.match.params.id),
    },
    skip: !props.match.params.id,
  });

  if (loading) {
    return <Loading />;
  }
  if (data) {
    return <TuningTaskForm tuningTask={data} />;
  }
  return <TuningTaskForm />;
};

export default TuningTask;
