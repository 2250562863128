import types from './action-types';

export const cleanPageState = () => ({
  type: types.CLEAN_PAGE_STATE,
});

export const setStatusInfo = (code, messages) => ({
  type: types.SET_STATUS_INFO,
  payload: { code, messages },
});
