import React, { useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ScrollBar from 'react-perfect-scrollbar';
import { connect } from 'react-redux';
import get from 'lodash/get';
import useOutsideClick from '../../../../hooks/useOutsideClick';
import { setThemePreference } from '../../../../redux/userSettings/actions';

const ThemeSelector = ({ onHide, theme, setThemePreference }) => {
  const wrapperRef = useRef(null);
  useOutsideClick(wrapperRef, () => onHide());

  const items = [];
  for (let i = 1; i <= 10; i++) {
    items.push(i);
  }

  return (
    <div ref={wrapperRef} className="themes toggled">
      <ScrollBar>
        <div className="os-viewport os-viewport-native-scrollbars-invisible">
          <div className="os-content">
            {items.map((item) => (
              <a
                key={item}
                href="#"
                onClick={() => setThemePreference(item)}
                className={`themes__item${theme == item ? ' active' : ''}`}
                data-sa-value={item}
              >
                <img src={`/img/bg/${item}.jpg`} alt="" />
              </a>
            ))}
          </div>
        </div>
      </ScrollBar>
    </div>
  );
};

ThemeSelector.defaultProps = {
  onHide: () => undefined,
};

ThemeSelector.propTypes = {
  onHide: PropTypes.func,
};

export default connect(
  (state) => ({
    theme: get(state, 'userSettings.theme'),
  }),
  { setThemePreference },
)(ThemeSelector);
