import React from 'react';
import { UnControlled as CodeMirror } from 'react-codemirror2';
import get from 'lodash/get';
import { Form } from 'react-bootstrap';
import './style.css';
import 'codemirror/lib/codemirror.css';
import 'codemirror/theme/blackboard.css';

require('codemirror/mode/xml/xml');
require('codemirror/mode/sql/sql');

export const FieldError = ({
  error,
  touched,
}: {
  error?: string;
  touched?: boolean;
}) => (error && touched ? (
  <div className="invalid-tooltip">{error}</div>
) : null);

export const Field = (props) => (
  <>
    <Form.Label>{props.label}</Form.Label>
    <Form.Group controlId={props.input}>
      <div
        className={
            get(props, `errors[${props.input}]`)
            && (props.submitCount > 0 || get(props, `touched[${props.input}]`))
            && 'is-invalid'
          }
      >
        {props.children}
      </div>
      <FieldError
        error={get(props, `errors[${props.input}]`)}
        touched={
            props.submitCount > 0 || get(props, `touched[${props.input}]`)
          }
      />
    </Form.Group>
  </>
);

export const InputText = (props) => {
  const { input, values, onChange } = props;
  return (
    <Field {...props}>
      <Form.Control
        type="text"
        value={get(values, input, '') || ''}
        onChange={(e) => {
          onChange && onChange(e);
        }}
        className={
          get(props, `errors[${props.input}]`)
          && (get(props, `touched[${props.input}]`) || props.submitCount > 0)
          && 'is-invalid'
        }
      />
    </Field>
  );
};

export const InputFormatted = (props) => {
  const {
    mode = 'sql', input, values, onChange,
  } = props;
  return (
    <Field {...props}>
      <CodeMirror
        autoScroll={false}
        autoCursor={false}
        value={get(values, input, '') || ''}
        options={{
          mode,
          theme: 'blackboard',
          lineNumbers: true,
        }}
        onChange={(editor, data, value) => {
          onChange({ target: { name: input, value: value || '' } });
        }}
        className={
          get(props, `errors[${props.input}]`)
          && (get(props, `touched[${props.input}]`) || props.submitCount > 0)
          && 'is-invalid'
        }
      />
    </Field>
  );
};
