import React from 'react';
import { useMutation } from '@apollo/client';
import { useDispatch } from 'react-redux';
import { getUserGql, updateUserSettingGql } from '../../queries/user';
import { getUser } from '../../redux/userSettings/actions';

type Props = {
  children: (props: {
    change: (value: string | number | object | boolean) => Promise<void>;
    code: string;
    loading: boolean;
  }) => React.ReactNode;
  code: string;
};

const UserSettingChange: React.FC<Props> = ({ code, children }) => {
  const [update, { loading }] = useMutation(updateUserSettingGql, { refetchQueries: [{ query: getUserGql }] });
  const dispatch = useDispatch();
  const change = (value: string | number | object | boolean): Promise<void> => update({
    variables: {
      code,
      value: typeof value === 'object' ? JSON.stringify(value) : value.toString(),
    },
  }).then(() => {
    dispatch(getUser());
  });

  return <>{children({ change, code, loading })}</>;
};

export default UserSettingChange;
