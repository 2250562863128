/**
 * Add offset to date
 * @param date
 * @param offset Number of minutes
 */
const addOffset = (date: Date, offsetMinutes: number): Date => {
  const newDate = new Date(date.getTime());
  newDate.setMinutes(newDate.getMinutes() + offsetMinutes);
  return newDate;
};

const addTimezone = (date: Date): Date => addOffset(date, date.getTimezoneOffset());

const withoutTimezone = (date: Date): Date => addOffset(date, -date.getTimezoneOffset());

export { addOffset, addTimezone, withoutTimezone };
