import React from 'react';
import { captureException } from '@sentry/browser';
import ErrorMessage from './ErrorMessage';

type ErrorBoundaryProps = Partial<{
  errorContent: (error: Error, errorInfo: React.ErrorInfo) => React.ReactNode;
  errorMessage: string;
  silent: boolean;
  children?: React.ReactNode;
}>;

type ErrorBoundaryState = {
  error: Error | null;
  errorInfo: React.ErrorInfo | null;
};

class ErrorBoundary extends React.Component<ErrorBoundaryProps, ErrorBoundaryState> {
  constructor(props: ErrorBoundaryProps) {
    super(props);

    this.state = { error: null, errorInfo: null };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    this.setState({
      error, errorInfo,
    });

    try {
      captureException(error, {
        extra: {
          info: errorInfo, logger: 'react.componentDidCatch', props: this.props,
        },
        logger: 'react.componentDidCatch',
        tags: {
          componentName: (this.constructor as { displayName?: string }).displayName
            || this.constructor.name || 'Component',
        },
      } as any);
    } catch (e) {
      // Ignore the error
    }
  }

  render() {
    const { errorContent, errorMessage, silent = false } = this.props;
    const { error, errorInfo } = this.state;

    if (errorInfo) {
      if (silent) {
        return null;
      }

      if (errorContent) {
        return errorContent(error as Error, errorInfo);
      }

      return <ErrorMessage>{errorMessage || 'Error occurred'}</ErrorMessage>;
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
