import { PageDefinition } from '../redux/userSettings/types';

const filterPages = (pages: PageDefinition[], parent: number | null = null): PageDefinition[] => {
  const result = pages.filter((p) => p.parent === parent && p.inMenu === true);

  result.forEach((p) => {
    result.push(...filterPages(pages, p.id));
  });

  return result;
};

export default filterPages;
