import React from 'react';
import { Modal } from 'react-bootstrap';

type MultiInputProps = {
  onChange: (value: string) => void;
} & Partial<{
  defaultValue: string;
  placeholder: string;
  title: string;
  value: string;
}>;

const getDefaultValue = (defaultValue = '', value = ''): string[] => {
  const val = JSON.parse(value || defaultValue || '[]') as string[];
  if (!Array.isArray(val)) {
    return [val];
  }
  return val;
};

const MultiInput: React.FC<MultiInputProps> = ({
  defaultValue, onChange, placeholder, title, value,
}) => {
  const [showModal, setShowModal] = React.useState(false);
  const [currentValue, setCurrentValue] = React.useState<string[]>(getDefaultValue(defaultValue, value));

  return (
    <>
      <div className="input-group mb-3">
        <input
          type="text"
          className="form-control"
          placeholder={placeholder}
          value={value}
          readOnly
        />
        <button
          className="input-group-text btn btn-secondary"
          type="button"
          onClick={() => setShowModal(true)}
        >
          <i className="fa fa-edit" />
          {' '}
          edit
        </button>
      </div>
      <Modal show={showModal} onHide={() => setShowModal(false)}>
        <Modal.Header closeButton closeVariant="white">
          <Modal.Title>{title || ' '}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {currentValue.map((lineValue, i) => (
            <div key={i.toString()} className="input-group mb-3">
              <input
                type="text"
                className="form-control"
                placeholder={placeholder}
                value={lineValue}
                onChange={(e) => {
                  const newValue = currentValue.map((val, j) => (j === i ? e.target.value : val));
                  setCurrentValue(newValue);
                }}
              />
              <span
                className="input-group-text btn btn-secondary"
                onClick={() => {
                  const newValue = currentValue.filter((val, j) => j !== i);
                  setCurrentValue(newValue);
                }}
              >
                <i className="fa fa-times" />
                {' '}
                remove
              </span>
            </div>
          ))}
          <button
            className="btn btn-primary"
            disabled={currentValue[currentValue.length - 1] === ''}
            onClick={() => {
              setCurrentValue([...currentValue, '']);
            }}
          >
            <i className="fa fa-plus" />
            {' '}
            Add
          </button>
          <div className="mt-3">
            <button
              className="btn btn-primary"
              onClick={() => {
                onChange(JSON.stringify(currentValue));
                setShowModal(false);
              }}
            >
              Save
            </button>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default MultiInput;
