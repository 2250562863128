import React, { useState } from 'react';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import SideBarItem from './SideBarItem';

const SideBarItems = (props) => {
  const [selected, setSelected] = useState();

  return (
    <ul className={props.className || ''} style={{ display: 'block' }}>
      {(props.items || [])
        .sort((a, b) => b.order - a.order)
        .map((item, index) => (
          <SideBarItem
            {...omit(item, 'backOfficeType')}
            key={item.id}
            opened={
              typeof selected === 'undefined' ? undefined : item.id === selected
            }
            onClick={() => {
              if (props.belowLg) {
                props.setCollapsed(true);
              }
            }}
            onExpand={() => setSelected(item.id)}
            onCollapse={() => setSelected(undefined)}
            currentItemIndexes={props.currentItemIndexes}
          />
        ))}
    </ul>
  );
};

SideBarItems.defaultProps = {
  setCollapsed: () => {
    /* no action by default */
  },
  items: [],
};

SideBarItems.propTypes = {
  items: PropTypes.array,
  setCollapsed: PropTypes.func,
  belowLg: PropTypes.bool,
  currentItemIndexes: PropTypes.arrayOf(PropTypes.number),
};

export default SideBarItems;
