import React, { useEffect } from 'react';
import { Placement } from 'react-joyride';
import { useLocation, useNavigate } from 'react-router-dom';
import { GuideProps } from '.';
import ControlledJoyride from './ControlledJoyride';
import { useInstanceIdSelector, useIntervalSelector } from '../../redux/hooks';
import { getPersistentUrl } from 'utils/urlHelper';

const BasicGuide = ({ onFinished }: GuideProps) => {
  const navigate = useNavigate();
  const location = useLocation();
  const interval = useIntervalSelector();
  const instance = useInstanceIdSelector();
  useEffect(() => {
    if (!location.pathname.includes('/report/1124')) {
      navigate(getPersistentUrl('/report/1124', instance, interval));
    }
  }, [instance, interval, location.pathname, navigate]);

  const steps = [
    {
      disableBeacon: true,
      placement: 'center' as Placement,
      target: 'body',
      title: 'Welcome',
      content: (
        <p>
          Welcome to basic SMT introduction. You can close this window and start from the beginning at anytime. You
          can start over from the settings menu in the top right corner of SMT app.
        </p>
      ),
    },
    {
      disableBeacon: true,
      placement: 'bottom' as Placement,
      target: '[data-intro-id="top-filter"]',
      title: 'Main filter',
      content: (
        <>
          <p>
            In the top bar, you may choose which SQL instance you would like to analyze. Additionally, select the date,
            start time, and length of the chosen window you want to explore. Then hit the filter button.
          </p>
          <p>
            To simplify traversing/comparing currently chosen interval and next/previous interval (of the same size as
            you have already decided, e.g., 30min to Month), you may use arrow buttons. We also added shortcut buttons
            to jump into the same interval as you set up already but in the day or week before or after the currently
            selected window. We often use this when you quickly check if the presently analyzed situation is different
            against a baseline from the week/day before or after.
          </p>
        </>
      ),
    },
    {
      placement: 'bottom' as Placement,
      target: '[data-intro-id="button-notifications"]',
      title: 'Notifications',
      content: (
        <>
          <p>
            Here you may find notifications from SMT. There are two categories, events, and alerts. Events are just for
            your information and try to provide interesting details but are not critical for SQL Server performance.
            Alerts are somehow like your watchdog, trying to draw your attention to important things which happen on the
            SQL Server.
          </p>
          <p>
            Also, you may mark specific events that are read or silence them if you are not interested.
          </p>
          <p>
            There is a shortcut to a full report to explore more details regarding current or past events & Alerts.
          </p>
        </>
      ),
    },
    {
      placement: 'bottom' as Placement,
      target: '[data-intro-id="button-auto-refresh"]',
      title: 'Automatic data refresh',
      content: (
        <>
          <p>
            This refresh button allows you to refresh the current report view with new data automatically. It is handy
            when you are analyzing open intervals. Open interval is the situation when you select start date and time
            with interval reaching into the future. For example, the current date-time is 28.10. 11 am, I will choose
            28.10. 10 am on the top bar and also select 4hour interval. In that case, the server will show only
            available information which is currently stored in the database. If you wish to receive new data
            automatically into the current report, enable this automatic refresh.
          </p>
          <p>
            Please note that it is helpful to disable automatic refresh if you use reports with pop-up windows such as
            Query details, etc. Pop-up windows will automatically close when autorefresh occurs, and this might not be
            very pleasant.
          </p>
        </>
      ),
    },
    {
      placement: 'bottom' as Placement,
      target: '[data-intro-id="button-theme"]',
      title: 'Theme selection',
      content: (<p>Here you may choose your favorite personal background for the SMT.</p>),
    },
    {
      placement: 'bottom' as Placement,
      target: '[data-intro-id="button-settings"]',
      title: 'Settings',
      content: (
        <p>
          You may manually log off from SMT or use additional user options if you have enough application rights.
        </p>
      ),
    },
    {
      placement: 'right' as Placement,
      target: 'aside.sidebar',
      title: 'Navigation',
      content: (
        <p>
          This is the main navigation panel where you find all reports packed in their respective report categories.
        </p>
      ),
    },
    {
      placement: 'center' as Placement,
      target: 'section.content',
      title: 'Content',
      content: (
        <p>
          Report body based on what you chose from the navigation panel.
        </p>
      ),
    },
    {
      target: '.content > div:nth-child(1) > .card:nth-of-type(1)',
      content: (
        <>
          <p>
            This is the status bar. A handy tool to help you understand which data in the report you are looking at. It
            describes granularity of reported data, various important report-specific messages, and interval information
            from SMT such as data availability in levels of SMT DWH, currently shown data interval.
          </p>
          <p>
            We often use it when trying to compare data from different intervals, which looks different due to the
            nature of their source. E.g., on different levels of DHW, we store data in different granularity.
          </p>
          <p>
            Also, if the report is not showing any data, it is handy to know if for selected interval SMT has any data
            or not.
          </p>
          <p>
            Beyond the default SMT data level called &quot;Collector&quot; (based on a specific check
            collection frequency), three different levels of data are handled by SMT DWH.
          </p>
          <ul>
            <li>
              level 0 stores 15-minute intervals for 30 days
            </li>
            <li>
              level 1 stores 1-hour intervals for 90 days
            </li>
            <li>
              level 2 stores data on day intervals for one three year period
            </li>
          </ul>
        </>
      ),
    },
    {
      target: '.content > div:nth-child(1) > .card:nth-of-type(2)',
      content: (
        <p>
          In SMT we are using various graphs to help you visualize the data from the monitoring. Generally, most line
          and stacked bar graphs provide functionalities like zooming in and filtering specific values by clicking on
          them in the graph legend. Additional information is shown when you hover your mouse over the data in the
          chart.
        </p>
      ),
    },
    {
      target: '.content > div:nth-child(1) > p',
      content: (
        <>
          <p>
            This is the chart description window, where we usually present details about metrics reported in the graph
            to explain them or guide you on how to interpret them.
          </p>
          <p>
            We strongly recommend always looking for those graph descriptions until you fully understand/remember what
            they mean.
          </p>
        </>
      ),
    },
    {
      target: '.info-modal-button-wrapper',
      content: (
        <p>
          You may find the following question mark on various tables, graphs, and pages. It is our solution to
          provide more information to you and keep reports clean and easy to use. This question mark may contain
          descriptions to interpret better the results or what to do if you find the issue or background knowledge
          needed to understand the results better.
        </p>
      ),
    },
    {
      placement: 'bottom' as Placement,
      target: '[data-intro-id="button-settings"]',
      content: (
        <>
          <p>
            Specific reports may also have other elements such as explanatory texts, tables, grouped tables, filters,
            tabs to help you simplify the overall reporting experience. We hope you will find a lot of value in those
            reports.
          </p>
          <p>
            Now you have been introduced to basic navigation through the tool. Suppose you are interested in a more
            detailed guide through the SMT. In that case, we suggest continuing with a longer advanced overview of
            report categories available in SMT or contact us through our web page and set up an introductory call with
            our specialists. We wish you a pleasant experience with our SQL Server Monitoring & Tuning tool!
          </p>
        </>
      ),
    },
  ];

  return (
    <ControlledJoyride
      steps={steps}
      onFinished={onFinished}
    />
  );
};

export default BasicGuide;
