import styled, { css } from 'styled-components';
import PropTypes from 'prop-types';
import { getRem, getRemMedia } from '../../utils/css';

const SVGWrapper = styled.span`
  display: inline-block;
  margin: ${(p) => p.margin};
  padding: ${(p) => p.padding};
  border: none;
  background: none;
  outline: none;
  &:focus {
    outline: none;
  }
  ${(props) => (props.showPointer
    ? css`
          cursor: pointer;
        `
    : null)}
  svg {
    fill: ${(props) => props.fill};
    height: ${(props) => props.height};
    width: ${(props) => props.width};
    @media (min-width: ${getRemMedia(1024.2)}) {
      height: ${(props) => props.heightMd || props.height};
      width: ${(props) => props.widthMd || props.width};
    }
    transition: fill 0.2s;
  }
  &:hover {
    svg {
      fill: ${(props) => props.hoverFill};
    }
    transition: fill 0.2s;
  }
`;

SVGWrapper.defaultProps = {
  fill: '#000',
  hoverFill: '#000',
  showPointer: false,
  width: getRem(24),
  height: getRem(24),
  padding: '0',
  margin: '0',
};

SVGWrapper.propTypes = {
  fill: PropTypes.string,
  hoverFill: PropTypes.string,
  showPointer: PropTypes.bool,
  width: PropTypes.string,
  widthMd: PropTypes.string,
  height: PropTypes.string,
  heightMd: PropTypes.string,
  padding: PropTypes.string,
  margin: PropTypes.string,
};
export default SVGWrapper;
