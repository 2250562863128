type envValue = string | number | boolean | undefined;
type envType = {
  GRAPHQL_ENDPOINT: string;
  KEYCLOAK_URL: string;
  KEYCLOAK_REDIRECT_URI: string;
  KEYCLOAK_CLIENT_ID: string;
  SENTRY_DSN: string;
  SENTRY_SAMPLE_RATE: number;
  SENTRY_DUPLICATE_THROTTLING: number;
  BUILD_ENVIRONMENT: string;
  AUTO_REFRESH_INTERVALS: string;
  FILTER_DEBOUNCE_INTERVAL: number;
  NOTIFICATIONS_ENABLE: boolean;
  NOTIFICATIONS_LIMIT: number;
  SQL_FORMAT_LIMIT: number;
  CALENDLY_SUPPORT_LINK: string;
  DEFAULT_MIN_REFRESH: number;
} & Partial<{
  CLIENT_ID: string;
  WEBSOCKET_ENDPOINT: string;
  SYS_DEBUG: false | 'user' | 'global';
}>;

const LocalValues: envType = {
  GRAPHQL_ENDPOINT: 'https://woodler-api.lvh.me/graphql',
  KEYCLOAK_URL:
    'https://login.woodler.eu/auth/realms/SMT_test/protocol/openid-connect/',
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: 'frontend',
  SENTRY_DSN: '',
  SENTRY_SAMPLE_RATE: 1.0,
  SENTRY_DUPLICATE_THROTTLING: 900, // in seconds, 15 minutes
  BUILD_ENVIRONMENT: 'localbuild',
  AUTO_REFRESH_INTERVALS: '5,10,20,30,60',
  FILTER_DEBOUNCE_INTERVAL: 500,
  NOTIFICATIONS_ENABLE: true,
  NOTIFICATIONS_LIMIT: 10,
  SQL_FORMAT_LIMIT: 40000,
  CALENDLY_SUPPORT_LINK: 'https://calendly.com/woodler/troubleshooting',
  SYS_DEBUG: 'user',
  DEFAULT_MIN_REFRESH: 60,
};
const DevValues: envType = {
  GRAPHQL_ENDPOINT: 'https://192.168.1.55/graphql',
  KEYCLOAK_URL:
    'https://login.woodler.eu/auth/realms/SMT/protocol/openid-connect/',
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: 'frontend',
  SENTRY_DSN:
    'https://0cf2ffa26b494ba1a7e394d4ede6bb0e@o430084.ingest.sentry.io/5378071',
  SENTRY_SAMPLE_RATE: 1.0,
  SENTRY_DUPLICATE_THROTTLING: 900, // in seconds, 15 minutes
  BUILD_ENVIRONMENT: 'development',
  AUTO_REFRESH_INTERVALS: '5,10,20,30,60',
  FILTER_DEBOUNCE_INTERVAL: 500,
  NOTIFICATIONS_ENABLE: true,
  NOTIFICATIONS_LIMIT: 10,
  SQL_FORMAT_LIMIT: 40000,
  CALENDLY_SUPPORT_LINK: 'https://calendly.com/woodler/troubleshooting',
  DEFAULT_MIN_REFRESH: 60,
};
const ProdValues: envType = {
  GRAPHQL_ENDPOINT: '/gql',
  KEYCLOAK_URL:
    'https://login.woodler.eu/auth/realms/SMT/protocol/openid-connect/',
  KEYCLOAK_REDIRECT_URI: `${window.location.origin}/login`,
  KEYCLOAK_CLIENT_ID: 'frontend',
  SENTRY_DSN:
    'https://2ae37f90d0914d94b2ae3c794c8b7b75@o430084.ingest.sentry.io/5378178',
  SENTRY_SAMPLE_RATE: 0.1,
  SENTRY_DUPLICATE_THROTTLING: 900, // in seconds, 15 minutes
  BUILD_ENVIRONMENT: 'production',
  AUTO_REFRESH_INTERVALS: '5,10,20,30,60',
  FILTER_DEBOUNCE_INTERVAL: 500,
  NOTIFICATIONS_ENABLE: true,
  NOTIFICATIONS_LIMIT: 10,
  SQL_FORMAT_LIMIT: 40000,
  CALENDLY_SUPPORT_LINK: 'https://calendly.com/woodler/troubleshooting',
  DEFAULT_MIN_REFRESH: 60,
};

const process: { env: any } = { env: { REACT_APP_ENV: 'local' } };

export const ENV: {[key: string]: envValue} = window?.ENV || process?.env;

/**
 * @param {string} Key
 * @param {any} def
 * @returns {*}
 */
const getEnvVal = <K extends keyof envType>(key: K, def: envType[K] | undefined = undefined): envType[K] => {
  if (ENV[`REACT_APP_${key}`]) {
    return ENV[`REACT_APP_${key}`] as envType[K];
  }

  let value: envType[K];
  switch (process.env.REACT_APP_ENV) {
    case 'local':
      value = LocalValues[key] || def as envType[K];
      break;
    case 'dev':
      value = DevValues[key] || def as envType[K];
      break;
    case 'prod':
      value = ProdValues[key] || def as envType[K];
      break;
    default:
      value = DevValues[key] || def as envType[K];
      break;
  }

  if (!value) {
    if (key === 'WEBSOCKET_ENDPOINT') {
      const socketValue = getEnvVal('GRAPHQL_ENDPOINT');
      if (socketValue) {
        return socketValue
          .replace('https://', 'wss://')
          .replace('http://', 'ws://')
          .replace('/graphql', '/ws') as envType[K];
      }
    }
  }

  return value;
};

export default getEnvVal;
