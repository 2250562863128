import gql from 'graphql-tag';
import { CommonFragment } from './common';

const TuningNote = `
  noteId
  regServer_ID
  database_name
  user_name
  stamp
  tags
  sql_message
  execday
  before_query_plan
  before_query_text
  before_stats
  before_duration_ms
  before_cpu_ms
  before_reads
  before_queryhash
  before_planId
  before_stmtId
  after_query_plan
  after_query_text
  after_stats
  after_duration_ms
  after_cpu_ms
  after_reads
  after_queryhash
  after_planId
  after_stmtId
  status
  visibleName
`;

export const getTuningNotesGql = gql`
  query getTuningNotes($interval: DateInterval, $status: String, $onlyOwn: Boolean! = false, $useTimeline: Boolean! = false) {
    getTuningNotesList(interval: $interval, status: $status, onlyOwn: $onlyOwn, useTimeline: $useTimeline) {
      ${CommonFragment}
      data {
        tunings {
          noteId
          ${TuningNote}
        }
      }
    }
  }
`;

export const getTuningNoteGql = gql`
  query getTuningNote($noteId: Int!) {
    getTuningNoteDetail(noteId: $noteId) {
      ${CommonFragment}
      data {
        tuning {
          ${TuningNote}
        }
      }
    }
  }
`;

export const createTuningNoteGql = gql`
  mutation createTuningNote($parameters: NoteParamItem) {
    createTuningNote(parameters: $parameters) {
      ${CommonFragment}
      data {
        id
      }
    }
  }
`;

export const updateTuningNoteGql = gql`
  mutation updateTuningNote($noteId: Int!, $parameters: NoteParamItem) {
    updateTuningNote(noteId: $noteId, parameters: $parameters) {
      ${CommonFragment}
    }
  }
`;

export const deleteTuningNoteGql = gql`
  mutation deleteTuningNote($noteId: Int!) {
    deleteTuningNote(noteId: $noteId) {
      ${CommonFragment}
    }
  }
`;
