import React from 'react';
import { Navigate } from 'react-router-dom';
import { useInstanceIdSelector, useIntervalSelector } from '../redux/hooks';
import { getPersistentUrl } from '../utils/urlHelper';

const Dashboard = () => {
  const interval = useIntervalSelector();
  const instance = useInstanceIdSelector();

  return <Navigate to={getPersistentUrl('/report/2174', instance, interval)} replace />;
};

export default Dashboard;
