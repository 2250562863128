import React, { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import getEnvVal from 'constants/getEnvVal';
import DropdownItemWrapper from './DropdownItemWrapper';
import TopBarItem from './TopBarItem';
import Modal from 'react-bootstrap/Modal';

type Props = Partial<{
  type: 'link' | 'icon';
}>;

const title = 'Organize meeting with Woodler';

const SupportLink: React.FC<Props> = ({ type = 'link' }) => {
  const [isOpen, setOpen] = useState(false);

  if (!getEnvVal('CALENDLY_SUPPORT_LINK')) {
    return null;
  }

  const renderModal = () => (
    <Modal
      size="xl"
      show={isOpen}
      onHide={() => setOpen(false)}
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="example-modal-sizes-title-sm">&nbsp;</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <InlineWidget
          url={getEnvVal('CALENDLY_SUPPORT_LINK')}
          utm={{
            utmSource: 'SMT',
            utmMedium: 'Link',
            utmCampaign: 'Support',
          }}
        />
      </Modal.Body>
    </Modal>

  );

  if (type === 'icon') {
    return (
      <>
        <TopBarItem
          dataIntroId="support"
          iconName="zwicon-headphone"
          onClick={() => setOpen(true)}
          items={[]}
          title={title}
        />
        {renderModal()}
      </>
    );
  }

  return (
    <DropdownItemWrapper key="support" className="dropdown-item">
      <span
        role="button"
        onClick={() => setOpen(true)}
        title={title}
      >
        Support
        {' '}
        <i className="float-end fa fa-solid fa-phone" />
      </span>
      {renderModal()}
    </DropdownItemWrapper>
  );
};

export default SupportLink;
