import React, { useState } from 'react';
import { hasRole } from 'utils/auth';
import { useDebugSelector } from 'redux/hooks';
import useInterval from 'hooks/useInterval';

type Perf = {
  memory: {
    usedJSHeapSize: number;
  };
};

const MemoryUsage = () => {
  const debug = useDebugSelector();

  if (
    !hasRole('smt_debug')
    || !debug
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    || !(window?.performance as unknown as Perf)?.memory?.usedJSHeapSize
  ) {
    return null;
  }

  return <MemoryUsageInner />;
};

const MemoryUsageInner = () => {
  const [memory, setMemory] = useState<number>(
    (window?.performance as unknown as Perf)?.memory?.usedJSHeapSize,
  );
  useInterval(() => setMemory((window?.performance as unknown as Perf)?.memory?.usedJSHeapSize), 3000);

  return (
    <li className="dropdown d-none d-sm-inline-block btn--icon-text btn btn-theme-dark">
      <span style={{ fontWeight: 600, color: 'white', marginRight: '10px' }}>
        Memory:
        {' '}
        {memory.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')}
      </span>
    </li>
  );
};

export default MemoryUsage;
