import React, { useEffect, useMemo, useState } from 'react';
import styled, { css } from 'styled-components';
import { ScrollbarProps, Scrollbars } from 'react-custom-scrollbars';
import merge from 'lodash/merge';
import useSticky from './useSticky';

type Props = {
  children: React.ReactElement;
  styles: ScrollbarStyles;
  sticky: boolean;
  stickyOffset: number;
};

type ScrollbarStyles = {
  thumb: { size: number; styles: React.CSSProperties };
  track: { size: number; styles: React.CSSProperties };
  activeThumb: { size: number; styles: React.CSSProperties };
  activeTrack: { size: number; styles: React.CSSProperties };
};

const defaultStyles: ScrollbarStyles = {
  thumb: { size: 4, styles: { backgroundColor: '#999', opacity: 0.5 } },
  track: { size: 6, styles: { backgroundColor: '#eee', opacity: 1 } },
  activeThumb: {
    size: 11,
    styles: {
      backgroundColor: '#999',
      opacity: 0.9,
      transition: 'opacity 0.5s ease-in-out',
    },
  },
  activeTrack: {
    size: 15,
    styles: {
      backgroundColor: '#eee',
      opacity: 0.9,
      transition: 'opacity 0.5s ease-in-out',
    },
  },
};

type WrapperProps = {
  ref: React.MutableRefObject<any>;
  sticky: boolean;
  style: React.CSSProperties;
  top: number;
};

const Wrapper = styled.div<WrapperProps>`
  ${(p) => (p.sticky
    ? css`
      thead {
        position: sticky;
        top: ${p.top}px;
        z-index: 50;
        background-color: black;
      }
    `
    : '')}
`;

const ScrollableTable: React.FC<ScrollbarProps & Partial<Props>> = ({
  children,
  stickyOffset = 71,
  sticky = false,
  styles: originalStyles,
  ...props
}) => {
  // Table is hovered at the time
  const [hover, setHover] = useState<boolean>(false);
  // Table overflows and should have horizontal scrollbar
  const [overflow, setOverflow] = useState<boolean>(false);
  const {
    tableRef, isSticky, topShift, isBottomSticky, bottomShift,
  } = useSticky({ offsetY: stickyOffset });
  const styles: ScrollbarStyles = merge(originalStyles, defaultStyles);
  const spacing: number = useMemo(
    () => Math.max(...(Object.keys(styles).map((s) => styles[s].size) as number[])),
    [styles],
  );

  useEffect(() => {
    const handleResize = () => {
      if (tableRef.current) {
        setOverflow(
          tableRef.current?.scrollWidth > tableRef.current?.clientWidth,
        );
      }
    };
    window.addEventListener('resize', handleResize);
    if (tableRef.current) {
      setOverflow(
        tableRef.current?.scrollWidth > tableRef.current?.clientWidth,
      );
    }

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [tableRef.current]);

  if (!children) {
    return <></>;
  }

  const getSize = <K extends keyof ScrollbarStyles>(element: K): number => styles[
    hover
      ? `active${element.charAt(0).toUpperCase()}${element.slice(1)}`
      : element
  ].size;

  return (
    <Scrollbars
      autoHeight
      autoHeightMin={10}
      autoHeightMax={20000}
      renderThumbVertical={({ style, ...originalProps }: { style: React.CSSProperties }) => (
        <div
          {...originalProps}
          style={{
            ...style,
            width: `${getSize('thumb')}px`,
            borderRadius: `${getSize('thumb')}px`,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
            ...(hover ? styles.activeThumb.styles : styles.thumb.styles),
          }}
        />
      )}
      renderTrackHorizontal={({ style, ...originalProps }: { style: React.CSSProperties }) => (
        <div
          {...originalProps}
          style={{
            ...style,
            backgroundColor: 'blue',
            bottom: '2px',
            right: '2px',
            left: '2px',
            height: overflow ? `${getSize('track')}px` : 0,
            ...(hover ? styles.activeTrack.styles : styles.track.styles),
            ...(isBottomSticky
              ? {
                position: 'absolute',
                bottom: `${bottomShift + getSize('track') - 1}px`,
              }
              : {}),
          }}
        />
      )}
      renderThumbHorizontal={({ style, ...originalProps }: { style: React.CSSProperties }) => (
        <div
          {...originalProps}
          style={{
            ...style,
            height: overflow ? `${getSize('thumb')}px` : 0,
            borderRadius: `${getSize('thumb')}px`,
            boxShadow: '0 2px 4px 0 rgba(0, 0, 0, 0.16)',
            marginTop: (getSize('track') - getSize('thumb')) / 2,
            ...(hover ? styles.activeThumb.styles : styles.thumb.styles),
          }}
        />
      )}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
      {...props}
    >
      <Wrapper
        sticky={isSticky}
        top={topShift}
        ref={tableRef}
        style={{ marginBottom: `${spacing}px` }}
      >
        {children}
      </Wrapper>
    </Scrollbars>
  );
};

export default ScrollableTable;
