import React from 'react';
import styled from 'styled-components';

const Component = (props) => (
  <header className={`header${props.scrolled ? ' header--scrolled' : ''}`}>
    {props.children}
  </header>
);

const TopBarWrapper = styled(Component)``;

export default TopBarWrapper;
