export const userItems = [];

export const flyOutItems = [
  {
    text: 'Logout',
    key: 'logout',
    route: '/logout',
    onClick: () => undefined,
  },
];
