import React, { ForwardedRef, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { FilterType } from '../../molecules/Filter/index';
import { Interval } from '../../../redux/userSettings/types';
import {
  Button, Dropdown, FormControl, InputGroup,
} from 'react-bootstrap';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { useDispatch } from 'react-redux';
import { saveIntervalPreference } from '../../../redux/userSettings/actions';
import { UserSettingsEnum } from '../../../constants/UserSettingsEnum';
import UserSettingChange from 'components/atoms/UserSettingChange';
import { redirectToState } from '../../molecules/Filter/helpers';
import { useNavigate } from 'react-router-dom';
import { FilterBoxValue } from '../../molecules/GlobalFilter/FilterBox';
import { useBookmarkSelector, useDebugSelector } from 'redux/hooks';

const BookmarksContext = React.createContext<BookmarksContextProps>({ page: 1, filter: {}, interval: {} });

type BookmarksContextProps = {
  page: number;
  filter: FilterType;
  interval: Interval;
};

type Props = {
  page: number;
  filter: FilterType;
  interval: Interval;
};

type BookmarkItem = {
  title: string;
  filter: FilterType;
  interval: Interval;
};

type BookmarksMenuProps = {
  // eslint-disable-next-line react/require-default-props
  style?: React.CSSProperties;
  // eslint-disable-next-line react/require-default-props
  className?: string;
  // eslint-disable-next-line react/require-default-props
  'aria-labelledby'?: string;
};

type BookmarkStorageType = { [key: number]: BookmarkItem[] };

const getBookmark = (bookmarks: BookmarkStorageType, page: number): BookmarkItem[] => bookmarks[page] || [];

const BookmarksMenu = React.forwardRef(
  ({
    style = {}, className = '', 'aria-labelledby': labeledBy = '',
  }: BookmarksMenuProps, ref: ForwardedRef<HTMLDivElement>) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const debug = useDebugSelector();
    const bookmarks = useBookmarkSelector();
    const [value, setValue] = useState('');

    return (
      <div
        ref={ref}
        style={{ ...style, minWidth: '300px' }}
        className={className}
        aria-labelledby={labeledBy}
      >
        <BookmarksContext.Consumer>
          {(contextValue) => (
            <UserSettingChange code={UserSettingsEnum.BOOKMARKS}>
              {({ change }) => (
                <>
                  <Dropdown.Header>
                    <InputGroup className="mb-3">
                      <FormControl
                        autoFocus
                        placeholder="Save as preset..."
                        onChange={(e) => setValue(e.target.value)}
                        value={value}
                      />
                      <Button
                        disabled={!value}
                        onClick={() => {
                          change(JSON.stringify({
                            ...bookmarks,
                            [contextValue.page]: [...getBookmark(bookmarks, contextValue.page), {
                              title: value,
                              filter: contextValue.filter,
                              interval: contextValue.interval,
                            }],
                          }))
                            .then(() => {
                              setValue('');
                              toast.success('Bookmark saved successfully');
                            })
                            .catch(() => { toast.error('Error when saving the bookmark'); });
                        }}
                      >
                        Save
                      </Button>
                    </InputGroup>
                  </Dropdown.Header>
                  {getBookmark(bookmarks, contextValue.page).length > 0 && (
                    <div className="listview listview--hover">
                      <div
                        className="os-content"
                        style={{ padding: 0, height: '100%', width: '100%' }}
                      >
                        <div className="listview__item listview__item-compact">
                          <h4>Load preset</h4>
                        </div>
                        <PerfectScrollbar>
                          {getBookmark(bookmarks, contextValue.page).map((item, index) => (
                            <div
                              key={index.toString()}
                              className="listview__item listview__item-compact"
                            >
                              <div style={{ display: 'flex' }}>
                                {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
                                <a
                                  role="button"
                                  key={index.toString()}
                                  className="listview__content"
                                  style={{ flex: '1 1 0%', minWidth: '0px' }}
                                  onClick={() => {
                                    dispatch(saveIntervalPreference(item.interval));
                                    redirectToState({
                                      filter: item.filter,
                                      interval: item.interval as FilterBoxValue,
                                      hash: '',
                                    }, navigate);
                                  }}
                                >
                                  <div className="listview__heading">{item.title}</div>
                                  {debug && <p>{JSON.stringify(item)}</p>}
                                </a>
                                <span>
                                  <button
                                    type="button"
                                    className="btn btn-link"
                                    onClick={() => {
                                      const newBookmarks = getBookmark(bookmarks, contextValue.page);
                                      newBookmarks.splice(index, 1);
                                      change(JSON.stringify({
                                        ...bookmarks,
                                        [contextValue.page]: newBookmarks,
                                      }))
                                        .then(() => {
                                          setValue('');
                                          toast.success('Bookmark deleted successfully');
                                        })
                                        .catch(() => { toast.error('Error when deleting the bookmark'); });
                                    }}
                                  >
                                    <i className="fa fa-trash pull-right" />
                                  </button>
                                </span>
                              </div>
                            </div>
                          ))}
                        </PerfectScrollbar>
                      </div>
                    </div>
                  )}
                </>
              )}
            </UserSettingChange>
          )}
        </BookmarksContext.Consumer>
      </div>
    );
  },
);

const BookmarksButton: React.FC<Props> = ({ page, filter, interval }) => {
  const contextValue = useMemo(() => ({ page, filter, interval }), [page, filter, interval]);
  return (
    <Dropdown align="end">
      <BookmarksContext.Provider value={contextValue}>
        <Dropdown.Toggle variant="link" bsPrefix="p-0">
          <i className="actions__item fa fa-star" />
        </Dropdown.Toggle>
        <Dropdown.Menu as={BookmarksMenu} />
      </BookmarksContext.Provider>
    </Dropdown>
  );
};

export default BookmarksButton;
