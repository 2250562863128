/* eslint-disable react/prop-types */
import React from 'react';
import get from 'lodash/get';
import keyBy from 'lodash/keyBy';
import trim from 'lodash/trim';
import isUndefined from 'lodash/isUndefined';
import { Link } from 'react-router-dom';
import { toast } from 'react-toastify';
import { filterUrlPrefix } from '../constants/variables';
import CopyClipboard from '../components/atoms/CopyClipboard';
import DownloadLink from '../components/atoms/DownloadLink';
import Filter from '../components/molecules/Filter';
import client from '../apolloClient';
import { getAutocomplete } from '../queries/filter';
import CodeHighlighter from '../components/atoms/CodeHighlighter';
import formatSql from './formatSql';
import IntervalHelper from './IntervalHelper';
import { addOffset } from './dateTimezone';
import formatXml from './formatters/formatXml';
import SparklineBar from 'components/molecules/Widgets/SparklineBar';
import SparklineLine from 'components/molecules/Widgets/SparklineLine';
import { placeholderReplace } from './string';

const globalFilterKeys = ['@InputDate', '@InputInterval'];

const sparklineProps = {
  ariaLabel: 'Graph',
  width: 100,
  height: 25,
  margin: {
    top: 3, right: 5, bottom: 3, left: 5,
  },
};

export const stripTags = (text) => {
  const tmp = document.createElement('DIV');
  tmp.innerHTML = text;
  return tmp.textContent || tmp.innerText || '';
};

export const parseParams = (procedureParams) => {
  if (!procedureParams) {
    return [];
  }
  const params = [];

  procedureParams
    .trim()
    .split(',')
    .forEach((param) => {
      const [key, val] = param.split('=');
      if (!key) {
        return;
      }
      if (val) {
        const [ref, def] = val.split('|');
        params.push({
          param: key.trim().replace('@!', '@'),
          required: key.trim().indexOf('!') === 1,
          field: ref && ref.trim(),
          value: def && def.trim(),
        });
      } else {
        params.push({
          param: key.trim().replace('@!', '@'),
          required: key.trim().indexOf('!') === 1,
          field: null,
          value: null,
        });
      }
    });

  return params;
};

export const parseMapping = (queryParams) => {
  const mapping = {};
  queryParams
    .trim()
    .split(',')
    .forEach((param) => {
      // eslint-disable-next-line prefer-const
      let [key, val] = param.split('=');
      if (key && val) {
        val = val.trim();
        mapping[key.trim()] = val.startsWith('get-') ? val.substr(4) : val;
      }
    });

  return mapping;
};

export const fillFilter = (procedureParams, filter, queryParams, addInterval = false) => {
  let paramsLine = procedureParams;
  if (addInterval) {
    paramsLine = `@InputDate, @InputInterval, ${paramsLine}`;
  }
  const mapping = keyBy(parseParams(queryParams), 'param');

  return parseParams(paramsLine)
    .map((row) => {
      let { value } = row;
      if (Object.prototype.hasOwnProperty.call(mapping, row.param)) {
        value = get(filter, mapping[row.param].field, value);
        if (value === undefined || value === '' || isUndefined(value) || value === null) {
          value = mapping[row.param].value;
          if ((value === undefined || value === '' || isUndefined(value) || value === null) && row.field === '%b') {
            value = false;
          }
        }
      } else if (!row.field || !row.field.startsWith('%')) {
        value = trim(row.field, "'");
      }
      return {
        param: row.param,
        required: row.required,
        field: row.field,
        value,
      };
    })
    .filter((f) => f.param);
};

export const graphqlFilter = (filter) => filter
  .filter((row) => globalFilterKeys.indexOf(row.param) === -1)
  .filter((i) => i.value !== undefined && i.value !== null && i.value !== '')
  .map((p) => {
    if (Array.isArray(p.value)) {
      return {
        key: p.param,
        value: p.value.map((f) => `"${f.replace('"', '""')}"`).join('|'),
      };
    }
    return { key: p.param, value: p.value };
  });

export const graphqlInterval = (filter) =>
  // const offset = filter.timestamp === 'now' ? (-stepMap[filter.range] / 60) : 0;
  ({
    date: filter.timestamp === 'now' ? null : addOffset(IntervalHelper.getDate(filter.timestamp), 0),
    interval: filter.range,
  });
export const isFilterValid = (filter) => (
  filter
    .filter((row) => globalFilterKeys.indexOf(row.param) === -1)
    .filter((row) => row.required && (row.value === undefined || row.value === null || row.value === '')).length === 0
);

export const transformText = (text, format, data = {}) => {
  const { trans } = format;
  let res = text;

  if (Array.isArray(trans) && trans.length > 0) {
    if (trans[0] === 'static') {
      res = trans[1].toString();
    } else if (trans[0] === 'truncate') {
      if (trans[2] === 'true') {
        res = stripTags(res);
      }

      res = res.substr(0, trans[1]);
    } else if (trans[0] === 'expression') {
      res = trans[1].replace('{{value}}', res);
    }
  }

  if (format.highlighting || format.formatting || format.newLine) {
    const additional = {};
    if (format.highlighting) {
      additional.highlight = format.highlighting;
    }
    if (format.formatting) {
      additional.format = format.formatting;
    }
    if (format.newLine === false || format.newLine === true) {
      additional.newLine = format.newLine;
    }
    res = (
      <CodeHighlighter {...additional} style={{ background: 'transparent' }}>
        {res}
      </CodeHighlighter>
    );
  }

  return res;
};

export const createLink = (linkUrl, linkParams, values, filter, escape = true) => {
  let link;
  let params = [];
  const [defLink, hash] = linkUrl.split('#');
  if (defLink.startsWith('page:')) {
    link = `/report/${placeholderReplace(defLink.substr(defLink.indexOf(':') + 1), values)}`;
  } else if (defLink.indexOf(':') !== -1) {
    link = defLink;
  } else {
    link = `/${defLink}`;
  }
  if (linkParams) {
    params = getTableLink(linkParams, values, filter, escape);
  }

  if (link) {
    return link + (params ? `?${params.join('&')}` : '') + (hash ? `#${hash}` : '');
  }

  return '';
};

export const getTableCell = (
  instanceScope,
  columns,
  column,
  filter,
  onUpdated = () => undefined,
  cb = {
    showModal: () => undefined,
    // eslint-disable-next-line no-unused-vars
    closeModal: (id) => undefined,
  },
) => {
  let cell;
  const columnTitles = {};
  columns.forEach((col) => {
    columnTitles[`columnName#${col.col}`] = col.title;
  });

  if (column.format.type === 'databar') {
    cell = (props) => {
      if (get(props.row.values, column.col) === null) {
        return <>N/A</>;
      }
      const max = Math.max(...props.data.map((i) => i[column.col]));
      const percent = get(props.row.values, column.col, 0) / max;
      let addStyle = {};
      if (column.format && column.format.colorFrom && column.format.colorTo) {
        addStyle = getGradient(column.format.colorFrom, column.format.colorTo, percent);
      }
      return (
        <span>
          <div
            className="progress progress-label"
            style={{
              minWidth: (get(props.row.values, column.col, 0) || 0).toString().length * 9,
            }}
          >
            <div
              className={`progress-bar ${addStyle === {} ? 'bg-primary' : ''}`}
              role="progressbar"
              style={{
                ...{ width: `${percent * 100}%` },
                ...addStyle,
              }}
              aria-label={get(props.row.original, column.col, 0)}
              aria-valuenow={get(props.row.original, column.col, 0)}
              aria-valuemin="0"
              aria-valuemax={max}
            />
            <span>{get(props.row.values, column.col, 0)}</span>
          </div>
        </span>
      );
    };
  } else if (column.format.type === 'comparebar') {
    cell = (props) => {
      const x = get(props.row.original, column.format.parameters[0]);
      const y = get(props.row.original, column.format.parameters[1]);
      const percent = x / (x + y);
      let addStyle = {};
      if (column.format && column.format.colorFrom && column.format.colorTo) {
        addStyle = getGradient(column.format.colorFrom, column.format.colorTo, percent);
      }
      return (
        <table className="comparebar">
          <tr>
            <td>
              {transformText(x, column?.format)}
            </td>
            <td className="bar">
              <div
                className="progress progress-label"
                style={{
                  minWidth: get(props.row.values, column.col, 0).toString().length * 7,
                }}
              >
                <div
                  className="progress-bar bg-primary"
                  role="progressbar"
                  style={{
                    ...{ width: `${percent * 100}%` },
                    ...addStyle,
                  }}
                  aria-label={x}
                  aria-valuenow={x}
                  aria-valuemin="0"
                  aria-valuemax={x + y}
                />
              </div>
            </td>
            <td>
              {transformText(y, column?.format)}
            </td>
          </tr>
        </table>
      );
    };
  } else if (column.format.type === 'link') {
    cell = (props) => {
      if (column.format.link === 'download') {
        const downloadLink = getFilledParams(column.format.linkParameters, props.row.original, filter);
        return (
          <DownloadLink
            instanceScope={instanceScope}
            primaryId={downloadLink.primaryId?.toString()}
            secondaryId={downloadLink.secondaryId?.toString()}
            type={downloadLink.type}
            title={transformText(get(props.row.original, column.col) || 'Download', column?.format) || 'Download'}
          />
        );
      }

      const outerLink = createLink(
        column.format.link,
        column.format.linkParameters,
        props.row.original,
        filter,
        !column.format.linkParametersWhenGroup,
      );
      return (
        <Link
          to={outerLink || '#'}
          className={column.format.linkParametersWhenGroup ? 'btn btn-sm btn-outline-theme' : ''}
        >
          {transformText(
            get(props.row.values, column.col, 'link'),
            column?.format,
            { ...columnTitles, ...props.row.original },
          )}
        </Link>
      );
    };
  } else if (
    column.format.type === 'dialog'
    || column.format.type === 'dialogsql'
    || column.format.type === 'dialogxml'
  ) {
    cell = (props) => {
      if (!get(props.row.original, column.col, '')) {
        return null;
      }

      let content = (
        <span
          dangerouslySetInnerHTML={{
            __html: get(props.row.original, column.col, 'link'),
          }}
        />
      );
      let copyFormattedText = '';
      if (column.format.type === 'dialogsql') {
        content = <CodeHighlighter language="plsql">{get(props.row.original, column.col, '')}</CodeHighlighter>;
        copyFormattedText = formatSql(get(props.row.original, column.col, ''));
      } else if (column.format.type === 'dialogxml') {
        content = (
          <CodeHighlighter language="xml-doc">
            {formatXml(get(props.row.original, column.col, ''))}
          </CodeHighlighter>
        );
        copyFormattedText = formatXml(get(props.row.original, column.col, ''));
      }

      return (
        <span
          role="button"
          style={{ borderBottom: '1px dotted', cursor: 'pointer' }}
          onClick={() => {
            cb.showModal({
              id: 'dialog',
              button: '',
              size: 'lg',
              content: (
                <>
                  <div>
                    <CopyClipboard text={() => get(props.row.original, column.col, '')} />
                    {copyFormattedText && (
                      <CopyClipboard
                        buttonText="Copy formatted"
                        text={copyFormattedText}
                        className="ml-2"
                      />
                    )}
                  </div>

                  <div style={{ margin: '15px 0' }}>
                    {content}
                  </div>

                  <div>
                    <CopyClipboard text={() => stripTags(get(props.row.original, column.col, ''))} />
                    {copyFormattedText && (
                      <CopyClipboard
                        buttonText="Copy formatted"
                        text={copyFormattedText}
                        className="ml-2"
                      />
                    )}
                  </div>
                </>
              ),
            });
          }}
        >
          {transformText(
            stripTags(get(props.row.original, column.col, 'link')),
            column.format,
            { ...columnTitles, ...props.row.original },
          )}
        </span>
      );
    };
  } else if (column.format.type === 'sparkline_serial') {
    cell = (props) => (
      <SparklineLine
        {...sparklineProps}
        data={JSON.parse(get(props.row.values, column.col, '[]'))}
      />
    );
  } else if (column.format.type === 'sparkline_column') {
    cell = (props) => (
      <SparklineBar
        {...sparklineProps}
        data={JSON.parse(get(props.row.values, column.col, '[]'))}
      />
    );
  } else if (column.format.type === 'icon') {
    cell = (props) => <i className={`fa fa-${get(props.row.original, column.col, '')}`} />;
  } else if (column.format.type === 'hashtags') {
    cell = (props) => (
      <>
        {JSON.parse(get(props.row.original, column.col) || '[]').map((t) => (
          <>
            <span className="badge bg-primary mb-2 mr-2">{t}</span>
            {' '}
          </>
        ))}
      </>
    );
  } else if (column.format.type === 'submit') {
    if (!column.format.submitAction || column.format.submitAction.controls?.length === 0) {
      return () => <span className="text-danger">Definition error</span>;
    }
    cell = (props) => {
      const { controls } = column.format.submitAction;
      const newControls = [];
      const filterValues = {};
      controls.forEach((v, k) => {
        newControls[k] = { ...v };
        newControls[k].title = placeholderReplace(newControls[k].title, { ...columnTitles, ...props.row.original });
        if (v.type === 'select' || v.type === 'dropdown') {
          const definition = JSON.parse(props.row.original[v.columnValue]);
          newControls[k].values = definition.values.reduce((acc, cur) => {
            acc[cur] = cur;
            return acc;
          }, {});
          filterValues[v.filterKey] = definition.current;
        } else {
          filterValues[v.filterKey] = props.row.original[v.columnValue];
        }
      });

      return (
        <span
          className="btn btn-sm btn-primary text-nowrap"
          role="link"
          onClick={() => {
            cb.showModal({
              id: 'dialog',
              button: '',
              size: column.format.submitAction?.modalSize,
              content: (
                <div>
                  <h3 className="mb-5">{column.format.submitAction.text}</h3>
                  <Filter
                    name="filter"
                    controls={newControls}
                    manualSubmit
                    submitTitle={column.format.submitAction.submitText}
                    defaultFilter={filterValues}
                    onChange={(values, onError) => {
                      const submitFilter = fillFilter(
                        column.format.submitAction.query.procedureParams,
                        values,
                        column.format.submitAction.query.queryParams,
                      ).map((v) => ({ key: v.param, value: v.value }));

                      client
                        .query({
                          query: getAutocomplete,
                          context: {
                            silent: true,
                          },
                          variables: {
                            instance: instanceScope,
                            code: column.format.submitAction.query.procedureName,
                            filter: submitFilter,
                            smtConfig: column.format.submitAction.query.smtConfig || false,
                          },
                        })
                        .then((data) => {
                          if (data.data.getAutocomplete.errors) {
                            toast.error('Problem when calling the API.');
                          } else if (data.data.getAutocomplete.data?.values[0].result === -1) {
                            onError(data.data.getAutocomplete.data?.values.map((e) => e.Msg));
                          } else {
                            toast.success(data.data.getAutocomplete.data?.values[0].Msg);
                            cb.closeModal('dialog');
                            onUpdated();
                          }
                        });
                    }}
                  />
                </div>
              ),
            });
          }}
        >
          {column.buttonTitle || 'action'}
        </span>
      );
    };
  } else if (column.format.type && column.format.type !== 'text') {
    cell = () => '';
  } else {
    cell = ({ row, value }) => (
      <span
        dangerouslySetInnerHTML={{ __html: placeholderReplace(value, { ...columnTitles, ...row.original }) }}
      />
    );
  }

  return cell;
};

/**
 * Get first line for actual row from grouped react table
 * @param row
 * @returns Object
 */
const getFirstAggRow = (row) => {
  if (row.subRows && row.subRows.length > 0) {
    return getFirstAggRow(row.subRows[0]);
  }
  return row.original;
};

export const getAggregatedColumn = (column, cellProps, instanceScope, filter) => {
  const { row, state: tableState } = cellProps;
  if (row.depth < tableState.groupBy.length) {
    const firstRow = getFirstAggRow(row);
    if (column.whenGroup && Object.prototype.hasOwnProperty.call(column.whenGroup, row.depth + 1)) {
      return get(firstRow, column.whenGroup[row.depth + 1], '- no value? -');
    }

    if (column.format.linkParametersWhenGroup) {
      const linkDefinition = column.format.linkParametersWhenGroup.find((p) => parseInt(p.group, 10) === row.depth + 1);
      if (column.format.link === 'download') {
        const downloadLink = getFilledParams(column.format.linkParameters, row.values, filter);
        return (
          <DownloadLink
            instanceScope={instanceScope}
            primaryId={downloadLink.primaryId?.toString()}
            secondaryId={downloadLink.secondaryId?.toString()}
            type={downloadLink.type}
            title={transformText(get(row.original, column.col) || 'Download', column?.format) || 'Download'}
          />
        );
      }
      if (linkDefinition) {
        const link = createLink(column.format.link, linkDefinition.linkParameters, firstRow, [], false);
        return (
          <Link to={link || '#'} className="btn btn-sm btn-outline-theme">
            {transformText(get(firstRow, column.col, 'Detail'), column?.format)}
          </Link>
        );
      }
    }
  }

  return null;
};

export const getFilledParams = (def, row, filter) => {
  const params = {};
  Object.keys(def).forEach((k) => {
    if (k === 'do') {
      return;
    }
    if (!def[k].startsWith('%')) {
      params[k] = def[k];
    } else if (def[k].startsWith('%get-')) {
      params[k] = get(filter, def[k].substr(5), '');
    } else if (def[k].startsWith('%filter-')) {
      params[k] = get(filter, def[k].substr(8), '');
    } else {
      params[k] = get(row, trim(def[k], '%'), '');
    }
  });

  return params;
};

export const getTableLink = (def, row, filter, escape = true) => {
  const params = [];
  let maybeEscape;
  if (escape) {
    maybeEscape = (param) => encodeURIComponent(param);
  } else {
    maybeEscape = (param) => param;
  }

  Object.keys(def).forEach((k) => {
    if (k === 'do') {
      return;
    }
    if (!def[k].startsWith('%')) {
      params.push(def[k]);
    } else if (def[k].startsWith('%get-')) {
      params.push(`${maybeEscape(filterUrlPrefix + k)}=${maybeEscape(get(filter, def[k].substr(5), ''))}`);
    } else if (def[k].startsWith('%filter-')) {
      params.push(`${maybeEscape(filterUrlPrefix + k)}=${maybeEscape(get(filter, def[k].substr(8), ''))}`);
    } else {
      params.push(`${maybeEscape(filterUrlPrefix + k)}=${maybeEscape(get(row, trim(def[k], '%'), ''))}`);
    }
  });

  return params;
};

const getGradient = (from, to, percent) => ({
  background: `linear-gradient(90deg, ${from} 0%, ${to} ${
    percent > 0 ? Math.round((100 / percent) * 100) : '1000'
  }%)`,
});
