import React, { useContext, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useLazyQuery, useQuery } from '@apollo/client';
import get from 'lodash/get';
import Table from './Table';
import { getTableData, getTableDefinition } from '../../../queries/graphs';
import Loading from '../Loader/Loading';
import {
  fillFilter,
  getAggregatedColumn,
  getTableCell,
  graphqlFilter,
  graphqlInterval,
  isFilterValid,
  parseMapping,
} from '../../../utils/definitionParser';
import InfoBox from '../../atoms/InfoBox';
import InfoModal from '../../atoms/InfoModal';
import DebugText from '../Debug/DebugText';
import { ColumnFilter } from './Filters';
import ErrorBoundary from '../../atoms/ErrorBoundary';
import StatusInfo from '../../atoms/StatusInfo';
import CardWrapper from '../../atoms/CardWrapper';
import { statusInfoMessages } from '../../../utils/message';
import { setStatusInfo } from '../../../redux/page/actions';
import { getServerErrors } from '../../../utils/error';
import UserError from '../../atoms/UserError';
import Debug from './Debug';
import { ModalContext } from '../GlobalModal/ModalContext';
import ScrollableTable from './ScrollableTable';
import { downloadCsv } from 'utils/generateCsv';
import { makeSlug } from 'utils/urlHelper';
import getRefreshInterval from 'utils/refreshInterval/getRefreshInterval';
import useInterval from '../../../hooks/useInterval';
import { useIntervalSelector, usePollIntervalSelector } from '../../../redux/hooks';
import { FilterType } from '../Filter/index';
import { GraphQLError } from 'graphql';
import { TableStyle } from './types';

type GridTableProps = {
  code: string
  filter: FilterType;
  instanceScope: number;
  queryParams: string;
} & Partial<{
  cache: boolean
  expandable: boolean;
  expanded: boolean;
  minRefresh: number;
  showStatusInfo: boolean;
  style: TableStyle;
}>;

type TableDefinition = {
  config: Partial<{
    defPageSize: number;
    pagination: boolean;
  }>;
  description: string;
  title: string;
} & Partial<{
  sticky: boolean;
}>;

const GridTable: React.FC<GridTableProps> = ({
  instanceScope,
  code,
  filter,
  queryParams,
  cache = true,
  expandable = false,
  expanded = true,
  minRefresh = 0,
  showStatusInfo = true,
  style = 'default',
}) => {
  const dispatch = useDispatch();
  const interval = useIntervalSelector();
  const pollInterval = usePollIntervalSelector();
  const { addModal, closeModal } = useContext(ModalContext);
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState<{ getTableData: Partial<{ errors: GraphQLError[] }> }>();
  const apiFilter = fillFilter(
    Object.keys(parseMapping(queryParams)).join(','),
    filter,
    queryParams,
    true,
  );
  const definition = useQuery<{ getTableDefinition: { data: TableDefinition } }>(getTableDefinition, {
    variables: { instanceId: instanceScope, code },
    skip: !isFilterValid(apiFilter),
    // context: { batch: true },
  });

  const [fetchMore, { called }] = useLazyQuery(getTableData, {
    variables: {
      instanceId: instanceScope,
      code,
      interval: graphqlInterval(interval),
      filter: graphqlFilter(apiFilter) as { key: string; value: string },
      cache,
    },
    errorPolicy: 'all',
    context: { batch: true },
    onCompleted: (res) => {
      setData(res);
      if (!showStatusInfo) {
        dispatch(setStatusInfo(code, statusInfoMessages(res.getTableData.messages)));
      }
      setLoading(false);
    },
  });

  useInterval(() => {
    fetchMore({
      variables: {
        instanceId: instanceScope,
        code,
        interval: graphqlInterval(interval),
        filter: graphqlFilter(apiFilter) as { key: string; value: string },
        cache,
      },
    }).catch(undefined);
  }, getRefreshInterval(pollInterval / 1000, minRefresh) * 1000);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  const error = data?.getTableData?.errors;

  if (!isFilterValid(apiFilter)) {
    return <InfoBox>Select values in the filter to show the graph</InfoBox>;
  }
  if (!definition.called || definition.loading) {
    return <Loading text="Loading table definition..." />;
  }
  if (
    getServerErrors(get(definition, 'errors'))
    || !get(definition, 'data.getTableDefinition.data', false)
  ) {
    return (
      <InfoBox level="error">
        Problem when loading the table!
        <DebugText>
          <p>
            Code:
            {code}
          </p>
          {!get(definition, 'data.getTableDefinition.data', false) ? (
            <p>No definition received</p>
          ) : (
            ''
          )}
          {getServerErrors(get(definition, 'errors')) ? (
            <p>
              Definition error:
              {' '}
              {JSON.stringify(getServerErrors(get(definition, 'errors')))}
            </p>
          ) : (
            ''
          )}
        </DebugText>
      </InfoBox>
    );
  }

  const columns: any[] = [];
  const tableDefinition = definition.data?.getTableDefinition.data;
  if (!tableDefinition) {
    return null;
  }
  // @ts-ignore
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  tableDefinition.columns.forEach((column, idx: number) => {
    const columnData = {
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      id: column.col === null || column.col === 'dummy' ? `__${idx}` : column.col,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      colTag: column.col === null || column.col === 'dummy' ? `__${idx}` : column.col,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      Header: column.title,
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      accessor: column.col,
      disableSortBy: !get(column, 'sortable', false),
      tooltip:
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
        column.tooltip && column.tooltip.length > 2 ? (
          // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
          <InfoModal iconSize="xs">{column.tooltip}</InfoModal>
        ) : (
          ''
        ),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      // eslint-disable-next-line @typescript-eslint/no-unsafe-return
      Aggregated: (props) => getAggregatedColumn(column, props, instanceScope, filter),
      // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-assignment
      group: column.group && column.group > 0 ? column.group : null,
      sortDescFirst: true,
    };

    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (column.searchable) {
      // @ts-ignore
      columnData.Filter = ColumnFilter;
    }
    const tableCell = getTableCell(
      instanceScope,
      // @ts-ignore
      tableDefinition.columns,
      column,
      filter,
      () => {
        fetchMore({
          variables: {
            instanceId: instanceScope,
            code,
            interval: graphqlInterval(interval),
            filter: graphqlFilter(apiFilter) as { key: string; value: string },
            cache,
          },
        }).catch(undefined);

        return undefined;
      },
      { showModal: addModal, closeModal },
    );
    if (tableCell) {
      // @ts-ignore
      columnData.Cell = tableCell;
    }
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
    if (column.format.type === 'icon') {
      // @ts-ignore
      columnData.style = { textAlign: 'center', verticalAlign: 'middle' };
    }

    columns.push(columnData);
  });

  const pageSize = tableDefinition.config.defPageSize
    || (tableDefinition.config.pagination ? 10 : 1000);
  let tableRender = (
    <Table
      key="table"
      interval={interval}
      filter={apiFilter}
      loading={!called || loading}
      error={error}
      paging={tableDefinition.config.pagination || false}
      columns={columns}
      data={called && data ? get(data, 'getTableData.data.data', []) : []}
      fetchData={async (options) => {
        setLoading(true);
        await fetchMore(options);
      }}
      pageSize={pageSize}
      totalCount={get(
        data,
        'getTableData.data.totalCount',
        get(data, 'getTableData.data.data', []).length,
      )}
      sticky={tableDefinition.sticky || false}
      style={style}
    />
  );

  if (tableDefinition?.sticky === true) {
    tableRender = <ScrollableTable>{tableRender}</ScrollableTable>;
  }

  return (
    <CardWrapper
      title={style !== 'button' ? tableDefinition.title : ''}
      info={tableDefinition.description}
      expandable={expandable}
      expanded={expanded}
      actions={[
        <Debug
          key="debug"
          theme="action"
          code={code}
          definition={get(definition, 'data.getTableDefinition')}
          data={get(data, 'getTableData')}
          filter={apiFilter}
        />,
        style !== 'button' ? (
          <i
            key="download"
            aria-label="Download"
            role="button"
            className="actions__item fa fa-download"
            title="Download"
            onClick={() => {
              downloadCsv(get(data, 'getTableData.data.data', []), makeSlug(`SMT_${tableDefinition.title}`));
            }}
          />
        ) : null,
      ]}
    >
      <div style={{ position: 'relative' }}>
        <ErrorBoundary silent>
          {showStatusInfo ? (
            <StatusInfo messages={get(data, 'getTableData.messages', [])} />
          ) : (
            ''
          )}
          <UserError errors={get(data, 'getTableData.errors', [])} />
        </ErrorBoundary>
        <ErrorBoundary>{tableRender}</ErrorBoundary>
      </div>
    </CardWrapper>
  );
};

export default GridTable;
