import { Location } from 'history';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Modal from 'react-bootstrap/esm/Modal';
import ModalHeader from 'react-bootstrap/esm/ModalHeader';
import { ModalBody, ModalFooter } from 'react-bootstrap';
import { usePrompt } from '../../../hooks/usePrompt';

type Props = Partial<{
  actions: (props: {
    cancel: () => void;
    confirm: () => void;
  }) => React.ReactNode;
  navigate: (path: string) => void;
  onLeave: () => void;
  shouldBlockNavigation: (location: Location) => boolean;
  when: boolean | undefined;
}>;

const RouteLeavingGuard: React.FC<React.PropsWithChildren<Props>> = ({
  actions = () => null,
  onLeave = () => undefined,
  shouldBlockNavigation = () => true,
  when = false,
  children,
}) => {
  const [modalVisible, setModalVisible] = useState(false);
  const [lastLocation, setLastLocation] = useState<Location | null>(null);
  const [confirmedNavigation, setConfirmedNavigation] = useState(false);
  const [dirty, setDirty] = useState<boolean>(when || false);
  const navigate = useNavigate();

  const handleWindowClose = (e: BeforeUnloadEvent): boolean => {
    e.preventDefault();
    e.returnValue = '';
    setModalVisible(true);
    return false;
  };

  useEffect(() => {
    window.removeEventListener('beforeunload', handleWindowClose);
    if (when) {
      window.addEventListener('beforeunload', handleWindowClose);
    }
    setDirty(when || false);
    return () => {
      window.removeEventListener('beforeunload', handleWindowClose);
    };
  }, [when]);

  const closeModal = () => {
    setModalVisible(false);
  };

  const handleBlockedNavigation = (nextLocation: Location): boolean => {
    if (!confirmedNavigation && shouldBlockNavigation(nextLocation)) {
      setModalVisible(true);
      setLastLocation(nextLocation);
      return false;
    }
    onLeave();
    return true;
  };

  const handleConfirmNavigationClick = () => {
    window.removeEventListener('beforeunload', handleWindowClose);
    setModalVisible(false);
    setConfirmedNavigation(true);
  };

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      // Navigate to the previous blocked location with your navigate function
      if (navigate && lastLocation?.pathname) {
        navigate(lastLocation.pathname);
      // } else {
      //   navigate(lastLocation.pathname);
      }
    }
  }, [confirmedNavigation, lastLocation]);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,no-undef
  // usePrompt(handleBlockedNavigation, dirty);

  if (modalVisible) {
    return (
      <Modal show onClose={closeModal}>
        <ModalHeader>Warning</ModalHeader>
        <ModalBody>{children}</ModalBody>
        <ModalFooter>
          <button
            className="btn btn-outline-theme"
            onClick={handleConfirmNavigationClick}
            type="button"
          >
            Leave
          </button>
          <button className="btn btn-outline-theme" onClick={closeModal} type="button">
            Cancel
          </button>
          {actions({
            cancel: closeModal,
            confirm: handleConfirmNavigationClick,
          })}
        </ModalFooter>
      </Modal>
    );
  }

  return null;
};

export default RouteLeavingGuard;
