import styled from 'styled-components';
import PropTypes from 'prop-types';
import { getRem, getRemMedia } from '../../utils/css';

const ContentWrapper = styled.div`
  padding-top: ${(props) => (props.isMobile ? getRem(5) : getRem(0))};
  padding-left: 0;
  width: 100vw;
  max-width: 100%;
  @media (min-width: ${getRemMedia(1024.2)}) {
    transition: padding 200ms cubic-bezier(0.4, 0, 0.2, 1);
  }
  position: inherit;
`;

ContentWrapper.propTypes = {
  isMobile: PropTypes.bool,
};
export default ContentWrapper;
