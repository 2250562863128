import React, { useState } from 'react';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import copy from 'copy-to-clipboard';

type Props = Partial<{
  children: React.ReactElement;
  text: string | (() => string);
  buttonText: string;
  className?: string;
}>;

export async function copyTextToClipboard(text) {
  if ('clipboard' in navigator) {
    try {
      return await navigator.clipboard.writeText(text);
    } catch {
      return copy(text, { format: 'text/plain' });
    }
  } else {
    return copy(text, { format: 'text/plain' });
  }
}

const CopyClipboard = ({
  children,
  buttonText = 'Copy to clipboard',
  className,
  text = '',
  ...rest
}: Props) => {
  const [copied, setCopied] = useState(false);

  const inText = typeof text === 'function' ? text() : text;

  let content: JSX.Element;
  if (copied) {
    content = <span className={`btn ${className || ''}`}>Copied</span>;
  } else if (children) {
    content = children;
  } else {
    content = (
      <span className={`btn btn-theme ${className || ''}`}>
        <FontAwesomeIcon icon={['fas', 'clipboard']} />
        {' '}
        {buttonText}
      </span>
    );
  }

  if (inText) {
    const onClick = () => {
      copyTextToClipboard(inText).then(() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      });
    };

    return React.cloneElement(content, { ...rest, onClick });
  }
  return (
    <CopyToClipboard
      onCopy={() => {
        setCopied(true);
        setTimeout(() => setCopied(false), 1000);
      }}
      {...rest}
    >
      {content}
    </CopyToClipboard>
  );
};

export default CopyClipboard;
