import React, { useEffect } from 'react';
import { Placement } from 'react-joyride';
import { clickMenu, waitForElement } from '../../utils/domHelper';
import { scrollNavigationTo } from './helpers';
import { ControlledStep, GuideProps } from '.';
import ControlledJoyride from './ControlledJoyride';
import { hasRole } from '../../utils/auth';

const openPage = async (id: number) => {
  if (!document.querySelector(`.navigation [data-id="${id}"]`)) {
    clickMenu(2213);
    await waitForElement(`.navigation [data-id="${id}"]`);
  }

  clickMenu(id);
};

const SettingsGuide = ({ onFinished }: GuideProps) => {
  // @TODO Workaround, the first beforeActive() is not called. Expanding the navigation is not working in Firefox
  useEffect(() => {
    scrollNavigationTo(2213);
    clickMenu(2213);
  }, []);

  const collectionId = hasRole('smt_admin') ? 2218 : 2211;
  const parameterId = hasRole('smt_admin') ? 2219 : 2212;

  const gotoPage = (page: number) => async ({ target }: ControlledStep) => {
    await openPage(page);

    return waitForElement(target) as Promise<unknown> as Promise<void>;
  };

  const steps: Array<ControlledStep> = [
    {
      disableBeacon: true,
      placement: 'top' as Placement,
      target: '.navigation [data-id="2213"]',
      title: 'SMT Settings',
      content: (
        <p>
          There are three main items in the menu for SMT settings. This is where you can affect how data are
          collected and to see what is the progress of collections.
        </p>
      ),
    },
    {
      disableBeacon: true,
      placement: 'right' as Placement,
      target: `.navigation [data-id="${collectionId.toString()}"]`,
      title: 'Collection settings',
      content: (
        <p>
          Allows you to disable or change a collection of a particular SQL metric or SMT alert.
        </p>
      ),
      beforeActive: gotoPage(collectionId),
    },
    {
      placement: 'bottom' as Placement,
      target: '.filter-card',
      content: (
        <p>
          You can filter these settings by selecting a category so only related collections are shown.
        </p>
      ),
      beforeActive: gotoPage(collectionId),
    },
    {
      placement: 'top' as Placement,
      target: '.content .dataTable > thead > tr > th:nth-child(4)',
      content: (
        <p>
          Read the check name and description to make sure you understand it well.
        </p>
      ),
      beforeActive: gotoPage(collectionId),
    },
    {
      placement: 'top' as Placement,
      target: '.content .dataTable > tbody > tr:nth-child(1) > td:nth-child(16)',
      content: (
        <p>
          Use configure button to change the data retention, interval or disable/enable the collection entirely. Be
          aware that changing data retention and intervals may affect the continuity of data between DWH levels, so
          you may need to update the accordingly.
        </p>
      ),
      beforeActive: gotoPage(collectionId),
    },
    {
      placement: 'top' as Placement,
      target: '.content .dataTable > tbody > tr:nth-child(1) > td:nth-child(17)',
      content: (
        <p>
          You can always use a base config when you made changes that you are not sure about. Base config could change
          anytime at central level if we think it is better to use different interval, for example. However, your local
          setting, if exists, precedes the base one.
        </p>
      ),
      beforeActive: gotoPage(collectionId),
    },
    {
      placement: 'right' as Placement,
      target: `.navigation [data-id="${parameterId.toString()}"]`,
      title: 'Parameters config',
      content: (
        <p>
          To affect some of the collections or SMT behavior in general (e.g. alerting, index definition collection)
        </p>
      ),
      beforeActive: gotoPage(parameterId),
    },
    {
      placement: 'bottom' as Placement,
      target: '.filter-card',
      content: (
        <p>
          Like at collection settings you can filter parameters related to certain SMT area.
        </p>
      ),
      beforeActive: gotoPage(parameterId),
    },
    {
      placement: 'top' as Placement,
      target: '.content .dataTable > thead > tr > th:nth-child(6)',
      content: (
        <p>
          Always read Parameter description and note to make sure you understand the setting.
        </p>
      ),
      beforeActive: gotoPage(parameterId),
    },
    {
      placement: 'top' as Placement,
      target: '.content .dataTable > thead > tr > th:nth-child(11)',
      content: (
        <p>
          You can compare your current setting with base values, which can be always changed on our side, however
          you local setting, if exists, precedes the base one.
        </p>
      ),
      beforeActive: gotoPage(parameterId),
    },
    {
      placement: 'top' as Placement,
      target: '.content .dataTable > thead > tr > th:nth-child(14)',
      content: (
        <p>
          Clicking on Set param new modal window appears where we recommend to read details again and set desired value.
        </p>
      ),
      beforeActive: gotoPage(parameterId),
    },
    {
      placement: 'right' as Placement,
      target: '.navigation [data-id="2214"]',
      title: 'Collector status',
      content: (
        <p>
          To view how the current monitoring is performing
        </p>
      ),
      beforeActive: async () => openPage(2214),
    },
    {
      placement: 'center' as Placement,
      target: '.content',
      content: (
        <p>
          This page contains few tables to provide information about current SMT collection, last 100 collections
          and further information about the work queue and SMT threads ready to collect data. Please read tooltips
          for specific table to get more details.
        </p>
      ),
      beforeActive: async () => openPage(2214),
    },
  ];

  return (
    <ControlledJoyride
      steps={steps}
      onFinished={onFinished}
    />
  );
};

export default SettingsGuide;
