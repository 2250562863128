import {
  AliasMode, CommaPosition, format, FormatOptions,
} from 'prettier-sql';
import getEnvVal from 'constants/getEnvVal';

const formatSql = (value: string | null, cfg?: Partial<FormatOptions>): string => {
  if (!value) {
    return '';
  }

  try {
    return format(
      getEnvVal('SQL_FORMAT_LIMIT') > 0 ? value.substr(0, getEnvVal('SQL_FORMAT_LIMIT')) : value,
      {
        language: 'tsql',
        uppercase: true,
        keywordPosition: 'standard',
        breakBeforeBooleanOperator: true,
        aliasAs: AliasMode.never,
        newline: 0,
        tabulateAlias: false,
        commaPosition: CommaPosition.after,
        parenOptions: {
          openParenNewline: false,
          closeParenNewline: true,
        },
        lineWidth: 80,
        linesBetweenQueries: 1,
        denseOperators: false,
        semicolonNewline: false,
        ...cfg,
      },
    );
  } catch {
    console.error('Error when formatting the SQL');
    return value;
  }
};

export default formatSql;
