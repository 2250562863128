import { useState } from 'react';

export type ModalData = {
  id: string;
  size: 'sm' | 'lg' | 'xl';
  button: any;
  content: any;
};

const useModal = () => {
  const [modals, setModals] = useState<ModalData[]>([]);

  const addModal = (modal: ModalData) => {
    setModals([...modals.filter((m) => m.id !== modal.id), modal]);
  };

  const closeModal = (modalId) => {
    setModals([...modals.filter((m) => m.id !== modalId)]);
  };

  return { modals, addModal, closeModal };
};

export default useModal;
