import ltrim from 'lodash/trim';
import { instanceUrlPrefix, intervalUrlPrefix } from '../constants/variables';
import { isEmptyValue } from './string';
import { FilterType } from '../components/molecules/Filter/index';
import { FilterBoxValue } from 'components/molecules/GlobalFilter/FilterBox';
import IntervalHelper from './IntervalHelper';
import { Interval } from '../redux/userSettings/types';

type urlValue = string | string[] | number | number[] | null;
type urlParameters = { [key: string]: urlValue };
type urlParsedParameters = { [key: string]: string };

export class UrlParams {
  params: urlParsedParameters = {};

  constructor(search: string | urlParsedParameters) {
    if (typeof search === 'string') {
      const parts: string[] = search.split('?');
      if (!parts[1]) {
        return;
      }

      this.parseQueryString(parts[1]);
    } else if (typeof search === 'object') {
      this.params = search;
    }
  }

  parseQueryString(qs: string): void {
    qs.split('&').forEach((pair) => {
      const [key, val] = pair.split('=');
      this.params[key] = val;
    });
  }

  get(key: string): string {
    return this.params[key];
  }

  set(key: string, value: string): void {
    this.params[key] = value;
  }

  delete(key: string): void {
    delete this.params[key];
  }

  items(): urlParsedParameters {
    return this.params;
  }

  toString(): string {
    const ret: string[] = [];
    Object.keys(this.params).forEach((k) => {
      ret.push(`${k}=${this.params[k]}`);
    });
    return ret.join('&').replace(/\+/g, '%20');
  }
}

/**
 * Convert filter object to URL
 *
 * It will update the URL, not replace the values
 */
export const filterToUrl = (
  filter: FilterType,
  prefix: string,
  wipe = false,
): string => {
  if (!filter) {
    return '';
  }
  const urlFilter = Object.assign(
    {},
    ...Object.keys(filter).map((key) => {
      if (Array.isArray(filter[key])) {
        const values = (filter[key] as string[]).filter(
          (i) => !isEmptyValue(i),
        );
        return {
          [`${prefix + key}[]`]:
            values.length > 0 ? `[${values.join('|')}]` : '',
        };
      }
      return { [prefix + key]: !isEmptyValue(filter[key]) ? filter[key] : '' };
    }),
  ) as urlParsedParameters;

  const currentUrlParams = new UrlParams(wipe ? '' : window.location.search);
  Object.keys(urlFilter).forEach((k) => {
    if (urlFilter[k] === '') {
      currentUrlParams.delete(k);
    } else {
      currentUrlParams.set(k, encodeURIComponent(urlFilter[k]));
    }
  });

  return currentUrlParams.toString();
};

/**
 * Convert URL to filter object
 */
export const urlToFilter = (
  url: string,
  prefix: string,
  ignorePrefix?: string[],
): {[key: string]: urlValue} => {
  const paramUrl = ltrim(url, '?');
  const params = paramUrl.split('&');
  const filter = {};
  params.forEach((param) => {
    const [k, value] = param.split('=');
    if (isEmptyValue(k) || isEmptyValue(value)) {
      return;
    }
    const key = decodeURIComponent(k);
    if (!prefix || key.startsWith(prefix)) {
      if (!ignorePrefix || !ignorePrefix.find((ignore) => key.startsWith(ignore))) {
        const v = decodeURIComponent(value);
        if (key.endsWith('[]') && v.startsWith('[') && v.endsWith(']')) {
          filter[key.substr(prefix.length, key.length - prefix.length - 2)] = v
            .substr(1, v.length - 2)
            .split('|')
            .filter((i) => !isEmptyValue(i));
        } else {
          filter[key.substr(prefix.length)] = v;
        }
      }
    }
  });

  return filter;
};

export const urlToInterval = (
  url: string,
  prefix: string,
  ignorePrefix?: string[],
): {[key: string]: urlValue} => urlToFilter(url, prefix, ignorePrefix);

export const intervalToUrl = (
  interval: FilterBoxValue,
): string => (`${intervalUrlPrefix}timestamp=${encodeURI(IntervalHelper.getUrlValue(interval.timestamp))}`
  + `&${intervalUrlPrefix}range=${encodeURI(interval.range)}`
);

export const getPersistentParameters = (): {
  timestamp?: string | null;
  range?: string | null;
  instance?: string | null;
} => {
  const ret: {timestamp?: string | null; range?: string | null; instance?: string | null} = {};
  const currentUrlParams = new UrlParams(window.location.search);

  if (currentUrlParams.get(`${instanceUrlPrefix}instance`)) {
    ret.instance = decodeURI(currentUrlParams.get(`${instanceUrlPrefix}instance`) || '');
  }
  if (currentUrlParams.get(`${intervalUrlPrefix}timestamp`)) {
    ret.timestamp = decodeURI(currentUrlParams.get(`${intervalUrlPrefix}timestamp`) || '');
  }
  if (currentUrlParams.get(`${intervalUrlPrefix}range`)) {
    ret.range = decodeURI(currentUrlParams.get(`${intervalUrlPrefix}range`) || '');
  }

  return ret;
};

export const getPersistentQuery = (
  timestamp?: string | null,
  range?: string | null,
  instance?: string | null,
): string => {
  const filter = getPersistentParameters();
  const params = {};
  if (instance) {
    params[`${instanceUrlPrefix}instance`] = instance;
  } else if (filter.instance) {
    params[`${instanceUrlPrefix}instance`] = filter.instance;
  }
  if (instance) {
    params[`${intervalUrlPrefix}timestamp`] = timestamp;
  } else if (filter.timestamp) {
    params[`${intervalUrlPrefix}timestamp`] = filter.timestamp;
  }
  if (instance) {
    params[`${intervalUrlPrefix}range`] = range;
  } else if (filter.range) {
    params[`${intervalUrlPrefix}range`] = filter.range;
  }
  const currentUrlParams = new UrlParams(params);

  return currentUrlParams.toString().replace(/\+/g, '%20');
};

export const addUrlParams = (query: string, params: urlParameters): string => {
  const currentUrlParams = new UrlParams(query);
  Object.keys(params).forEach((k) => currentUrlParams.set(k, params[k]?.toString() || ''));
  return currentUrlParams.toString();
};

export const removeUrlParams = (query: string, params: string[]): string => {
  const currentUrlParams = new UrlParams(query);
  params.forEach((k) => currentUrlParams.delete(k));
  return currentUrlParams.toString();
};

export const getUrlParam = (query: string, param: string): string | undefined => {
  const currentUrlParams = new UrlParams(query);
  return currentUrlParams.get(param) || undefined;
};

export const makeSlug = (title: string): string => title.replace(/\s+/g, '_');

export const getPersistentUrl = (
  url: string,
  instance: number,
  interval: Interval,
) => `${url}?${getPersistentQuery(interval.timestamp, interval.range, instance.toString())}`;
