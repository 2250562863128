import { __extends } from 'tslib';
import { ApolloLink } from '@apollo/client/link/core/index';
import { Observable } from '@apollo/client/utilities/index';
import { getErrors } from './apiResponse';

export function customOnError(errorHandler) {
  return new ApolloLink((operation, forward) => new Observable((observer) => {
    let sub;
    let retriedSub;
    let retriedResult;
    try {
      sub = forward(operation).subscribe({
        next(result) {
          if (getErrors(result)) {
            retriedResult = errorHandler({
              graphQLErrors: getErrors(result),
              response: result,
              operation,
              forward,
            });
            if (retriedResult) {
              retriedSub = retriedResult.subscribe({
                next: observer.next.bind(observer),
                error: observer.error.bind(observer),
                complete: observer.complete.bind(observer),
              });
              return;
            }
          }
          observer.next(result);
        },
        error(networkError) {
          retriedResult = errorHandler({
            operation,
            networkError,
            graphQLErrors:
                networkError
                && networkError.result
                && networkError.result.errors,
            forward,
          });
          if (retriedResult) {
            retriedSub = retriedResult.subscribe({
              next: observer.next.bind(observer),
              error: observer.error.bind(observer),
              complete: observer.complete.bind(observer),
            });
            return;
          }
          observer.error(networkError);
        },
        complete() {
          if (!retriedResult) {
            observer.complete.bind(observer)();
          }
        },
      });
    } catch (e) {
      errorHandler({ networkError: e, operation, forward });
      observer.error(e);
    }
    return function () {
      if (sub) {
        sub.unsubscribe();
      }
      if (retriedSub) {
        sub.unsubscribe();
      }
    };
  }));
}
const ErrorLink = (function (_super) {
  __extends(ErrorLink, _super);
  function ErrorLink(errorHandler) {
    const _this = _super.call(this) || this;
    _this.link = customOnError(errorHandler);
    return _this;
  }
  ErrorLink.prototype.request = function (operation, forward) {
    return this.link.request(operation, forward);
  };
  return ErrorLink;
}(ApolloLink));
export { ErrorLink };
