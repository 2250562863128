import types from './action-types';

export const getToken = (code, returnUrl = null) => ({
  type: types.GET_TOKEN,
  payload: { code, returnUrl },
});

export const getTokenSuccess = (payload) => ({
  type: types.GET_TOKEN_SUCCESS,
  payload,
});

export const getTokenError = (payload) => ({
  type: types.GET_TOKEN_ERROR,
  payload,
});

export const logOut = (payload) => ({
  type: types.LOG_OUT,
  payload,
});
