import gql from 'graphql-tag';
import { CommonFragment } from './common';

export const getUserGql = gql`
  query me {
    me {
      ${CommonFragment}
      data {
        settings {
          code
          value
        }
        user {
          id
          roles
          username
          email
        }
      }
    }
  }
`;

export const updateUserSettingGql = gql`
  mutation setUserSettings($code: String, $value: String) {
    setUserSettings(code: $code, value: $value) {
      ${CommonFragment}
    }
  }
`;
