import React, { useState } from 'react';
import get from 'lodash/get';
import omit from 'lodash/omit';
import DatePicker from 'react-datepicker';
import { TuningTask } from 'types/TuningTask';
import {
  Form, Col, Button, Row,
} from 'react-bootstrap';
import { Formik, FormikHelpers } from 'formik';
import * as Yup from 'yup';
import { getUsername } from 'utils/auth';
import { useQuery } from '@apollo/client';
import { FormikEffect } from 'components/molecules/Form/FormikEffect';
import omitBy from 'lodash/omitBy';
import isNil from 'lodash/isNil';
import Select from '../../molecules/Form/Select';
import CreatableSelect from '../../molecules/Form/CreatableSelect';
import { Field, InputText, InputFormatted } from '../../molecules/Form/Input';
import { getAutocomplete } from '../../../queries/filter';
import Loading from '../../molecules/Loader/Loading';
import DatePickerTimeInput from '../../atoms/DatePickerTimeInput';
import ErrorMessage from '../../atoms/ErrorMessage';
import formatSql from '../../../utils/formatSql';
import useLocalStorage from '../../../hooks/useLocalStorage';
import RouteLeavingGuard from '../../molecules/RouteLeavingGuard/RouteLeavingGuard';
import { useInstanceSelector, useInstancesSelector } from '../../../redux/hooks';

type Props<T = TuningTask> = {
  tuningTask: Partial<T>;
  onSubmit: (
    values: object,
    helpers: FormikHelpers<typeof defaultValues>
  ) => Promise<any>;
  apiErrors: Array<any>;
};

const validationSchema = Yup.object().shape({
  stamp: Yup.string().required('Stamp is required'),
  user_name: Yup.string().required('User name is required'),
  database_name: Yup.string().required('Database name is required'),
  regServer_ID: Yup.number().required('Reg server is required'),
  status: Yup.string().nullable(true).required('Status is required'),
});

const defaultValues = {
  regServer_ID: null,
  database_name: '',
  user_name: '',
  status: null,
  stamp: new Date(),
  tags: [],
  sql_message: '',
  execday: '',
  before_query_text: '',
  before_query_plan: '',
  before_stats: '',
  before_duration_ms: '',
  before_cpu_ms: '',
  before_reads: '',
  before_queryhash: '',
  before_planId: '',
  before_stmtId: '',
  after_query_text: '',
  after_query_plan: '',
  after_stats: '',
  after_duration_ms: '',
  after_cpu_ms: '',
  after_reads: '',
  after_queryhash: '',
  after_planId: '',
  after_stmtId: '',
  visibleName: '',
};

const TuningTaskForm: React.FC<Props> = ({
  tuningTask,
  onSubmit,
  apiErrors,
}) => {
  const instanceScope = useInstanceSelector();
  const instances = useInstancesSelector();
  const username = getUsername();
  const [
    unsavedState,
    setUnsavedState,
    { loaded: unsavedLoaded, isDirty: isUnsavedDirty },
  ] = useLocalStorage(
    'tuningNotesForm',
    tuningTask?.noteId?.toString() || 'new',
  );
  const isNew = !tuningTask?.noteId;
  const [initialValues] = useState<typeof defaultValues>(
    tuningTask
      ? {
        ...defaultValues,
        ...(isNew && unsavedState
          ? unsavedState
          : {
            ...omitBy(tuningTask, (v) => isNil(v) || v === ''),
            user_name: tuningTask.user_name || username,
          }),
      }
      : defaultValues,
  );

  const {
    data: statusData,
    loading: statusLoading,
    error: errorLoading,
  } = useQuery(getAutocomplete, {
    variables: {
      instance: instanceScope.id,
      code: 'reporting.TuningRecordStatusList',
      smtConfig: true,
    },
  });

  if (statusLoading) {
    return <Loading />;
  }

  if (errorLoading) {
    return (
      <ErrorMessage>
        Error when getting the data from the server. Try to refresh the page.
      </ErrorMessage>
    );
  }

  const statuses = statusData.getAutocomplete?.data?.values?.map((i) => ({
    label: i.status,
    value: i.status,
  })) || [];

  return (
    <Formik
      enableReinitialize={false}
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={(values, helpers) => {
        setUnsavedState(undefined);
        onSubmit(values, helpers).then(() => {
          helpers.setSubmitting(false);
        });
      }}
    >
      {/* Callback function containing Formik state and helpers that handle common form actions */}
      {({
        values,
        setFieldValue,
        errors,
        touched,
        handleChange,
        handleSubmit,
        dirty,
        resetForm,
        setValues,
        isSubmitting,
        setSubmitting,
        submitCount,
      }) => {
        const rest = {
          values,
          errors,
          touched,
          onChange: handleChange,
          submitCount,
        };

        return (
          <Form
            onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
              setSubmitting(true);
              e.preventDefault();
              handleSubmit(e);
              // setSubmitting(false);
            }}
            onKeyDown={(keyEvent) => {
              if ((keyEvent.charCode || keyEvent.keyCode) === 13) {
                keyEvent.preventDefault();
              }
            }}
          >
            <FormikEffect
              onChange={(a) => {
                setUnsavedState(values);
              }}
            />
            <RouteLeavingGuard
              when={dirty && submitCount === 0}
              onLeave={() => setUnsavedState(undefined)}
            >
              You have unsaved changes. Do you want to leave?
            </RouteLeavingGuard>

            {apiErrors && apiErrors.length > 0 && (
              <Row>
                <Col md={12}>
                  <div className="alert alert-danger" role="alert">
                    {apiErrors.map((e, i) => (
                      <div key={i}>{e.message}</div>
                    ))}
                  </div>
                </Col>
              </Row>
            )}
            <Row>
              <Col md={3} />
              <Col md={6}>
                {isNew && unsavedLoaded && unsavedState && !isUnsavedDirty && (
                  <div className="alert alert-info" role="alert">
                    <Row>
                      <Col md={9}>Data were loaded from previous state</Col>
                      <Col md={3} className="text-right">
                        <Button
                          variant="danger"
                          onClick={() => {
                            resetForm({
                              values: {
                                ...defaultValues,
                                user_name: tuningTask.user_name || username as string,
                              },
                            });
                            setUnsavedState(undefined);
                          }}
                        >
                          Clear form and history
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}
                {!isNew && unsavedLoaded && unsavedState && !isUnsavedDirty && (
                  <div className="alert alert-info" role="alert">
                    <Row>
                      <Col md={9}>
                        We've noticed an unsaved tuning task in progress, would
                        you like to load it?
                      </Col>
                      <Col md={3} className="text-right">
                        <Button
                          variant="danger"
                          onClick={() => {
                            setValues({
                              ...(unsavedState as any),
                              user_name: tuningTask.user_name || username,
                            });
                            setUnsavedState(unsavedState);
                          }}
                        >
                          Load unsaved state
                        </Button>
                      </Col>
                    </Row>
                  </div>
                )}

                <Field input="regServer_ID" label="Reg server" {...rest}>
                  <Select
                    id="regServer_ID"
                    value={
                      instances
                        .map((i) => ({ label: i.name, value: i.id.toString() }))
                        .find((i) => i.value == values.regServer_ID) || null
                    }
                    options={instances.map((i) => ({
                      label: i.name,
                      value: i.id.toString(),
                    }))}
                    onChange={(e: any) => {
                      handleChange({
                        target: { name: 'regServer_ID', value: e.value || '' },
                      });
                    }}
                    className={
                      errors.regServer_ID
                      && (submitCount > 0 || touched.regServer_ID)
                        ? 'is-invalid'
                        : ''
                    }
                    {...omit(rest, ['onChange'])}
                  />
                </Field>
                <InputText
                  input="database_name"
                  label="Database name"
                  {...rest}
                />
                <InputText
                  input="user_name"
                  label="User name"
                  {...omit(rest, ['onChange'])}
                />

                <Field input="status" label="Status" {...rest}>
                  <Select
                    id="status"
                    value={statuses.find((s) => s.value === values.status)}
                    options={statuses}
                    onChange={(e: any) => {
                      handleChange({
                        target: { name: 'status', value: e.value || '' },
                      });
                    }}
                    className={
                      errors.status && (submitCount > 0 || touched.status)
                        ? 'is-invalid'
                        : ''
                    }
                    {...omit(rest, ['onChange'])}
                  />
                </Field>

                <Field input="stamp" label="Tuning date" {...rest}>
                  <DatePicker
                    selected={
                      get(values, 'stamp')
                        ? new Date(get(values, 'stamp'))
                        : undefined
                    }
                    onChange={(date: Date) => {
                      const d = new Date(date);
                      d.setSeconds(0);
                      handleChange({ target: { name: 'stamp', value: d } });
                    }}
                    dateFormat="dd/MM/yyyy HH:mm"
                    className={`form-control ${
                      errors.stamp && (submitCount > 0 || touched.stamp)
                        ? 'is-invalid'
                        : ''
                    }`}
                    showTimeInput
                    customTimeInput={<DatePickerTimeInput />}
                  />
                </Field>

                <Field input="tags" label="Tags" {...rest}>
                  <CreatableSelect
                    id="tags"
                    isMulti
                    value={
                      values.tags
                        ? values.tags.map((v) => ({ value: v, label: v }))
                        : []
                    }
                    onChange={(newValue: any, actionMeta: any) => {
                      handleChange({
                        target: {
                          name: 'tags',
                          value: newValue ? newValue.map((v) => v.value) : [],
                        },
                      });
                    }}
                    className={
                      errors.tags
                      && (submitCount > 0 || touched.tags)
                      && 'is-invalid'
                    }
                    {...omit(rest, ['onChange'])}
                  />
                </Field>
              </Col>
            </Row>

            <Row className="mt-3">
              <Col>
                <InputFormatted
                  input="sql_message"
                  label={(
                    <>
                      Tuning message (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue(
                            'sql_message',
                            formatSql(values.sql_message),
                          );
                        }}
                      >
                        format
                      </a>
                      )
                    </>
                  )}
                  {...rest}
                />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <h3>Before</h3>
                <InputFormatted
                  input="before_query_plan"
                  label="Query plan"
                  mode="xml"
                  {...rest}
                />
                <InputFormatted
                  input="before_query_text"
                  label={(
                    <>
                      Query text (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue(
                            'before_query_text',
                            formatSql(values.before_query_text),
                          );
                        }}
                      >
                        format
                      </a>
                      )
                    </>
                  )}
                  {...rest}
                />
                <InputFormatted input="before_stats" label="Stats" {...rest} />
              </Col>
              <Col md={6}>
                <h3>After</h3>
                <InputFormatted
                  input="after_query_plan"
                  label="Query plan"
                  mode="xml"
                  {...rest}
                />
                <InputFormatted
                  input="after_query_text"
                  label={(
                    <>
                      Query text (
                      <a
                        href=""
                        onClick={(e) => {
                          e.preventDefault();
                          setFieldValue(
                            'after_query_text',
                            formatSql(values.after_query_text),
                          );
                        }}
                      >
                        format
                      </a>
                      )
                    </>
                  )}
                  {...rest}
                />
                <InputFormatted input="after_stats" label="Stats" {...rest} />
              </Col>
            </Row>

            <Row className="justify-content-md-center mt-3">
              <Col md={6}>
                <InputText input="execday" label="Executions / day" {...rest} />
              </Col>
            </Row>

            <Row className="mt-3">
              <Col md={6}>
                <InputText
                  input="before_duration_ms"
                  label="Duration (ms)"
                  {...rest}
                />
                <InputText input="before_cpu_ms" label="CPU (ms)" {...rest} />

                <InputText input="before_reads" label="Reads" {...rest} />
                <InputText input="before_queryhash" label="Query hash" {...rest} />

                <InputText input="before_planId" label="Plan ID" {...rest} />
                <InputText
                  input="before_stmtId"
                  label="Statement ID"
                  {...rest}
                />
              </Col>
              <Col md={6}>
                <InputText
                  input="after_duration_ms"
                  label="Duration (ms)"
                  {...rest}
                />
                <InputText input="after_cpu_ms" label="CPU (ms)" {...rest} />

                <InputText input="after_reads" label="Reads" {...rest} />
                <InputText input="after_queryhash" label="Query hash" {...rest} />

                <InputText input="after_planId" label="Plan ID" {...rest} />
                <InputText
                  input="after_stmtId"
                  label="Statement ID"
                  {...rest}
                />
              </Col>
            </Row>

            <Button type="submit" disabled={!dirty || isSubmitting}>
              {!isSubmitting && 'Save'}
              {isSubmitting && (
                <>
                  <span
                    className="spinner-border spinner-border-sm"
                    role="status"
                  />
                  {' '}
                  Saving...
                </>
              )}
            </Button>
          </Form>
        );
      }}
    </Formik>
  );
};

export default TuningTaskForm;
