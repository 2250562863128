import React, { CSSProperties } from 'react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import highlightStyle from './CodeHighlighter.styles';
import formatSql from 'utils/formatSql';

const formatMap: {[key: string]: string} = {
  plsql: 'plsql',
  sql: 'plsql',
  tsql: 'plsql',
};

type Props = {
  // Content to highlight
  children: string;
} & Partial<{
  // Additional styling
  style: CSSProperties;
  // Language to highlight
  language: 'plsql' | 'sql' | string;
  highlight: 'plsql' | 'sql' | string;
  format: 'plsql' | 'sql' | string;
  newLine: boolean;
}>;

const CodeHighlighter: React.FC<Props> = ({ children, style, ...props }) => {
  if (!children || children.trim().length === 0) {
    return null;
  }

  const highlight: string = props?.highlight || props?.language || 'sql';
  const format: string = props?.format || props?.language || 'sql';

  let text = !format && !props.newLine
    ? children.replaceAll('\n', ' ').replaceAll('\t', ' ')
    : children;
  if (formatMap[format?.toLowerCase()]) {
    if (formatMap[format?.toLowerCase()] === 'plsql') {
      text = formatSql(text);
    }
  }

  if (highlight) {
    return (
      <SyntaxHighlighter
        language={formatMap[highlight?.toLowerCase()] || 'text'}
        style={{ ...highlightStyle, ...(style || {}) }}
      >
        {text}
      </SyntaxHighlighter>
    );
  }
  return <span style={{ whiteSpace: 'pre-wrap' }}>{text}</span>;
};

export default CodeHighlighter;
