/* eslint-disable jsx-a11y/no-autofocus */
import React, {
  forwardRef, useEffect, useMemo, useState,
} from 'react';
import { Button } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import Select from '../Filter/Select';
import ControlBox from '../Filter/ControlBox';
import 'react-datepicker/dist/react-datepicker.css';
import { FilterType, FilterValue } from '../Filter';
import { withoutTimezone, addTimezone } from '../../../utils/dateTimezone';
import IntervalHelper from '../../../utils/IntervalHelper';
import { usePrevious } from '../../../hooks/useCompare';
import { useIntervalsSelector } from '../../../redux/hooks';

const rangeControl = {
  filterKey: 'range',
  type: 'select',
  title: '',
  ui: {
    hideIndicator: true,
    hideSelected: false,
  },
  addEmptyVal: false,
};

const datetimeControl = {
  filterKey: 'timestamp',
  type: 'datetime',
  title: '',
};

const mergeDefaults = (values: FilterBoxValue): FilterBoxValue => ({
  [datetimeControl.filterKey]: new Date().toJSON(),
  [rangeControl.filterKey]: '30Minute',
  ...values,
});

type Props = {
  instanceScope: number;
  filter: FilterBoxValue;
  onChange: (state: FilterBoxValue) => void;
};

export type FilterBoxValue = { range: string; timestamp: Date | string } & FilterType;

const useNowChanged = (value) => {
  const prevValue = usePrevious(value);
  return (prevValue === 'now' && value !== 'now') || (prevValue !== 'now' && value === 'now');
};

const FilterBox: React.FC<Props> = ({ onChange = () => { /* no action */ }, ...props }) => {
  const intervals = useIntervalsSelector();
  const [filterState, setFilterState] = useState<FilterBoxValue>(mergeDefaults(props.filter));
  useEffect(() => {
    setFilterState(mergeDefaults(props.filter));
  }, [props.filter]);

  function onControlChange(key: string, value: FilterValue | Date) {
    const newState = { ...filterState, [key]: value } as typeof filterState;
    setFilterState(newState);
    return newState;
  }

  function onSubmitClick(state: FilterBoxValue) {
    onChange(state);
  }

  function onStepClick(action: 'plus' | 'minus', step: { duration: number, unit: string }) {
    const newDateTime: Date = IntervalHelper.addIntervalTime(
      filterState[datetimeControl.filterKey] as string,
      action === 'plus' ? step.duration : -step.duration,
      step.unit,
    );
    onSubmitClick(
      onControlChange(datetimeControl.filterKey, newDateTime.toJSON()),
    );
  }

  function onDatepickerChange(key: string, newDate: Date | 'now') {
    onControlChange(key, newDate === 'now' ? newDate : withoutTimezone(newDate));
  }

  const displayedDate = filterState[datetimeControl.filterKey] === 'now' ? 'now' : addTimezone(new Date(
    (Date.parse(filterState[datetimeControl.filterKey] as string) > 0
      ? filterState[datetimeControl.filterKey]
      : null) as string,
  ));

  const buttonStyles = {
    className: 'btn--icon-text',
    variant: 'theme-dark',
  };

  const inputChanged = useNowChanged(displayedDate);

  const ReactDatePickerInput = useMemo(() => forwardRef<HTMLInputElement,
    React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>>(
      ({ value, ...pickerProps }, pickerRef) => (
        <input
          ref={pickerRef}
          {...pickerProps}
          value={displayedDate === 'now' ? 'Now' : value}
        />
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    ), [inputChanged]);

  const rangeControlOptions = {
    ...rangeControl,
    values: intervals.reduce((obj, item) => {
      // eslint-disable-next-line no-param-reassign
      obj[item.name] = item.display;
      return obj;
    }, {}),
  };

  const currentInterval = intervals.find((item) => item.name === filterState[rangeControlOptions.filterKey]);
  if (!currentInterval && intervals.length > 0) {
    onChange({ ...filterState, [rangeControlOptions.filterKey]: intervals[0].name });
  }

  return (
    <div
      className="inline"
      style={{ display: 'flex', flexWrap: 'wrap' }}
    >
      <ControlBox
        className=""
        control={rangeControlOptions}
        style={{ minWidth: '90px' }}
        hideLabel
        title="Date interval"
      >
        <Select
          control={rangeControlOptions}
          instanceScope={props.instanceScope}
          filters={filterState}
          value={currentInterval ? currentInterval.name : ''}
          onChange={(event) => onControlChange(rangeControlOptions.filterKey, event.target.value)}
        />
      </ControlBox>
      <DatePicker
        selected={displayedDate === 'now' ? new Date() : displayedDate}
        onChange={(date: Date, event) => {
          if (event && (event as unknown as Partial<{ target: { innerText: string } }>)?.target?.innerText === 'Now') {
            onDatepickerChange(datetimeControl.filterKey, 'now'); // new RelativeDate());
          } else {
            onDatepickerChange(datetimeControl.filterKey, date);
          }
        }}
        timeInputLabel="Time:"
        dateFormat="dd/MM/yyyy HH:mm"
        timeFormat="HH:mm"
        className="form-control"
        showTimeSelect
        todayButton="Now"
        customInput={(
          <ReactDatePickerInput />
        )}
      />
      <div className="align-middle">
        <Button {...buttonStyles} title="Set date and interval" onClick={() => onSubmitClick(filterState)}>
          Filter
        </Button>
        <Button
          {...buttonStyles}
          disabled={!currentInterval}
          onClick={() => {
            if (currentInterval) {
              onStepClick('minus', currentInterval);
            }
          }}
          title="Move start date back by selected interval"
        >
          ≪
        </Button>
        <Button
          {...buttonStyles}
          disabled={!currentInterval}
          onClick={() => {
            if (currentInterval) {
              onStepClick('plus', currentInterval);
            }
          }}
          title="Move start date forward by selected interval"
        >
          ≫
        </Button>
        <Button
          {...buttonStyles}
          onClick={() => onStepClick('minus', { duration: 1, unit: 'day' })}
          title="One day ago from the start date"
        >
          -1 Day
        </Button>
        <Button
          {...buttonStyles}
          onClick={() => onStepClick('plus', { duration: 1, unit: 'day' })}
          title="One day later from the start date"
        >
          +1 Day
        </Button>
        <Button
          {...buttonStyles}
          onClick={() => onStepClick('minus', { duration: 7, unit: 'day' })}
          title="One week ago from the start date"
        >
          -1 Wk
        </Button>
        <Button
          {...buttonStyles}
          onClick={() => onStepClick('plus', { duration: 7, unit: 'day' })}
          title="One week later from the start date"
        >
          +1 Wk
        </Button>
      </div>
    </div>
  );
};

export default FilterBox;
