import { UserSettingsEnum } from '../constants/UserSettingsEnum';

const now = new Date();
now.setHours(now.getHours() - 1);
now.setSeconds(0);
now.setMilliseconds(0);

const defaultValues = {
  interval: {
    timestamp: now.toJSON(),
    range: '4Hour',
  },
  me: {},
  preferences: {
    [UserSettingsEnum.SHOW_NOTIFICATION_ALERT]: 1,
    [UserSettingsEnum.SHOW_NOTIFICATION_PUSH]: true,
  },
};

export default defaultValues;
