import startsWith from 'lodash/startsWith';
import includes from 'lodash/includes';

export const getQueryParams = (qs) => {
  qs = qs.split('+').join(' ');

  const params = {};
  let tokens;
  const re = /[?&]?([^=]+)=([^&]*)/g;

  while ((tokens = re.exec(qs))) {
    params[decodeURIComponent(tokens[1])] = decodeURIComponent(tokens[2]);
  }

  return params;
};

export const startsWithAnyOf = (stringToSearch, needles) => {
  let result = false;
  needles.forEach((item) => {
    const resultForItem = startsWith(stringToSearch, item);
    if (resultForItem) {
      result = true;
    }
  });
  return result;
};

export const includesAnyOf = (stringToSearch, needles = []) => {
  let result = false;
  needles.forEach((item) => {
    if (includes(stringToSearch, item)) {
      result = true;
    }
  });
  return result;
};

export const randomString = (length, chars) => {
  let mask = '';
  if (chars.indexOf('a') > -1) {
    mask += 'abcdefghijklmnopqrstuvwxyz';
  }
  if (chars.indexOf('A') > -1) {
    mask += 'ABCDEFGHIJKLMNOPQRSTUVWXYZ';
  }
  if (chars.indexOf('#') > -1) {
    mask += '0123456789';
  }
  if (chars.indexOf('!') > -1) {
    mask += "~`!@#$%^&*()_+-={}[]:\";'<>?,./|\\";
  }
  let result = '';
  for (let i = length; i > 0; --i) {
    result += mask[Math.floor(Math.random() * mask.length)];
  }
  return result;
};

export const isEmptyValue = (value) => value === undefined || value === null || value === '';

export const toBool = (value) => {
  if (typeof value === 'undefined') {
    return false;
  }
  return (
    value === true
    || value.toLowerCase() === 'true'
    || value === '1'
    || value === 1
  );
};

/**
 * @param {string|number|Date|boolean|undefined} value Value
 * @param {number|Type} def Default value
 * @returns {number|Type} Value as number or undefined
 */
export const toNumber = (value = undefined, def = undefined) => {
  if (typeof value === 'boolean') {
    return value ? 1 : 0;
  }
  if (typeof value === 'undefined') {
    return def;
  }
  if (value instanceof Date) {
    return value.getTime();
  }
  if (typeof value === 'number' && isFinite(value)) {
    return value;
  }
  if (typeof value === 'string') {
    const num = Number(value);
    if (typeof num === 'number' && isFinite(num)) {
      return num;
    }
  }
  return def;
};

export const placeholderReplace = (title, data) => {
  if (typeof title !== 'string' || !title) {
    return title;
  }

  const pattern = /{%(.+?)%}/g;
  let result = title;
  [...title.toString().matchAll(pattern)].forEach((match) => {
    result = result.replace(match[0], data[match[1]] || '');
  });

  return result;
};
