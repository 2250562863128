import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Col, Row } from 'react-bootstrap';
import Tabs from 'react-bootstrap/Tabs';
import Tab from 'react-bootstrap/Tab';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import HtmlToReact from 'html-to-react';
import { DomUtils } from 'htmlparser2';
import { toJson } from 'really-relaxed-json';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import isEmpty from 'lodash/isEmpty';
import { saveFilterPreference, saveIntervalPreference } from '../../../redux/userSettings/actions';
import Filter from '../../molecules/Filter';
import Chart from '../../molecules/Chart';
import GridTable from '../../molecules/GridTable';
import ErrorBoundary from '../../atoms/ErrorBoundary';
import InfoModal from '../../atoms/InfoModal';
import { filterToUrl, urlToFilter } from '../../../utils/urlHelper';
import CardWrapper from '../../atoms/CardWrapper';
import StatusInfoRedux from '../../atoms/StatusInfo/StatusInfoRedux';
import { cleanPageState } from '../../../redux/page/actions';
import SparklineGroup from '../../atoms/SparklineGroup';
import Debug from './Debug';
import {
  filterUrlPrefix,
  instanceUrlPrefix,
  intervalUrlPrefix,
} from '../../../constants/variables';
import TuningNotes from '../CustomPages/TuningNotes';
import { useCompare } from '../../../hooks/useCompare';
import { ModalContext } from '../../molecules/GlobalModal/ModalContext';
import useModal from '../../molecules/GlobalModal/useModal';
import GlobalModal from '../../molecules/GlobalModal/GlobalModal';
import ShareButton from './ShareButton';
import BookmarksButton from './BookmarksButton';
import {
  useFiltersSelector, useInstanceSelector, useIntervalSelector,
} from '../../../redux/hooks';
import getEnvVal from '../../../constants/getEnvVal';
import { hasOneRole } from '../../../utils/auth';
import ErrorMessage from '../../atoms/ErrorMessage';
import Grid from '../../atoms/Grid';
import TileCard from '../../molecules/Widgets/TileCard';

const HtmlToReactParser = HtmlToReact.Parser;

const htmlToReactParser = new HtmlToReactParser({ xmlMode: true });

const sanitizeFilterControls = (controls) => {
  const newControls = [...controls];
  if (!newControls) {
    return [];
  }

  newControls.forEach((c, k) => {
    if (
      c.query
      && c.query.procedureParams
      && typeof c.query.procedureParams !== 'string'
    ) {
      newControls[k].query.procedureParams = '';
    }
    if (
      c.query
      && c.query.queryParams
      && typeof newControls[k].query.queryParams !== 'string'
    ) {
      newControls[k].query.queryParams = '';
    }
  });

  return newControls;
};

const findChildCodes = (children, rule) => {
  let ret = [];
  children.forEach((el) => {
    if (rule(el)) {
      ret.push(el.attribs.code);
    } else if (el.children) {
      ret = [...ret, ...findChildCodes(el.children, rule)];
    }
  });

  return ret;
};

const nextNode = (node) => {
  if (node.type === 'text' && /^\s+$/.test(node.data)) {
    return nextNode(node.next);
  }
  return node;
};

const prevNode = (node) => {
  if (node.type === 'text' && /^\s+$/.test(node.data)) {
    return prevNode(node.prev);
  }
  return node;
};

const camelize = (string) => string.replace(/-([a-z])/gi, (s, group) => group.toUpperCase());

const style2object = (style) => style
  .split(';')
  .filter((s) => s.length)
  .reduce((a, b) => {
    const keyValue = b.split(':');
    return { ...a, [camelize(keyValue[0])]: keyValue[1] };
  }, {});

const Page = (props) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { pageId } = props;
  const dispatch = useDispatch();

  const filter = urlToFilter(location.search, filterUrlPrefix, [
    intervalUrlPrefix,
    instanceUrlPrefix,
  ]);
  delete filter[''];

  const instanceScope = useInstanceSelector();
  const filtersLoading = useSelector((state) => state.userSettings.filtersLoading);
  const intervalLoading = useSelector((state) => state.userSettings.intervalLoading);
  const interval = useIntervalSelector();
  const currentTab = useMemo(() => (location.hash.startsWith('#tab-') ? location.hash.substr(5) : null), [location]);
  const allFilters = useFiltersSelector();
  // @var string[] currentRoles
  const currentRoles = useSelector((state) => state.userSettings.roles);

  const iterKey = (i, prefix = '') => `${pageId}_${prefix ? `${prefix}_` : ''}${i}`;
  const filters = !isEmpty(filter) ? filter : get(allFilters, pageId, {});
  const filterHasChanged = useCompare(filters);
  const intervalHasChanged = useCompare(interval);

  const modalContext = useModal();
  useEffect(() => {
    dispatch(cleanPageState());
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    dispatch(cleanPageState());
    if (filterHasChanged) {
      dispatch(saveFilterPreference(props.pageId, filters));
    }
    if (intervalHasChanged) {
      dispatch(saveIntervalPreference(interval));
    }
  }, [props.pageId]);

  // Save filter from URL to storage
  useEffect(() => {
    if (filterHasChanged) {
      dispatch(saveFilterPreference(props.pageId, filters));
    }
    if (intervalHasChanged) {
      dispatch(saveIntervalPreference(interval));
    }
  }, [interval, filters, filterHasChanged, intervalHasChanged]);

  if (
    filtersLoading
    || intervalLoading
    || !instanceScope
    || !instanceScope.id
  ) {
    return null;
  }

  let { content } = props;
  content = content.replace('<report>', '');
  content = content.replace('</report>', '');

  if (!content) {
    return null;
  }

  let ek = 1;

  let statusChildren = [];
  let globalStatusInfo = [];
  let hasMainActions = false;

  const renderMainActions = () => {
    if (hasMainActions) {
      return null;
    }
    hasMainActions = true;

    return (
      <>
        <ShareButton filter={filters} interval={interval} />
        <BookmarksButton page={pageId} filter={filters} interval={interval} />
      </>
    );
  };

  const processNodeDefinitions = new HtmlToReact.ProcessNodeDefinitions(React);
  const processingInstructions = [
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'h1';
      },
      processNode(node, children, index) {
        const next = nextNode(node.next);
        if (next.type === 'tag' && next.name === 'infobox') {
          return (
            <header key={index} className="content__title">
              <h1 style={{ flex: '0 1 auto', marginRight: '10px' }}>
                {children}
                {' '}
                <InfoModal>
                  <span
                    dangerouslySetInnerHTML={{
                      __html: DomUtils.getInnerHTML(next),
                    }}
                  />
                </InfoModal>
              </h1>
              <div className="actions">
                {renderMainActions()}
              </div>
            </header>
          );
        }
        return (
          <header key={index} className="content__title">
            <h1>
              {children}
            </h1>
            {renderMainActions()}
          </header>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return (
          node.type === 'tag'
          && node.name === 'a'
          && Object.prototype.hasOwnProperty.call(node.attribs, 'onclick')
          && node.attribs.onclick
        );
      },
      processNode(node, children) {
        return (
          <a
            role="link"
            tabIndex={0}
            {...omit(node.attribs, ['onclick'])}
            // eslint-disable-next-line no-eval
            onClick={() => eval(node.attribs.onclick)}
          >
            {children}
          </a>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        if (node.type === 'tag' && node.name === 'row') {
          statusChildren = findChildCodes(node.children, (sub) => (
            sub.type === 'tag'
              && (sub.name === 'chart' || sub.name === 'table')
          ));
          globalStatusInfo = [...globalStatusInfo, ...statusChildren];
        }
        return node.type === 'tag' && node.name === 'row';
      },
      processNode(node, children, index) {
        if (statusChildren) {
          return (
            <React.Fragment key={index}>
              <Row>
                <Col>
                  <StatusInfoRedux codes={statusChildren} />
                </Col>
              </Row>
              <Row key={index}>{children}</Row>
            </React.Fragment>
          );
        }
        return <Row key={index}>{children}</Row>;
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'col';
      },
      processNode(node, children, index) {
        const params = pick(node.attribs, ['sm', 'md', 'lg', 'xl']);
        if (node.attribs.style) {
          params.style = style2object(node.attribs.style);
          if (params.style.width && !params.style.maxWidth) {
            params.style.maxWidth = params.style.width;
            params.style.flexWidth = `0 0 ${params.style.width}px`;
          }
        }
        return <Col {...params} key={index} className="">{children}</Col>;
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'card';
      },
      processNode(node, children, index) {
        return <CardWrapper key={index}>{children}</CardWrapper>;
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'infobox';
      },
      processNode(node, children, index) {
        const prev = prevNode(node.prev);
        if (prev.type === 'tag' && prev.name === 'h1') {
          return '';
        }
        return (
          <InfoModal
            key={iterKey(index, 'info')}
            linkStyle={{ marginBottom: '16px' }}
          >
            {children}
          </InfoModal>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'filter_only';
      },
      processNode(node, children, index) {
        if (isEmpty(filters)) {
          return <></>;
        }
        return (
          <React.Fragment key={iterKey(index, 'table')} children={children} />
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'sparklines';
      },
      processNode(node, children, index) {
        let json = [];
        try {
          json = JSON.parse(toJson(children[0]));
        } catch (e) {
          console.error('Cannot parse sparkline data', children);
        }
        return (
          <ErrorBoundary key={iterKey(index, 'table')}>
            <SparklineGroup
              key={iterKey(index, 'sparkline')}
              instanceScope={instanceScope.id}
              title={node.attribs.title}
              sparklines={json}
              interval={interval}
              expandable={Object.prototype.hasOwnProperty.call(node.attribs, 'expanded')}
              expanded={node.attribs.expanded !== 'false'}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'table';
      },
      processNode(node, children, index) {
        const additional = {};
        if (node.attribs.style) {
          additional.style = node.attribs.style;
        }
        return (
          <ErrorBoundary key={iterKey(index, 'table')}>
            {globalStatusInfo.indexOf(node.attribs.code) === -1 ? (
              <StatusInfoRedux codes={[node.attribs.code]} />
            ) : null}
            <GridTable
              instanceScope={instanceScope.id}
              key={iterKey(index, 'table')}
              code={node.attribs.code}
              interval={interval}
              filter={filters}
              queryParams={node.attribs?.params || ''}
              cache={!(node.attribs.cache && node.attribs.cache === 'false')}
              expandable={Object.prototype.hasOwnProperty.call(node.attribs, 'expanded')}
              expanded={node.attribs.expanded !== 'false'}
              minRefresh={parseInt(node.attribs.minRefresh || getEnvVal('DEFAULT_MIN_REFRESH').toString(), 10)}
              {...additional}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'filter';
      },
      processNode(node, children, index) {
        const json = JSON.parse(children);
        return (
          <ErrorBoundary key={iterKey(index, 'filter')}>
            <CardWrapper>
              <Filter
                instanceScope={instanceScope.id}
                interval={interval}
                filter={filters}
                key={iterKey(index, 'filter')}
                name={json.name}
                manualSubmit={
                  json.manualSubmit === 'true' || json.manualSubmit === true
                }
                submitTitle={json.buttonName || undefined}
                clearButton={
                  json.clearButton === 'true' || json.clearButton === true
                }
                historySize={
                  json?.history?.size ? parseInt(json.history.size) : null
                }
                direction={json.direction || 'horizontal'}
                controls={sanitizeFilterControls(json.controls)}
                onChange={(filterValue) => {
                  dispatch(saveFilterPreference(props.pageId, filterValue));
                  const urlFilter = filterToUrl(filterValue, filterUrlPrefix);
                  navigate(
                    location.pathname
                      + (urlFilter ? `?${urlFilter.replace(/\+/g, '%20')}` : '')
                      + (location.hash ? location.hash : ''),
                  );
                }}
              />
            </CardWrapper>
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'chart';
      },
      processNode(node, children, index) {
        return (
          <ErrorBoundary key={iterKey(index, 'chart')}>
            {statusChildren.indexOf(node.attribs.code) === -1 ? (
              <StatusInfoRedux codes={[node.attribs.code]} />
            ) : (
              null
            )}
            <Chart
              instanceScope={instanceScope.id}
              interval={interval}
              filter={filters}
              queryParams={node.attribs?.params || ''}
              key={iterKey(index, 'chart')}
              cache={!(node.attribs.cache && node.attribs.cache === 'false')}
              {...omit(node.attribs, ['params', 'cache'])}
              expandable={Object.prototype.hasOwnProperty.call(node.attribs, 'expanded')}
              expanded={node.attribs.expanded !== 'false'}
              minRefresh={parseInt(node.attribs.minRefresh || getEnvVal('DEFAULT_MIN_REFRESH').toString(), 10)}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'TuningTasks';
      },
      processNode(node, children, index) {
        return (
          <ErrorBoundary key={iterKey(index, 'tuningTasks')}>
            <TuningNotes
              instanceScope={instanceScope.id}
              filter={filters}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'tabs';
      },
      processNode(node, children, index) {
        const tabsProps = {};
        if (currentTab) {
          tabsProps.activeKey = currentTab;
        }
        ek = 1;
        return (
          <CardWrapper key={iterKey(index, 'tab')}>
            <Tabs
              mountOnEnter
              unmountOnExit
              {...tabsProps}
              onSelect={(idx) => {
                const url = new URL(window.location);
                url.hash = `tab-${idx}`;
                navigate(url.href.replace(url.origin, ''));
              }}
            >
              {children}
            </Tabs>
          </CardWrapper>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'tab';
      },
      processNode(node, children, index) {
        const el = (
          <Tab
            eventKey={ek}
            title={node.attribs.title}
            key={iterKey(index, 'tab')}
          >
            <ErrorBoundary>{children}</ErrorBoundary>
          </Tab>
        );
        ek += 1;
        return el;
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'grid';
      },
      processNode(node, children, index) {
        return (
          <ErrorBoundary key={iterKey(index, 'grid')}>
            <Grid col={node.attribs.col}>
              {children}
            </Grid>
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'tilecard';
      },
      processNode(node, children, index) {
        return (
          <ErrorBoundary key={iterKey(index, 'tilecard')}>
            <TileCard
              instanceScope={instanceScope.id}
              code={node.attribs.code}
              titleColumn={node.attribs.titleColumn}
              valueColumn={node.attribs.valueColumn}
              linkColumn={node.attribs.linkColumn}
              interval={interval}
              filter={filters}
              queryParams={node.attribs?.params || ''}
              key={iterKey(index, 'tilecard')}
              cache={!(node.attribs.cache && node.attribs.cache === 'false')}
              minRefresh={parseInt(node.attribs.minRefresh || getEnvVal('DEFAULT_MIN_REFRESH').toString(), 10)}
            />
          </ErrorBoundary>
        );
      },
    },
    {
      replaceChildren: false,
      shouldProcessNode(node) {
        return node.type === 'tag' && node.name === 'if';
      },
      processNode(node, children) {
        const requiredRoles = JSON.parse((node.attribs.roles || '[]').replaceAll("'", '"'));
        const deniedRoles = JSON.parse((node.attribs['not-roles'] || '[]').replaceAll("'", '"'));
        if (requiredRoles.length > 0 && deniedRoles.length > 0) {
          return (
            <ErrorMessage>
              Cannot use bot &apos;roles&apos; and &apos;not-roles&apos; at the same time for &lt;if&gt;
            </ErrorMessage>
          );
        }

        if (requiredRoles.length > 0) {
          if (hasOneRole(requiredRoles, currentRoles)) {
            return children;
          }
          return null;
        }
        if (deniedRoles.length > 0) {
          if (hasOneRole(deniedRoles, currentRoles)) {
            return null;
          }
          return children;
        }

        return null;
      },
    },
    {
      // Anything else
      shouldProcessNode() {
        return true;
      },
      processNode: processNodeDefinitions.processDefaultNode,
    },
  ];

  return (
    <div style={{ position: 'relative' }}>
      <ModalContext.Provider value={modalContext}>
        <GlobalModal context={modalContext} />
        <Debug content={content} style={{ right: '50px' }} />
        {htmlToReactParser.parseWithInstructions(
          content,
          () => true,
          processingInstructions,
        )}
      </ModalContext.Provider>
    </div>
  );
};

Page.defaultProps = {
  content: '',
};

Page.propTypes = {
  pageId: PropTypes.number.isRequired,
  content: PropTypes.string,
};

export default Page;
