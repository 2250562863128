import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setDebugMode } from 'redux/userSettings/actions';
import { hasRole } from '../../../../utils/auth';
import DropdownItemWrapper from './DropdownItemWrapper';
import { RootState } from '../../../../redux/store';

type DebugModeSwitchProps = {
  className: string;
};

const DebugModeSwitch = ({
  className, // key, // , setDebugMode,
}: DebugModeSwitchProps): React.ReactElement | null => {
  const debug = useSelector<
    RootState,
    {[key: string]: string}
    >((state) => state.userSettings.debug);
  const dispatch = useDispatch();

  if (!hasRole('smt_debug')) {
    return null;
  }

  return (
    <DropdownItemWrapper className={className || ''} onClick={() => dispatch(setDebugMode(!debug))}>
      <span>Debug</span>
      <div className="float-end ml-3 toggle-switch" style={{ top: '5px' }}>
        <input
          type="checkbox"
          className="toggle-switch__checkbox"
          checked={!!debug}
          readOnly
        />
        <i className="toggle-switch__helper" />
      </div>
    </DropdownItemWrapper>
  );
};

export default DebugModeSwitch;
// connect(
//   () => ({
//   }),
//   { setDebugMode },
// )(DebugModeSwitch);
