import React from 'react';
import { curveCardinal } from '@visx/curve';
import {
  XYChart,
  Tooltip,
  LineSeries,
} from '@visx/xychart';
import { AxisScale } from '@visx/axis/lib/types';

type SparklineLineProps = {
  data: Array<number>;
  height: number;
  width: number;
  margin: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
};

type Point = {
  x: number;
  y: number;
};

const defaultMargin = {
  top: 0, bottom: 0, left: 0, right: 0,
};

const SparklineLine: React.FC<SparklineLineProps> = ({
  data, height, margin = defaultMargin, width,
}) => {
  const accessors = {
    xAccessor: (d: Point) => d.x,
    yAccessor: (d: Point) => d.y,
  };

  return (
    <XYChart
      height={height}
      width={width}
      xScale={{ type: 'band', paddingInner: 0.3 }}
      yScale={{ type: 'linear' }}
      margin={margin}
    >
      <LineSeries<AxisScale, AxisScale, Point>
        dataKey="Line 1"
        data={data.map((v, i) => ({ x: i, y: v }), {})}
        curve={curveCardinal}
        {...accessors}
      />
      <Tooltip<Point>
        snapTooltipToDatumX
        snapTooltipToDatumY
        showVerticalCrosshair
        showSeriesGlyphs
        renderTooltip={({ tooltipData }) => (
          <div>
            {accessors.yAccessor(tooltipData?.nearestDatum?.datum as Point)}
          </div>
        )}
      />
    </XYChart>
  );
};

export default SparklineLine;
