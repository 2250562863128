const baseType = 'USER_SETTINGS';

const types = {
  SAVE_INSTANCE_SCOPE: `${baseType}/SAVE_INSTANCE_SCOPE`,
  LOAD_INSTANCE_SCOPE: `${baseType}/LOAD_INSTANCE_SCOPE`,
  LOAD_INSTANCES: `${baseType}/LOAD_INSTANCES`,
  GET_INSTANCES: `${baseType}/GET_INSTANCES`,
  GET_INSTANCES_SUCCESS: `${baseType}/GET_INSTANCES_SUCCESS`,
  GET_INSTANCES_ERROR: `${baseType}/GET_INSTANCES_ERROR`,
  GET_PAGES: `${baseType}/GET_PAGES`,
  GET_PAGES_SUCCESS: `${baseType}/GET_PAGES_SUCCESS`,
  GET_PAGES_ERROR: `${baseType}/GET_PAGES_ERROR`,
  GET_LIVE_DATA_PREFERENCE: `${baseType}/GET_LIVE_DATA_PREFERENCE`,
  SAVE_LIVE_DATA_PREFERENCE: `${baseType}/SAVE_LIVE_DATA_PREFERENCE`,

  LOAD_FILTER_PREFERENCE: `${baseType}/LOAD_FILTER_PREFERENCE`,
  LOAD_FILTER_SUCCESS: `${baseType}/LOAD_FILTER_SUCCESS`,
  LOAD_FILTER_ERROR: `${baseType}/LOAD_FILTER_ERROR`,
  SAVE_FILTER_PREFERENCE: `${baseType}/SAVE_FILTER_PREFERENCE`,
  SET_FILTER: `${baseType}/SET_FILTER`,
  UNSET_FILTER: `${baseType}/UNSET_FILTER`,
  RESET_FILTER: `${baseType}/RESET_FILTER`,

  LOAD_INTERVALS: `${baseType}/LOAD_INTERVALS`,
  LOAD_INTERVALS_SUCCESS: `${baseType}/LOAD_INTERVALS_SUCCESS`,

  LOAD_INTERVAL_PREFERENCE: `${baseType}/LOAD_INTERVAL_PREFERENCE`,
  LOAD_INTERVAL_SUCCESS: `${baseType}/LOAD_INTERVAL_SUCCESS`,
  LOAD_INTERVAL_ERROR: `${baseType}/LOAD_INTERVAL_ERROR`,
  SAVE_INTERVAL_PREFERENCE: `${baseType}/SAVE_INTERVAL_PREFERENCE`,

  GET_USER: `${baseType}/LOAD_USER`,
  GET_USER_SUCCESS: `${baseType}/GET_USER_SUCCESS`,
  GET_USER_ERROR: `${baseType}/GET_USER_ERROR`,
  SET_USER_SETTING: `${baseType}/SET_USER_SETTING`,

  SET_THEME_PREFERENCE: `${baseType}/SET_THEME_PREFERENCE`,

  SET_DEBUG: `${baseType}/SET_DEBUG`,
};

export default types;
