import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { ApolloError, useMutation, useQuery } from '@apollo/client';
import { useLocation, useNavigate } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import {
  createTuningNoteGql,
  getTuningNoteGql,
  updateTuningNoteGql,
} from '../../../../queries/tuningNotes';
import ErrorBoundary from '../../../atoms/ErrorBoundary';
import Loading from '../../../molecules/Loader/Loading';
import CardWrapper from '../../../atoms/CardWrapper';
import { TuningTaskMap } from '../../../../types/TuningTask';
import TuningNotesTable from './TuningNotesTable';
import TuningTaskForm from '../../TuningTaskForm';
import { addUrlParams, removeUrlParams } from '../../../../utils/urlHelper';
import ErrorMessage from '../../../atoms/ErrorMessage';
import { RootState } from 'redux/store';
import omit from 'lodash/omit';

type StateProps = {
  instanceScope: {id: number; name: string};
};

type Props = {
  // add: boolean;
  filter: {[key: string]: string};
  // noteId: number | null;
};

const tryParseInt = (value: string | null): number | null => {
  if (!value) {
    return null;
  }

  const result = parseInt(value, 10);
  return result || null;
};

const TuningNotes: React.FC<Props & StateProps> = ({ filter: originalFilter }) => {
  const filter = omit(originalFilter, ['add', 'noteId']);
  const navigate = useNavigate();
  const location = useLocation();
  const [noteId, setNoteId] = useState<number | null>(
    tryParseInt(new URLSearchParams(location.search).get('noteId')),
  );
  const [add, setAdd] = useState<boolean>(
    new URLSearchParams(location.search).get('add') === '1',
  );

  useEffect(() => {
    const newId = tryParseInt(
      new URLSearchParams(location.search).get('noteId'),
    );
    if (noteId !== newId) {
      setNoteId(newId);
    }

    const newAdd = new URLSearchParams(location.search).get('add') === '1';
    if (add !== newAdd) {
      setAdd(newAdd);
    }
  }, [location]);

  const [formError, setFormError] = useState<Array<any> | null>();
  const { data, loading } = useQuery(getTuningNoteGql, {
    variables: {
      noteId,
    },
    skip: !noteId,
  });

  const mutationSuccess = () => {
    setFormError(null);
    navigate({
      pathname: location.pathname,
      search: removeUrlParams(location.search, ['add', 'noteId']),
    }, {
      state: { redirect: true },
    });
  };

  const [createTuningNote, { error: createError }] = useMutation(
    createTuningNoteGql,
    {
      onCompleted: (apiData) => {
        if (!apiData || apiData.createTuningNote.errors) {
          return;
        }
        if (apiData.createTuningNote.errors) {
          setFormError(apiData.createTuningNote.errors);
          return;
        }
        mutationSuccess();
      },
      onError: (error: ApolloError) => {
        // @ts-ignore
        setFormError(error.graphQLErrors);
      },
    },
  );
  const [updateTuningNote, { error: updateError }] = useMutation(
    updateTuningNoteGql,
    {
      onCompleted: (apiData) => {
        if (!apiData) {
          return;
        }
        if (apiData.updateTuningNote.errors) {
          setFormError(apiData.updateTuningNote.errors);
          return;
        }
        mutationSuccess();
      },
      onError: (error: ApolloError) => {
        // @ts-ignore
        setFormError(error.graphQLErrors);
      },
    },
  );

  useEffect(() => {
    if (createError && createError.graphQLErrors) {
      setFormError(createError.graphQLErrors as Array<any>);
      window.scrollTo(0, 0);
    } else if (updateError && updateError.graphQLErrors) {
      setFormError(updateError.graphQLErrors as Array<any>);
      window.scrollTo(0, 0);
    }
  }, [createError, updateError]);

  let content: JSX.Element;
  if (noteId || add) {
    if (loading) {
      content = <Loading />;
    } else {
      const tuningTask = add
        ? {}
        : TuningTaskMap.fromRaw(data.getTuningNoteDetail.data.tuning);
      content = (
        <>
          <Button variant="primary" onClick={mutationSuccess}>
            Back
          </Button>
          {data?.getTuningNoteDetail?.errors
          && data?.getTuningNoteDetail?.errors?.length > 0 ? (
            <ErrorMessage>
              {data.getTuningNoteDetail.errors.map((e, i) => (
                <div key={i}>{e.message}</div>
              ))}
            </ErrorMessage>
            ) : (
              <TuningTaskForm
                tuningTask={tuningTask}
                apiErrors={formError || []}
                onSubmit={(values) => {
                  if (noteId) {
                    return updateTuningNote({
                      variables: {
                        noteId,
                        parameters: TuningTaskMap.toPersistence(
                          TuningTaskMap.fromRaw(values),
                        ),
                      },
                    });
                  }
                  return createTuningNote({
                    variables: {
                      parameters: TuningTaskMap.toPersistence(
                        TuningTaskMap.fromRaw(values),
                      ),
                    },
                  });
                }}
              />
            )}
        </>
      );
    }
  } else {
    content = (
      <>
        <button
          onClick={() => navigate({
            ...location,
            search: addUrlParams(location.search, { add: '1' }),
          })}
          className="btn btn-primary mb-2"
        >
          <i className="fa fa-plus" />
          {' '}
          Add
        </button>
        <TuningNotesTable
          filter={filter}
          onSelected={(id) => {
            navigate({
              ...location,
              search: addUrlParams(location.search, { noteId: id }),
            });
          }}
        />
      </>
    );
  }

  return (
    <ErrorBoundary>
      <CardWrapper>{content}</CardWrapper>
    </ErrorBoundary>
  );
};

const mapStateToProps = (state: RootState) => ({
  instanceScope: state.userSettings.instanceScope,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(TuningNotes);
