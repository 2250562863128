import { useEffect } from 'react';
import { useFormikContext, FormikContextType } from 'formik';
import { useCompare } from '../../../hooks/useCompare';

export interface FormikEffectProps<T> {
  onChange: (context: FormikContextType<T>) => void;
}

export const FormikEffect = <T extends unknown>({
  onChange,
}: FormikEffectProps<T>) => {
  const context = useFormikContext<T>();

  const changed = useCompare(context.values);

  useEffect(() => {
    if (!context.dirty || !changed) {
      return;
    }
    onChange(context);
  }, [context.values, onChange]);

  return null;
};
