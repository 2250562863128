import React, { useState } from 'react';
import { useAsyncDebounce } from 'react-table';

export const filterTypes = {
  text: (rows, id, filterValue) => rows.filter((row) => {
    const rowValue = row.values[id];
    return rowValue !== undefined
      ? String(rowValue)
        .toLowerCase()
        .startsWith(String(filterValue).toLowerCase())
      : true;
  }),
};

export const ColumnFilter = ({ column: { filterValue, setFilter, filter } }) => {
  const [value, setValue] = useState(filterValue || '');
  const onChange = useAsyncDebounce((v) => {
    setFilter(v || undefined);
  }, 500);

  return (
    <input
      className="form-control form-control-sm"
      value={value}
      onChange={(e) => {
        setValue(e.target.value);
        onChange(e.target.value);
      }}
      placeholder={`Search ${filter || ''}...`}
    />
  );
};
