import * as Sentry from '@sentry/react';
import { call, put, select } from 'redux-saga/effects';
import { push } from 'connected-react-router';
import get from 'lodash/get';
import omit from 'lodash/omit';
import pick from 'lodash/pick';
import {
  getInstancesError,
  getInstancesSuccess,
  saveInstanceScope,
  getPages,
  getPagesSuccess,
  getPagesError,
  saveLiveDataPreference,
  loadFilterSuccess,
  loadIntervalSuccess,
  getUserSuccess,
  getUserError,
  getUser,
  saveIntervalPreference,
  getIntervalsSuccess,
} from './actions';
import types from './action-types';
import client from '../../apolloClient';
import getInstances from '../../queries/getInstances';
import { loadMenuItems } from '../../queries/backoffice';
import { instanceUrlPrefix, intervalUrlPrefix } from '../../constants/variables';
import { setCookie } from '../../utils/auth';
import { genReportUrlPrefix } from '../../constants/common';
import { IntervalAction, Interval } from './types';
import {
  addUrlParams, filterToUrl, getPersistentParameters, getUrlParam,
} from '../../utils/urlHelper';
import { ServerInstance } from '../../types/ServerInstance';
import { getUserGql, updateUserSettingGql } from '../../queries/user';
import { FilterType } from '../../components/molecules/Filter';
import { RootState } from '../store';
import { UserPreferences, UserSettingsEnum } from '../../constants/UserSettingsEnum';
import { defaultUserSettings } from './defaults';
import tryParseJSON from '../../utils/tryParseJSON';
import getIntervals, { GetIntervalsResponseProps } from '../../queries/getIntervals';

export function* getPagesEffect() {
  try {
    // When pages will be different for each instance
    const instance = yield select((state: RootState) => state.userSettings.instanceScope?.id);
    if (!instance) {
      console.debug('No instance selected');
      return;
    }

    const result = yield client.query({
      query: loadMenuItems,
      fetchPolicy: 'cache-first',
      variables: {
        instanceId: instance,
      },
    });
    const pages = get(result, 'data.getPages.data.pages');

    yield put(
      getPagesSuccess({
        pages: pages.map((item) => ({
          ...item,
          route: `${genReportUrlPrefix}/${item.id}`,
        })),
        roles: get(result, 'data.getUserPermissions.data.roles'),
      }),
    );
  } catch (e) {
    console.error(e);
    yield put(getPagesError());
  }
}

const findInstance = (instances: ServerInstance[], id: number) => instances
  .find((i) => i.id === id);

export function* getInstancesEffect() {
  try {
    const result = yield client.query({
      query: getInstances,
      fetchPolicy: 'cache-first',
    });
    const instances = (get(result, 'data.getInstances.data.instances') as ServerInstance[]).map((i) => ({
      ...i,
      id: parseInt(i.id.toString(), 10),
    }));
    yield put(
      getInstancesSuccess(instances),
    );

    let currentInstance: ServerInstance | undefined;
    const urlParams = getPersistentParameters();
    if (urlParams.instance) {
      currentInstance = findInstance(instances, parseInt(urlParams.instance?.toString(), 10));
    } else if (localStorage.getItem('instance')) {
      currentInstance = (JSON.parse(localStorage.getItem('instance') as string) as ServerInstance);
    }

    if (!currentInstance) {
      currentInstance = instances[0];
    }

    yield put({
      type: types.LOAD_INSTANCE_SCOPE,
      payload: currentInstance,
    });
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
    yield put(getInstancesError());
  }
}

export function* saveInstanceScopePreference(action: { payload: ServerInstance }) {
  try {
    yield localStorage.setItem('instance', JSON.stringify(action.payload));
    yield put(
      push({
        ...window.location,
        search: addUrlParams(window.location.search, {
          [`${instanceUrlPrefix}instance`]: action.payload.id.toString(),
        }),
      }),
    );
    yield call(getPagesEffect);
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
}

export function* loadInstanceScopePreference(action: { payload: ServerInstance }) {
  try {
    let instance: ServerInstance | undefined;
    const instances = (yield select((state: RootState) => state.userSettings.instances)) as ServerInstance[];

    const instanceUrl = getUrlParam(
      window.location.search,
      `${instanceUrlPrefix}instance`,
    );
    if (instanceUrl && parseInt(instanceUrl, 10) > 0) {
      instance = instances.find((i) => i.id === parseInt(instanceUrl, 10));
    } else {
      const instanceStorage = localStorage.getItem('instance');
      try {
        instance = instanceStorage
          ? instances.find((i) => i.id === parseInt((JSON
            .parse(instanceStorage))?.id.toString(), 10)) as ServerInstance
          : undefined;
      } catch (e) {
        // Ignore exception
      }
    }

    if (!instance || !instance.id || !instance.name || !instance.groupName) {
      instance = action.payload
        ? {
          id: action.payload.id,
          name: action.payload.name,
          groupName: action.payload.groupName,
        }
        : { name: '', id: 0, groupName: '' };
    }

    yield put(saveInstanceScope(instance));
    yield put(
      push({
        ...window.location,
        search: addUrlParams(window.location.search, {
          [`${instanceUrlPrefix}instance`]: instance.id.toString(),
        }),
      }),
    );
    yield put(getPages());
  } catch (e) {
    console.error(e);
    Sentry.captureException(e);
  }
}

export function* saveLiveDataPreferenceEffect(action) {
  try {
    yield localStorage.setItem('pollInterval', action.payload);
  } catch (e) {
    console.error(e);
  }
}

export function* getLiveDataPreferenceEffect(action) {
  try {
    const pollingInterval = localStorage.getItem('pollInterval');
    if (pollingInterval !== null) {
      yield put(saveLiveDataPreference(pollingInterval));
    } else {
      yield put(saveLiveDataPreference(!!action.payload));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* saveFilterPreferenceEffect(
  action: { payload: { page: string; filter: FilterType; silent: boolean } },
) {
  try {
    const currFilters = (yield select((state) => state.userSettings.filters)) as FilterType;
    const pageFilter: FilterType = {
      ...(get(currFilters, action.payload.page, {}) as FilterType),
      ...action.payload.filter,
    };
    // @TODO move to shared place
    delete pageFilter[`${instanceUrlPrefix}-instance`];
    delete pageFilter[`${intervalUrlPrefix}-timestamp`];
    delete pageFilter[`${intervalUrlPrefix}-range`];

    const filters = {
      ...currFilters,
      ...{
        [action.payload.page]: pageFilter,
      },
    } as FilterType;

    localStorage.setItem('filters', JSON.stringify(filters));
    if (!action.payload.silent) {
      yield put(loadFilterSuccess(filters));
    }
  } catch (e) {
    console.error(e);
  }
}

export function* loadFilterPreferenceEffect() {
  try {
    let filters = localStorage.getItem('filters');
    if (filters) {
      filters = JSON.parse(filters);
    }

    yield put(loadFilterSuccess(filters));
  } catch (e) {
    console.error(e);
  }
}

export function* saveIntervalPreferenceEffect(action: IntervalAction) {
  try {
    if (typeof action.payload !== 'object') {
      console.trace('wrong interval');
      return;
    }
    const payload: Interval = {};
    if (action.payload.timestamp === 'now' || (action.payload.timestamp && Date.parse(action.payload.timestamp) > 0)) {
      payload.timestamp = action.payload.timestamp;
    }
    if (action.payload.range) {
      payload.range = action.payload.range;
    }
    if (payload && Object.keys(payload).length > 0) {
      localStorage.setItem('interval', JSON.stringify(payload));
    } else {
      localStorage.removeItem('interval');
    }

    // Save filter to URL
    const urlFilter = filterToUrl(payload, intervalUrlPrefix);
    window.history.pushState(
      {},
      '',
      window.location.pathname
      + (urlFilter ? `?${urlFilter}` : '')
      + (window.location.hash ? window.location.hash : ''),
    );

    yield put(loadIntervalSuccess(payload));
  } catch (e) {
    console.error(e);
  }
}

export function* loadIntervalsEffect() {
  try {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    const result = yield client.query<GetIntervalsResponseProps>({
      query: getIntervals,
      fetchPolicy: 'cache-first',
    });

    if (!result) {
      throw new Error('No result for intervals returned');
    }

    const intervals = ((result as { data: GetIntervalsResponseProps }).data.getIntervals.data.intervals).map((i) => ({
      display: i.display,
      name: i.name,
      duration: i.length,
      unit: i.unit,
    }));
    yield put(
      getIntervalsSuccess(intervals),
    );
  } catch (e) {
    console.error(e);
  }
}

export function* loadIntervalPreferenceEffect() {
  try {
    const intervalSetting = localStorage.getItem('interval');
    let interval: Interval | null = null;
    if (intervalSetting) {
      interval = JSON.parse(intervalSetting) as Interval;
    }

    const currentUrlParams = new URLSearchParams(window.location.search);
    if (
      currentUrlParams.get(`${intervalUrlPrefix}timestamp`)
      || currentUrlParams.get(`${intervalUrlPrefix}range`)
    ) {
      interval = interval || {};
      if (currentUrlParams.get(`${intervalUrlPrefix}timestamp`)) {
        if (currentUrlParams.get(`${intervalUrlPrefix}timestamp`) === 'now') {
          interval.timestamp = 'now';
        } else {
          const t = Date.parse(
            currentUrlParams.get(`${intervalUrlPrefix}timestamp`) as string,
          );
          if (t > 0) {
            interval.timestamp = new Date(t).toJSON();
          }
        }
      }
      if (currentUrlParams.get(`${intervalUrlPrefix}range`)) {
        interval.range = currentUrlParams.get(
          `${intervalUrlPrefix}range`,
        ) as string;
      }
    } else {
      yield put(saveIntervalPreference(interval || { timestamp: 'now', range: '4Hour' } as Interval));
    }

    yield put(loadIntervalSuccess(interval));
  } catch (e) {
    console.error(e);
  }
}

export function* setThemePreferenceEffect(action) {
  try {
    yield setCookie('theme', action.payload);
  } catch (e) {
    console.error(e);
  }
}

export function* setDebugModeEffect(action) {
  try {
    yield localStorage.setItem('debug', action.payload);
  } catch (e) {
    console.error(e);
  }
}

export function* getUserEffect() {
  try {
    const result = yield client.query({
      query: getUserGql,
      fetchPolicy: 'no-cache',
    });
    if (result.data.me.data) {
      const preferences: UserPreferences = defaultUserSettings;
      result.data.me.data.settings.forEach(
        (v) => {
          if (v.code === UserSettingsEnum.SHOW_NOTIFICATION_ALERT) {
            // Backward compatibility
            if (!isNaN(v.value)) {
              if (v.value.toString() === '0') {
                preferences[v.code].enabled = false;
              } else if (v.value.toString() === '1') {
                preferences[v.code].ignore = [0];
              }
            } else {
              preferences[v.code] = {
                ...preferences[v.code],
                ...pick(tryParseJSON(v.value, preferences[v.code]), ['enabled', 'ignore']),
              };
            }
          } else {
            preferences[v.code] = v.value;
          }
        },
      );
      yield put(
        getUserSuccess({
          me: omit(result.data.me.data, ['settings']),
          preferences,
        }),
      );
    } else {
      yield put(getUserError());
    }
  } catch (e) {
    yield put(getUserError());
  }
}

export function* setUserSettingEffect(action: any) {
  const { code, value } = action.payload;
  try {
    yield client.mutate({
      mutation: updateUserSettingGql,
      variables: { code, value },
    });
    yield put(getUser());
  } catch (e) {
    console.error(e);
  }
}
