import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useLazyQuery } from '@apollo/client';
import { getDownload } from 'queries/download';

enum DownloadState {
  Inactive,
  Downloading,
  Downloaded,
  Error,
}

const DownloadLink = ({
  instanceScope, primaryId, secondaryId, title, type,
}) => {
  const [download, setDownload] = useState(DownloadState.Inactive);

  // fetchPolicy as workaround for https://github.com/apollographql/react-apollo/issues/3709
  const [fetch] = useLazyQuery(getDownload, {
    variables: {
      instanceId: instanceScope,
      primaryId,
      secondaryId,
      type,
    },
    onCompleted: (data) => {
      setDownload(DownloadState.Downloaded);
      window.location = data.download.data.link;
      setTimeout(() => setDownload(DownloadState.Inactive), 1000);
    },
    onError: (error) => {
      setDownload(DownloadState.Error);
    },
    fetchPolicy: 'no-cache',
  });

  if (download == DownloadState.Downloading) {
    return <>Downloading...</>;
  }
  if (download == DownloadState.Downloaded) {
    return <>Downloaded</>;
  }
  if (download == DownloadState.Error) {
    return <>Error</>;
  }

  return (
    <a
      href="#"
      onClick={async (e) => {
        e.preventDefault();
        setDownload(DownloadState.Downloading);
        fetch();
      }}
    >
      {title}
    </a>
  );
};

DownloadLink.defaultProps = {};

DownloadLink.propTypes = {
  title: PropTypes.string.isRequired,
  instanceScope: PropTypes.number.isRequired,
  primaryId: PropTypes.string.isRequired,
  secondaryId: PropTypes.string,
  type: PropTypes.string,
};

export default DownloadLink;
