import { useRef, useEffect } from 'react';

/**
 * A hook to repeatedly execute a `callback` after `intervalInMs` passed
 */
const useInterval = (callback: (...args) => void, intervalInMs: number): void => {
  const savedCallback = useRef<(...args) => void>();

  useEffect(() => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
    savedCallback.current = callback;
  }, [callback]);

  // eslint-disable-next-line consistent-return
  useEffect(() => {
    if (intervalInMs) {
      const handler = (...args) => {
        if (savedCallback && savedCallback.current) {
          // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
          savedCallback.current(...args);
        }
      };
      const id = setInterval(handler, intervalInMs);

      return () => {
        if (id) {
          clearInterval(id);
        }
      };
    }
  }, [callback, intervalInMs]);
};

export default useInterval;
