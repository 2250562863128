import { useEffect, useRef } from 'react';
import isEqual from 'lodash/isEqual';

export const useCompare = (value) => {
  const prevValue = usePrevious(value);
  return !isEqual(prevValue, value);
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};
