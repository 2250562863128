import React, { useMemo } from 'react';
import { usePagesSelector } from '../../redux/hooks';
import { PageDefinition } from '../../redux/userSettings/types';
import { ControlledStep, GuideProps } from '.';
import { expandNavigation } from '../../utils/domHelper';
import ControlledJoyride from './ControlledJoyride';
import { scrollNavigationTo } from './helpers';
import { Placement } from 'react-joyride';

const getTooltips = (pages: PageDefinition[], parent: number | undefined = undefined): PageDefinition[] => pages
  .filter((page) => ((!parent && !page.parent) || (parent && page.parent === parent)))
  .sort((a, b) => (b.order || 0) - (a.order || 0));

const CategoryGuide = ({ onFinished }: GuideProps) => {
  const pages = usePagesSelector();

  const steps = useMemo(() => {
    const sorted = getTooltips(pages);
    const tooltips: ControlledStep[] = [
      {
        disableBeacon: true,
        placement: 'right' as Placement,
        target: 'aside.sidebar',
        title: 'Navigation',
        content: (
          <p>
            This is a walkthrough of items in the left menu. If you skip it now, you can always start over or see
            the same information as a description while hovering your cursor over an individual item.
          </p>
        ),
      },
    ];

    sorted
      .forEach((page) => {
        if (page.tooltip) {
          tooltips.push({
            disableBeacon: true,
            placement: 'right',
            target: `.navigation [data-id="${page.id}"]`,
            title: page.title,
            content: page.tooltip,
            beforeActive: () => new Promise<void>((resolve) => {
              expandNavigation(page.id)
                .then(() => {
                  scrollNavigationTo(page.id);
                  resolve();
                })
                .catch(resolve);
            }),
          });
        }

        getTooltips(pages, page.id).forEach((subPage) => {
          if (subPage.tooltip) {
            tooltips.push({
              disableBeacon: true,
              placement: 'right',
              target: `.navigation [data-id="${subPage.id}"]`,
              title: subPage.title,
              content: subPage.tooltip,
              beforeActive: () => new Promise<void>((resolve) => {
                expandNavigation(page.id)
                  .then(() => {
                    scrollNavigationTo(subPage.id);
                    expandNavigation(subPage.id)
                      .then(resolve)
                      .catch(resolve);
                  })
                  .catch(resolve);
              }),
            });
          }
        });
      });

    return tooltips;
  }, [pages]);

  return (
    <ControlledJoyride
      steps={steps}
      onFinished={onFinished}
    />
  );
};

export default CategoryGuide;
