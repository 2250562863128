import omit from 'lodash/omit';
import actionTypes from './action-types';
import defaultValues from '../defaultValues';
import { getCookie } from '../../utils/auth';
import { getUrlParam } from '../../utils/urlHelper';
import { instanceUrlPrefix } from '../../constants/variables';

const initialState = {
  pages: [],
  gettingPages: true,
  pagesError: false,
  instanceScope: getUrlParam(
    window.location.search,
    `${instanceUrlPrefix}instance`,
  )
    ? {
      id: parseInt(
        getUrlParam(window.location.search, `${instanceUrlPrefix}instance`),
      ),
    }
    : localStorage.getItem('instance')
      ? JSON.parse(localStorage.getItem('instance'))
      : {},
  instances: [],
  gettingInstances: true,
  instancesError: false,
  isLive: !!localStorage.getItem('pollInterval'),
  intervals: [],
  intervalsLoading: true,
  interval: defaultValues.interval,
  intervalLoading: false,
  filters: localStorage.getItem('filters')
    ? JSON.parse(localStorage.getItem('filters'))
    : {},
  filtersLoading: false,
  pollInterval: parseInt(localStorage.getItem('pollInterval')) || null,
  theme: getCookie('theme') > 0 ? getCookie('theme') : '4',
  debug: localStorage.getItem('debug') === 'true',
  me: defaultValues.me,
  preferences: defaultValues.preferences,
  gettingMe: true,
  roles: [],
};

const mergeInterval = (state, payload) => {
  const newState = { ...state };
  if (payload.timestamp === 'now' || Date.parse(payload.timestamp) > 0) {
    newState.timestamp = payload.timestamp;
  }
  if (payload.range) {
    newState.range = payload.range;
  }

  return newState;
};

const reducer = (state = initialState, action = {}) => {
  switch (action.type) {
    case actionTypes.GET_PAGES:
      return {
        ...state,
        gettingPages: true,
      };
    case actionTypes.GET_PAGES_SUCCESS:
      if (
        (action.payload === state.pages && !action.payload)
        || action.payload.pages.length === 0
      ) {
        return {
          ...state,
          gettingPages: false,
        };
      }
      return {
        ...state,
        pages: action.payload.pages,
        roles: action.payload.roles,
        gettingPages: false,
        pagesError: false,
      };
    case actionTypes.GET_PAGES_ERROR:
      return {
        ...state,
        gettingPages: false,
        pagesError: true,
      };
    case actionTypes.SAVE_INSTANCE_SCOPE:
      if (state.instanceScope?.id === action.payload.id) {
        return state;
      }
      return {
        ...state,
        instanceScope: action.payload,
      };
    case actionTypes.GET_INSTANCES:
      return {
        ...state,
        gettingInstances: true,
      };
    case actionTypes.GET_INSTANCES_SUCCESS:
      const instance = state.instanceScope.id
        ? action.payload.find((i) => i.id === state.instanceScope.id)
        : action.payload.length
          ? action.payload[0]
          : { id: '', name: '' };

      return {
        ...state,
        instances: action.payload,
        gettingInstances: false,
        instancesError: false,
        instanceScope: instance,
      };
    case actionTypes.GET_INSTANCES_ERROR:
      return {
        ...state,
        gettingInstances: false,
        instancesError: true,
      };
    case actionTypes.SAVE_LIVE_DATA_PREFERENCE:
      if (typeof action.payload === 'number') {
        if (action.payload < 2000) {
          console.error('Polling interval is to small');
          return { ...state };
        }
        return {
          ...state,
          isLive: true,
          pollInterval: action.payload,
        };
      }

      return {
        ...state,
        isLive: false,
        pollInterval: null,
      };

    case actionTypes.LOAD_FILTER_SUCCESS:
      return {
        ...state,
        filters: action.payload || {},
        filtersLoading: false,
      };
    case actionTypes.LOAD_FILTER_ERROR:
    case actionTypes.RESET_FILTER:
      return {
        ...state,
        filters: {},
        filtersLoading: false,
      };
    case actionTypes.SET_FILTER:
      return {
        ...state,
        filters: {
          ...state.filters,
          [action.payload.key]: action.payload.value,
        },
        filtersLoading: false,
      };
    case actionTypes.UNSET_FILTER:
      return {
        ...state,
        filters: omit({ ...state.filters }, action.payload.key),
      };

    case actionTypes.LOAD_INTERVALS_SUCCESS:
      return {
        ...state,
        intervals: action.payload,
        intervalsLoading: false,
      };

    case actionTypes.LOAD_INTERVAL_SUCCESS:
      if (action.payload === null) {
        return {
          ...state,
          intervalLoading: false,
        };
      }
      return {
        ...state,
        interval: action.payload
          ? mergeInterval(state.interval, action.payload)
          : defaultValues.interval,
        intervalLoading: false,
      };
    case actionTypes.LOAD_INTERVAL_ERROR:
    case actionTypes.SAVE_INTERVAL_PREFERENCE:
      return {
        ...state,
        interval: mergeInterval(state.interval, action.payload),
        intervalLoading: false,
      };

    case actionTypes.SET_THEME_PREFERENCE:
      return {
        ...state,
        theme: action.payload,
      };

    case actionTypes.SET_DEBUG:
      return {
        ...state,
        debug: action.payload,
      };

    case actionTypes.GET_USER_SUCCESS:
      return {
        ...state,
        me: action.payload.me,
        preferences: {
          ...defaultValues.preferences,
          ...action.payload.preferences,
        },
        gettingMe: false,
      };

    default:
      return state;
  }
};

export default reducer;
