import types from './action-types';
import { State as ErrorState } from './reducer';

export const addError = (errorObj: ErrorState['errors']) => ({
  type: types.ADD_ERROR,
  payload: errorObj,
});

export const removeError = (index: number) => ({
  type: types.REMOVE_ERROR,
  payload: index,
});

export const removeAllErrors = () => ({
  type: types.REMOVE_ALL_ERROR,
});
