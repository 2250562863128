import styled from 'styled-components';
import { getRem } from 'utils/css';

const DropdownItemWrapper = styled.div`
  flex: 0 0 auto;
  padding-right: ${getRem(20)};
  position: relative;
  cursor: pointer;
  word-break: keep-all;
`;

export default DropdownItemWrapper;
