import React, { useState } from 'react';
import { connect } from 'react-redux';
import get from 'lodash/get';

const DebugText = ({ children, debug }) => {
  if (!debug) {
    return <></>;
  }

  return <div>{children}</div>;
};

DebugText.defaultProps = {};

DebugText.propTypes = {};

export default connect(
  (state) => ({
    debug: get(state, 'userSettings.debug'),
  }),
  {},
)(DebugText);
