import gql from 'graphql-tag';

export const getGraphDefinition = gql`
  query getGraphDefinition(
    $instanceId: Int!
    $code: String!
    $interval: DateInterval
    $filter: [FilterItem]
  ) {
    getGraphDefinition(
      instanceId: $instanceId
      code: $code
      interval: $interval
      filter: $filter
    ) {
      data {
        version
        title
        description
        class
        definition
        width
        height
      }
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;

export const getGraphData = gql`
  query getGraphData(
    $instanceId: Int!
    $code: String!
    $interval: DateInterval
    $filter: [FilterItem]
    $cache: Boolean! = true
  ) {
    getGraphData(
      instanceId: $instanceId
      code: $code
      interval: $interval
      filter: $filter
      fromCache: $cache
    ) {
      data {
        data
      }
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;


export const getTableDefinition = gql`
  query getTableDefinition($instanceId: Int!, $code: String!) {
    getTableDefinition(instanceId: $instanceId, code: $code) {
      data {
        title
        description
        width
        height
        sticky
        config {
          pagination
          defPageSize
        }
        columns {
          buttonTitle
          col
          title
          format {
            type
            submitAction {
              controls {
                columnValue
                filterKey
                gridWidth
                rows
                readonly
                required
                title
                type
              }
              modalSize
              query {
                procedureName
                procedureParams
                queryParams
                smtConfig
              }
              submitText
              text
            }
            link
            linkNewWindow
            linkParameters
            trans
            colorFrom
            colorTo
            formatting
            highlighting
            newLine
            parameters
            linkParametersWhenGroup {
              group
              linkParameters
            }
          }
          sortable
          searchable
          group
          whenGroup
          tooltip
        }
      }
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;

export const getTableData = gql`
  query getTableData(
    $instanceId: Int!
    $code: String!
    $interval: DateInterval
    $sort: [Sort]
    $filter: [FilterItem]
    $cache: Boolean! = true
    $limit: Int
    $offset: Int
  ) {
    getTableData(
      instanceId: $instanceId
      code: $code
      interval: $interval
      filter: $filter
      sort: $sort
      fromCache: $cache
      limit: $limit
      offset: $offset
    ) {
      data {
        totalCount
        data
      }
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
    }
  }
`;
