/* eslint-disable no-console */
/* eslint-disable @typescript-eslint/no-unsafe-argument */
/* eslint-disable @typescript-eslint/no-explicit-any */

import getEnvVal from '../constants/getEnvVal';

const debug = {
  log: (message: any, ...optionalParams: any[]) => {
    if (debug.isEnabled()) {
      console.log(message, ...optionalParams);
      debug.trace(message, ...optionalParams);
    }
  },
  error: (message: any, ...optionalParams: any[]) => {
    if (debug.isEnabled()) {
      console.error(message, ...optionalParams);
      debug.trace(message, ...optionalParams);
    }
  },
  trace: (message: any, ...optionalParams: any[]) => {
    if (localStorage.getItem('sys_debug_trace') === '1') {
      // eslint-disable-next-line no-console
      console.trace(message, ...optionalParams);
    }
  },
  table: (tabularData: any, properties?: ReadonlyArray<string>) => {
    if (debug.isEnabled()) {
      // eslint-disable-next-line no-console
      console.table(tabularData, properties);
    }
  },
  isEnabled: () => {
    const deb = getEnvVal('SYS_DEBUG', false);
    return (deb === 'global' || (deb === 'user' && localStorage.getItem('sys_debug') === '1'));
  },
};

export default debug;
