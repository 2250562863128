import styled, { css, keyframes } from 'styled-components';

const FADE_OUT = keyframes`
    0% {
      opacity: 1;
    }
    100% {
      opacity: 0;
    }
`;

export const Wrapper = styled.div`
  ${(p) => (p.isMobile
    ? css`
          width: calc(100vw - 32px);
        `
    : css`
          width: 566px;
        `)};
  box-shadow: 0 4px 32px 0 rgba(0, 0, 0, 0.07), 0 0 2px 0 rgba(0, 0, 0, 0.1);
  background-color: #323232;
  position: fixed;
  bottom: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 10001; //TODO need to clean up z-index across the project. Such a high number must not be necessary. z-index of other components need to be reduced
  display: flex;
  ${(p) => (p.show
    ? null
    : css`
          animation: ${FADE_OUT} 0.5s;
        `)}
`;

export const Text = styled.div`
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  color: ${(p) => p.theme.colors.white90};
  display: flex;
  align-items: center;
  padding-right: 20px;
`;
