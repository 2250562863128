import React, { useState } from 'react';
import Gap from './Gap';

const ErrorFallback = ({ error, componentStack, resetError }) => {
  const [detail, showDetail] = useState(false);

  return (
    <section className="error">
      <div className="error__inner">
        <h1>Error</h1>
        <h2>You have encountered an error</h2>
        {detail ? (
          <div>
            <div>
              <a onClick={() => showDetail(false)}>Hide details</a>
            </div>
            <div>{error ? error.toString() : ''}</div>
            <code>{componentStack}</code>
          </div>
        ) : (
          <div>
            <a onClick={() => showDetail(true)}>Show details</a>
          </div>
        )}
        <div className="mt-3">
          <button
            type="button"
            className="btn btn-outline-theme"
            onClick={() => {
              resetError();
              window.location.reload();
            }}
          >
            Reload
          </button>
          <Gap />
          <button
            type="button"
            className="btn btn-outline-theme"
            onClick={() => {
              localStorage.clear();
              resetError();
              window.location.reload();
            }}
          >
            Reset & reload
          </button>
        </div>
      </div>
    </section>
  );
};

export default ErrorFallback;
