import React from 'react';
import Modal from 'react-bootstrap/Modal';

const GlobalModal = ({ context }) => {
  const { modals, closeModal } = context;

  if (modals.length === 0) {
    return <></>;
  }

  const modal = modals[0];
  return (
    <Modal
      size={modal.size}
      show
      onHide={() => closeModal(modal.id)}
      aria-labelledby="example-modal-sizes-title-sm"
      centered
    >
      <Modal.Header closeButton closeVariant="white">
        <Modal.Title id="example-modal-sizes-title-sm">
          {modal.title || 'Detail'}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        {typeof modal.content === 'function'
          ? modal.content({ close: () => closeModal(modal.id) })
          : modal.content}
      </Modal.Body>
    </Modal>
  );
};

export default GlobalModal;
