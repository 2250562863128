import React from 'react';
import { toast } from 'react-toastify';
import { filterToUrl } from 'utils/urlHelper';
import { copyTextToClipboard } from '../../atoms/CopyClipboard';
import { intervalUrlPrefix } from '../../../constants/variables';
import { FilterType } from '../../molecules/Filter/index';
import IntervalHelper from '../../../utils/IntervalHelper';
import { Interval } from '../../../redux/userSettings/types';
import { useIntervalsSelector } from '../../../redux/hooks';

type Props = {
  filter: FilterType;
  interval: Interval;
};

const ShareButton: React.FC<Props> = ({ filter, interval }) => {
  const intervals = useIntervalsSelector();

  const currentInterval = intervals.find((i) => i.name === interval.range);

  return (
    <button
      type="button"
      className="btn btn-link"
      title="Share page link"
      onClick={() => {
        let now = IntervalHelper.getDate(interval.timestamp as Date | 'now');
        if (interval.timestamp === 'now' && currentInterval) {
          now = IntervalHelper.addIntervalTime(
            interval.timestamp,
            -currentInterval.duration,
            currentInterval.unit,
          );
        }

        const filterUrl = filterToUrl(filter, '')
          .replace(`${intervalUrlPrefix}timestamp=now`, `${intervalUrlPrefix}timestamp=${now.toISOString()}`);

        copyTextToClipboard(
          `${window.location.origin}${window.location.pathname}?${filterUrl}`
          + `${window.location.hash}`,
        )
          .then(() => toast.success('Page link copied to the clipboard'))
          .catch(() => toast.error('Error when creating the link'));
      }}
    >
      <i className="actions__item fa fa-share-alt" />
    </button>
  );
};

export default ShareButton;
