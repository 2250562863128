import React, { Component } from 'react';
import * as am4core from '@amcharts/amcharts4/core';
import * as am4charts from '@amcharts/amcharts4/charts';
import Proptypes from 'prop-types';
import { omit } from 'lodash';
import { color } from '@amcharts/amcharts4/core';
import { randomString } from '../../../utils/string';

am4core.useTheme((object) => {
  if (object.className === 'InterfaceColorSet') {
    object.setFor('stroke', color('#000000'));
    object.setFor('fill', color('#ffffff'));

    object.setFor('primaryButton', color('#0975da'));
    object.setFor('primaryButtonHover', color('#0975da'));
    object.setFor('primaryButtonDown', color('#0975da'));
    object.setFor('primaryButtonActive', color('#0975da'));
    object.setFor('primaryButtonText', color('#FFFFFF'));
    object.setFor('primaryButtonStroke', color('#0975da'));

    object.setFor('secondaryButton', color('#fff'));
    object.setFor('secondaryButtonDown', color('#fff'));
    object.setFor('secondaryButtonActive', color('#fff'));
    object.setFor('secondaryButtonText', color('#fff'));
    object.setFor('secondaryButtonStroke', color('#222'));

    object.setFor('grid', color('#fff'));
    object.setFor('background', color('#F5F'));
    object.setFor('alternativeBackground', color('#fff'));
    object.setFor('text', color('#ffffff'));
    object.setFor('alternativeText', color('#222'));
  }

  if (object.className === 'Button') {
    object.background.fillOpacity = 1;
    object.background.strokeOpacity = 0.5;
    object.background.fill = color('#fff');
  }

  if (object.className === 'AmChartsLogo') {
    object.disabled = true;
  }
});

/**
 * Component which contains the dynamic state for the AmChart 4
 *
 * Default style is in src/constants/styledTheme.js
 */
class Chart4 extends Component {
  defaultStyle = {
    width: '100%',
    height: 300,
  };

  constructor(props) {
    super(props);
    this.key = randomString(16, 'aA#');

    this.state = {
      config: props.config,
      data: props.data,
      legendDiv: randomString(10, 'aA#'),
    };
  }

  componentDidMount() {
    const { config, data } = this.state;
    this.chart = am4core.createFromConfig(
      this.state.config,
      `chart4_${this.key}`,
      am4charts[config.type],
    );

    this.chart.data = data;
  }

  componentWillUnmount() {
    if (this.chart) {
      this.chart.dispose();
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.data !== this.props.data) {
      this.setState({ data: this.props.data });
      this.chart.data = this.props.data;
    }
  }

  render() {
    const { legendDiv } = this.state;
    const style = { ...this.defaultStyle, ...this.props.style };
    if (style.height < 10) {
      style.height = this.defaultStyle.height;
    }

    return (
      <>
        <div
          id={`chart4_${this.key}`}
          style={style}
          {...omit(this.props, ['config', 'data', 'style'])}
        />
        <div
          id={`${legendDiv}_legend`}
          className="chart-legend"
          style={{ background: '#ff0' }}
        />
      </>
    );
  }
}

Chart4.defaultProps = {};

Chart4.propTypes = {
  config: Proptypes.object.isRequired,
  data: Proptypes.array,
};

export default Chart4;
