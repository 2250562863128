import React, { useState } from 'react';
import { connect } from 'react-redux';
import { getToken } from 'redux/auth/actions';
import Button from 'react-bootstrap/Button';
import { Col, Row } from 'react-bootstrap';
import Page from '../components/organisms/Page/Page';
import { InputFormatted } from '../components/molecules/Form/Input';
import ErrorBoundary from '../components/atoms/ErrorBoundary';

const PageDebug = () => {
  const [show, setShow] = useState(false);
  const [preContent, setPreContent] = useState('');
  const [content, setContent] = useState('');

  return (
    <>
      <h1>Page preview mode</h1>
      {show && (
        <Row>
          <Col>
            <InputFormatted
              input="content"
              label="Page definition"
              values={{ content: preContent }}
              onChange={(e) => setPreContent(e.target.value)}
            />
            <Button
              onClick={() => {
                setContent(preContent);
                setShow(false);
              }}
            >
              Preview
            </Button>
          </Col>
        </Row>
      )}
      {!show && (
        <>
          <Row style={{ marginBottom: '20px' }}>
            <Col>
              <Button onClick={() => setShow(true)}>Edit</Button>
            </Col>
          </Row>
          <ErrorBoundary>
            <Page pageId={-1} content={content} />
          </ErrorBoundary>
        </>
      )}
    </>
  );
};

export default connect((state) => ({ auth: state.auth }), { getToken })(PageDebug);
