import React, { useContext, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import omit from 'lodash/omit';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { findIconDefinition } from '@fortawesome/fontawesome-svg-core';
import SideBarItems from './SideBarItems';
import SideBarItemWrapper from './SideBarItemWrapper';
import Gap from '../../../atoms/Gap';
import { getPersistentQuery } from '../../../../utils/urlHelper';
import TooltipContext from '../TooltipContext';

const iconMap = {
  'bar-chart': 'chart-bar',
  'double-angle-right': 'angle-double-right',
  dashboard: 'tachometer',
};

const remapIcon = (icon) => {
  if (Object.prototype.hasOwnProperty.call(iconMap, icon)) {
    return iconMap[icon];
  }
  return icon;
};

const Icon = ({ icon, iconType }) => {
  if (!icon) {
    return null;
  }
  let iconComponent;
  if (iconType === 'class') {
    iconComponent = <i className={icon} />;
  } else if (iconType === 'fa') {
    const faIcon = findIconDefinition({ prefix: 'fas', iconName: icon });
    if (faIcon) {
      iconComponent = <FontAwesomeIcon icon={faIcon} />;
    }
  } else if (!iconType && icon) {
    const iconKey = icon.replace('icon-', '');
    const faIcon = findIconDefinition({ prefix: 'fas', iconName: remapIcon(iconKey) })
      || findIconDefinition({ prefix: 'far', iconName: remapIcon(iconKey) });
    if (faIcon) {
      iconComponent = <FontAwesomeIcon icon={faIcon} />;
    }
  }

  if (!iconComponent) {
    return null;
  }

  return (
    <>
      {iconComponent}
      <Gap />
    </>
  );
};

Icon.defaultProps = {
  icon: null,
  iconType: null,
};

Icon.propTypes = {
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  iconType: PropTypes.string,
};

const SideBarItem = (props) => {
  const showTooltip = useContext(TooltipContext);
  const [open, setOpen] = useState(
    (props.currentItemIndexes || []).indexOf(props.id) !== -1,
  );
  useEffect(() => {
    if (typeof props.opened === 'boolean') {
      setOpen(props.opened);
    }
  }, [props.opened]);

  const params = {};
  if (props.route && props.content) {
    params.to = {
      pathname: props.route,
      search: getPersistentQuery(),
    };
  }

  if (props.items) {
    params.onClick = () => {
      if (open) {
        props.onCollapse();
      } else {
        props.onExpand();
      }
      setOpen(!open);
    };
  }

  const hasContent = props.presenter === 'Genreport:Genreport' || props.content.length > 0;
  const linkStyle = !hasContent && props.items.length === 0
    ? { textDecoration: 'line-through' }
    : {};

  return (
    <SideBarItemWrapper
      className={`navigation__sub ${
        props.currentItemIndexes.indexOf(props.id) !== -1
          ? ' navigation__sub--active'
          : ''
      }`}
      onClick={props.onClick}
      title={showTooltip ? props.tooltip : ''}
      data-id={props.id}
    >
      {params.to ? (
        <Link
          {...params}
          style={linkStyle}
        >
          <Icon icon={props.icon} iconType={props.iconType} />
          {' '}
          {props.title}
        </Link>
      ) : (
        // eslint-disable-next-line jsx-a11y/anchor-is-valid
        <a
          href="#"
          {...omit(params, ['to2'])}
          style={linkStyle}
        >
          <Icon icon={props.icon} iconType={props.iconType} />
          {props.title}
        </a>
      )}
      {props.items && open ? (
        <SideBarItems
          className="navigation__sub"
          items={props.items}
          currentItemIndexes={props.currentItemIndexes}
        />
      ) : (
        ''
      )}
    </SideBarItemWrapper>
  );
};

SideBarItem.defaultProps = {
  currentItemIndexes: [],
  icon: null,
  iconType: null,
  id: null,
  text: '',
  onClick: () => { /* no default action */ },
  onExpand: () => { /* no default action */ },
  onCollapse: () => { /* no default action */ },
  route: '/',
  items: [],
  opened: undefined,
  presenter: '',
  tooltip: '',
};

SideBarItem.propTypes = {
  content: PropTypes.string.isRequired,
  icon: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  iconType: PropTypes.string,
  id: PropTypes.number,
  text: PropTypes.string,
  onClick: PropTypes.func,
  onExpand: PropTypes.func,
  onCollapse: PropTypes.func,
  route: PropTypes.string,
  items: PropTypes.arrayOf(PropTypes.any),
  currentItemIndexes: PropTypes.arrayOf(PropTypes.number),
  opened: PropTypes.bool,
  presenter: PropTypes.string,
  title: PropTypes.string.isRequired,
  tooltip: PropTypes.string,
};

export default SideBarItem;
