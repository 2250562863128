import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import DebugModeSwitch from './DebugModeSwitch';
import { Link } from 'react-router-dom';
import DropdownItemWrapper from './DropdownItemWrapper';
import { setShowGuide } from '../../../../redux/app/actions';
import TopBarItem from './TopBarItem';
import { usePagesSelector } from '../../../../redux/hooks';

const UserMenu = () => {
  const pages = usePagesSelector();
  const dispatch = useDispatch();

  const hasSettings = useMemo(() => pages.filter((p) => p.id === 2213).length > 0, [pages]);

  const items = [
    <DebugModeSwitch key="debug" className="dropdown-item" />,
    <Link
      key="preferences"
      to="/preferences"
      className="dropdown-item"
      title="Personal SMT config"
    >
      Settings
      {' '}
      <i className="float-end fa fa-solid fa-cog" />
    </Link>,
    <hr key="hr1" />,
    <DropdownItemWrapper
      key="intro"
      className="dropdown-item"
      onClick={() => { dispatch(setShowGuide({ continue: false, guide: 'basic' })); }}
      title="Start basic SMT introduction"
    >
      <span>
        Introduction
        <i className="float-end fa fa-solid fa-question-circle" />
      </span>
    </DropdownItemWrapper>,
    <DropdownItemWrapper
      key="categoryIntro"
      className="dropdown-item"
      onClick={() => { dispatch(setShowGuide({ continue: false, guide: 'category' })); }}
      title="Walk-trough of SMT reporting and tools"
    >
      <span>
        Category introduction
        <i className="float-end fa fa-solid fa-question-circle" />
      </span>
    </DropdownItemWrapper>,
  ];

  if (hasSettings) {
    items.push(
      <DropdownItemWrapper
        key="settingsIntro"
        className="dropdown-item"
        onClick={() => { dispatch(setShowGuide({ continue: false, guide: 'settings' })); }}
        title="Basics of SMT settings"
      >
        <span>
          Settings introduction
          <i className="float-end fa fa-solid fa-question-circle" />
        </span>
      </DropdownItemWrapper>,
    );
  }

  items.push(...[
    <hr key="hr2" />,
    <Link key="logout" to="/logout" className="dropdown-item">
      Logout
      <i className="float-end fa fa-solid fa-sign-out-alt" />
    </Link>,
  ]);

  return (
    <TopBarItem
      dataIntroId="button-settings"
      iconName="zwicon-more-h"
      dropdownStyle={{ minWidth: '180px' }}
      title="Settings/log out"
      items={items}
    />
  );
};

export default UserMenu;
