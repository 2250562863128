import { ExportToCsv, Options } from 'export-to-csv-fix-source-map';

const defaultOptions: Options = {
  fieldSeparator: ',',
  quoteStrings: '"',
  decimalSeparator: '.',
  showLabels: true,
  useTextFile: false,
  useBom: true,
  useKeysAsHeaders: true,
};

export const generateCsv = (data: { [key: string]: unknown }[], options: Options = {}): string => {
  const csvExporter = new ExportToCsv({ ...defaultOptions, ...options });
  return csvExporter.generateCsv(data) as string;
};

export const downloadCsv = (data: { [key: string]: unknown }[], filename: string, options: Options = {}) => {
  generateCsv(data, { ...options, filename });
};
