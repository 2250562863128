import React, { useMemo, useRef } from 'react';
import SideBarItems from './Partials/SideBarItems';
import PropTypes from 'prop-types';
import { getRem } from '../../../utils/css';
import { connect, useDispatch } from 'react-redux';
import get from 'lodash/get';
import PerfectScrollbar from 'react-perfect-scrollbar';
import { getPages, setUserSetting } from '../../../redux/userSettings/actions';

import 'react-perfect-scrollbar/dist/css/styles.css';
import { useLocation } from 'react-router-dom';
import useOutsideClick from '../../../hooks/useOutsideClick';
import Switch from 'react-bootstrap/Switch';
import TooltipContext from './TooltipContext';
import { Col, Row } from 'react-bootstrap';
import { usePreferencesSelector } from '../../../redux/hooks';
import { UserSettingsEnum } from '../../../constants/UserSettingsEnum';

const getParentIds = (pages, id) => {
  let ret = [];
  ret.push(id);
  const page = pages.find((p) => p.id === id);
  if (page && page.parent) {
    ret = ret.concat(...getParentIds(pages, page.parent));
  }
  return ret;
};

const SideBar = (props) => {
  const wrapperRef = useRef(null);
  const location = useLocation();
  useOutsideClick(wrapperRef, () => props.setCollapsed(true));
  const preferences = usePreferencesSelector();
  const tooltip = useMemo(() => preferences[UserSettingsEnum.SHOW_MENU_DESCRIPTION] !== '0', [preferences]);
  const dispatch = useDispatch();

  const pages = useMemo(
    () => [...get(props, 'pages', []).filter((p) => p.inMenu)],
    [props.pages],
  );
  const selectedItem = pages.find(
    (p) => p.route === location.pathname,
  );
  const selectedIndexes = useMemo(() => {
    if (!pages || !selectedItem) {
      return [];
    }
    return getParentIds(pages, selectedItem.id);
  }, [pages, selectedItem]);

  const menuItems = (pages || [])
    .filter(
      (item) => item.parent === null || item.parent === 0 || item.parent === '0',
    )
    .sort((a, b) => (a.order < b.order ? 1 : -1))
    .map((item) => ({
      ...item,
      items: (pages || [])
        .filter((subItem) => subItem.parent === item.id)
        .sort((subA, subB) => (subA.order < subB.order ? 1 : -1))
        .map((subItem) => ({
          ...subItem,
          items: (pages || []).filter(
            (subSubItem) => subSubItem.parent === subItem.id,
          ),
        })),
    }));

  return (
    <TooltipContext.Provider value={tooltip}>
      <aside
        ref={wrapperRef}
        className={`sidebar ${!props.collapsed && 'toggled'}`}
        style={{ overflow: 'none' }}
      >
        <PerfectScrollbar id="sidebar-scrollbar">
          <div className="os-content">
            <Row style={{ padding: '0.25rem 1rem 0.5rem' }}>
              <Col style={{ margin: 'auto 0' }}>
                <Switch
                  type="switch"
                  id="tooltip-help"
                  label="Menu descriptions"
                  checked={tooltip}
                  onChange={(e) => {
                    dispatch(setUserSetting(UserSettingsEnum.SHOW_MENU_DESCRIPTION, e.target.checked ? '1' : '0'));
                  }}
                />
              </Col>
            </Row>
            <hr />
            <SideBarItems
              className="navigation"
              onMouseEnter={() => {
                if (props.collapsed) {
                  props.onEnterMenuArea();
                }
              }}
              onMouseLeave={() => {
                if (props.collapsed) {
                  props.onLeaveMenuArea();
                }
              }}
              items={menuItems}
              offsetTop={props.offsetTop}
              belowLg={props.belowLg}
              currentItemIndexes={selectedIndexes}
            />
          </div>
        </PerfectScrollbar>
      </aside>
    </TooltipContext.Provider>
  );
};
SideBar.defaultProps = {
  onEnterMenuArea: () => {
    /* no action by default */
  },
  onLeaveMenuArea: () => {
    /* no action by default */
  },
  onLeaveSideBar: () => {
    /* no action by default */
  },
  onClickCollapse: () => {
    /* no action by default */
  },
  collapsed: true,
  setCollapsed: () => {
    /* no action by default */
  },
  paddingTop: getRem(80),
  offsetTop: 13.0,
  collapseMenuText: 'Collapse Menu',
};
SideBar.propTypes = {
  onEnterMenuArea: PropTypes.func,
  onLeaveMenuArea: PropTypes.func,
  onLeaveSideBar: PropTypes.func,
  onClickCollapse: PropTypes.func,
  items: PropTypes.array,
  offsetTop: PropTypes.number,
  paddingTop: PropTypes.string,
  collapseMenuText: PropTypes.string,
  collapsed: PropTypes.bool,
  setCollapsed: PropTypes.func,
  belowLg: PropTypes.bool,
  shouldScrollMenu: PropTypes.bool,
};

export default connect(
  (state) => ({
    pages: get(state, 'userSettings.pages'),
  }),
  { getPages },
)(SideBar);
