import React from 'react';
import Proptypes from 'prop-types';

const InfoBox = ({ level, children }) => {
  if (level === 'error') {
    level = 'danger';
  }
  return (
    <div className={`alert alert-${level}`} role="alert">
      {children}
    </div>
  );
};

InfoBox.defaultProps = {
  level: 'info',
};

InfoBox.propTypes = {
  level: Proptypes.string.isRequired,
};

export default InfoBox;
