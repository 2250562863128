import { useInstanceIdSelector } from 'redux/hooks';
import { useQuery } from '@apollo/client';
import { getTableData } from '../../../../queries/graphs';

const Version = () => {
  const instanceId = useInstanceIdSelector();
  const data = useQuery(getTableData, {
    variables: {
      instanceId,
      code: 'version_list',
      interval: null,
      filter: [],
      cache: true,
    },
  });

  // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
  if (data?.data?.getTableData?.data?.data?.[0]?.smtboxv) {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-unsafe-return
    return data?.data?.getTableData?.data?.data?.[0]?.smtboxv;
  }
  return null;
};

export default Version;
