import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import StatusInfo from './index';
import { getLiveDataPreference } from '../../../redux/userSettings/actions';
import { setStatusInfo } from '../../../redux/page/actions';

const StatusInfoRedux = ({ messages }) => <StatusInfo messages={messages} />;

StatusInfoRedux.defaultProps = {
  messages: [],
};

StatusInfoRedux.propTypes = {
  codes: PropTypes.arrayOf(PropTypes.string.isRequired).isRequired,
  messages: PropTypes.array.isRequired,
};

export default connect((state, ownProps) => {
  let messages = [];
  ownProps.codes.forEach((c) => {
    messages = [...messages, ...get(state, `page.statusInfo.${c}`, [])];
  });
  return { messages };
}, {})(StatusInfoRedux);
