import { scrollNavigationTo } from '../components/SmtIntro/helpers';

export const clickMenu = (id: number) => {
  const link = document.querySelector(`.navigation li[data-id="${id.toString()}"] a`) as HTMLAnchorElement;

  if (!link) {
    return;
  }

  if (link.getAttribute('href') === '#' || !window.location.pathname.includes(`/report/${id}`)) {
    link.click();
  }
};

export const expandNavigation = (pageId: number) => new Promise<void>((resolve, reject) => {
  const el = document.querySelector(`.navigation [data-id="${pageId}"]`);
  const anchor = el?.querySelector('a');
  if (anchor?.getAttribute('href') === '#') {
    if (!el?.querySelector('ul')) {
      clickMenu(pageId);
      waitForElement(`.navigation [data-id="${pageId}"] ul`)
        .then(() => {
          scrollNavigationTo(pageId);
          resolve();
        })
        .catch(() => { reject(); });
      return;
    }
  }

  resolve();
});

export const waitForElement = <T extends Element>(selector: string | HTMLElement) => new Promise<T>((resolve) => {
  const getElement = () => {
    if (typeof selector === 'string') {
      const element = document.querySelector(selector) as T[] | null;
      if (element) {
        resolve(element[0]);
      } else {
        requestAnimationFrame(getElement);
      }
    } else {
      resolve(selector as unknown as T);
    }
  };
  getElement();
});
