import { all, fork, takeLatest } from 'redux-saga/effects';
import types from './action-types';
import {
  saveInstanceScopePreference,
  loadInstanceScopePreference,
  getInstancesEffect,
  getPagesEffect,
  saveLiveDataPreferenceEffect,
  getLiveDataPreferenceEffect,
  saveFilterPreferenceEffect,
  loadFilterPreferenceEffect,
  saveIntervalPreferenceEffect,
  loadIntervalPreferenceEffect,
  setThemePreferenceEffect,
  setDebugModeEffect,
  getUserEffect,
  setUserSettingEffect,
  loadIntervalsEffect,
} from './effects';

function* watchGetPages() {
  yield takeLatest(types.GET_PAGES, getPagesEffect);
}

function* watchGetIntervals() {
  yield takeLatest(types.LOAD_INTERVALS, loadIntervalsEffect);
}

function* watchGetInstances() {
  yield takeLatest(types.GET_INSTANCES, getInstancesEffect);
}

function* watchSaveInstanceScopePreference() {
  yield takeLatest(types.SAVE_INSTANCE_SCOPE, saveInstanceScopePreference);
}

function* watchLoadInstanceScopePreference() {
  yield takeLatest(types.LOAD_INSTANCE_SCOPE, loadInstanceScopePreference);
}

function* watchSaveLiveDataPreference() {
  yield takeLatest(
    types.SAVE_LIVE_DATA_PREFERENCE,
    saveLiveDataPreferenceEffect,
  );
}

function* watchGetLiveDataPreference() {
  yield takeLatest(types.GET_LIVE_DATA_PREFERENCE, getLiveDataPreferenceEffect);
}

function* watchSaveFilterPreference() {
  yield takeLatest(types.SAVE_FILTER_PREFERENCE, saveFilterPreferenceEffect);
}

function* watchLoadFilterPreference() {
  yield takeLatest(types.LOAD_FILTER_PREFERENCE, loadFilterPreferenceEffect);
}

function* watchSaveIntervalPreference() {
  yield takeLatest(
    types.SAVE_INTERVAL_PREFERENCE,
    saveIntervalPreferenceEffect,
  );
}

function* watchLoadIntervalPreference() {
  yield takeLatest(
    types.LOAD_INTERVAL_PREFERENCE,
    loadIntervalPreferenceEffect,
  );
}

function* watchSetThemePreference() {
  yield takeLatest(types.SET_THEME_PREFERENCE, setThemePreferenceEffect);
}

function* watchSetDebugMode() {
  yield takeLatest(types.SET_DEBUG, setDebugModeEffect);
}

function* watchGetUser() {
  yield takeLatest(types.GET_USER, getUserEffect);
}

function* watchSetUserSetting() {
  yield takeLatest(types.SET_USER_SETTING, setUserSettingEffect);
}

export default function* rootSaga() {
  yield all([
    fork(watchGetPages),
    fork(watchGetInstances),
    fork(watchSaveInstanceScopePreference),
    fork(watchLoadInstanceScopePreference),
    fork(watchSaveLiveDataPreference),
    fork(watchGetLiveDataPreference),
    fork(watchSaveFilterPreference),
    fork(watchLoadFilterPreference),
    fork(watchSaveIntervalPreference),
    fork(watchLoadIntervalPreference),
    fork(watchSetThemePreference),
    fork(watchSetDebugMode),
    fork(watchGetUser),
    fork(watchSetUserSetting),
    fork(watchGetIntervals),
  ]);
}
