export const getErrors = (result) => {
  let errors = [];
  if (result.errors) {
    errors = errors.concat(result.errors);
  }
  if (!result.data) {
    return errors;
  }
  Object.keys(result.data).forEach((k) => {
    if (
      result.data[k]?.errors
      && Array.isArray(result.data[k]?.errors)
      && result.data[k]?.errors.length > 0
    ) {
      errors = errors.concat(result.data[k].errors);
    }
  });
  return errors.length > 0 ? errors : null;
};
