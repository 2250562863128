import actionTypes from './action-types';
import { GuideType } from './types';
import get from 'lodash/get';
import { BaseAction } from '../types';

export type State = {
  guide: {
    continue: boolean
    guide?: GuideType;
  }
};

const initialState: State = {
  guide: {
    continue: true,
    guide: undefined,
  },
};

type Action = {
  type: 'APP/SET_SHOW_GUIDE',
  payload: {
    continue: boolean;
    guide?: GuideType;
  }
} | BaseAction;

const reducer = (state: State = initialState, action: Action = {}) => {
  switch (action.type) {
    case actionTypes.SET_SHOW_GUIDE:
      return {
        ...state,
        guide: {
          continue: get(action.payload, 'continue', false) as boolean,
          guide: get(action.payload, 'guide', undefined) as unknown as GuideType,
        },
      };

    default:
      return state;
  }
};

export default reducer;
