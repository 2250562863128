import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useLazyQuery } from '@apollo/client';
import styled from 'styled-components';
import Loading from '../Loader/Loading';
import GetDataComponentProps from 'types/GetDataComponentProps';
import { getTableData } from '../../../queries/graphs';
import {
  fillFilter, graphqlFilter, graphqlInterval,
} from '../../../utils/definitionParser';
import useInterval from '../../../hooks/useInterval';
import getRefreshInterval from '../../../utils/refreshInterval/getRefreshInterval';
import { useInstanceIdSelector, useIntervalSelector, usePollIntervalSelector } from '../../../redux/hooks';
import CardWrapper from '../../atoms/CardWrapper';
import { useCompare } from 'hooks/useCompare';
import RequestDebugModal from '../Debug/RequestDebugModal';

type TileCardProps = {
  titleColumn: string;
  valueColumn: string;
  linkColumn: string;
} & GetDataComponentProps;

const WidgetNumber = styled.div<{ gap?: string }>`
  font-weight: bold;
  font-size: 2.5rem;
  display: block;
  line-height: 1;
  margin: 1rem auto;
`;

const TileCard: React.FC<TileCardProps> = ({
  titleColumn,
  valueColumn,
  linkColumn,
  filter,
  queryParams,
  code,
  cache = true,
  minRefresh = 0,
}) => {
  const navigate = useNavigate();
  const interval = useIntervalSelector();
  const pollInterval = usePollIntervalSelector();
  const instanceScope = useInstanceIdSelector();
  const apiFilter = fillFilter(
    queryParams,
    filter,
    '',
    true,
  );

  const [loading, setLoading] = useState(false);
  const [data, setData] = useState({ title: '', value: '', link: '' });

  const [fetchMore] = useLazyQuery(getTableData, {
    variables: {
      instanceId: instanceScope,
      code,
      interval: graphqlInterval(interval),
      filter: graphqlFilter(apiFilter) as { key: string; value: string },
      cache,
    },
    errorPolicy: 'all',
    context: { batch: true },
    onCompleted: (res) => {
      const row = res.getTableData.data.data[0] as typeof data;
      const newData = {
        title: (row[titleColumn] || '') as string,
        value: (row[valueColumn] || '') as string,
        link: (row[linkColumn] || '') as string,
      };
      setData(newData);
      setLoading(false);
    },
  });

  const changed = useCompare([apiFilter, cache, code, fetchMore, instanceScope, interval]);

  useEffect(() => {
    if (changed) {
      fetchMore({
        variables: {
          instanceId: instanceScope,
          code,
          interval: graphqlInterval(interval),
          filter: graphqlFilter(apiFilter) as { key: string; value: string },
          cache,
        },
      }).catch(undefined);
    }
  }, [apiFilter, cache, changed, code, fetchMore, instanceScope, interval]);

  useInterval(() => {
    fetchMore({
      variables: {
        instanceId: instanceScope,
        code,
        interval: graphqlInterval(interval),
        filter: graphqlFilter(apiFilter) as { key: string; value: string },
        cache,
      },
    }).catch(undefined);
  }, getRefreshInterval(pollInterval / 1000, minRefresh) * 1000);

  if (loading) {
    return <CardWrapper><Loading /></CardWrapper>;
  }

  return (
    <div
      role="button"
      onClick={() => { if (data.link) { navigate(data.link); } }}
      style={data.link ? { cursor: 'pointer' } : {}}
    >
      <CardWrapper title={data.title}>
        <RequestDebugModal
          title="Tile debug"
          code={code}
          theme="absolute"
          definition={{
            data: {
              data: [{
                titleColumn, valueColumn, linkColumn, filter, queryParams, code, cache, minRefresh,
              }],
            },
          }}
          data={{ data: { data: [data] } }}
          filter={apiFilter}
        />
        <WidgetNumber className="widget-numbers">
          <span dangerouslySetInnerHTML={{ __html: data.value }} />
        </WidgetNumber>
      </CardWrapper>
    </div>
  );
};

export default TileCard;
