import React from 'react';
import { scaleBand, scaleLinear } from '@visx/scale';
import { Group } from '@visx/group';
import { Bar } from '@visx/shape';
import { useTooltip, useTooltipInPortal } from '@visx/tooltip';
import { localPoint } from '@visx/event';

type SparklineBarProps = {
  data: Array<number>;
  height: number;
  width: number;
  margin: {
    bottom: number;
    left: number;
    right: number;
    top: number;
  };
};

const defaultMargin = {
  top: 0, bottom: 0, left: 0, right: 0,
};

const SparklineBar: React.FC<SparklineBarProps> = ({
  data, height, margin = defaultMargin, width,
}) => {
  const {
    tooltipData,
    tooltipLeft,
    tooltipTop,
    tooltipOpen,
    showTooltip,
    hideTooltip,
  } = useTooltip();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    detectBounds: true,
    scroll: true,
  });

  const handleMouseOver = (event: React.MouseEvent<SVGElement, MouseEvent>, value: number) => {
    const coords = localPoint((event.target as SVGElement)?.ownerSVGElement as Element, event);
    showTooltip({
      tooltipLeft: coords?.x,
      tooltipTop: coords?.y,
      tooltipData: value.toString(),
    });
  };

  const xMax = width - margin.left - margin.right;
  const yMax = height - margin.top - margin.bottom;

  const xScale = scaleBand({
    range: [0, xMax],
    round: true,
    domain: [...data.keys()],
    padding: 0.2,
    paddingOuter: 0,
  });
  const yScale = scaleLinear({
    range: [yMax, 0],
    round: true,
    domain: [0, Math.max(...data)],
  });

  return (
    <>
      <svg ref={containerRef} width={width} height={height}>
        {data.map((d, i) => {
          const barHeight = yMax - yScale(d);
          return (
            <Group key={`bar-${i.toString()}`}>
              <Bar
                width={xScale.bandwidth()}
                height={d === 0 ? 1 : barHeight}
                x={xScale(i)}
                y={yMax - barHeight - (d === 0 ? 1 : 0)}
                fill={d === 0 ? '#fff' : '#0d7686'}
                onMouseOver={(e) => handleMouseOver(e, d)}
                onMouseOut={hideTooltip}
              />
            </Group>
          );
        })}
      </svg>
      {tooltipOpen && (
        <TooltipInPortal
          key={Math.random()}
          top={tooltipTop}
          left={tooltipLeft}
        >
          {tooltipData?.toString()}
        </TooltipInPortal>
      )}
    </>
  );
};

export default SparklineBar;
