import axios from 'axios';
import { call, put } from 'redux-saga/effects';
import get from 'lodash/get';
import {
  getCookie,
  removeCookie,
  transformRequest,
  getKeycloakLogoutUrl,
} from '../../utils/auth';
import {
  getTokenError,
  getTokenSuccess,
  logOut as logoutAction,
} from './actions';
import { processToken } from '../../utils/interceptor';
import getEnvVal from '../../constants/getEnvVal';
import client from '../../apolloClient';

export function* getToken({ payload }) {
  try {
    const [data, options] = getDataAndOptionsForTokenRequest(payload);
    const response = yield call(
      axios.post,
      `${getEnvVal('KEYCLOAK_URL')}token`,
      data,
      options,
    );

    if (response && response.data && response.data.access_token) {
      yield processTokenResponse(response);
    } else {
      yield put(getTokenError());
    }
  } catch (error) {
    yield put(getTokenError(error));
  }
}

export function* refreshToken({ payload }) {
  try {
    const refreshToken = getCookie('refresh_token');
    const [data, options] = getDataAndOptionsForTokenRequest({}, refreshToken);
    const response = yield call(
      axios.post,
      `${getEnvVal('KEYCLOAK_URL')}token`,
      data,
      options,
    );
    yield processTokenResponse(response);
  } catch (error) {
    yield put(getTokenError(error));
  }
}

export function* tokenErrorEffect() {
  yield put(logoutAction());
}

export function* logOut() {
  console.trace('logout');
  yield removeCookie('refresh_token_expiry');
  yield removeCookie('refresh_token');
  yield removeCookie('access_token');
  yield removeCookie('access_token_expiry');
  yield removeCookie('returnUri');
  yield removeCookie('user_roles');
  yield client.clearStore();
  window.location = getKeycloakLogoutUrl();
}

export function getDataAndOptionsForTokenRequest(
  payload,
  refreshToken = undefined,
) {
  const data = transformRequest({
    grant_type: refreshToken ? 'refresh_token' : 'authorization_code',
    client_id: getEnvVal('KEYCLOAK_CLIENT_ID'),
    code: refreshToken ? undefined : payload.code,
    refresh_token: refreshToken || undefined,
    redirect_uri: refreshToken
      ? undefined
      : getEnvVal('KEYCLOAK_REDIRECT_URI')
          + (payload.returnUrl
            ? `?ret=${encodeURIComponent(payload.returnUrl)}`
            : '') || '',
  });

  const options = {
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    timeout: 10000,
  };
  return [data, options];
}

function* processTokenResponse(response) {
  if (get(response, 'data.error')) {
    yield put(getTokenError(get(response, 'data')));
  } else {
    processToken(response);
    yield put(getTokenSuccess(get(response, 'data')));
  }
}
