import React, { Component } from 'react';

interface IProps {
  value: string;
  disabled: boolean;
  mountFocus?: boolean;
  onChange(value: string): void;
  className?: string;
  name?: string;
  placeholder: string;
  type?: string;
  onFocusHandler(event: React.FocusEvent): void;
  onBlurHandler(event: React.FocusEvent): void;
}

interface IState {
  time: string;
}

class DatePickerTimeInput extends Component<IProps, IState> {
  public static defaultProps: IProps = {
    value: '',
    placeholder: '',
    disabled: false,
    onChange: () => undefined,
    onFocusHandler: () => undefined,
    onBlurHandler: () => undefined,
  };

  lastVal = '';

  inputRef: HTMLInputElement | null = null;

  constructor(props) {
    super(props);
    this.state = {
      time: this.props.value || '',
    };
    this.lastVal = '';
  }

  componentDidMount() {
    if (!this.props.disabled && this.props.mountFocus) {
      setTimeout(() => {
        if (this.inputRef) {
          this.inputRef.focus();
        }
      }, 0);
    }
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    if (nextProps.initTime) {
      this.onChangeHandler(nextProps.initTime);
    }
  }

  componentDidUpdate() {
    if (this.props.mountFocus) {
      setTimeout(() => {
        if (this.inputRef) {
          this.inputRef.focus();
        }
      }, 0);
    }
  }

  onChangeHandler(value: string): void {
    let val = value;
    if (val === this.state.time) {
      return;
    }
    if (this.isValid(val)) {
      if (
        val.length === 2
        && this.lastVal.length !== 3
        && val.indexOf(':') === -1
      ) {
        val += ':';
      }

      if (val.length === 2 && this.lastVal.length === 3) {
        val = val.slice(0, 1);
      }

      if (val.length > 5) {
        return;
      }

      this.lastVal = val;

      this.setState({
        time: val,
      });

      if (val.length === 5) {
        this.props.onChange(val);
      }
    }
  }

  getType(): string {
    if (this.props.type) {
      return this.props.type;
    }
    return 'tel';
  }

  // eslint-disable-next-line class-methods-use-this
  isValid(val: string): boolean {
    const regexp = /^\d{0,2}?:?\d{0,2}$/;

    const [hoursStr, minutesStr] = val.split(':');

    if (!regexp.test(val)) {
      return false;
    }

    const hours = Number(hoursStr);
    const minutes = Number(minutesStr);

    const isValidHour = (hour) => Number.isInteger(hour) && hour >= 0 && hour < 24;
    const isValidMinutes = (minute) => (Number.isInteger(minute) && hours >= 0 && hours < 24)
      || Number.isNaN(minute);
    if (!isValidHour(hours) || !isValidMinutes(minutes)) {
      return false;
    }

    if (minutes < 10 && Number(minutesStr[0]) > 5) {
      return false;
    }

    const valArr = val.indexOf(':') !== -1 ? val.split(':') : [val];

    // check mm and HH
    if (
      valArr[0]
      && valArr[0].length
      && (parseInt(valArr[0], 10) < 0 || parseInt(valArr[0], 10) > 23)
    ) {
      return false;
    }

    return !(valArr[1]
      && valArr[1].length
      && (parseInt(valArr[1], 10) < 0 || parseInt(valArr[1], 10) > 59));
  }

  render() {
    return (
      <input
        name={this.props.name ? this.props.name : undefined}
        className={this.props.className}
        type={this.getType()}
        disabled={this.props.disabled}
        placeholder={this.props.placeholder}
        value={this.state.time}
        onChange={(e) => this.onChangeHandler(e.target.value)}
        onFocus={
          this.props.onFocusHandler
            ? (e) => this.props.onFocusHandler(e)
            : undefined
        }
        onBlur={
          this.props.onBlurHandler
            ? (e) => this.props.onBlurHandler(e)
            : undefined
        }
        ref={(c) => { this.inputRef = c; }}
      />
    );
  }
}

export default DatePickerTimeInput;
