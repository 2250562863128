import React from 'react';
import ReactSelect, {
  components,
  ValueContainerProps,
} from 'react-select';
import ScrollBar from 'react-perfect-scrollbar';
import selectStyles from './selectStyles';
import { Props } from 'react-select/dist/declarations/src/Select';

const Select = ({ components: comp, ...props }: Partial<Props<any, any, any>>) => (
  <ReactSelect<{label: string; value: string}>
    components={{
      MenuList,
      ValueContainer,
      ...comp,
    }}
    {...props}
    styles={selectStyles}
  />
);

const MenuList = ({ children }) => (
  <ScrollBar style={{ maxHeight: 300, overflow: 'auto' }}>
    {children}
  </ScrollBar>
);

const ValueContainer: React.FC<
  ValueContainerProps<{label: string; value: string}, false>
> = ({ children, ...props }) => {
  const { getValue, hasValue } = props;
  const values = getValue().length;
  if (!hasValue || values <= 1) {
    return (
      <components.ValueContainer {...props}>
        {children}
      </components.ValueContainer>
    );
  }
  return (
    <components.ValueContainer {...props}>
      {values}
      {' '}
      items selected
    </components.ValueContainer>
  );
};

export default Select;
