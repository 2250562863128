import startsWith from 'lodash/startsWith';
import axios from 'axios';
import get from 'lodash/get';
import { getDataAndOptionsForTokenRequest } from '../redux/auth/effects';
import {
  getCookie,
  getKeycloakLoginUrl,
  getRoles,
  getTokenExpiryTimestamp,
  hasValidAuthToken,
  hasValidRefreshToken,
  removeCookie,
  setCookie,
} from './auth';
import getEnvVal from '../constants/getEnvVal';
import client from '../apolloClient';
import websocketClient from '../websocketClient';
import debug from './debug';

export async function requestInterceptor(config) {
  if (!startsWith(config.url, getEnvVal('KEYCLOAK_URL'))) {
    if (!hasValidAuthToken()) {
      if (hasValidRefreshToken()) {
        await refreshToken();
      } else {
        clearOut();
      }
    }
    const token = getCookie('access_token');
    if (token) {
      config.headers = {
        ...config.headers,
        Authorization: `Bearer ${token}`,
      };
    }
  }
  return config;
}

export function responseSuccessInterceptor(response) {
  return response;
}

export async function responseErrorInterceptor(error) {
  const originalRequest = error.config;
  if (!startsWith(originalRequest.url, getEnvVal('KEYCLOAK_URL'))) {
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      if (hasValidRefreshToken()) {
        await refreshToken();
        axios.defaults.headers.common.Authorization = `Bearer ${getCookie(
          'access_token',
        )}`;
        return axios(originalRequest);
      }
      debug.error('function:responseErrorInterceptor: No valid refresh token');
      clearOut();
    }
  }
}

export const processToken = (response) => {
  const accessToken = get(response, 'data.access_token', null);
  if (accessToken) {
    setCookie('access_token', accessToken);
    setCookie('access_token_expiry', getTokenExpiryTimestamp(accessToken));
    setCookie('user_roles', JSON.stringify(getRoles(accessToken)));
    websocketClient.auth(accessToken);
  }
  const refreshToken = get(response, 'data.refresh_token', null);
  if (refreshToken) {
    setCookie('refresh_token', refreshToken);
    setCookie('refresh_token_expiry', getTokenExpiryTimestamp(refreshToken));
  }
  return accessToken;
};

export const refreshToken = async () => {
  const refreshToken = getCookie('refresh_token');
  const [data, options] = getDataAndOptionsForTokenRequest({}, refreshToken);
  try {
    const response = await axios.post(
      `${getEnvVal('KEYCLOAK_URL')}token`,
      data,
      options,
    );
    if (response) {
      return processToken(response);
    }
    debug.error('Didn\'t receive response for refreshToken()');
    clearOut();
  } catch (e) {
    debug.error('Exception in refreshToken()', e);
    clearOut();
  }
};

export const clearOut = async () => {
  debug.trace('failed to get token');
  removeCookie('refresh_token_expiry');
  removeCookie('refresh_token');
  removeCookie('access_token');
  removeCookie('access_token_expiry');
  removeCookie('user_roles');

  await client.clearStore();
  window.location = getKeycloakLoginUrl();
};

export const clearAllCookies = () => {
  debug.trace('clear all cookies');
  removeCookie('refresh_token_expiry');
  removeCookie('refresh_token');
  removeCookie('access_token');
  removeCookie('access_token_expiry');
  removeCookie('user_roles');
  removeCookie('returnUri');
};
