import React, { useEffect } from 'react';
import styled from 'styled-components';
import { connect } from 'react-redux';
import { logOut } from 'redux/auth/actions';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: calc(100vh - 13.4rem);
`;

const Logout = (props) => {
  useEffect(() => {
    props.logOut();
    // eslint-disable-next-line
  }, []);
  return (
    <Wrapper>
      <div>Logging you out...</div>
    </Wrapper>
  );
};

Logout.defaultProps = {};
Logout.propTypes = {};

export default connect(() => ({}), { logOut })(Logout);
