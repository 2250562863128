import React from 'react';
import get from './get';
import { GraphQLError } from 'graphql';
import { Operation } from '@apollo/client/link/core';

export type GraphQLErrorObject = {
  error: {
    code: number | null;
    message: string;
    path: string[];
    extensions: any[];
    distribute_trace_id: string | null;
    error: string;
  };
  variables: Record<string, any>;
  operationName: string;
};

export const makeErrorObject = (
  graphQLErrors: ReadonlyArray<GraphQLError>,
  operation: Operation,
  statusCodeInExt: number,
): GraphQLErrorObject => {
  const errorMessage: string = get(graphQLErrors, '[0].message', '') as string;
  let errorCode: number | null = statusCodeInExt || Number.parseInt(errorMessage.slice(0, 3));
  if (Number.isNaN(errorCode)) {
    errorCode = null;
  }

  return {
    error: {
      code: errorCode,
      message: errorMessage,
      path: get(graphQLErrors, '[0].path', []) as string[],
      extensions: get(graphQLErrors, '[0].extensions', []) as any[],
      distribute_trace_id: get(graphQLErrors, '[0].distribute_trace_id') as string,
      error: get(graphQLErrors, '[0].error') as string,
    },
    variables: operation.variables,
    operationName: operation.operationName,
  };
};

export const shouldShowErrorPopup = (errors) => errors.length > 0;

export const hasCriticalError = (errors) => {
  const critical = errors.filter((error) => error.error.code >= 500);

  return critical.length > 0;
};

export const getErrorMessage = (errors: GraphQLErrorObject[], isCritical) => {
  const errorCode = get(errors, '[0].error.error') as string | undefined;

  return (
    <div>
      {errors.map((error, idx) => (
        <div className="mb-2" key={idx.toString()}>
          {error?.error.message}
          {!error?.error.message && (
            <>
              Sorry! The server encountered an error
              {isCritical
                ? additionalCriticalMessage(errors)
                : additionalMessage(errors)}
              {' '}
              If the problem persists, report your problem
              {' '}
              {errorCode && (
                <>
                  and mention the error code:
                  {' '}
                  <code>{errorCode}</code>
                </>
              )}
            </>
          )}
        </div>
      ))}
    </div>
  );
};

export const getErrorCodes = (errors) => 'placeholder-codes';

const additionalCriticalMessage = (errors) => {
  const messages: string[] = [];
  if (hasPagesError(errors)) {
    messages.push('load your navigation pane');
  }

  if (messages.length) {
    const commaSeparated = messages.slice(0, -2).join(', ');
    const andSeparated = messages.slice(-2).join(' and ');
    return `. We couldn't ${
      commaSeparated ? `${commaSeparated}, ${andSeparated}` : andSeparated
    }.`;
  }
  return '.';
};

export const additionalMessage = (errors) => {
  const messages: string[] = [];
  if (hasUserDetailsQueryError(errors)) {
    messages.push('load your profile');
  }

  if (messages.length) {
    const commaSeparated = messages.slice(0, -2).join(', ');
    const andSeparated = messages.slice(-2).join(' and ');
    return `. We could not ${
      commaSeparated ? `${commaSeparated}, ${andSeparated}` : andSeparated
    }.`;
  }
  return '.';
};

const hasPagesError = (errors) => !!errors.filter((error) => error.operationName === 'getPages').length;

const hasUserDetailsQueryError = (errors) => !!errors.filter(
  (error) => error.operationName === 'getUserDetailsQuery',
).length;

export const getServerErrors = (errors) => {
  const err = (errors || []).filter(
    (m) => m.extensions.code < 1000 && m.message.length > 0,
  );
  return err.length > 0 ? err : null;
};

export const getUserErrors = (errors) => (errors || []).filter(
  (m) => m.extensions.code >= 50000
    && m.extensions.code < 100000
    && m.message.length > 0,
);
