import { useState } from 'react';

type StorageObject = {[key: string]: any};

function useLocalStorage<T = StorageObject>(
  storageKey: string,
  objectId?: string,
  initialValue?: T,
): [
  T | undefined,
  (value: Function | T | undefined) => void,
  {loaded: boolean; isDirty: boolean}
] {
  let wasLoaded = false;

  const setStorage = (value: T | undefined): void => {
    const stored = localStorage.getItem(storageKey);
    let save: T = stored ? JSON.parse(stored) : {};

    if (typeof value === 'undefined') {
      if (objectId) {
        delete save[objectId];
        localStorage.setItem(storageKey, JSON.stringify(save));
      } else {
        localStorage.removeItem(storageKey);
      }
      setLoaded(false);
    } else {
      if (objectId) {
        save[objectId] = value;
      } else {
        save = value;
      }
      localStorage.setItem(storageKey, JSON.stringify(save));
    }
  };

  const getStorage = (): T | undefined => {
    const stored = localStorage.getItem(storageKey);
    if (!stored) {
      return undefined;
    }

    const parsed = JSON.parse(stored);
    if (objectId) {
      return parsed[objectId] ? parsed[objectId] : undefined;
    }

    return parsed;
  };

  // State to store our value
  // Pass initial state function to useState so logic is only executed once
  const [storedValue, setStoredValue] = useState<T | undefined>(() => {
    try {
      // Get from local storage by key
      const item = getStorage(); // localStorage.getItem(key);
      // Parse stored json or if none return initialValue
      if (item) {
        wasLoaded = true;
        return item;
      }
      return initialValue;
    } catch (error) {
      // If error also return initialValue
      console.log(error);
      return initialValue || undefined;
    }
  });

  const [loaded, setLoaded] = useState<boolean>(wasLoaded);
  const [isDirty, setIsDirty] = useState<boolean>(false);

  // Return a wrapped version of useState's setter function that ...
  // ... persists the new value to localStorage.
  const setValue = (value: T | undefined | Function) => {
    try {
      // Allow value to be a function so we have same API as useState
      const valueToStore = value instanceof Function ? value(storedValue) : value;
      // Save state
      setStoredValue(valueToStore);
      // Save to local storage
      setStorage(valueToStore);
      setIsDirty(true);
    } catch (error) {
      // A more advanced implementation would handle the error case
      console.log(error);
    }
  };

  return [storedValue, setValue, { loaded, isDirty }];
}

export default useLocalStorage;
