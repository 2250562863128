import React, { Children } from 'react';

type GridProps = {
  children: React.ReactNode[];
  col: string
};

const Grid: React.FC<GridProps> = ({
  children,
  col,
}) => {
  const cols = col.split(',').map((i) => parseInt(i, 10));

  const arrays: Array<React.ReactNode[]> = [];
  const size = cols.length;
  const elements = (Children.toArray(children) as React.ReactNode[])
    .filter((child) => child !== null && child !== undefined && !(typeof child === 'string' && child.trim() === ''));

  for (let i = 0; i < elements.length; i += size) {
    arrays.push(elements.slice(i, i + size));
  }

  return (
    <div className="row">
      {elements.map((child, index) => (
        // @eslint-disable-next-line react/no-array-index-key
        <div
          className={`col-lg-${cols[index % cols.length]} col-md-${Math.min(cols[index % cols.length] * 2, 12)} col-12`}
          key={index.toString()}
        >
          {child}
        </div>
      ))}
    </div>
  );
};
export default Grid;
