import cookie from 'js-cookie';
import jwtDecode from 'jwt-decode';
import get from 'lodash/get';
import getEnvVal from '../constants/getEnvVal';
import { JwtDecodePayload } from '../types/JwtDecodePayload';

export const getKeycloakLoginUrl = (returnUrl: string | null = null) => (
  `${getEnvVal('KEYCLOAK_URL')}auth`
    + '?scope=openid+web-origins'
    + `&response_type=code&redirect_uri=${encodeURIComponent(
      getEnvVal('KEYCLOAK_REDIRECT_URI') + (returnUrl ? `?ret=${encodeURIComponent(returnUrl)}` : '') || '',
    )}&client_id=${encodeURIComponent(getEnvVal('KEYCLOAK_CLIENT_ID') || '')}`
);

export const getKeycloakLogoutUrl = (returnUrl: string | null = null) => (
  `${getEnvVal('KEYCLOAK_URL')}logout`
    + `?redirect_uri=${encodeURIComponent(
      getEnvVal('KEYCLOAK_REDIRECT_URI') + (returnUrl ? `?ret=${encodeURIComponent(returnUrl)}` : '') || '',
    )}&client_id=${encodeURIComponent(getEnvVal('KEYCLOAK_CLIENT_ID') || '')}`
);

export const setCookie = (key, value) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  cookie.set(key, value, {
    path: '/',
  });
};

/**
 * @param key string
 * @returns string
 */
// eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
export const getCookie = <T = unknown>(key): T => cookie.get(key) as T;

export const removeCookie = (key) => {
  // eslint-disable-next-line @typescript-eslint/no-unsafe-call,@typescript-eslint/no-unsafe-member-access
  cookie.remove(key, {
    expires: 1,
    path: '/',
  });
};

export const getTokenExpiryTimestamp = (token: string) => {
  const decodedToken = jwtDecode<JwtDecodePayload>(token);
  return decodedToken.exp;
};

/**
 * @param token
 * @returns {undefined|string}
 */
export const getUsername = (token?: string) => {
  const value = token || getCookie('access_token');
  if (value) {
    return jwtDecode<JwtDecodePayload>(value).preferred_username;
  }
  return undefined;
};

export const hasRole = (role: string, token?: string): boolean => {
  const roles = getRoles(token || getCookie('access_token'));
  return roles.indexOf(role) !== -1;
};

export const hasOneRole = (roles: string[], userRoles: string[]): boolean => {
  for (let i = 0; i < roles.length; i += 1) {
    if (userRoles.indexOf(roles[i]) !== -1) {
      return true;
    }
  }

  return false;
};

export const getRoles = (token: string): string[] => {
  if (token) {
    const decodedToken = jwtDecode<JwtDecodePayload>(token);
    return get(decodedToken, 'realm_access.roles') as unknown as string[];
  }
  return [];
};

export const hasValidAuthToken = () => {
  const authTokenExpiry = getCookie<number | undefined>('access_token_expiry');
  const authToken = getCookie<number | undefined>('access_token');
  if (!authTokenExpiry || !authToken) {
    return false;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return authTokenExpiry > currentTimestamp;
};

export const hasValidRefreshToken = () => {
  const refreshTokenExpiry = getCookie('refresh_token_expiry');
  const refreshToken = getCookie('refresh_token');
  if (!refreshTokenExpiry || !refreshToken) {
    return false;
  }
  const currentTimestamp = Math.floor(Date.now() / 1000);
  return refreshTokenExpiry > currentTimestamp;
};

export const isTokenExpired = (token?: string) => {
  if (!token) {
    return true;
  }
  try {
    const tokenExpiry = getTokenExpiryTimestamp(token);
    if (!tokenExpiry) {
      return true;
    }
    const currentTimestamp = Math.floor(Date.now() / 1000);
    return tokenExpiry < currentTimestamp;
  } catch (e) {
    return true;
  }
};

export const transformRequest = (jsonData = {}) => Object.entries(jsonData)
  .map((x) => `${encodeURIComponent(x[0])}=${x[1] ? encodeURIComponent(x[1].toString()) : ''}`)
  .join('&');
