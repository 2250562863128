import gql from 'graphql-tag';
import { CommonFragmentResponse } from './common';

export const loadDashboardData = gql`
  query loadDashboardData($instanceId: Int!) {
    getSystemInfo: getAutocomplete(
      instanceId: $instanceId
      code: "Reporting.SysInfo"
      filter: [{key: "@infoType", value: "SERVERINFO"}]
    ) {
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
      data {
        values
      }
    }

    getKpi: getKpiData(instanceId: $instanceId) {
      status
      code
      messages {
        level
        code
        message
      }
      errors {
        message
        extensions {
          code
        }
      }
      data {
        values: data
      }
    }
  }
`;

export type LoadDashboardDataResponse = {
  getKpi: CommonFragmentResponse & {
    data: {
      values: Array<{
        CatOrd: string;
        Category: string;
        Cntr: string;
        // eslint-disable-next-line camelcase
        Cntr_value: number;
        STAMP: string;
      }>
    }
  }
  getSystemInfo: CommonFragmentResponse & {
    data: {
      values: Array<{
        Property1: string;
        Property2: string;
        'Property Value1': string;
        'Property Value2': string;
      }>
    }
  }
};
