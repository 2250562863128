import React, { useState } from 'react';
import PropTypes from 'prop-types';
import get from 'lodash/get';
import { connect } from 'react-redux';
import { setDebugMode } from 'redux/userSettings/actions';
import { Modal } from 'react-bootstrap';
import Button from 'react-bootstrap/Button';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const iconStyle = { width: '1rem', height: '1rem', fontSize: '0.5rem' };

const DebugModal = ({
  debug, title, content, style,
}) => {
  const [show, setShow] = useState(false);

  if (!debug) {
    return <></>;
  }

  const handleClose = () => setShow(false);

  return (
    <>
      <button
        className="btn btn-theme btn-sm btn--icon"
        style={{ ...iconStyle, ...style }}
        onClick={() => setShow(true)}
      >
        <FontAwesomeIcon icon={{ prefix: 'fas', iconName: 'info' }} />
      </button>

      {show ? (
        <Modal size="lg" show={show} onHide={handleClose} centered>
          <Modal.Header closeButton closeVariant="white">
            <Modal.Title>{title}</Modal.Title>
          </Modal.Header>
          <Modal.Body>{content}</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={handleClose}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      ) : (
        <></>
      )}
    </>
  );
};

DebugModal.defaultProps = {
  title: 'Debug',
  content: '',
};

DebugModal.propTypes = {
  title: PropTypes.string,
  content: PropTypes.any.isRequired,
  style: PropTypes.object,
};

export default connect(
  (state) => ({
    debug: get(state, 'userSettings.debug'),
  }),
  { setDebugMode },
)(DebugModal);
